<template>
    <div class="seat-selection">
      <!-- <div>
        <div data-component="flight-seatmap-legends" class="Box-sc-kv6pi1-0 etPZYB">
          <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="SvgIconstyled__SvgIconStyled-sc-1i6f60b-0 cWblhD"><path d="M22.76.94a1.5 1.5 0 0 1 0 2.12l-8.839 8.84 8.84 8.838a1.5 1.5 0 0 1-2.122 2.122l-8.84-8.84-8.839 8.84A1.5 1.5 0 1 1 .84 20.738L9.678 11.9.838 3.061A1.5 1.5 0 1 1 2.96.939l8.838 8.839 8.84-8.839a1.5 1.5 0 0 1 2.122 0z"></path></svg>
          <span class="Spanstyled__SpanStyled-sc-16tp9kb-0 hVAnxv kite-js-Span " style="flex: 1 1 0%;">Unavailable</span>
        </div>
      </div> -->
      <div v-for="(row, index) in seatsData" :key="index" class="seat-row pt-2">
        <div
          v-for="seat in row.Seats"
          :key="seat.Code"
          @click="handleSeatClick(seat)"
          style="padding: 8px;"
          @mouseover="showTooltip(seat, $event)"
          @mouseleave="hideTooltip"
        >
          <!-- {{ seat.Code }} -->
          <img src="images/flight_chair_unavailable.svg" alt="unavailable" v-if="getSeatClass(seat)[1]=='unavailable'">
          <img src="images/fligh_chair_available.svg" alt="available" v-if="getSeatClass(seat)[1]=='available'">
          <img src="images/flight_chair_reserved.svg" alt="reserved" v-if="getSeatClass(seat)[1]=='reserved'">
          <img src="images/flight_chair_blocked.svg" alt="blocked" v-if="getSeatClass(seat)[1]=='blocked'">
          <img src="images/flight_chair_no_seat.svg" alt="no-seat" v-if="getSeatClass(seat)[1]=='no-seat'">
          <img src="images/flight_chair_selected.svg" alt="selected"  v-if="getSeatClass(seat)[1] === 'selected'">

          <div v-if="tooltipVisible" :style="tooltipStyle" style="border-radius: 8px; font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;">
              
            <p style="padding-bottom: 4px; margin: 0px;">Seat Code: <b style="color: #1E1E1E;">{{ current_seat?.Code }}</b></p>
            <p style="padding: 0px; margin: 0px;">Price: <b style="color: #1E1E1E;">{{ current_seat?.Currency }}. {{ current_seat?.Price }}</b></p>
          </div>
        </div>

        
      </div>

      
      <!-- <div v-if="selectedSeats.length" class="selected-seat-info">
        Selected Seats: {{ selectedSeats.join(', ') }}
      </div> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'SeatSelection',
    props: {
      seatsData: {
        type: Array,
        required: true,
      },
      numberOfPassengers: {
        type: Number,
        required: true,
      },
      flight_type_id:{
        type: String,
      },
      flight_key:{
        type: Number,
      }
    },
    data() {
      return {
        selectedSeats: [],
        tooltipVisible: false,
        tooltipStyle: {},
        currentSeat: null, // Track the seat being hovered

        leftSide: 0,
        toSide: 0,
        toolDisplay: 'none',
        current_seat: '',
      };
    },
    methods: {
      handleSeatClick(seat) {
        if (seat.AvailablityType === 1) {
          const seatIndex = this.selectedSeats.findIndex(
            (selectedSeat) => selectedSeat.Code === seat.Code
          );

          if (seatIndex !== -1) {
            this.selectedSeats.splice(seatIndex, 1);
          } else if (this.selectedSeats.length < this.numberOfPassengers) {
            this.selectedSeats.push(seat);
          }

          this.$emit('update-selected-seats', {
            selectedSeats: this.selectedSeats,
            flight_type_id: this.flight_type_id,
            flight_key: this.flight_key
          });
        }
      },
      getSeatClass(seat) {
        const availabilityClass = {
          0: 'unavailable',
          1: 'available',
          3: 'reserved',
          4: 'blocked',
          5: 'no-seat',
        };

        // return [
        //   'seat',
        //   availabilityClass[seat.AvailablityType] || 'unknown',
        //   this.selectedSeats.some((selectedSeat) => selectedSeat.Code === seat.Code) ? 'selected' : '',
        // ];
        const isSelected = this.selectedSeats.some(
          (selectedSeat) => selectedSeat.Code === seat.Code
        );

        return [
          'seat',
          isSelected ? 'selected' : availabilityClass[seat.AvailablityType] || 'unknown',
        ];
      },
      showTooltip(seat, event) {
        // console.log('this.tooltip_value:----', seat)
        // this.currentSeat = seat.Code; // Set the current seat to the hovered seat
        // this.tooltipVisible = true;
        this.tooltipStyle = {
          top: `${event.clientY - 300}px`,
          left: `${event.clientX - 150}px`,
          position: `absolute`,           
          color: `black`, 
          border: `1px black dashed`, 
          padding: `8px`,
          background: `white` 
        };

        // const x = `${event.clientY + 10}px`;
        // const y = `${event.clientX + 10}px`;

        this.tooltipVisible = true;
        // this.leftSide = x;
        // this.toSide = y;
        // this.toolDisplay = 'block';
        this.current_seat = seat;
        // console.log('this.tooltip_value:----', seat)
      },
      hideTooltip() {
        // console.log('this.hideTooltip_value:----');
        this.tooltipVisible = false;
        this.toolDisplay = 'none';
        this.currentSeat = null; // Clear the current seat
      },
    },
  };
  </script>
  
  <style scoped>
  .seat-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .seat-row {
    display: flex;
  }
  
  .seat {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .seat.unavailable {
    background-color: gray;
  }
  .seat.available {
    background-color: green;
  }
  .seat.reserved {
    background-color: orange;
  }
  .seat.blocked {
    background-color: red;
  }
  .seat.no-seat {
    background-color: transparent;
  }
  
  .seat.selected {
    background-color: #6c757d;
    color: #fff;
  }
  
  .selected-seat-info {
    margin-top: 20px;
    font-size: 18px;
  }

  </style>
  