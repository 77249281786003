<template>
    <div>
        <section class="gray-simple pt-3">
            <div class="container">
                <div class="py-2 bg-search position-relative mb-3">
                    <div class="container">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="search-wrap position-relative">
                                    <div class="row align-items-end gy-3 gx-md-3 gx-sm-2 mb-2">
                                        <div class="col-xl-8 col-lg-7 col-md-12">
                                            <div class="row gy-3 gx-md-3 gx-sm-2">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                    <div class="form-group hdd-arrow mb-0">
                                                        <label class="text-white text-sm">TO</label>
                                                        <City :customStyle="'height:50px !important'" :initialize="search.CityName" @selectCity="selectDestination($event)" :class="{ 'is-invalid': errors?.DestinationCityId }"></City>
                                                        <span v-if="errors?.DestinationCityId" class="invalid-feedback">{{ errors?.DestinationCityId[0] }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div class="form-group hdd-arrow mb-0">
                                                        <label class="text-white text-sm">CHOOSE DATE</label>
                                                        <flatPickr
                                                            style="height: 50px !important;"
                                                            class="form-control hgt-62 fw-bold"
                                                            placeholder="Check-In & Check-Out"
                                                            :class="{ 'is-invalid': errors?.selectedDates }"
                                                            v-model="search.selectedDates"
                                                            :config="{mode:'range', minDate: 'today' }"
                                                            @onChange="getSelectedDates"
                                                        ></flatPickr>
                                                        <span v-if="errors?.selectedDates" class="invalid-feedback">{{ errors?.selectedDates[0] }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <div class="row align-items-end gy-3 gx-md-3 gx-sm-2">
                                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                    <div class="form-group mb-0">
                                                        <label class="text-white text-sm">ROOMS & GUESTS</label>
                                                        <div class="booking-form__input guests-input mixer-auto dropdown guest-selector me-2" >
                                                            <button name="guests-btn" id="dropdownGuest" data-bs-auto-close="outside" data-bs-toggle="dropdown" class="form-guest-selector form-control selection-result"  style="height: 50px !important;">
                                                                <span v-if="search.NoOfAdults <= 1">{{ search.NoOfAdults }} Adult, </span>
                                                                <span v-if="search.NoOfAdults > 1">{{ search.NoOfAdults }} Adults, </span>
                                                                <span v-if="search?.NoOfChild == 1">1 Child, </span>
                                                                <span v-if="search?.NoOfChild > 1">{{search?.NoOfChild}} Children, </span>
                                                                <span v-if="search?.NoOfRooms == 1">1 Room</span>
                                                                <span v-if="search?.NoOfRooms > 1">{{search?.NoOfRooms}} Rooms</span>
                                                            </button>
                                                            <ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
                                                                <li class="d-flex justify-content-between">
                                                                    <div  class="hstack gap-1 align-items-center">
                                                                        <h6 class="mb-0" style="color: black;">Rooms</h6>
                                                                    </div>
                                                                    <div class="hstack gap-1 align-items-center">
                                                                        <button @click="updatePaxRooms('subtract')" type="button" class=" p-0 mb-0">
                                                                            <span class="guests-input__ctrl minus" id="room-subs-btn">
                                                                                <i class="fa-solid fa-minus"></i>
                                                                            </span>										
                                                                        </button>
                                                                        <span id="guests-count-room">{{ search.NoOfRooms }}</span>
                        
                                                                        <button @click="updatePaxRooms('add')" type="button" class="btn btn-link p-0 mb-0">
                                                                            <span class="guests-input__ctrl plus" id="room-add-btn">
                                                                                <i class="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                                <li class="dropdown-divider"></li>
                                                                <li v-for="room,key in search.PaxRooms" :key="key">
                                                                    <div class="d-flex justify-content-between">
                                                                        <div>
                                                                            <h6 class="mb-0" style="color:black">Adults</h6>
                                                                            <small class="fs-12" >Ages 13 or above</small>
                                                                            <div class="d-flex align-items-center mt-2">
                                                                                <button @click="changePassengersCount('Adult','-', room)" class="p-0 mb-0 text-center">
                                                                                    <span class="guests-input__ctrl minus" id="adults-subs-btn">
                                                                                        <i class="fa-solid fa-minus"></i>
                                                                                    </span>
                                                                                </button>
                                                                                <span id="guests-count-adults">{{ room.Adults }}</span>
                                                                                <button @click="changePassengersCount('Adult','+', room)" type="button" class="text-center p-0 mb-0">
                                                                                    <span class="guests-input__ctrl plus" id="adults-add-btn">
                                                                                        <i class="fa-solid fa-plus"></i>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h6 class="mb-0" style="color:black">Children</h6>
                                                                            <small class="fs-12">Ages less than 13 years</small>
                                                                            <div class="d-flex align-items-center mt-2">
                                                                                <button @click="changePassengersCount('Child','-', room)" class="p-0 mb-0 text-center">
                                                                                    <span class="guests-input__ctrl minus" id="children-subs-btn">
                                                                                        <i class="fa-solid fa-minus"></i>
                                                                                    </span>													
                                                                                </button>
                                                                                <span id="guests-count-children"> {{ room.Children }}</span>
                                                                                <button @click="changePassengersCount('Child','+', room)" type="button" class="text-center p-0 mb-0">
                                                                                    <span class="guests-input__ctrl plus" id="children-add-btn">
                                                                                        <i class="fa-solid fa-plus"></i>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>															
                                                                    <div class="mt-2" v-if="room.Children>0">
                                                                        <small class="fs-12">Children ages less than 13 years</small>
                                                                        <div class="row g-2">
                                                                            <div v-for="child, child_key in room.Children" :key="child_key" class="col-6" >
                                                                                <select class="form-control" v-model="room.ChildrenAges[child_key]" :class="{'is-invalid': errors.ChildNo}" style="height: 35px !important;">
                                                                                    <option value="">Select <i class="fas fa fa-angle-down"></i></option>
                                                                                    <option value=0><1</option>
                                                                                        <option :value="num" v-for="num, key in 13" :key="key">{{ num }}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>	
                                                                    </div>
                                                                    <li class="dropdown-divider"></li>
                                                                </li>											
                                                            </ul>	
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                    <div class="form-group mb-0">
                                                        <button @click.prevent="resetSearchPage()" type="button" class="btn hgt-50 btn-primary full-width fw-medium">Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                    <!-- Sidebar -->
                    <div class="col-xl-3 col-lg-4 col-md-12">
                        <div class="filter-searchBar bg-white rounded-3">
                            <div class="filter-searchBar-head border-bottom">
                                <div class="searchBar-headerBody d-flex align-items-start justify-content-between px-3 py-3">
                                    <div class="searchBar-headerfirst">
                                        <h6 class="fw-bold fs-5 m-0">Filters</h6>
                                    </div>
                                    <div class="searchBar-headerlast text-end">
                                        <a href="javascript:void(0)" class="text-md fw-medium text-primary active" @click="clearAll()">Clear All</a>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-searchBar-body">
                                <div class="searchBar-single px-3 py-3 border-bottom">
                                    <div class="searchBar-single-title d-flex mb-3">
                                        <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Hotel name search</h6>
                                    </div>
                                    <div class="searchBar-single-wrap search-box">
                                        <input type="text" class="form-control" placeholder="Type keyword and press enter" v-model="search.Filters.HotelName" @keypress.enter="resetSearchPage()">
                                        <i class="ri-search-2-line"></i>
                                    </div>
                                </div>

                                <div class="searchBar-single px-3 py-3 border-bottom">
                                    <div class="searchBar-single-title d-flex mb-3">
                                        <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Popular Filters</h6>
                                    </div>
                                    <div class="searchBar-single-wrap">
                                        <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2 mb-0">
                                            <li class="col-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="refundable" v-model="search.Filters.Refundable" />
                                                    <label class="form-check-label" for="refundable">Refundable</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="searchBar-single px-3 py-3 border-bottom">
                                    <div class="searchBar-single-title d-flex mb-3">
                                        <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Meal Type</h6>
                                    </div>
                                    <div class="searchBar-single-wrap">
                                        <div class="radio-group">
                                            <label class="radio-label"> 
                                                <input type="radio" value="0" v-model="search.Filters.MealType"/>&nbsp;&nbsp;All
                                            </label>
                                            
                                            <label class="radio-label">
                                                <input type="radio" value="1" v-model="search.Filters.MealType"/>&nbsp;&nbsp;With Meal
                                            </label>
                                
                                            <label class="radio-label">
                                                <input type="radio" value="2" v-model="search.Filters.MealType"/>&nbsp;&nbsp;Room Only
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="searchBar-single px-3 py-3 border-bottom">
                                    <div class="searchBar-single-title d-flex mb-3">
                                        <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Star Ratings</h6>
                                    </div>
                                    <div class="searchBar-single-wrap">
                                        <div class="radio-group">
                                            <label class="radio-label" v-for="star in stars" :key="star"> 
                                                <input type="radio" :value="star" v-model="search.Filters.StarRating"/>&nbsp; 
                                                <i class="fa fa-star text-warning" v-for="star in parseInt(star)" :key="star"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>

                    <!-- All List -->
                    <div class="col-xl-9 col-lg-8 col-md-12">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-xl-4 col-lg-4 col-md-4">
                                <h5 class="fw-bold fs-6 mb-lg-0 mb-3" v-if="error" style="color:red"> {{ error }} </h5>
                                <h5 class="fw-bold fs-6 mb-lg-0 mb-3" v-else><span>Showing {{ search.from }} to {{ search.to }} of {{ search.totalRows }} search result</span></h5>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-12">
                                <div class="d-flex align-items-center justify-content-start justify-content-lg-end flex-wrap">
                                    <div class="flsx-first mt-sm-0 mt-2">
                                        <ul class="nav nav-pills nav-fill p-1 small lights blukker bg-white rounded-3 shadow-sm" id="filtersblocks" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active rounded-3" id="trending" data-bs-toggle="tab" type="button" role="tab" aria-selected="true">Low Price</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link rounded-3" id="mostpopular" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">High Price</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center g-4 mt-2">
                            <!-- Single List -->
                            <div class="col-xl-12 col-lg-12 col-12" v-for="hotel,key in hotels" :key="key">
                                <div class="card list-layout-block rounded-3 p-3">
                                    <a href="javascript:void(0)" class="anchor_tag_border">
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-3 col-md" @click="showHotelDetails(hotel)">
                                                <div class="cardImage__caps rounded-2 overflow-hidden h-1001" v-if="hotel.hotel_details?.Images?.length" >
                                                    <img v-if="hotel.hotel_details.Images" class="img-fluid hotel_list_img" :src="hotel.hotel_details.Images[0]" alt="image"/>
                                                    <img v-else class="img-fluid hotel_list_img" src="../../../public/images/hotel-dummy.jpg" alt="image"/>
                                                </div>
                                            </div>

                                            <div class="col-xl col-lg col-md">
                                                <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-inline-block" @click="showHotelDetails(hotel, false)">
                                                            <div v-if="hotel.hotel_details?.HotelRating">
                                                            <i class="fa fa-star text-warning text-xs" v-for="n in hotel.hotel_details?.HotelRating" :key="n"></i>
                                                            </div>
                                                            <h4 class="fs-5 fw-bold mb-1">{{  hotel.hotel_name }}</h4>
                                                        </div>
                                                        <div class="row align-items-center justify-content-start justify-content-md-end gx-2 ">
                                                        <div class="col-auto text-start text-md-end">
                                                            
                                                            <div class="text-md text-dark fw-medium"><a href="javascript:void(0)" data-bs-toggle="modal" @click="showHotelDetails(hotel, true)" data-bs-target="#descriptionModal">Description</a></div>
                                                            <div class="text-md text-muted-2"><i class="fa-solid fa-phone ms-2"></i> {{ hotel.hotel_details?.PhoneNumber }}</div>
                                                        </div>
                                                        <div class="col-auto" @click="showHotelDetails(hotel, false)">
                                                            <div class="square--40 rounded-2 bg-primary text-light">{{ hotel.hotel_details?.HotelRating }}</div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div @click="showHotelDetails(hotel, false)">
                                                        <ul class="row gx-2 p-0 excortio mb-0">
                                                            <li class="col-auto">
                                                                <span class="text-muted-2 text-md">{{ hotel.hotel_details?.Address }}</span>
                                                            </li>
                                                        </ul>

                                                        <div v-if="hotel.hotel_details.HotelFacilities?.length">
                                                        <div class="detail ellipsis-container mt-1">
                                                            <span class="ellipsis" v-for="facility, ht_key in hotel.hotel_details.HotelFacilities.slice(0, 7)" :key="ht_key">{{ facility }}</span>
                                                        </div>
                                                    </div>
                                                
                                                    </div>
                                                    
                                                    <div class="position-relative mt-auto full-width d-flex justify-content-between" @click="showHotelDetails(hotel, false)">
                                                        <div v-if="hotel.search_result?.Rooms?.length">
                                                            <div class="position-relative mt-3" v-if="hotel.search_result.Rooms[0].Name?.length">
                                                                <div class="fw-medium text-dark">{{ hotel.search_result.Rooms[0].Name[0] }}</div>
                                                            </div>
                                                        </div>
                                                        <div v-if="hotel.search_result?.Rooms?.length">
                                                            <div class="text-dark fw-bold fs-5 text-end"><span class="text-muted-2">{{ hotel.search_result?.Currency }}</span>&nbsp;{{ hotel.search_result?.Rooms[0].DayRates[0][0].BasePrice }}</div>
                                                            <div class="text-muted-2 text-sm" style=" margin-top: -5px;">Exclusive of taxes for {{  no_of_days }} Nights</div>
                                                        </div>
                                                        <div v-else>
                                                            <div class="text-muted-2 text-sm" style="font-size: 16pt;">Sold out</div>
                                                            <div class="text-muted-2 text-sm">No room type available, please adjust your check-in date</div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-4">
                                <span>Showing {{ search.from }} to {{ search.to }} of {{ search.totalRows }}</span>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-8">
                                <div style="float: right;">
                                    <Pagination :maxPage="search.maxPage" :totalPages="search.lastPage" :currentPage="search.page"
                                        @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade modal-lg" id="descriptionModal" tabindex="-1" aria-labelledby="descriptionModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="descriptionModalLabel">Description</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p v-html="description" style="text-align: justify"></p>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Search from "@/components/Search.vue";
    let City = require("@/components/City.vue").default;
    import Pagination from "@/components/Pagination";
    export default {
        components: { flatPickr, Search, City, Pagination },
        data() {
            return {
                showTooltip: false,
                stars: [5, 4, 3],
                three_star: 3,
                four_star: 4,
                five_star: 5,
                selectedMealType:'',
                description:'',
                search: {
                    CheckIn: "",
                    CheckOut: "",
                    CountryCode: "IN",
                    DestinationCityId: "",
                    CityName: "",
                    CityId: "",
                    CityCode: "",
                    GuestNationality: "IN",
                    NoOfRooms: 1,
                    NoOfAdults: 1,
                    NoOfChild: 0,
                    PaxRooms: [],
                    ResponseTime: "1",
                    HotelCodes: "",
                    IsDetailedResponse: true,
                    Filters: {
                        MealType: 0,
                        NoOfRooms: 10,
                        OrderBy: 0,
                        Refundable: false,
                        StarRating: 0,
                        HotelName:''
                    },
                    selectedDates: [],
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 100,
                    totalRows : '',
                    lastPage : '',
                    from : '',
                    maxPage : '',
                    page:'',
                    to:''
                },
                filtered_hotels:[],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 100,
                    city_code:'',
                    totalRows : '',
                    lastPage : '',
                    from : '',
                    maxPage : ''
                },
                HotelCodeList: [],
                hotels:[],
                cities: [],
                errors: [],
                no_of_days:'',
                error:'',
                Children:[],
                hotel_code_list:true,
                hotel_details:[],
                search_result:[]
            };
        },
        mounted(){
            this.search = this.$store.getters.hotel_search
            this.daysCount()
        },  
        
        methods: {
            toggleTooltip() {
                this.showTooltip = !this.showTooltip;
            },

            updatePaxRooms(operand){
				if(operand == 'add' && this.search.NoOfRooms < 3){
					this.search.PaxRooms.push({
						Adults:2,
						Children:0,
						ChildrenAges:[]
					})
					this.search.NoOfRooms++
					this.search.NoOfAdults += 2
				}
				if(operand == 'subtract' &&  this.search.NoOfRooms > 1){
					this.search.PaxRooms.pop()
					this.search.NoOfRooms--
					this.updateAdultsChildrenCount()
				}
			},

            updateAdultsChildrenCount(){
				let adults = 0
				let children = 0
				this.search.PaxRooms.map(function(room){
					adults += room.Adults
					children += room.Children
				})
				this.search.NoOfAdults = adults
				this.search.NoOfChild = children
			},

			changePassengersCount(pax_type, sign, room) {
				if (pax_type === 'Adult' && sign === "+" && room.Adults<8) {
					this.search.NoOfAdults++
					room.Adults++
				} 
				if (pax_type === 'Adult' && sign === "-" && room.Adults > 1) {
					this.search.NoOfAdults--
					room.Adults--
				}

				if (pax_type === 'Child' && sign === "+" && room.Children<4) {
					this.search.NoOfChild++;
					room.Children++
					room.ChildrenAges.push(1)
				} 
				if (pax_type === 'Child' && sign === "-" && room.Children>0) {
					this.search.NoOfChild--;
					room.Children--
					room.ChildrenAges.pop()
				}
			},

            getSeatClass(children) {
				if(children==1){
					return 'col-12'
				}
				else if(children==2){
					return 'col-6'
				}
				else if(children>=3){
					return 'col-4'
				}
			},

            clearAll(){
                this.search.Filters.MealType  = 0
                this.search.Filters.NoOfRooms = 0
                this.search.Filters.OrderBy = 0
                this.search.Filters.Refundable = false
                this.search.Filters.StarRating = 0
            },
            
            getBasePrice(hotel){
                let vm = this 
                let search_res = vm.hotels.search_result.filter(function(element){
                    return element.HotelCode == hotel.HotelCode
                })
                if(search_res.length){
                    if(search_res[0].Rooms.length){
                        hotel.room_name = search_res[0].Rooms[0].Name[0] 
                        if(search_res[0].Rooms[0].DayRates.length){
                            if(search_res[0].Rooms[0].DayRates[0].length){
                                hotel.BasePrice = search_res[0].Rooms[0].DayRates[0][0].BasePrice
                            }
                        }
                    }
                }
                return false
            },
            showHotelDetails(hotel, description){
                let vm = this
                if(hotel.search_result && !description){
                    vm.$store.dispatch("setHotel", null)
                    vm.$store.dispatch("setHotel", hotel)
                    vm.$router.push("/hotel_detail")
                }
                if(hotel.hotel_details?.Description && description){
                    this.description = hotel.hotel_details.Description
                }
            },
            getInclusions(inclusions){
                return inclusions.split(",")
            },
            daysCount(){
                let vm = this 
                let startDate = moment(this.search.CheckIn)
                let endDate = moment(this.search.CheckOut)
                this.no_of_days = endDate.diff(startDate, 'days');
                vm.paginateHotels();
            },
            generateToken() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "generateToken" })
                    .then(function (response) {
                        vm.search.EndUserIp = response.data.EndUserIp;
                        vm.search.TokenId = response.data.Token;
                        vm.$store.dispatch("setHotelSearch", vm.search);
                        vm.searchHotels();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getHotelCodes() {
                let vm = this
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "TBOHotelCodeList", data: { CityCode: vm.search.CityCode } })
                    .then(function (response) {
                        loader.hide();
                        vm.hotel_code_list = false
                        vm.HotelCodeList = response.data.Hotels;
                        vm.HotelCodeList.filter(function (ele) {
                            if (vm.search.HotelCodes == "") {
                                vm.search.HotelCodes = ele.HotelCode;
                            } else {
                                vm.search.HotelCodes = vm.search.HotelCodes + "," + ele.HotelCode;
                            }
                        });
                        vm.searchHotels();
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);

                    });
            },
            onPerPageChange() {
                this.search.page = 1;
                this.paginateHotels();
            },
            onPageChange(page) {
                this.search.page = page;
                this.paginateHotels();
            },
            
            resetSearchPage(){
                this.search.page = 1
                this.paginateHotels()
            },

            paginateHotels(){
                let vm = this
                let loader = vm.$loading.show();
                vm.error = null
                vm.$store
                    .dispatch("post", { uri: "hotels/paginateHotels", data: vm.search })
                    .then(function (response) {
                        loader.hide();
                        if('hotels' in response.data){
                                vm.hotels = response.data.hotels.data
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            
                        }

                        if('hotel_details' in response.data){
                            if('HotelDetails' in response.data.hotel_details){
                                vm.hotel_details = response.data.hotel_details.HotelDetails
                            }
                        }

                        if('search_result' in response.data){
                            vm.search_result = response.data.search_result
                        }
                        vm.search.page = response.data.current_page
                        vm.search.totalRows = response.data.total
                        vm.search.lastPage = response.data.last_page
                        vm.search.from = response.data.from
                        vm.search.to = response.data.to
                        vm.search.per_page = response.data.per_page
                        vm.search.maxPage = vm.search.lastPage >= 3 ? 3 : vm.search.lastPage
                        vm.filtered_hotels = [];
                        if(vm.hotels.length){
                            vm.hotels.map(function(hotel){
                                if(vm.hotel_details.length){
                                    let hotel_details = vm.hotel_details.filter(function(hotel_detail){
                                        return hotel_detail.HotelCode == hotel.hotel_code
                                    })
                                    if(hotel_details.length){
                                        hotel.hotel_details = hotel_details[0]
                                    }
                                }
                                if(vm.search_result.length){
                                    let search = vm.search_result.filter(function(search_element){
                                        return search_element.HotelCode == hotel.hotel_code
                                    })
                                    if(search.length){
                                        hotel.search_result = search[0]
                                    }
                                }

                            })
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error)
                        vm.error = error?.response?.data?.error
                        vm.$store.dispatch("error", error?.response?.data?.error);
                    });
            },

            searchHotels() {
                let vm = this;
                vm.search.Filters.NoOfRooms = vm.search.NoOfRooms
                vm.updatePaxRooms()
            },
            selectDestination(e) {
                let vm = this;
                const city = e.target.value;
                vm.search.CountryCode = city.country_code;
                vm.search.DestinationCityId = city.city_code;
                vm.search.CityName = city.city_name;
                vm.search.CityId = city.city_code;
                vm.search.CityCode = city.city_code;
                vm.hotel_code_list = true
            },
            getSelectedDates(dates) {
                let vm = this;
                vm.search.CheckIn = moment(dates[0]).format("YYYY-MM-DD");
                vm.search.CheckOut = moment(dates[1]).format("YYYY-MM-DD");
            },
        },
    };
</script>
<style scoped>
    .booking-form__input.guests-input button {
        min-width: 3rem !important;
    }
    .guests-input button:before {
        content: none;
    }

    .guests-input button:after {
        content: none;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        color: #6c757d;
        content: "/";
    }
    .anchor_tag_border {
        text-decoration: none; /* Remove default underline */
        color: inherit; /* Inherit color from parent */
        border: 2px solid transparent; /* Default transparent border */
        transition: border-color 0.3s ease; /* Smooth transition for border color */
        padding:5px;
    }

    .anchor_tag_border:hover {
        border-color: blue; /* Blue border on hover */
    }

    .radio-label {
        margin-right: 20px; /* Adjust the margin value to create space */
        font-family: Arial, sans-serif;
        font-size: 16px;
    }
    .radio-group{
        display: flex;
        flex-direction: column; /* Arrange items vertically */
        gap: 10px; /* Space between radio buttons */
    }
    .filter-searchBar {
        position: sticky;
        top: 6rem;
        z-index: 7;
    }

    .detail.ellipsis-container .ellipsis {
    padding: 4px 8px;
  
}
.hotel_list_img{
    width: 100%; 
    height: 250px;
     object-fit: cover;
}
</style>
