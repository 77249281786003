<template>
	<div>
    	<section>
			<div class="container">
				<div class="row mb-5">
					<div class="col-xl-10 mx-auto text-center">
						<h4>Terms of Services</h4>
					</div>
				</div>
			</div>
    	</section>
	</div>
</template>
<script>
    export default {
        mounted() {
            window.scrollTo(0, 0);
        },
    };
</script>
