<template>
    <section class="py-4 gray-simple position-relative">
        <div class="container">
            <div class="row align-items-start">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="card mb-3">
                        <div class="car-body px-xl-5 px-lg-4 py-lg-5 py-4 px-2">
                            <div class="d-flex align-items-center justify-content-center mb-3">
                                <div class="square--80 circle text-light bg-success"><i class="fa-solid fa-check-double fs-1"></i></div>
                            </div>
                            <div class="d-flex align-items-center justify-content-center flex-column text-center mb-4">
                                <h3 class="mb-0">Your bus was confirmed successfully!</h3>
                                <p class="text-md mb-0">Booking detail send to: <span class="text-primary">{{booking?.Passenger?.email  }}</span></p>
                            </div>
                            <div class="row gx-3 align-items-center justify-content-center mb-4">
                                                <div class="col-auto">
                                                    <div class="text-dark fw-medium">{{search?.OriginName  }}</div>
                                                    <div class="text-dark fw-bold">{{getTime(search?.BoardingPointTime,'time') }}</div>
                                                    <div class="text-muted text-sm fw-medium">{{getTime(search?.BoardingPointTime,'day') }}</div>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <div class="flightLine return">
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                    <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="text-dark fw-medium">{{search?.DestinationName  }}</div>
                                                    <div class="text-dark fw-bold">{{getTime(search?.DroppingPointTime,'time') }}</div>
                                                    <div class="text-muted text-sm fw-medium">{{getTime(search?.DroppingPointTime,'day') }}</div>
                                                </div>
                                            </div>
                            <div class="d-flex align-items-center justify-content-center flex-column mb-4">
                                <div class="border br-dashed full-width rounded-2 p-3 pt-0">
                                    <ul class="row align-items-center justify-content-start g-3 m-0 p-0">
                                        <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Order Invoice</p>
                                                <p class="text-muted mb-0 lh-2">{{ booking?.bus_result?.InvoiceNumber }}</p>
                                            </div>
                                        </li>
                                        <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Ticket No.</p>
                                                <p class="text-muted mb-0 lh-2">{{ booking?.bus_result?.TicketNo }}</p>
                                            </div>
                                        </li> 
                                        <!--<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Date</p>
                                                <p class="text-muted mb-0 lh-2">24 Aug 2023</p>
                                            </div>
                                        </li> 
                                          <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Total Amount</p>
                                                <p class="text-muted mb-0 lh-2">$772.40</p>
                                            </div>
                                        </li>
                                        <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Payment Mode</p>
                                                <p class="text-muted mb-0 lh-2">Visa Card</p>
                                            </div>
                                        </li> -->
                                        <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Email</p>
                                                <p class="text-muted mb-0 lh-2">{{booking?.Passenger?.email  }}</p>
                                            </div>
                                        </li>
                                        <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Mobile No. </p>
                                                <p class="text-muted mb-0 lh-2">{{booking?.Passenger?.mobile_no  }}</p>
                                            </div>
                                        </li>
                                        <!-- <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Phone</p>
                                                <p class="text-muted mb-0 lh-2">9584563625</p>
                                            </div>
                                        </li>
                                        <li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                            <div class="d-block">
                                                <p class="text-dark fw-medium lh-2 mb-0">Email</p>
                                                <p class="text-muted mb-0 lh-2">paythemezhub@gmail.com</p>
                                            </div>
                                        </li> -->
                                        <!-- <li class="col-xl-12">
                                            <div class="row gx-3 align-items-center justify-content-center">
                                                <div class="col-auto">
                                                    <div class="text-dark fw-medium">{{search?.OriginName  }}</div>
                                                    <div class="text-dark fw-bold">{{getTime(search?.BoardingPointTime,'time') }}</div>
                                                    <div class="text-muted text-sm fw-medium">{{getTime(search?.BoardingPointTime,'day') }}</div>
                                                </div>
                                                <div class="col-6 text-center">
                                                    <div class="flightLine return">
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                    <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="text-dark fw-medium">{{search?.DestinationName  }}</div>
                                                    <div class="text-dark fw-bold">{{getTime(search?.DroppingPointTime,'time') }}</div>
                                                    <div class="text-muted text-sm fw-medium">{{getTime(search?.DroppingPointTime,'day') }}</div>
                                                </div>
                                            </div>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>

                            <div class="text-center d-flex align-items-center justify-content-center">
                                <router-link to="/bookings" class="btn btn-md btn-light-seegreen fw-semibold mx-2">My Bookings</router-link>
                                <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#invoice" class="btn btn-md btn-light-primary fw-semibold mx-2">View invoice Print</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import moment from "moment";
    export default {
        data() {
            return {
                search: {},
                booking: {},
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                window.scrollTo(0, 0);
                vm.search = vm.$store.getters.bus_search;
                vm.booking = vm.$store.getters.bus_booking;
                console.log(vm.booking)
            });
        },

        methods: {
            getTime(datetime,keyword){
                let vm=this;
                if(keyword=='date'){
                    let date=moment(datetime).format("Do MMM")
                    return date
                }
                if(keyword=='time'){
                let time=moment(datetime).format("HH:mm")
                return time
                }
                if(keyword=='cancel_date'){
                    let cancel_date=moment(datetime).format("DD MMM YYYY")
                    return cancel_date
                }
                if(keyword=='day'){
                    let day=moment(datetime).format("ddd, MMMM Do YYYY")
                    return day
                }
            },
        },
    };
</script>
<style scoped>
.flightLine.return:before {
    content: "\f5b6";
    font-family: "Font Awesome 6 Free";
	font-weight: 900;
    left: 41%;
    transform: translate(65%, -50%);
    top: 65%;
    position: absolute;
    rotate: 360deg;
    color: #a0abb8;
}
</style>