import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/auth/Profile.vue'
import Hotel from '../views/hotel/Hotel.vue'
import HotelList from '../views/hotel/HotelList.vue'
import HotelDetail from '../views/hotel/HotelDetail.vue'
import HotelBooking from '../views/hotel/HotelBooking.vue'
import HotelBookingGuest from '../views/hotel/HotelBookingGuest.vue'
import HotelBookingPayment from '../views/hotel/HotelBookingPayment.vue'
import HotelBookingSuccess from '../views/hotel/HotelBookingSuccess.vue'
import HotelBookingDetails from '../views/hotel/HotelBookingDetails.vue'

import Flight from '../views/flight/Flight.vue'
import FlightList from '../views/flight/FlightList.vue'
import FlightDetail from '../views/flight/FlightDetail.vue'
import FlightBooking from '../views/flight/FlightBooking.vue'
import FlightBookingSuccess from '../views/flight/FlightBookingSuccess.vue'

import Bus from '../views/bus/Bus.vue'
import BusList from '../views/bus/BusList.vue'
import BusDetail from '../views/bus/BusDetail.vue'
import BusBooking from '../views/bus/BusBooking.vue'
import BusBookingSuccess from '../views/bus/BusBookingSuccess.vue'

import Bookings from "@/views/Bookings.vue";
import BookingDetails from '../views/BookingDetails.vue';

import About from "@/views/About";
import Contact from "@/views/Contact";
import TermsOfServices from "@/views/TermsOfServices";
import PrivacyPolicies from "@/views/PrivacyPolicies";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/aboutus',
    name: 'About',
    component: About
  },
  {
    path: '/contactus',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/terms_of_services',
    name: 'TermsOfServices',
    component: TermsOfServices
  },
  {
    path: '/privacy_policies',
    name: 'PrivacyPolicies',
    component: PrivacyPolicies
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/hotels',
    name: 'Hotel',
    component: Hotel
  },
  {
    path: '/hotel_list',
    name: 'HotelList',
    component: HotelList
  },
  {
    path: '/hotel_detail',
    name: 'HotelDetail',
    component: HotelDetail
  },
  {
    path: '/hotel_booking',
    name: 'HotelBooking',
    component: HotelBooking
  },
  {
    path: '/hotel_booking_guest',
    name: 'HotelBookingGuest',
    component: HotelBookingGuest
  },
  {
    path: '/hotel_booking_payment',
    name: 'HotelBookingPayment',
    component: HotelBookingPayment
  },
  {
    path: '/booking_success/:BookingId/hotel',
    name: 'HotelBookingSuccess',
    component: HotelBookingSuccess
  },
  {
		path: "/bookingDetails/Hotel/:BookingId/:booking_id",
		name: "HotelBookingDetails",
		component: HotelBookingDetails,
	},
  {
    path: '/flights',
    name: 'Flight',
    component: Flight
  },
  {
    path: '/flight_list',
    name: 'FlightList',
    component: FlightList
  },
  {
    path: '/flight_detail',
    name: 'FlightDetail',
    component: FlightDetail
  },
  {
    path: '/flight_booking',
    name: 'FlightBooking',
    component: FlightBooking
  },
  {
    path: '/flight_booking_success/:booking_id',
    name: 'FlightBookingSuccess',
    component: FlightBookingSuccess
  },


  {
    path: '/buses',
    name: 'Bus',
    component: Bus
  },
  {
    path: '/bus_list',
    name: 'BusList',
    component: BusList
  },
  {
    path: '/bus_detail',
    name: 'BusDetail',
    component: BusDetail 
  },
  {
    path: '/bus_booking',
    name: 'BusBooking',
    component: BusBooking 
  },
  {
    path: '/bus_booking_success',
    name: 'BusBookingSuccess',
    component: BusBookingSuccess 
  },

  {
		path: "/bookings",
		name: "Bookings",
		component: Bookings,
	},

  {
		path: "/bookingDetails/:traveller_type/:booking_id",
		name: "BookingDetails",
		component: BookingDetails,
	},
  
]

const router = createRouter({
  history: createWebHashHistory(),
	routes,
})

export default router;
