<template>
    <section class="pt-4 pt-md-5">
        <div class="container">
            <div class="row mb-5">
                <div class="col-xl-10">
                    <!-- Title -->
                    <h1>Secure Your Retreat Now.</h1>
                    <p class="lead mb-0">Book now for diverse accommodations, personalized attention, and exclusive offerings. We ensure a memorable experience designed to cater to your preferences.</p>
                </div>
            </div>

            <!-- Contact info -->
            <div class="row g-4">
                <!-- Contact item START -->
                <div class="col-md-6 col-xl-4">
                    <div class="card card-body shadow text-center align-items-center h-100">
                        <!-- Icon -->
                        <div class="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2"><i class="bi bi-headset fs-5"></i></div>
                        <!-- Title -->
                        <h5>Call us</h5>
                        <!-- <p>24/7 Costumer Service</p> -->
                        <!-- Buttons -->
                        <div class="d-grid gap-3 d-sm-block">
                            <button class="btn btn-sm btn-primary-soft"><i class="bi bi-phone me-2"></i>+91 91102 99065</button>
                            <!-- <button class="btn btn-sm btn-light"><i class="bi bi-telephone me-2"></i>+(222)4567 586</button> -->
                        </div>
                    </div>
                </div>
                <!-- Contact item END -->

                <!-- Contact item START -->
                <div class="col-md-6 col-xl-4">
                    <div class="card card-body shadow text-center align-items-center h-100">
                        <!-- Icon -->
                        <div class="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2"><i class="bi bi-inboxes-fill fs-5"></i></div>
                        <!-- Title -->
                        <h5>Email us</h5>
                        <!-- <p>24/7 Costumer Service</p> -->
                        <!-- Buttons -->
                        <a href="#" class="btn btn-link text-decoration-underline p-0 mb-0"><i class="bi bi-envelope me-1"></i>book@fastays.com</a>
                    </div>
                </div>
                <!-- Contact item END -->

                <!-- Contact item START -->
                <div class="col-xl-4 position-relative">
                    <!-- Svg decoration -->
                    <figure class="position-absolute top-0 end-0 z-index-1 mt-n4 ms-n7">
                        <svg class="fill-warning" width="77px" height="77px">
                            <path
                                d="M76.997,41.258 L45.173,41.258 L67.676,63.760 L63.763,67.673 L41.261,45.171 L41.261,76.994 L35.728,76.994 L35.728,45.171 L13.226,67.673 L9.313,63.760 L31.816,41.258 L-0.007,41.258 L-0.007,35.725 L31.816,35.725 L9.313,13.223 L13.226,9.311 L35.728,31.813 L35.728,-0.010 L41.261,-0.010 L41.261,31.813 L63.763,9.311 L67.676,13.223 L45.174,35.725 L76.997,35.725 L76.997,41.258 Z"
                            ></path>
                        </svg>
                    </figure>

                    <div class="card card-body shadow text-center align-items-center h-100">
                        <!-- Icon -->
                        <div class="icon-lg bg-success bg-opacity-10 text-success rounded-circle mb-2"><i class="bi bi-globe2 fs-5"></i></div>
                        <!-- Title -->
                        <h5>Social media</h5>
                        <!-- <p>Sympathize Large above be to means.</p> -->
                        <!-- Buttons -->
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a target="_blank" class="btn btn-sm bg-facebook px-2 mb-0" href="https://www.facebook.com/Fastays/"><i class="fab fa-fw fa-facebook-f"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a target="_blank" class="btn btn-sm bg-instagram px-2 mb-0" href="https://www.instagram.com/fastays/"><i class="fab fa-fw fa-instagram"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a target="_blank" class="btn btn-sm bg-twitter px-2 mb-0" href="https://twitter.com/FastaysBySkygen"><i class="fab fa-fw fa-twitter"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a target="_blank" class="btn btn-sm bg-youtube px-2 mb-0" href="https://www.youtube.com/channel/UCM4mmus0oavrF7Po2KHJrCA"><i class="fab fa-fw fa-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Contact item END -->
            </div>
        </div>
    </section>
    <section class="pt-0 pt-lg-5">
        <div class="container">
            <div class="row g-4 g-lg-5 align-items-center">
                <!-- Vector image START -->
                <div class="col-lg-6 text-center">
                    <img src="assets/images/contact.png" alt="" />
                </div>
                <!-- Vector image END -->

                <!-- Contact form START -->
                <div class="col-lg-6">
                    <div class="card bg-light p-4">
                        <!-- Svg decoration -->
                        <figure class="position-absolute end-0 bottom-0 mb-n4 me-n2">
                            <svg class="fill-orange" width="104.2px" height="95.2px">
                                <circle cx="2.6" cy="92.6" r="2.6" />
                                <circle cx="2.6" cy="77.6" r="2.6" />
                                <circle cx="2.6" cy="62.6" r="2.6" />
                                <circle cx="2.6" cy="47.6" r="2.6" />
                                <circle cx="2.6" cy="32.6" r="2.6" />
                                <circle cx="2.6" cy="17.6" r="2.6" />
                                <circle cx="2.6" cy="2.6" r="2.6" />
                                <circle cx="22.4" cy="92.6" r="2.6" />
                                <circle cx="22.4" cy="77.6" r="2.6" />
                                <circle cx="22.4" cy="62.6" r="2.6" />
                                <circle cx="22.4" cy="47.6" r="2.6" />
                                <circle cx="22.4" cy="32.6" r="2.6" />
                                <circle cx="22.4" cy="17.6" r="2.6" />
                                <circle cx="22.4" cy="2.6" r="2.6" />
                                <circle cx="42.2" cy="92.6" r="2.6" />
                                <circle cx="42.2" cy="77.6" r="2.6" />
                                <circle cx="42.2" cy="62.6" r="2.6" />
                                <circle cx="42.2" cy="47.6" r="2.6" />
                                <circle cx="42.2" cy="32.6" r="2.6" />
                                <circle cx="42.2" cy="17.6" r="2.6" />
                                <circle cx="42.2" cy="2.6" r="2.6" />
                                <circle cx="62" cy="92.6" r="2.6" />
                                <circle cx="62" cy="77.6" r="2.6" />
                                <circle cx="62" cy="62.6" r="2.6" />
                                <circle cx="62" cy="47.6" r="2.6" />
                                <circle cx="62" cy="32.6" r="2.6" />
                                <circle cx="62" cy="17.6" r="2.6" />
                                <circle cx="62" cy="2.6" r="2.6" />
                                <circle cx="81.8" cy="92.6" r="2.6" />
                                <circle cx="81.8" cy="77.6" r="2.6" />
                                <circle cx="81.8" cy="62.6" r="2.6" />
                                <circle cx="81.8" cy="47.6" r="2.6" />
                                <circle cx="81.8" cy="32.6" r="2.6" />
                                <circle cx="81.8" cy="17.6" r="2.6" />
                                <circle cx="81.8" cy="2.6" r="2.6" />
                                <circle cx="101.7" cy="92.6" r="2.6" />
                                <circle cx="101.7" cy="77.6" r="2.6" />
                                <circle cx="101.7" cy="62.6" r="2.6" />
                                <circle cx="101.7" cy="47.6" r="2.6" />
                                <circle cx="101.7" cy="32.6" r="2.6" />
                                <circle cx="101.7" cy="17.6" r="2.6" />
                                <circle cx="101.7" cy="2.6" r="2.6" />
                            </svg>
                        </figure>

                        <!-- Card header -->
                        <div class="card-header bg-light p-0 pb-3" style="border-bottom: 0px;">
                            <h3 class="mb-0">Send us message</h3>
                        </div>

                        <!-- Card body START -->
                        <div class="card-body p-0">
                            <form class="row g-4">
                                <!-- Name -->
                                <div class="col-md-6">
                                    <label class="form-label">Your name *</label>
                                    <input type="text" class="form-control" />
                                </div>
                                <!-- Email -->
                                <div class="col-md-6">
                                    <label class="form-label">Email address *</label>
                                    <input type="email" class="form-control" />
                                </div>
                                <!-- Mobile number -->
                                <div class="col-12">
                                    <label class="form-label">Mobile number *</label>
                                    <input type="text" class="form-control" />
                                </div>
                                <!-- Message -->
                                <div class="col-12">
                                    <label class="form-label">Message *</label>
                                    <textarea class="form-control" rows="3"></textarea>
                                </div>
                                <!-- Checkbox -->
                                <div class="col-12 form-check ms-2">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                    <label class="form-check-label" for="exampleCheck1">
                                        By submitting this form you agree to our terms and conditions.
                                    </label>
                                </div>
                                <!-- Button -->
                                <div class="col-12">
                                    <button class="btn btn-dark mb-0" type="button">Send Message</button>
                                </div>
                            </form>
                        </div>
                        <!-- Card body END -->
                    </div>
                </div>
                <!-- Contact form END -->
            </div>
        </div>
    </section>
    <section class="pt-0 pt-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <iframe
                        class="w-100 h-300px grayscale rounded"
                        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Office No. 2545, 4th B Main Road, 3rd Stage, LIG Yelahanka 560064.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        height="500"
                        style="border: 0;"
                        aria-hidden="false"
                        tabindex="0"
                    ></iframe>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        mounted() {
            window.scrollTo(0, 0);
        },
    };
</script>
<style scoped>
.icon-lg {
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    font-size: 1.2rem;
}
.bg-facebook {
    background-color: #5d82d1;
    color: #fff;
    border: none;
}
.bg-facebook:hover, .bg-facebook:active, .bg-facebook:focus {
    background-color: #5475bc;
    color: #fff;
}
.bg-instagram {
    background: #c22b72;
    color: #fff;
    border: none;
}
.bg-twitter {
    background-color: #40bff5;
    color: #fff;
    border: none;
}
.bg-twitter:hover, .bg-twitter:active, .bg-twitter:focus {
    background-color: #3aacdd;
    color: #fff;
}
.bg-youtube:hover, .bg-youtube:active, .bg-youtube:focus {
    background-color: #e60000;
    color: #fff;
}
.bg-youtube {
    background-color: #e60000;
    color: #fff;
}
.fill-orange {
    fill: #fd7e14 !important;
}
.fill-warning {
    fill: #f7c32e !important;
}
.z-index-1 {
    z-index: 1 !important;
}
.ms-n7 {
    margin-left: -4rem !important;
}
.mt-n4 {
    margin-top: -1.6rem !important;
}
.text-orange {
    --bs-text-opacity: 1;
    color: rgba(253, 126, 20, var(--bs-text-opacity)) !important;
}
.bg-orange {
    --bs-bg-opacity: 1;
    background-color: rgba(253, 126, 20, var(--bs-bg-opacity)) !important;
}
</style>