import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VOtpInput from "vue3-otp-input";

import moment from "moment";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// createApp(App).use(store).use(router).use(LoadingPlugin).mount('#app')

const app = createApp(App);

app.use(store);
app.use(router);
app.use(LoadingPlugin);
app.component('v-otp-input', VOtpInput);
app.component('VueDatePicker', VueDatePicker);

app.config.globalProperties.$formatDate = (date, format) => {
	const options = {};
	if(date ==null || date=='') {
		return null;
	}
	else if(format === 'long') {
		options.weekday = 'short';
		options.year = 'numeric';
		options.month = 'short';
		options.day = 'numeric';
		return new Date(date).toLocaleDateString(undefined, options);
	} 
	else if (format === 'short') {
		options.year = 'numeric';
		options.month = 'short';
		options.day = 'numeric';
		return new Date(date).toLocaleDateString(undefined, options);
	}
	else if(format === 'india') {
		return moment(date).format('DD-MM-YY hh:mm A')
	}
	else if(format === 'time') {
		return moment(date).format('HH:mm:ss')
	}
	else {
		return new Date(date).toLocaleDateString(undefined, options);
	}
};

app.config.globalProperties.$dateDiff = (from_date,to_date) => {
	const start = moment(from_date);
    const end = moment(to_date);
    const duration = moment.duration(end.diff(start));
    return `${duration.hours()} hr ${duration.minutes()} min`;
};

app.mount('#app');
