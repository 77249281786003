<template>
	<div>
    	<div class="image-cover hero-header bg-white" style="background:url(img/hotel.png)no-repeat;" data-overlay="5">
			<div class="container">
				<div class="row justify-content-center align-items-center">
					<div class="col-xl-9 col-lg-10 col-md-12 col-sm-12">
						<div class="position-relative text-center mb-3">
							<h1>Book Your Hotel</h1>
						</div>
					</div>

					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div class="search-wrap with-label bg-white rounded-3 p-3">
							<div class="row gx-lg-2 g-3">
								<div class="col-xl-3 col-lg-3 col-md-12">
									<div class="row gy-3 gx-lg-2 gx-3">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
											<div class="form-group mb-0">
												<label>Leaving From</label>
												<City :initialize="search.CityName" @selectCity="selectDestination($event)" :class="{ 'is-invalid': errors?.DestinationCityId }"></City>
                                            	<span v-if="errors?.DestinationCityId" class="invalid-feedback">{{ errors?.DestinationCityId[0] }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-4 col-lg-3 col-md-12">
									<div class="row gy-3 gx-lg-2 gx-3">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
											<div class="form-group mb-0">
												<label>Choose Date</label>
												<flatPickr class="form-control hgt-62 fw-bold" placeholder="Check-In & Check-Out" :class="{ 'is-invalid': errors?.selectedDates }" v-model="search.selectedDates" :config="{mode:'range', minDate: 'today' }" @onChange="getSelectedDates"></flatPickr>
												<span v-if="errors?.selectedDates" class="invalid-feedback">{{ errors?.selectedDates[0] }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-3 col-md-12">
									<div class="row gy-3 gx-lg-2 gx-3">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
											<div class="form-group mb-0">
												<label>Rooms & Guests</label>
												<div class="booking-form__input guests-input mixer-auto dropdown guest-selector me-2">
													<button name="guests-btn" id="dropdownGuest" data-bs-auto-close="outside" data-bs-toggle="dropdown" class="form-guest-selector form-control selection-result">
														<span v-if="search.NoOfAdults <= 1">{{ search.NoOfAdults }} Adult, </span>
														<span v-if="search.NoOfAdults > 1">{{ search.NoOfAdults }} Adults, </span>
														<span v-if="search?.NoOfChild == 1">1 Child, </span>
														<span v-if="search?.NoOfChild > 1">{{search?.NoOfChild}} Children, </span>
														<span v-if="search?.NoOfRooms == 1">1 Room</span>
														<span v-if="search?.NoOfRooms > 1">{{search?.NoOfRooms}} Rooms</span>
													</button>
													<ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
														<li class="d-flex justify-content-between">
															<div  class="hstack gap-1 align-items-center">
                                                                <h6 class="mb-0" style="color: black;">Rooms</h6>
                                                            </div>
															<div class="hstack gap-1 align-items-center">
																<button @click="updatePaxRooms('subtract')" type="button" class=" p-0 mb-0">
																	<span class="guests-input__ctrl minus" id="room-subs-btn">
																		<i class="fa-solid fa-minus"></i>
																	</span>										
																</button>
																<span id="guests-count-room">{{ search.NoOfRooms }}</span>
				
																<button @click="updatePaxRooms('add')" type="button" class="btn btn-link p-0 mb-0">
																	<span class="guests-input__ctrl plus" id="room-add-btn">
																		<i class="fa-solid fa-plus"></i>
																	</span>
																</button>
															</div>
														</li>
														<li class="dropdown-divider"></li>
														<li v-for="room,key in search.PaxRooms" :key="key">
															<div class="d-flex justify-content-between">
																<div>
																	<h6 class="mb-0" style="color:black">Adults</h6>
																	<small class="fs-12" >Ages 13 or above</small>
																	<div class="d-flex align-items-center mt-2">
																		<button @click="changePassengersCount('Adult','-', room)" class="p-0 mb-0 text-center">
																			<span class="guests-input__ctrl minus" id="adults-subs-btn">
																				<i class="fa-solid fa-minus"></i>
																			</span>
																		</button>
																		<span id="guests-count-adults">{{ room.Adults }}</span>
																		<button @click="changePassengersCount('Adult','+', room)" type="button" class="text-center p-0 mb-0">
																			<span class="guests-input__ctrl plus" id="adults-add-btn">
																				<i class="fa-solid fa-plus"></i>
																			</span>
																		</button>
																	</div>
																</div>
																<div>
																	<h6 class="mb-0" style="color:black">Children</h6>
																	<small class="fs-12">Ages less than 13 years</small>
																	<div class="d-flex align-items-center mt-2">
																		<button @click="changePassengersCount('Child','-', room)" class="p-0 mb-0 text-center">
																			<span class="guests-input__ctrl minus" id="children-subs-btn">
																				<i class="fa-solid fa-minus"></i>
																			</span>													
																		</button>
																		<span id="guests-count-children"> {{ room.Children }}</span>
																		<button @click="changePassengersCount('Child','+', room)" type="button" class="text-center p-0 mb-0">
																			<span class="guests-input__ctrl plus" id="children-add-btn">
																				<i class="fa-solid fa-plus"></i>
																			</span>
																		</button>
																	</div>
																</div>
															</div>															
															<div class="mt-2"v-if="room.Children>0">
																<small class="fs-12">Children ages less than 13 years</small>
																<div class="row g-2">
																	<div v-for="child, child_key in room.Children" :key="child_key" class="col-6" >
																		<select class="form-control" v-model="room.ChildrenAges[child_key]" :class="{'is-invalid': errors.ChildNo}" style="height: 35px !important;">
																			<option value="">Select <i class="fas fa fa-angle-down"></i></option>
																			<option value=0><1</option>
																				<option :value="num" v-for="num, key in 13" :key="key">{{ num }}</option>
																		</select>
																	</div>
																</div>	
															</div>
															<li class="dropdown-divider"></li>
														</li>											
													</ul>	
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-2 col-md-12">
									<div class="form-group mb-0">
										<button type="submit" @click.prevent="searchHotels()" class="btn btn-primary full-width fw-medium"><i class="fa-solid fa-magnifying-glass me-2"></i>Search</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ================================ Article Section Start ======================================= -->
		<!-- ============================ Features Start ================================== -->
    <section class="border-bottom">
      <div class="container">
        <div class="row align-items-center justify-content-between g-4">

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-sack-dollar fs-1 text-primary"></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Easy Booking</h4>
                <p class="m-0">Cicero famously orated against his political opponent Lucius Sergius Catilina.
                  Occasionally the first Oration against Catiline.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-umbrella-beach fs-1 text-primary"></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Best Destinations</h4>
                <p class="m-0">Cicero famously orated against his political opponent Lucius Sergius Catilina.
                  Occasionally the first Oration against Catiline.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-person-walking-luggage fs-1 text-primary"></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Travel Guides</h4>
                <p class="m-0">Cicero famously orated against his political opponent Lucius Sergius Catilina.
                  Occasionally the first Oration against Catiline.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-headset fs-1 text-primary"></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Friendly Support</h4>
                <p class="m-0">Cicero famously orated against his political opponent Lucius Sergius Catilina.
                  Occasionally the first Oration against Catiline.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- ============================ Features End ================================== -->


    <!-- ============================ Best Locations Design Start ================================== -->
    <section>
      <div class="container">

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
            <div class="secHeading-wrap text-center mb-5">
              <h2>Explore Popular Destination</h2>
              <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center gy-4 gx-3">

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-8.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>New York</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">10 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">5 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-7.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Las Vegas</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">06 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">14 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-1.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>San Antonio</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">09 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">16 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-2.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Houston</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">07 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">18 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-3.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>San Francisco</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">4 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">12 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-4.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Nashville</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">16 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">7 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-5.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Philadelphia</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">14 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">10 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src="img/city/c-6.png" class="img-fluid rounded" alt=""></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>San Diego</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">12 Destinations</span>
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">32 Hotels</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- ============================ Best Locations Design Start ================================== -->


    <!-- ============================ Popular Routes Design Start ================================== -->
    <section class="gray-simple">
      <div class="container">

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
            <div class="secHeading-wrap text-center mb-5">
              <h2>Explore Popular Routes</h2>
              <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center gy-4 gx-3">

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class=" position-absolute top-0 left-0 ms-3 mt-3 z-1">
                    <div class="label bg-success text-light d-inline-flex align-items-center justify-content-center">
                      <span class="svg-icon text-light svg-icon-2hx me-1">
                        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3"
                            d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                            fill="currentColor"></path>
                          <path
                            d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                            fill="currentColor"></path>
                        </svg>
                      </span>Featured
                    </div>
                  </div>
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-1.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>New York</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>Los Angeles</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-2.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>San Diego</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>San Jose</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class=" position-absolute top-0 left-0 ms-3 mt-3 z-1">
                    <div class="label bg-success text-light d-inline-flex align-items-center justify-content-center">
                      <span class="svg-icon text-light svg-icon-2hx me-1">
                        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3"
                            d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                            fill="currentColor"></path>
                          <path
                            d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                            fill="currentColor"></path>
                        </svg>
                      </span>Featured
                    </div>
                  </div>
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-3.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>Dallas</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>Philadelphia</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-4.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>Denver</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>Nashville</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-5.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>Chicago</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>San Francisco</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class=" position-absolute top-0 left-0 ms-3 mt-3 z-1">
                    <div class="label bg-success text-light d-inline-flex align-items-center justify-content-center">
                      <span class="svg-icon text-light svg-icon-2hx me-1">
                        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3"
                            d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                            fill="currentColor"></path>
                          <path
                            d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                            fill="currentColor"></path>
                        </svg>
                      </span>Featured
                    </div>
                  </div>
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-10.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>Houston</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>Los Angeles</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-9.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>San Antonio</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>Columbus</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                  <div class="popFlights-item-overHidden">
                    <img src="img/destination/tr-6.jpg" class="img-fluid" alt="">
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>Los Angeles</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>Canada</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">Round-trip</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">3 days</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">From</span> <span class="price">&#8377; 492</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

        </div>

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="text-center position-relative mt-5">
              <button type="button" class="btn btn-light-primary fw-medium px-5">Explore More<i
                  class="fa-solid fa-arrow-trend-up ms-2"></i></button>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- ============================ Popular Routes Design Start ================================== -->


    <!-- ============================ Flexible features End ================================== -->
    <section>
      <div class="container">
        <div class="row align-items-center justify-content-between">

          <div class="col-xl-5 col-lg-5 col-md-12 position-relative pe-xl-5 pe-lg-4 pe-md-4 pb-xl-5 pb-lg-4 pb-md-4">
            <div class="position-relative mb-lg-0 mb-4">
              <img src="img/img-3.jpg" class="img-fluid rounded-3 position-relative z-1" alt="">
              <div class="position-absolute bottom-0 start-0 z-index-1 mb-4 ms-5">
                <div class="bg-body d-flex d-inline-block rounded-3 position-relative p-3 z-2">

                  <!-- Avatar group -->
                  <div class="me-4">
                    <h6 class="fw-normal">Client</h6>
                    <!-- Avatar group -->
                    <ul class="avatar-group mb-0">
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src="img/team-1.jpg" alt="avatar">
                      </li>
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src="img/team-2.jpg" alt="avatar">
                      </li>
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src="img/team-3.jpg" alt="avatar">
                      </li>
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src="img/team-4.jpg" alt="avatar">
                      </li>
                      <li class="avatar avatar-md">
                        <div class="avatar-img circle bg-primary">
                          <span class="text-white position-absolute top-50 start-50 translate-middle small">1K+</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <!-- Rating -->
                  <div>
                    <h6 class="fw-normal mb-3">Rating</h6>
                    <h6 class="m-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6>
                  </div>
                </div>
              </div>
            </div>
            <figure class="position-absolute bottom-0 end-0 d-none d-md-block mb-n5 me-n4">
              <svg height="400" class="fill-primary opacity-25" viewBox="0 0 340 340">
                <circle cx="194.2" cy="2.2" r="2.2"></circle>
                <circle cx="2.2" cy="2.2" r="2.2"></circle>
                <circle cx="218.2" cy="2.2" r="2.2"></circle>
                <circle cx="26.2" cy="2.2" r="2.2"></circle>
                <circle cx="242.2" cy="2.2" r="2.2"></circle>
                <circle cx="50.2" cy="2.2" r="2.2"></circle>
                <circle cx="266.2" cy="2.2" r="2.2"></circle>
                <circle cx="74.2" cy="2.2" r="2.2"></circle>
                <circle cx="290.2" cy="2.2" r="2.2"></circle>
                <circle cx="98.2" cy="2.2" r="2.2"></circle>
                <circle cx="314.2" cy="2.2" r="2.2"></circle>
                <circle cx="122.2" cy="2.2" r="2.2"></circle>
                <circle cx="338.2" cy="2.2" r="2.2"></circle>
                <circle cx="146.2" cy="2.2" r="2.2"></circle>
                <circle cx="170.2" cy="2.2" r="2.2"></circle>
                <circle cx="194.2" cy="26.2" r="2.2"></circle>
                <circle cx="2.2" cy="26.2" r="2.2"></circle>
                <circle cx="218.2" cy="26.2" r="2.2"></circle>
                <circle cx="26.2" cy="26.2" r="2.2"></circle>
                <circle cx="242.2" cy="26.2" r="2.2"></circle>
                <circle cx="50.2" cy="26.2" r="2.2"></circle>
                <circle cx="266.2" cy="26.2" r="2.2"></circle>
                <circle cx="74.2" cy="26.2" r="2.2"></circle>
                <circle cx="290.2" cy="26.2" r="2.2"></circle>
                <circle cx="98.2" cy="26.2" r="2.2"></circle>
                <circle cx="314.2" cy="26.2" r="2.2"></circle>
                <circle cx="122.2" cy="26.2" r="2.2"></circle>
                <circle cx="338.2" cy="26.2" r="2.2"></circle>
                <circle cx="146.2" cy="26.2" r="2.2"></circle>
                <circle cx="170.2" cy="26.2" r="2.2"></circle>
                <circle cx="194.2" cy="50.2" r="2.2"></circle>
                <circle cx="2.2" cy="50.2" r="2.2"></circle>
                <circle cx="218.2" cy="50.2" r="2.2"></circle>
                <circle cx="26.2" cy="50.2" r="2.2"></circle>
                <circle cx="242.2" cy="50.2" r="2.2"></circle>
                <circle cx="50.2" cy="50.2" r="2.2"></circle>
                <circle cx="266.2" cy="50.2" r="2.2"></circle>
                <circle cx="74.2" cy="50.2" r="2.2"></circle>
                <circle cx="290.2" cy="50.2" r="2.2"></circle>
                <circle cx="98.2" cy="50.2" r="2.2"></circle>
                <circle cx="314.2" cy="50.2" r="2.2"></circle>
                <circle cx="122.2" cy="50.2" r="2.2"></circle>
                <circle cx="338.2" cy="50.2" r="2.2"></circle>
                <circle cx="146.2" cy="50.2" r="2.2"></circle>
                <circle cx="170.2" cy="50.2" r="2.2"></circle>
                <circle cx="194.2" cy="74.2" r="2.2"></circle>
                <circle cx="2.2" cy="74.2" r="2.2"></circle>
                <circle cx="218.2" cy="74.2" r="2.2"></circle>
                <circle cx="26.2" cy="74.2" r="2.2"></circle>
                <circle cx="242.2" cy="74.2" r="2.2"></circle>
                <circle cx="50.2" cy="74.2" r="2.2"></circle>
                <circle cx="266.2" cy="74.2" r="2.2"></circle>
                <circle cx="74.2" cy="74.2" r="2.2"></circle>
                <circle cx="290.2" cy="74.2" r="2.2"></circle>
                <circle cx="98.2" cy="74.2" r="2.2"></circle>
                <circle cx="314.2" cy="74.2" r="2.2"></circle>
                <circle cx="122.2" cy="74.2" r="2.2"></circle>
                <circle cx="338.2" cy="74.2" r="2.2"></circle>
                <circle cx="146.2" cy="74.2" r="2.2"></circle>
                <circle cx="170.2" cy="74.2" r="2.2"></circle>
                <circle cx="194.2" cy="98.2" r="2.2"></circle>
                <circle cx="2.2" cy="98.2" r="2.2"></circle>
                <circle cx="218.2" cy="98.2" r="2.2"></circle>
                <circle cx="26.2" cy="98.2" r="2.2"></circle>
                <circle cx="242.2" cy="98.2" r="2.2"></circle>
                <circle cx="50.2" cy="98.2" r="2.2"></circle>
                <circle cx="266.2" cy="98.2" r="2.2"></circle>
                <circle cx="74.2" cy="98.2" r="2.2"></circle>
                <circle cx="290.2" cy="98.2" r="2.2"></circle>
                <circle cx="98.2" cy="98.2" r="2.2"></circle>
                <circle cx="314.2" cy="98.2" r="2.2"></circle>
                <circle cx="122.2" cy="98.2" r="2.2"></circle>
                <circle cx="338.2" cy="98.2" r="2.2"></circle>
                <circle cx="146.2" cy="98.2" r="2.2"></circle>
                <circle cx="170.2" cy="98.2" r="2.2"></circle>
                <circle cx="194.2" cy="122.2" r="2.2"></circle>
                <circle cx="2.2" cy="122.2" r="2.2"></circle>
                <circle cx="218.2" cy="122.2" r="2.2"></circle>
                <circle cx="26.2" cy="122.2" r="2.2"></circle>
                <circle cx="242.2" cy="122.2" r="2.2"></circle>
                <circle cx="50.2" cy="122.2" r="2.2"></circle>
                <circle cx="266.2" cy="122.2" r="2.2"></circle>
                <circle cx="74.2" cy="122.2" r="2.2"></circle>
                <circle cx="290.2" cy="122.2" r="2.2"></circle>
                <circle cx="98.2" cy="122.2" r="2.2"></circle>
                <circle cx="314.2" cy="122.2" r="2.2"></circle>
                <circle cx="122.2" cy="122.2" r="2.2"></circle>
                <circle cx="338.2" cy="122.2" r="2.2"></circle>
                <circle cx="146.2" cy="122.2" r="2.2"></circle>
                <circle cx="170.2" cy="122.2" r="2.2"></circle>
                <circle cx="194.2" cy="146.2" r="2.2"></circle>
                <circle cx="2.2" cy="146.2" r="2.2"></circle>
                <circle cx="218.2" cy="146.2" r="2.2"></circle>
                <circle cx="26.2" cy="146.2" r="2.2"></circle>
                <circle cx="242.2" cy="146.2" r="2.2"></circle>
                <circle cx="50.2" cy="146.2" r="2.2"></circle>
                <circle cx="266.2" cy="146.2" r="2.2"></circle>
                <circle cx="74.2" cy="146.2" r="2.2"></circle>
                <circle cx="290.2" cy="146.2" r="2.2"></circle>
                <circle cx="98.2" cy="146.2" r="2.2"></circle>
                <circle cx="314.2" cy="146.2" r="2.2"></circle>
                <circle cx="122.2" cy="146.2" r="2.2"></circle>
                <circle cx="338.2" cy="146.2" r="2.2"></circle>
                <circle cx="146.2" cy="146.2" r="2.2"></circle>
                <circle cx="170.2" cy="146.2" r="2.2"></circle>
                <circle cx="194.2" cy="170.2" r="2.2"></circle>
                <circle cx="2.2" cy="170.2" r="2.2"></circle>
                <circle cx="218.2" cy="170.2" r="2.2"></circle>
                <circle cx="26.2" cy="170.2" r="2.2"></circle>
                <circle cx="242.2" cy="170.2" r="2.2"></circle>
                <circle cx="50.2" cy="170.2" r="2.2"></circle>
                <circle cx="266.2" cy="170.2" r="2.2"></circle>
                <circle cx="74.2" cy="170.2" r="2.2"></circle>
                <circle cx="290.2" cy="170.2" r="2.2"></circle>
                <circle cx="98.2" cy="170.2" r="2.2"></circle>
                <circle cx="314.2" cy="170.2" r="2.2"></circle>
                <circle cx="122.2" cy="170.2" r="2.2"></circle>
                <circle cx="338.2" cy="170.2" r="2.2"></circle>
                <circle cx="146.2" cy="170.2" r="2.2"></circle>
                <circle cx="170.2" cy="170.2" r="2.2"></circle>
                <circle cx="194.2" cy="194.2" r="2.2"></circle>
                <circle cx="2.2" cy="194.2" r="2.2"></circle>
                <circle cx="218.2" cy="194.2" r="2.2"></circle>
                <circle cx="26.2" cy="194.2" r="2.2"></circle>
                <circle cx="242.2" cy="194.2" r="2.2"></circle>
                <circle cx="50.2" cy="194.2" r="2.2"></circle>
                <circle cx="266.2" cy="194.2" r="2.2"></circle>
                <circle cx="74.2" cy="194.2" r="2.2"></circle>
                <circle cx="290.2" cy="194.2" r="2.2"></circle>
                <circle cx="98.2" cy="194.2" r="2.2"></circle>
                <circle cx="314.2" cy="194.2" r="2.2"></circle>
                <circle cx="122.2" cy="194.2" r="2.2"></circle>
                <circle cx="338.2" cy="194.2" r="2.2"></circle>
                <circle cx="146.2" cy="194.2" r="2.2"></circle>
                <circle cx="170.2" cy="194.2" r="2.2"></circle>
                <circle cx="194.2" cy="218.2" r="2.2"></circle>
                <circle cx="2.2" cy="218.2" r="2.2"></circle>
                <circle cx="218.2" cy="218.2" r="2.2"></circle>
                <circle cx="26.2" cy="218.2" r="2.2"></circle>
                <circle cx="242.2" cy="218.2" r="2.2"></circle>
                <circle cx="50.2" cy="218.2" r="2.2"></circle>
                <circle cx="266.2" cy="218.2" r="2.2"></circle>
                <circle cx="74.2" cy="218.2" r="2.2"></circle>
                <circle cx="290.2" cy="218.2" r="2.2"></circle>
                <circle cx="98.2" cy="218.2" r="2.2"></circle>
                <circle cx="314.2" cy="218.2" r="2.2"></circle>
                <circle cx="122.2" cy="218.2" r="2.2"></circle>
                <circle cx="338.2" cy="218.2" r="2.2"></circle>
                <circle cx="146.2" cy="218.2" r="2.2"></circle>
                <circle cx="170.2" cy="218.2" r="2.2"></circle>
                <circle cx="194.2" cy="242.2" r="2.2"></circle>
                <circle cx="2.2" cy="242.2" r="2.2"></circle>
                <circle cx="218.2" cy="242.2" r="2.2"></circle>
                <circle cx="26.2" cy="242.2" r="2.2"></circle>
                <circle cx="242.2" cy="242.2" r="2.2"></circle>
                <circle cx="50.2" cy="242.2" r="2.2"></circle>
                <circle cx="266.2" cy="242.2" r="2.2"></circle>
                <circle cx="74.2" cy="242.2" r="2.2"></circle>
                <circle cx="290.2" cy="242.2" r="2.2"></circle>
                <circle cx="98.2" cy="242.2" r="2.2"></circle>
                <circle cx="314.2" cy="242.2" r="2.2"></circle>
                <circle cx="122.2" cy="242.2" r="2.2"></circle>
                <circle cx="338.2" cy="242.2" r="2.2"></circle>
                <circle cx="146.2" cy="242.2" r="2.2"></circle>
                <circle cx="170.2" cy="242.2" r="2.2"></circle>
                <circle cx="194.2" cy="266.2" r="2.2"></circle>
                <circle cx="2.2" cy="266.2" r="2.2"></circle>
                <circle cx="218.2" cy="266.2" r="2.2"></circle>
                <circle cx="26.2" cy="266.2" r="2.2"></circle>
                <circle cx="242.2" cy="266.2" r="2.2"></circle>
                <circle cx="50.2" cy="266.2" r="2.2"></circle>
                <circle cx="266.2" cy="266.2" r="2.2"></circle>
                <circle cx="74.2" cy="266.2" r="2.2"></circle>
                <circle cx="290.2" cy="266.2" r="2.2"></circle>
                <circle cx="98.2" cy="266.2" r="2.2"></circle>
                <circle cx="314.2" cy="266.2" r="2.2"></circle>
                <circle cx="122.2" cy="266.2" r="2.2"></circle>
                <circle cx="338.2" cy="266.2" r="2.2"></circle>
                <circle cx="146.2" cy="266.2" r="2.2"></circle>
                <circle cx="170.2" cy="266.2" r="2.2"></circle>
                <circle cx="194.2" cy="290.2" r="2.2"></circle>
                <circle cx="2.2" cy="290.2" r="2.2"></circle>
                <circle cx="218.2" cy="290.2" r="2.2"></circle>
                <circle cx="26.2" cy="290.2" r="2.2"></circle>
                <circle cx="242.2" cy="290.2" r="2.2"></circle>
                <circle cx="50.2" cy="290.2" r="2.2"></circle>
                <circle cx="266.2" cy="290.2" r="2.2"></circle>
                <circle cx="74.2" cy="290.2" r="2.2"></circle>
                <circle cx="290.2" cy="290.2" r="2.2"></circle>
                <circle cx="98.2" cy="290.2" r="2.2"></circle>
                <circle cx="314.2" cy="290.2" r="2.2"></circle>
                <circle cx="122.2" cy="290.2" r="2.2"></circle>
                <circle cx="338.2" cy="290.2" r="2.2"></circle>
                <circle cx="146.2" cy="290.2" r="2.2"></circle>
                <circle cx="170.2" cy="290.2" r="2.2"></circle>
                <circle cx="194.2" cy="314.2" r="2.2"></circle>
                <circle cx="2.2" cy="314.2" r="2.2"></circle>
                <circle cx="218.2" cy="314.2" r="2.2"></circle>
                <circle cx="26.2" cy="314.2" r="2.2"></circle>
                <circle cx="242.2" cy="314.2" r="2.2"></circle>
                <circle cx="50.2" cy="314.2" r="2.2"></circle>
                <circle cx="266.2" cy="314.2" r="2.2"></circle>
                <circle cx="74.2" cy="314.2" r="2.2"></circle>
                <circle cx="290.2" cy="314.2" r="2.2"></circle>
                <circle cx="98.2" cy="314.2" r="2.2"></circle>
                <circle cx="314.2" cy="314.2" r="2.2"></circle>
                <circle cx="122.2" cy="314.2" r="2.2"></circle>
                <circle cx="338.2" cy="314.2" r="2.2"></circle>
                <circle cx="146.2" cy="314.2" r="2.2"></circle>
                <circle cx="170.2" cy="314.2" r="2.2"></circle>
                <circle cx="194.2" cy="338.2" r="2.2"></circle>
                <circle cx="2.2" cy="338.2" r="2.2"></circle>
                <circle cx="218.2" cy="338.2" r="2.2"></circle>
                <circle cx="26.2" cy="338.2" r="2.2"></circle>
                <circle cx="242.2" cy="338.2" r="2.2"></circle>
                <circle cx="50.2" cy="338.2" r="2.2"></circle>
                <circle cx="266.2" cy="338.2" r="2.2"></circle>
                <circle cx="74.2" cy="338.2" r="2.2"></circle>
                <circle cx="290.2" cy="338.2" r="2.2"></circle>
                <circle cx="98.2" cy="338.2" r="2.2"></circle>
                <circle cx="314.2" cy="338.2" r="2.2"></circle>
                <circle cx="122.2" cy="338.2" r="2.2"></circle>
                <circle cx="338.2" cy="338.2" r="2.2"></circle>
                <circle cx="146.2" cy="338.2" r="2.2"></circle>
                <circle cx="170.2" cy="338.2" r="2.2"></circle>
              </svg>
            </figure>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="row gy-xl-5 g-4">

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-primary"><i class="fa-solid fa-gifts text-primary fs-3"></i>
                    </div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">Get Superb Deals</h5>
                    <p class="fw-light fs-6 m-0">Just fill up a page with draft copy about the client’s business and
                      they will actually read it and comment on it. They will be drawn to it, fiercely. Do it the wrong
                      way.</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-info"><i class="fa-solid fa-gifts text-info fs-3"></i></div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">100% Transparency Price</h5>
                    <p class="fw-light fs-6 m-0">Just fill up a page with draft copy about the client’s business and
                      they will actually read it and comment on it. They will be drawn to it, fiercely. Do it the wrong
                      way.</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-success"><i class="fa-solid fa-gifts text-success fs-3"></i>
                    </div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">Pure Trusted & Free</h5>
                    <p class="fw-light fs-6 m-0">Just fill up a page with draft copy about the client’s business and
                      they will actually read it and comment on it. They will be drawn to it, fiercely. Do it the wrong
                      way.</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-warning"><i class="fa-solid fa-gifts text-warning fs-3"></i>
                    </div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">Travel With Confidence</h5>
                    <p class="fw-light fs-6 m-0">Just fill up a page with draft copy about the client’s business and
                      they will actually read it and comment on it. They will be drawn to it, fiercely. Do it the wrong
                      way.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- ============================ Flexible features End ================================== -->

    <!-- ============================ Our Reviews Start ================================== -->
    <section class="gray">
      <div class="container">

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
            <div class="secHeading-wrap text-center mb-5">
              <h2>Loving Reviews By Our Customers</h2>
              <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
            </div>
          </div>
        </div>

        <div class="row align-items-center justify-content-center g-xl-4 g-lg-4 g-md-4 g-3">

          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card border-0 rounded-3">
              <div class="card-body">
                <div class="position-absolute top-0 end-0 mt-3 me-3"><span
                    class="square--40 circle text-primary bg-light-primary"><i
                      class="fa-solid fa-quote-right"></i></span></div>
                <div class="d-flex align-items-center flex-thumbes">
                  <div class="revws-pic"><img src="img/team-1.jpg" class="img-fluid rounded-2" width="80" alt="">
                  </div>
                  <div class="revws-caps ps-3">
                    <h6 class="fw-bold fs-6 m-0">Aman Diwakar</h6>
                    <p class="text-muted-2 text-md m-0">United States</p>
                    <div class="d-flex align-items-center justify-content-start">
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                    </div>
                  </div>
                </div>
                <div class="revws-desc mt-3">
                  <p class="m-0 text-md">Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,
                    sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card border-0 rounded-3">
              <div class="card-body">
                <div class="position-absolute top-0 end-0 mt-3 me-3"><span
                    class="square--40 circle text-primary bg-light-primary"><i
                      class="fa-solid fa-quote-right"></i></span></div>
                <div class="d-flex align-items-center flex-thumbes">
                  <div class="revws-pic"><img src="img/team-2.jpg" class="img-fluid rounded-2" width="80" alt="">
                  </div>
                  <div class="revws-caps ps-3">
                    <h6 class="fw-bold fs-6 m-0">Kunal M. Thakur</h6>
                    <p class="text-muted-2 text-md m-0">United States</p>
                    <div class="d-flex align-items-center justify-content-start">
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                    </div>
                  </div>
                </div>
                <div class="revws-desc mt-3">
                  <p class="m-0 text-md">Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,
                    sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card border-0 rounded-3">
              <div class="card-body">
                <div class="position-absolute top-0 end-0 mt-3 me-3"><span
                    class="square--40 circle text-primary bg-light-primary"><i
                      class="fa-solid fa-quote-right"></i></span></div>
                <div class="d-flex align-items-center flex-thumbes">
                  <div class="revws-pic"><img src="img/team-3.jpg" class="img-fluid rounded-2" width="80" alt="">
                  </div>
                  <div class="revws-caps ps-3">
                    <h6 class="fw-bold fs-6 m-0">Divya Talwar</h6>
                    <p class="text-muted-2 text-md m-0">United States</p>
                    <div class="d-flex align-items-center justify-content-start">
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                    </div>
                  </div>
                </div>
                <div class="revws-desc mt-3">
                  <p class="m-0 text-md">Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,
                    sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card border-0 rounded-3">
              <div class="card-body">
                <div class="position-absolute top-0 end-0 mt-3 me-3"><span
                    class="square--40 circle text-primary bg-light-primary"><i
                      class="fa-solid fa-quote-right"></i></span></div>
                <div class="d-flex align-items-center flex-thumbes">
                  <div class="revws-pic"><img src="img/team-4.jpg" class="img-fluid rounded-2" width="80" alt="">
                  </div>
                  <div class="revws-caps ps-3">
                    <h6 class="fw-bold fs-6 m-0">Karan Maheshwari</h6>
                    <p class="text-muted-2 text-md m-0">United States</p>
                    <div class="d-flex align-items-center justify-content-start">
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                    </div>
                  </div>
                </div>
                <div class="revws-desc mt-3">
                  <p class="m-0 text-md">Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,
                    sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="card border-0 rounded-3">
              <div class="card-body">
                <div class="position-absolute top-0 end-0 mt-3 me-3"><span
                    class="square--40 circle text-primary bg-light-primary"><i
                      class="fa-solid fa-quote-right"></i></span></div>
                <div class="d-flex align-items-center flex-thumbes">
                  <div class="revws-pic"><img src="img/team-5.jpg" class="img-fluid rounded-2" width="80" alt="">
                  </div>
                  <div class="revws-caps ps-3">
                    <h6 class="fw-bold fs-6 m-0">Ritika Mathur</h6>
                    <p class="text-muted-2 text-md m-0">United States</p>
                    <div class="d-flex align-items-center justify-content-start">
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                      <span class="me-1 text-xs text-warning"><i class="fa-solid fa-star"></i></span>
                    </div>
                  </div>
                </div>
                <div class="revws-desc mt-3">
                  <p class="m-0 text-md">Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,
                    sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
	</div>


</template>
<script>
	import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Search from "@/components/Search.vue";
	let City = require("@/components/City.vue").default;

	export default {
		components: { flatPickr, Search, City},
		data() {
            return {
				search: {
					HotelCodes:[],
                    CheckIn: "",
                    CheckOut: "",
                    CountryCode: "IN",
                    DestinationCityId: "",
                    CityName: "",
                    CityId:"",
                    CityCode:"",
                    GuestNationality: "IN",
                    NoOfRooms: 1,
                    NoOfAdults: 1,
                    NoOfChild: 0,
					EndUserIp:'',
                    TokenId:'',
					PaxRooms:[],
					selectedDates:[],
					ResponseTime: 1,
					IsDetailedResponse: true,
					Filters: {
                        MealType: 0,
                        NoOfRooms: 10,
                        OrderBy: 0,
                        Refundable: false,
                        StarRating: 0,
                        HotelName:''
                    },
					search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 100,
                    totalRows : '',
                    lastPage : '',
                    from : '',
                    maxPage : '',
                    page:'',
                    to:''
                },
				hotels:[],
				meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 100,
                },
				PaxRoom:{
					Adults:1,
					Children:0,
					ChildrenAges: []
				},
				Children:[],
                cities: [],
                errors:[],
				allocatedRooms:[]
			}
		},
		mounted() {
			this.$store.dispatch("setHotel", null)
            this.$store.dispatch("setSelectedRoom", null)
            this.$store.dispatch("setHotelSearch", null);
			this.search.PaxRooms.push({
				Adults:2,
				Children:0,
				ChildrenAges:[]
			})
			this.search.NoOfAdults = 2
        },
		methods: {
			updatePaxRooms(operand){
				if(operand == 'add' && this.search.NoOfRooms < 3){
					this.search.PaxRooms.push({
						Adults:2,
						Children:0,
						ChildrenAges:[]
					})
					this.search.NoOfRooms++
					this.search.NoOfAdults += 2
				}
				if(operand == 'subtract' &&  this.search.NoOfRooms > 1){
					this.search.PaxRooms.pop()
					this.search.NoOfRooms--
					this.updateAdultsChildrenCount()
				}
			},

            updateAdultsChildrenCount(){
				let adults = 0
				let children = 0
				this.search.PaxRooms.map(function(room){
					adults += room.Adults
					children += room.Children
				})
				this.search.NoOfAdults = adults
				this.search.NoOfChild = children
			},

			changePassengersCount(pax_type, sign, room) {
				if (pax_type === 'Adult' && sign === "+" && room.Adults<8) {
					this.search.NoOfAdults++
					room.Adults++
				} 
				if (pax_type === 'Adult' && sign === "-" && room.Adults > 1) {
					this.search.NoOfAdults--
					room.Adults--
				}

				if (pax_type === 'Child' && sign === "+" && room.Children<4) {
					this.search.NoOfChild++;
					room.Children++
					room.ChildrenAges.push(1)
				} 
				if (pax_type === 'Child' && sign === "-" && room.Children>0) {
					this.search.NoOfChild--;
					room.Children--
					room.ChildrenAges.pop()
				}
			},
		
			validateSearch(){
				let vm = this
				vm.errors = {};
				if (vm.search.DestinationCityId == "") {
						vm.errors.DestinationCityId = ["Destination id field cannot be empty"];
					}
				if (vm.search.NoOfAdults == 0 && vm.search.NoOfAdults == 0) {
					vm.errors.NoOfAdults = ["Rooms & Guests filed cannot be empty"];
				}
				if (vm.search.selectedDates.length == 0) {
					vm.errors.selectedDates = ["Checkin and Checkout filed cannot be empty"];
				} 
			},

			searchHotels(){
				let vm = this
				vm.validateSearch()
				console.log(vm.errors)
				if (Object.keys(vm.errors).length > 0) {
					console.log(vm.errors)
				}else{
					vm.$store.dispatch("setHotelSearch", vm.search)
                	vm.$router.push("/hotel_list")
				}
			},


			getSelectedDates(dates){
				let vm = this;	
				vm.search.CheckIn = moment(dates[0]).format("YYYY-MM-DD")
				vm.search.CheckOut = moment(dates[1]).format("YYYY-MM-DD")
			},

			selectDestination(e) {
				let vm = this
                const city = e.target.value
                vm.search.CountryCode =city.country_code
                vm.search.DestinationCityId =city.city_code
                vm.search.CityName =city.city_name
				vm.search.CityId =city.city_code
                vm.search.CityCode =city.city_code
            },
		}

		
	}
</script>

<style scoped>
.search-wrap .form-control {
    height: 62px!important;
}
.hgt-62 {
	height: 62px !important;
}
.hero-header {
	padding: 5em 0 5em;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

@media (min-width: 993px) {
	.hero-header {
		min-height: 400px;
		padding: 7rem 0;
		height: 0vh;
	}
}
.booking-form__input.guests-input button {
	min-width:3rem!important
}
.guests-input button:before {
    content:none;
}

.guests-input button:after {
    content:none;
}
.formcontrol {
	height: 35px !important;
}


.fs-12{
	font-size: 12px;
}
.dropdown-menu.show {
    display: block;
    width: 300px;
}
</style>