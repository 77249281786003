<template>
    	<section class="py-4 gray-simple position-relative">
			<div class="container">

				<div class="row align-items-start">
					<div class="col-xl-12 col-lg-12 col-md-12">
						<div class="card mb-3">
							<div class="car-body px-xl-5 px-lg-4 py-lg-5 py-4 px-2">

								<div class="d-flex align-items-center justify-content-center mb-3">
									<div class="square--80 circle text-light bg-success"><i class="fa-solid fa-check-double fs-1"></i>
									</div>
								</div>
								<div class="d-flex align-items-center justify-content-center flex-column text-center mb-5">
									<h3 class="mb-0">Your flight was confirmed successfully!</h3>
									<!-- <p class="text-md mb-0">Booking detail send to: <span
											class="text-primary">paythemezhub@gmail.com</span></p> -->
								</div>
								<div class="d-flex align-items-center justify-content-center flex-column mb-4" v-if="ob_book_response">

									<div class="border br-dashed full-width rounded-2 p-3 pt-0">
										<ul class="row align-items-center justify-content-start g-3 m-0 p-0">
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Order Invoice</p>
													<p class="text-muted mb-0 lh-2">{{ ob_book_response?.FlightItinerary?.InvoiceNo }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Date</p>
													<p class="text-muted mb-0 lh-2">{{ $formatDate(ob_book_response?.FlightItinerary?.InvoiceCreatedOn,'india') }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Total Amount</p>
													<p class="text-muted mb-0 lh-2">{{ ob_book_response?.FlightItinerary?.InvoiceAmount }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Payment Mode</p>
													<p class="text-muted mb-0 lh-2">Visa Card</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">First Name</p>
													<p class="text-muted mb-0 lh-2">{{ ob_book_response?.FlightItinerary?.Passenger[0]?.FirstName }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Last Name</p>
													<p class="text-muted mb-0 lh-2">{{ ob_book_response?.FlightItinerary?.Passenger[0]?.LastName }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Phone</p>
													<p class="text-muted mb-0 lh-2">{{ ob_book_response?.FlightItinerary?.Passenger[0]?.ContactNo }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Email</p>
													<p class="text-muted mb-0 lh-2">{{ ob_book_response?.FlightItinerary?.Passenger[0]?.Email }}</p>
												</div>
											</li>
										</ul>
									</div>
								</div>

								<div class="d-flex align-items-center justify-content-center flex-column mb-4" v-if="ib_book_response">
									<div class="border br-dashed full-width rounded-2 p-3 pt-0">
										<ul class="row align-items-center justify-content-start g-3 m-0 p-0">
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Order Invoice</p>
													<p class="text-muted mb-0 lh-2">{{ ib_book_response?.FlightItinerary?.InvoiceNo }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Date</p>
													<p class="text-muted mb-0 lh-2">{{ $formatDate(ib_book_response?.FlightItinerary?.InvoiceCreatedOn,'india') }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Total Amount</p>
													<p class="text-muted mb-0 lh-2">{{ ib_book_response?.FlightItinerary?.InvoiceAmount }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Payment Mode</p>
													<p class="text-muted mb-0 lh-2">Visa Card</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">First Name</p>
													<p class="text-muted mb-0 lh-2">{{ ib_book_response?.FlightItinerary?.Passenger[0]?.FirstName }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Last Name</p>
													<p class="text-muted mb-0 lh-2">{{ ib_book_response?.FlightItinerary?.Passenger[0]?.LastName }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Phone</p>
													<p class="text-muted mb-0 lh-2">{{ ib_book_response?.FlightItinerary?.Passenger[0]?.ContactNo }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Email</p>
													<p class="text-muted mb-0 lh-2">{{ ib_book_response?.FlightItinerary?.Passenger[0]?.Email }}</p>
												</div>
											</li>
										</ul>
									</div>
								</div>

								<div class="text-center d-flex align-items-center justify-content-center">
									<!-- <a href="home-2.html" class="btn btn-md btn-light-seegreen fw-semibold mx-2">Book Next Tour</a> -->
									<router-link to="/flights" class="btn btn-md btn-primary fw-semibold mx-2">Book Next Tour</router-link>
									<router-link :to="'/bookingDetails/Flight/'+booking_id" class="btn btn-md btn-primary fw-semibold mx-2">Booking Details</router-link>
									<!-- <a href="#" data-bs-toggle="modal" data-bs-target="#invoice"
										class="btn btn-md btn-light-primary fw-semibold mx-2">View invoice Print</a> -->
								</div>

							</div>
						</div>

					</div>
				</div>

			</div>
		</section>
</template>

<script>
export default {
	name: "",
	data() {
		return {
			ob_book_response:'',
			ib_book_response:'',
			booking_id:'',
		}
	},
	beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.booking_id = to.params.booking_id;
        });
    },    
	mounted(){
		window.scrollTo(0, 0);
		this.ob_book_response = this.$store.getters.flight_booking;
		this.ib_book_response = this.$store.getters.return_flight_booking;
	},

	methods:{
	}
	
}
</script>