<template>
	<div>
        <section class="gray-simple pt-3">
            <div class="container">
				<!-- <div class="py-2 bg-search position-relative mb-3">
				<div class="container">
					<div class="row justify-content-center align-items-center bg-white px-5 py-5">
						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div class="search-wrap  position-relative">
								<div class="search-upper">
									<div class="d-flex align-items-center justify-content-between flex-wrap">
										<div class="flx-start mb-sm-0 mb-2">
											<div class="form-check form-check-inline">											
												<input class="form-check-input" type="radio" @click="showMultiCity('oneway', 1)" name="trip" id="oneway" value="1"  :checked="search?.JourneyType==1">
												<label class="form-check-label" for="oneway">One Way</label>
											</div>
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="radio" @click="showMultiCity('oneway', 2)" name="trip" id="round-trip" value="2" :checked="search?.JourneyType==2">
												<label class="form-check-label" for="round-trip">Round-trip</label>
											</div>
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="radio" @click="showMultiCity('multitrip', 3)" name="trip" id="multi-city" :checked="search?.JourneyType==3">
												<label class="form-check-label" for="multi-city" >Multi-city</label>
											</div>
										</div>
										<div class="flx-end d-flex align-items-center flex-wrap">
											<div class="px-sm-2 pb-3 pt-0 ps-0 mob-full">								  
												<input readonly type="text" class="leaving form-control fw-bold select2"
													:value="search?.AdultCount + +search?.ChildCount + +search?.InfantCount + ' Passengers '" id="dropdownGuest" data-bs-auto-close="outside" data-bs-toggle="dropdown"
												/>
												<ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color:black">Adults</h6>
															<small>Ages 13 or above</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="adults-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>
															</button>
															<span id="guests-count-adults">{{ search?.AdultCount }}</span>
															<button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="adults-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color:black">Child</h6>
															<small>Ages Between 2 to 12 Years</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="children-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>													
															</button>
															<span id="guests-count-children"> {{ search?.ChildCount }}</span>
															<button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="children-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color:black">Infant</h6>
															<small>Ages Below 2 Years</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="infant-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>										
															</button>
															<span id="guests-count-room">{{ search?.InfantCount }}</span>

															<button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="infant-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
												</ul>								  
											</div>
											<div class="ps-1 pb-3 pt-0 mob-full" v-if="!status">									
												<div class="form-group hdd-arrow mb-0">
													<select class="leaving form-control fw-bold" v-model="search.FlightCabinClass">
														<option value="1">All Cabin Class</option>
														<option value="2">Economy Class</option>
														<option value="3">Premium Economy Class</option>
														<option value="4">Business Class</option>
														<option value="5">Premium Business Class</option>
														<option value="6">First Class</option>
													</select>
												</div>
											</div>
											<div class="ps-1 pb-3 pt-0 mob-full" v-if="status">									
												<div class="form-group hdd-arrow mb-0">
													<select class="leaving form-control fw-bold" v-model="search.FlightCabinClass" @change="updateFlightCabinClass(search.FlightCabinClass)">
														<option value="1">All Cabin Class</option>
														<option value="2">Economy Class</option>
														<option value="3">Premium Economy Class</option>
														<option value="4">Business Class</option>
														<option value="5">Premium Business Class</option>
														<option value="6">First Class</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-if="!status"  class="row align-items-end gy-3 gx-md-3 gx-sm-2">
									<div class="col-xl-6 col-lg-6 col-md-12">
										<div class="row gy-3 gx-lg-2 gx-3">
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
												<div class="form-group hdd-arrow mb-0">
													<FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="search?.OriginName" @selectCity="selectOrigin($event)"></FlightCity>
												</div>
												<div class="btn-flip-icon mt-md-0">
													<button class="p-0 m-0 text-primary" @click.prevent="exchangeAirport"><i class="fa-solid fa-right-left"></i></button>
												</div>
											</div>
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div class="form-groupp hdd-arrow mb-0">
													<FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="search?.DestinationName" @selectCity="selectDestination($event)"></FlightCity>
												</div>
											</div>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-md-12">
										<div class="row gy-3 gx-lg-2 gx-3">
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div class="form-group mb-0">
													<input class="form-control fw-bold choosedate " type="date" :min="mindate" v-model="search.PreferredDepartureTime" placeholder="Departure..">
												</div>
											</div>
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" v-if="search.JourneyType==2">
												<div class="form-group mb-0">
													<input class="form-control fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredArrivalTime" placeholder="Return..">
												</div>
											</div>
										</div>
									</div>
									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group mb-0">
											<button ype="button" class="btn btn-primary full-width fw-medium" @click="searchFlights">
												<i class="fa-solid fa-magnifying-glass me-2"></i>Search
											</button>
										</div>
									</div>
								</div>
								<div class="row align-items-end gy-3 gx-md-3 gx-sm-2" v-if="status">
									<div class="row" v-for="(flight, index) in multi_city_flights" :key="index" style="padding-top:10px;">									
										<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 position-relative">
											<div class="form-group hdd-arrow mb-0">
												<FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="flight.OriginName" @selectCity="selectOriginMultiple($event, index)"></FlightCity>
											</div>
											<div class="btn-flip-icon mt-md-0">
												<button @click="swapLocations(index)"  class="p-0 m-0 text-primary"><i class="fa-solid fa-right-left"></i></button>
											</div>
										</div>
										<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
											<div class="form-groupp hdd-arrow mb-0">
												<FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="flight.DestinationName" @selectCity="selectDestinationMultiple($event, index)"></FlightCity>
											</div>
										</div>
																			
										<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
											<div class="form-group mb-0">
												<input class="form-control fw-bold choosedate " type="date" :min="mindate" v-model="flight.PreferredDepartureTime" @change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)" placeholder="Departure..">
											</div>
										</div>
										<div class="col-xl-2 col-lg-2 col-md-2">
											<div class="form-group mb-0">
												<button @click="removeFlight(index)" type="button" class="btn fw-medium">
													<i class="fa-solid fa-times me-2"></i>
												</button>
											</div>
										</div>
									</div>
									<div class="row" style="text-align:right">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
											<button style="width:16%" @click="addFlight" type="button" class="btn btn-light-primary full-width fw-medium">
												<i class="fa-solid fa-add me-2"></i>Add Flight
											</button>
										</div>
									</div>
									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group mb-0">
											<button ype="button" class="btn btn-primary full-width fw-medium" @click.prevent="searchFlights">
												<i class="fa-solid fa-magnifying-glass me-2"></i>Search
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
	
			<!-- try start -->
			<div class="py-2 bg-search position-relative mb-3">
				<div class="container">
					<div class="row justify-content-center align-items-center">
						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div class="search-wrap position-relative">
								<div class="mb-3 text-center">
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" @click="showMultiCity('oneway', 1)" name="trip" id="oneway" value="1" :checked="search?.JourneyType==1" />
										<label class="form-check-label text-white" for="oneway">One Way</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" @click="showMultiCity('oneway', 2)" name="trip" id="round-trip" value="2" :checked="search?.JourneyType==2" />
										<label class="form-check-label text-white" for="round-trip">Round-trip</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" @click="showMultiCity('multitrip', 3)" name="trip" id="multi-city" :checked="search?.JourneyType==3" />
										<label class="form-check-label text-white" for="multi-city">Multi-city</label>
									</div>
								</div>
								<!-- {{status}} -->
								<!-- <div class="row align-items-end gy-3 gx-md-3 gx-sm-2 mb-2" v-if="!status">
									<div class="col  position-relative">
										<div class="form-group hdd-arrow mb-0">
											<label class="text-white text-sm">FROM</label>
											<FlightCity :customClass="[errors?.Origin ? 'is-invalid' : '']" :customStyle="'height:50px !important'" :initialize="search.OriginName" @selectCity="selectOrigin($event)"></FlightCity>
											<span v-if="errors?.Origin" class="invalid-feedback" :style="errors?.Origin ? 'display:block' : ''">{{ errors?.Origin[0] }}</span>
										</div>
										<div class="btn-flip-icon " >
											<button class="p-0 m-0 text-primary"  @click.prevent="exchangeAirport"><i class="fa-solid fa-right-left"></i></button>
										</div>
									</div>
									<div class="col">
										<div class="form-group hdd-arrow mb-0">
											<label class="text-white text-sm">TO</label>
											<FlightCity :customClass="[errors?.Destination ? 'is-invalid' : '']" :customStyle="'height:50px !important'" :initialize="search?.DestinationName" @selectCity="selectDestination($event)"></FlightCity>
											<span v-if="errors?.Destination" class="invalid-feedback" :style="errors?.Destination ? 'display:block' : ''">{{ errors?.Destination[0] }}</span>
										</div>
									</div>
									<div class="col">
										<div class="form-group mb-0">
											<label class="text-white text-sm">DEPARTURE</label>												
											<input :class="{ 'is-invalid': errors?.PreferredDepartureTime }" class="form-control fw-bold choosedate  hgt-50" type="date" :min="mindate" v-model="search.PreferredDepartureTime" placeholder="Departure.." />
											<span v-if="errors?.PreferredDepartureTime" class="invalid-feedback">{{ errors?.PreferredDepartureTime[0] }}</span>
										</div>
									</div>
									<div class="col" v-if="search.JourneyType==2"> 
										<div class="form-group mb-0">
											<label class="text-white text-sm">RETURN</label>
											<input :class="{ 'is-invalid': errors?.PreferredArrivalTime }" class="form-control fw-bold choosedate  hgt-50" type="date" :min="mindate" v-model="search.PreferredArrivalTime" placeholder="Return.." />
											<span v-if="errors?.PreferredDepartureTime" class="invalid-feedback">{{ errors?.PreferredDepartureTime[0] }}</span>
										</div>
									</div>
									<div class="col">						
										<div class="">
											<label class="text-white text-sm">TRAVELLERS & CLASS</label>
											<input readonly type="text" class="leaving form-control fw-bold hgt-50 select2"
											:value="search?.AdultCount + +search?.ChildCount + +search?.InfantCount + ' Passengers, ' + printClass(search.FlightCabinClass)"
											id="dropdownGuest" data-bs-auto-close="outside" data-bs-toggle="dropdown"/>
											<ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
												<li class="d-flex justify-content-between">
													<div>
														<h6 class="mb-0" style="color: black;">Adults</h6>
														<small>Ages 13 or above</small>
													</div>
													<div class="hstack gap-1 align-items-center">
														<button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl minus" id="adults-subs-btn">
																<i class="fa-solid fa-minus"></i>
															</span>
														</button>
														<span id="guests-count-adults">{{ search?.AdultCount }}</span>
														<button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl plus" id="adults-add-btn">
																<i class="fa-solid fa-plus"></i>
															</span>
														</button>
													</div>
												</li>
												<li class="dropdown-divider"></li>
												<li class="d-flex justify-content-between">
													<div>
														<h6 class="mb-0" style="color: black;">Child</h6>
														<small>Ages Between 2 to 12 Years</small>
													</div>
													<div class="hstack gap-1 align-items-center">
														<button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl minus" id="children-subs-btn">
																<i class="fa-solid fa-minus"></i>
															</span>
														</button>
														<span id="guests-count-children"> {{ search?.ChildCount }}</span>
														<button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl plus" id="children-add-btn">
																<i class="fa-solid fa-plus"></i>
															</span>
														</button>
													</div>
												</li>
												<li class="dropdown-divider"></li>
												<li class="d-flex justify-content-between">
													<div>
														<h6 class="mb-0" style="color: black;">Infant</h6>
														<small>Ages Below 2 Years</small>
													</div>
													<div class="hstack gap-1 align-items-center">
														<button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl minus" id="infant-subs-btn">
																<i class="fa-solid fa-minus"></i>
															</span>
														</button>
														<span id="guests-count-room">{{ search?.InfantCount }}</span>

														<button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl plus" id="infant-add-btn">
																<i class="fa-solid fa-plus"></i>
															</span>
														</button>
													</div>
												</li>
												<li class="dropdown-divider"></li>
												<li>
													<h6 class="mb-2" style="color: black;">Infant</h6>                                            
													<div class="form-group hdd-arrow mb-0">
														<select class="leaving form-control fw-bold" v-model="search.FlightCabinClass">
														<option value="1">All Cabin Class</option>
														<option value="2">Economy Class</option>
														<option value="3">Premium Economy Class</option>
														<option value="4">Business Class</option>
														<option value="5">Premium Business Class</option>
														<option value="6">First Class</option>
														</select>
													</div>													
												</li>
											</ul>
										</div>
									</div>
								</div> -->
								<div class="row gx-lg-2 g-3" v-if="!status">
									<div class="col-xl-6 col-lg-6 col-md-12">
										<div class="row gy-3 gx-lg-2 gx-3">
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
												<div class="form-group hdd-arrow mb-0">
													<label  class="text-white text-sm">Leaving From</label>
													<FlightCity :customClass="['leaving', 'form-control', 'fw-bold', 'select2', errors?.Origin ? 'is-invalid' : '']" :initialize="search.OriginName" @selectCity="selectOrigin($event)"></FlightCity>
													<span v-if="errors?.Origin" class="invalid-feedback" :style="errors?.Origin ? 'display:block' : ''">{{ errors?.Origin[0] }}</span>
												</div>
												<div class="btn-flip-icon mt-md-0">
													<button class="p-0 m-0 text-primary"  @click.prevent="exchangeAirport"><i class="fa-solid fa-right-left"></i></button>
												</div>
											</div>
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div class="form-group hdd-arrow mb-0">
													<label  class="text-white text-sm">Going To</label>
													<FlightCity :customClass="['leaving', 'form-control', 'fw-bold', 'select2', errors?.Destination ? 'is-invalid' : '']" :initialize="search.DestinationName" @selectCity="selectDestination($event)"></FlightCity>
													<span v-if="errors?.Destination" class="invalid-feedback" :style="errors?.Destination ? 'display:block' : ''">{{ errors?.Destination[0] }}</span>
												</div>
											</div>
										</div>
									</div>
									<div  class="col-xl-3 col-lg-4 col-md-12">
										<div class="row gy-3 gx-lg-2 gx-3" v-if="search.JourneyType==1">
											<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
												<div class="form-group mb-0">
													<label  class="text-white text-sm">Departure Date</label>
													<!-- <input :class="{ 'is-invalid': errors?.PreferredDepartureTime }" class="form-control hgt-62 fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredDepartureTime" placeholder="Departure.." />						 -->
													<flatPickr 
														class="form-control hgt-62 fw-bold choosedate" 
														placeholder="Select date" 
														:class="{ 'is-invalid': errors?.PreferredDepartureTime }"  
														type="date"  
														v-model="search.PreferredDepartureTime" 
														:config="flatpickrConfig" 
														:style="activeStyle" 
														@mouseover="activeStyle = { backgroundColor: 'transparent' }" 
														@mouseout="activeStyle = { backgroundColor: '' }">
													</flatPickr>
													<span v-if="errors?.PreferredDepartureTime" class="invalid-feedback">{{ errors?.PreferredDepartureTime[0] }}</span>
												</div>
											</div>
										</div>
										<div class="row gy-3 gx-lg-2 gx-3" v-if="search.JourneyType==2">
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" >
												<div class="form-group mb-0">
													<label  class="text-white text-sm">Departure Date</label>
													<!-- <input :class="{ 'is-invalid': errors?.PreferredDepartureTime }" class="form-control hgt-62 fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredDepartureTime" placeholder="Departure.." /> -->
													<flatPickr 
														class="form-control hgt-62 fw-bold choosedate" 
														placeholder="Select date" 
														:class="{ 'is-invalid': errors?.PreferredDepartureTime }"  
														type="date"  
														v-model="search.PreferredDepartureTime" 
														:config="flatpickrConfig" 
														:style="activeStyle" 
														@mouseover="activeStyle = { backgroundColor: 'transparent' }" 
														@mouseout="activeStyle = { backgroundColor: '' }">
													</flatPickr>
													<span v-if="errors?.PreferredDepartureTime" class="invalid-feedback">{{ errors?.PreferredDepartureTime[0] }}</span>
												</div>
											</div>
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" >
												<div class="form-group mb-0">
													<label  class="text-white text-sm">Return Date</label>
													<!-- <input :class="{ 'is-invalid': errors?.PreferredArrivalTime }" class="form-control hgt-62 fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredArrivalTime" placeholder="Return.." /> -->
													<flatPickr 
														class="form-control hgt-62 fw-bold choosedate" 
														placeholder="Select date" 
														:class="{ 'is-invalid': errors?.PreferredArrivalTime }"  
														type="date"  
														v-model="search.PreferredArrivalTime" 
														:config="flatpickrConfig" 
														:style="activeStyle" 
														@mouseover="activeStyle = { backgroundColor: 'transparent' }" 
														@mouseout="activeStyle = { backgroundColor: '' }">
													</flatPickr>
													<span v-if="errors?.PreferredArrivalTime" class="invalid-feedback">{{ errors?.PreferredArrivalTime[0] }}</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-xl-3 col-lg-2 col-md-12">
										<div class="form-group mb-0">
											<div class="px-sm-2 pb-3 pt-0 ps-0 mob-full">
												<label  class="text-white text-sm">Travellers & Class</label>
												<input
													readonly
													type="text"
													class="leaving form-control fw-bold select2"
													:value="search.AdultCount + +search.ChildCount + +search.InfantCount + ' Passengers, ' + printClass(search.FlightCabinClass) "
													id="dropdownGuest"
													data-bs-auto-close="outside"
													data-bs-toggle="dropdown"
												/>
												<ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color: black;">Adults</h6>
															<small>Ages 13 or above</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="adults-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>
															</button>
															<span id="guests-count-adults">{{ search.AdultCount }}</span>
															<button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="adults-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color: black;">Child</h6>
															<small>Ages Between 2 to 12 Years</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="children-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>
															</button>
															<span id="guests-count-children"> {{ search.ChildCount }}</span>
															<button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="children-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color: black;">Infant</h6>
															<small>Ages Below 2 Years</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="infant-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>
															</button>
															<span id="guests-count-room">{{ search.InfantCount }}</span>

															<button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="infant-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li>
														<div>
															<h6 class="mb-2" style="color: black;">Class</h6>
														</div>
														<select class="leaving form-control fw-bold select2" v-model="search.FlightCabinClass">
															<option value="1">All Cabin Class</option>
															<option value="2">Economy Class</option>
															<option value="3">Premium Economy Class</option>
															<option value="4">Business Class</option>
															<option value="5">Premium Business Class</option>
															<option value="6">First Class</option>
														</select>
													</li>                                            
												</ul>
											</div>
										</div>
									</div>
								</div>
								<!-- <div class="row align-items-end gy-3 gx-md-3 gx-sm-2 mb-2" v-if="status" v-for="(flight, index) in multi_city_flights" :key="index" style="padding-top: 10px;">                            
									<div class="col  position-relative">
										<div class="form-group hdd-arrow mb-0">
											<label class="text-white text-sm">Leaving From</label>										
											<FlightCity :customClass="'height:50px !important hgt-50'" :initialize="flight.OriginName" @selectCity="selectOriginMultiple($event, index)"></FlightCity>
											<FlightCity  :customClass="['leaving', 'form-control', 'fw-bold', 'select2', flight.errors?.Origin ? 'is-invalid' : '']"  :initialize="flight.OriginName" @selectCity="selectOriginMultiple($event, index)"></FlightCity>
                                            <span v-if="flight.errors?.Origin" class="invalid-feedback" :style="flight.errors?.Origin ? 'display:block' : ''">{{ flight.errors?.Origin[0] }}</span>
										</div>
										<div class="btn-flip-icon">
											<button @click="swapLocations(index)" class="p-0 m-0 text-primary"><i class="fa-solid fa-right-left"></i></button>
										</div>
									</div>
									<div class="col">
										<div class="form-group hdd-arrow mb-0">
											<label class="text-white text-sm">Going To</label>
											<FlightCity :customClass="'height:50px !important hgt-50'" :initialize="flight.DestinationName" @selectCity="selectDestinationMultiple($event, index)"></FlightCity>
											<FlightCity :customClass="['leaving', 'form-control', 'fw-bold', 'select2', flight.errors?.Destination ? 'is-invalid' : '']"  :initialize="flight.DestinationName" @selectCity="selectDestinationMultiple($event, index)"></FlightCity>
                                            <span v-if="flight.errors?.Destination" class="invalid-feedback" :style="flight.errors?.Destination ? 'display:block' : ''">{{ flight.errors?.Destination[0] }}</span>
										</div>
									</div>
									<div class="col">
										<div class="form-group mb-0">
											<label class="text-white text-sm">DEPARTURE</label>												
											<input class="form-control fw-bold choosedate  hgt-50" type="date" :min="mindate" v-model="flight.PreferredDepartureTime" @change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)" placeholder="Departure.." />
											<input
												class="form-control fw-bold choosedate"
												:class="{ 'is-invalid': flight.errors?.PreferredDepartureTime }" 
												type="date"
												:min="mindate"
												v-model="flight.PreferredDepartureTime"
												@change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)"
												placeholder="Departure.."
											/>
											<span v-if="flight.errors?.PreferredDepartureTime" class="invalid-feedback">{{ flight.errors?.PreferredDepartureTime[0] }}</span>
										</div>
									</div>																										
									<div class="col" v-if="index === 0">						
										<div class="">
											<label class="text-white text-sm">TRAVELLERS & CLASS</label>
											<input readonly type="text" class="leaving form-control fw-bold hgt-50 select2"
											:value="search?.AdultCount + +search?.ChildCount + +search?.InfantCount + ' Passengers, ' + printClass(search.FlightCabinClass)"
											id="dropdownGuest" data-bs-auto-close="outside" data-bs-toggle="dropdown"/>
											<ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
												<li class="d-flex justify-content-between">
													<div>
														<h6 class="mb-0" style="color: black;">Adults</h6>
														<small>Ages 13 or above</small>
													</div>
													<div class="hstack gap-1 align-items-center">
														<button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl minus" id="adults-subs-btn">
																<i class="fa-solid fa-minus"></i>
															</span>
														</button>
														<span id="guests-count-adults">{{ search?.AdultCount }}</span>
														<button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl plus" id="adults-add-btn">
																<i class="fa-solid fa-plus"></i>
															</span>
														</button>
													</div>
												</li>
												<li class="dropdown-divider"></li>
												<li class="d-flex justify-content-between">
													<div>
														<h6 class="mb-0" style="color: black;">Child</h6>
														<small>Ages Between 2 to 12 Years</small>
													</div>
													<div class="hstack gap-1 align-items-center">
														<button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl minus" id="children-subs-btn">
																<i class="fa-solid fa-minus"></i>
															</span>
														</button>
														<span id="guests-count-children"> {{ search?.ChildCount }}</span>
														<button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl plus" id="children-add-btn">
																<i class="fa-solid fa-plus"></i>
															</span>
														</button>
													</div>
												</li>
												<li class="dropdown-divider"></li>
												<li class="d-flex justify-content-between">
													<div>
														<h6 class="mb-0" style="color: black;">Infant</h6>
														<small>Ages Below 2 Years</small>
													</div>
													<div class="hstack gap-1 align-items-center">
														<button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl minus" id="infant-subs-btn">
																<i class="fa-solid fa-minus"></i>
															</span>
														</button>
														<span id="guests-count-room">{{ search?.InfantCount }}</span>

														<button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
															<span class="guests-input__ctrl plus" id="infant-add-btn">
																<i class="fa-solid fa-plus"></i>
															</span>
														</button>
													</div>
												</li>
												<li class="dropdown-divider"></li>
												<li>
													<h6 class="mb-2" style="color: black;">Infant</h6>                                            
													<div class="form-group hdd-arrow mb-0">
														<select class="leaving form-control fw-bold" v-model="search.FlightCabinClass">
														<option value="1">All Cabin Class</option>
														<option value="2">Economy Class</option>
														<option value="3">Premium Economy Class</option>
														<option value="4">Business Class</option>
														<option value="5">Premium Business Class</option>
														<option value="6">First Class</option>
														</select>
													</div>													
												</li>
											</ul>
										</div>
									</div>									
									<div class="col-xl-1 col-lg-1 col-md-1">
										<button v-if="index > 0 && index === multi_city_flights.length - 1" @click="removeFlight(index)" type="button" class="btn btn-primary fw-medium hgt-50">
											<i class="fa-solid fa-times"></i>
										</button>                                                                      
									</div>
									<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-end" v-if="index === multi_city_flights.length - 1" >
										<button @click="addFlight" type="button" class="btn btn-primary full-width1 fw-medium"><i class="fa-solid fa-add me-2"></i>Add Flight</button>                                
									</div>
								</div> -->
								<div class="row d-flex" v-if="status" v-for="(flight, index) in multi_city_flights" :key="index" style="padding-top: 10px; align-items: center;">                            
									<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
										<div class="row gy-3 gx-lg-2 gx-3">
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
												<div class="form-group hdd-arrow mb-0">
													<label class="text-white text-sm">Leaving From</label>
													<FlightCity  :customClass="['leaving', 'form-control', 'fw-bold', 'select2', flight.errors?.Origin ? 'is-invalid' : '']"  :initialize="flight.OriginName" @selectCity="selectOriginMultiple($event, index)"></FlightCity>
													<span v-if="flight.errors?.Origin" class="invalid-feedback" :style="flight.errors?.Origin ? 'display:block' : ''">{{ flight.errors?.Origin[0] }}</span>
												</div>
												<div class="btn-flip-icon mt-md-0">
													<button @click="swapLocations(index)" class="p-0 m-0 text-primary"><i class="fa-solid fa-right-left"></i></button>
												</div>
											</div>
											<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div class="form-group hdd-arrow mb-0">
													<label class="text-white text-sm">Going To</label>
													<FlightCity :customClass="['leaving', 'form-control', 'fw-bold', 'select2', flight.errors?.Destination ? 'is-invalid' : '']"  :initialize="flight.DestinationName" @selectCity="selectDestinationMultiple($event, index)"></FlightCity>
													<span v-if="flight.errors?.Destination" class="invalid-feedback" :style="flight.errors?.Destination ? 'display:block' : ''">{{ flight.errors?.Destination[0] }}</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
										<div class="form-group mb-0">
											<label class="text-white text-sm">Departure Date</label>
											<!-- <input
												class="form-control fw-bold choosedate"
												:class="{ 'is-invalid': flight.errors?.PreferredDepartureTime }" 
												type="date"
												:min="mindate"
												v-model="flight.PreferredDepartureTime"
												@change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)"
												placeholder="Departure.."
											/> -->
											<flatPickr 
												class="form-control hgt-62 fw-bold choosedate" 
												placeholder="Select date" 
												:class="{ 'is-invalid': flight.errors?.PreferredDepartureTime }"
												type="date"  
												v-model="flight.PreferredDepartureTime" 
												@change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)" 
												:config="flatpickrConfig"
												@mouseover="activeStyle = { backgroundColor: 'transparent' }" 
												@mouseout="activeStyle = { backgroundColor: '' }">
											</flatPickr>
											<span v-if="flight.errors?.PreferredDepartureTime" class="invalid-feedback">{{ flight.errors?.PreferredDepartureTime[0] }}</span>
										</div>
									</div>

									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="padding-top: 10px;" v-if="index === 0">
										<div class="form-group mb-0">
											<div class="px-sm-2 pb-3 pt-0 ps-0 mob-full">
												<label class="text-white text-sm">Travellers & Class</label>
												<input
													readonly
													type="text"
													class="leaving form-control fw-bold select2"
													:value="search.AdultCount + +search.ChildCount + +search.InfantCount + ' Passengers, ' + printClass(search.FlightCabinClass) "
													id="dropdownGuest"
													data-bs-auto-close="outside"
													data-bs-toggle="dropdown"
												/>
												<ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color: black;">Adults</h6>
															<small>Ages 13 or above</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="adults-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>
															</button>
															<span id="guests-count-adults">{{ search.AdultCount }}</span>
															<button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="adults-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color: black;">Child</h6>
															<small>Ages Between 2 to 12 Years</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="children-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>
															</button>
															<span id="guests-count-children"> {{ search.ChildCount }}</span>
															<button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="children-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li class="d-flex justify-content-between">
														<div>
															<h6 class="mb-0" style="color: black;">Infant</h6>
															<small>Ages Below 2 Years</small>
														</div>
														<div class="hstack gap-1 align-items-center">
															<button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl minus" id="infant-subs-btn">
																	<i class="fa-solid fa-minus"></i>
																</span>
															</button>
															<span id="guests-count-room">{{ search.InfantCount }}</span>

															<button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
																<span class="guests-input__ctrl plus" id="infant-add-btn">
																	<i class="fa-solid fa-plus"></i>
																</span>
															</button>
														</div>
													</li>
													<li class="dropdown-divider"></li>
													<li>
														<div>
															<h6 class="mb-2" style="color: black;">Class</h6>
														</div>
														<select class="leaving form-control fw-bold select2" v-model="search.FlightCabinClass">
															<option value="1">All Cabin Class</option>
															<option value="2">Economy Class</option>
															<option value="3">Premium Economy Class</option>
															<option value="4">Business Class</option>
															<option value="5">Premium Business Class</option>
															<option value="6">First Class</option>
														</select>
													</li>                                            
												</ul>
											</div>
										</div>
									</div>
									<!-- <div class="col-xl-1 col-lg-1 col-md-1">
										<button v-if="index > 0 && index === multi_city_flights.length - 1" @click="removeFlight(index)" type="button" class="btn fw-medium">
											<i class="fa-solid fa-times"></i>
										</button>                                                                      
									</div>
									<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-end" v-if="index === multi_city_flights.length - 1" >
										<button @click="addFlight" type="button" class="btn btn-light-primary full-width1 fw-medium"><i class="fa-solid fa-add me-2"></i>Add Flight</button>                                
									</div> -->
									<div class="col-xl-1 col-lg-1 col-md-1">
										<div class="form-group mb-0">
											<button v-if="index > 0 && index === multi_city_flights.length - 1" @click="removeFlight(index)" type="button" class="btn btn-primary fw-medium hgt-50">
												<i class="fa-solid fa-times"></i>
											</button>
										</div>
									</div>
									<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-end" v-if="index === multi_city_flights.length - 1" >
										<button @click="addFlight" type="button" class="btn btn-primary full-width1 fw-medium"><i class="fa-solid fa-add me-2"></i>Add Flight</button>                                
									</div>
								</div> 
								<div class="col-12 text-center mt-0">
									<div class="form-group mb-0">
										<button @click="searchFlights" type="button" class="btn hgt-50 btn-primary fw-medium  w-45">Search</button>
									</div>
								</div>	
							</div>
						</div>
					</div>
				</div>
			</div>

		<!-- TRY ENDS -->
	<!-- ============================ Hero Banner End ================================== -->


	<!-- ============================ All Flits Search Lists Start ================================== -->

		<div v-if="filteredFlightData?.length > 0">
			<div class="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">

				<!-- Sidebar Filter Options -->
				<div class="col-xl-3 col-lg-4 col-md-12">
					<div class="filter-searchBar bg-white rounded-3">
						<div class="filter-searchBar-head border-bottom">
							<div class="searchBar-headerBody d-flex align-items-start justify-content-between px-3 py-3">
								<div class="searchBar-headerfirst">
									<h6 class="fw-bold fs-5 m-0">Filters</h6>
								</div>
								<div class="searchBar-headerlast text-end">
									<a href="#" class="text-md fw-medium text-primary active">Clear All</a>
								</div>
							</div>
						</div>

						<div class="filter-searchBar-body">

							<div class="searchBar-single px-3 py-3 border-bottom">
								<div class="searchBar-single-title d-flex mb-3">
									<h6 class="sidebar-subTitle fs-6 fw-medium m-0">Departure</h6>
								</div>
								<div class="searchBar-single-wrap mb-4">
									<ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
										<li class="col-6" @click.prevent="toggleDepartureFilter('before6am')">
											<input type="checkbox" class="btn-check" id="before6am">
											<label :class="{ active: isSelectedDeparture('before6am') }"  class="btn btn-sm btn-secondary rounded-1 fw-medium px-0 full-width"
												for="before6am">Before 6AM</label>
										</li>
										<li class="col-6" @click.prevent="toggleDepartureFilter('6am12pm')">
											<input type="checkbox" class="btn-check" id="6am12pm">
											<label :class="{ active: isSelectedDeparture('6am12pm') }" class="btn btn-sm btn-secondary rounded-1 fw-medium px-0 full-width" for="6am12pm">6AM -
												12PM</label>
										</li>
										<li class="col-6" @click.prevent="toggleDepartureFilter('12pm6pm')">
											<input type="checkbox" class="btn-check" id="12pm6pm">
											<label :class="{ active: isSelectedDeparture('12pm6pm') }" class="btn btn-sm btn-secondary rounded-1 fw-medium px-0 full-width" for="12pm6pm">12PM -
												6PM</label>
										</li>
										<li class="col-6" @click.prevent="toggleDepartureFilter('after6pm')">
											<input type="checkbox" class="btn-check" id="after6pm">
											<label :class="{ active: isSelectedDeparture('after6pm') }" class="btn btn-sm btn-secondary rounded-1 fw-medium px-0 full-width" for="after6pm">After
												6PM</label>
										</li>
									</ul>
								</div>
							</div>

							<!-- Onward Stops -->
							<div class="searchBar-single px-3 py-3 border-bottom">
								<div class="searchBar-single-title d-flex mb-3">
									<h6 class="sidebar-subTitle fs-6 fw-medium m-0">Stops</h6>
								</div>
								<div class="searchBar-single-wrap">
									<ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
										<li class="col-6" @click.prevent="toggleStopFilter('direct')">
											<input type="checkbox" class="btn-check" id="direct">
											<label :class="{ active: isSelectedStop('direct') }" class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
												for="direct">Direct</label>
										</li>
										<li class="col-6" @click.prevent="toggleStopFilter('1stop')">
											<input type="checkbox" class="btn-check" id="1stop">
											<label :class="{ active: isSelectedStop('1stop') }" class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="1stop">1
												Stop</label>
										</li>
										<li class="col-6" @click.prevent="toggleStopFilter('2stop')">
											<input type="checkbox" class="btn-check" id="2stop">
											<label :class="{ active: isSelectedStop('2stop') }" class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="2stop">2+
												Stop</label>
										</li>
									</ul>
								</div>
							</div>

							<!-- Pricing -->
							<!-- <div class="searchBar-single px-3 py-3 border-bottom">
								<div class="searchBar-single-title d-flex mb-3">
									<h6 class="sidebar-subTitle fs-6 fw-medium m-0">Pricing Range in US$</h6>
								</div>
								<div class="searchBar-single-wrap">
									<input type="text" class="js-range-slider" name="my_range" value="" data-skin="round"
										data-type="double" data-min="0" data-max="1000" data-grid="false">
								</div>
							</div> -->							

							<!-- Popular Flights -->
							<div class="searchBar-single px-3 py-3 border-bottom">
								<div class="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
									<h6 class="sidebar-subTitle fs-6 fw-medium m-0">Preferred Airlines</h6>
								</div>
								<div class="searchBar-single-wrap">
									<ul class="row align-items-center justify-content-between p-0 gx-3 gy-2" style="height: 200px; overflow-x: hidden; overflow-y: none;">
										<li class="col-12" v-for="airline, key in filterAirlines" :key="key">
											<div class="form-check lg">
												<div class="frm-slicing d-flex align-items-center">
													<div class="frm-slicing-first">
														<input class="form-check-input" type="checkbox" :id="airline.airline" :value="airline.airline" v-model="filterAirline" @change="toggleFlightFilter()">
														<label class="form-check-label" for="baggage8"></label>
													</div>
													<div
														class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
														<div class="frms-flex d-flex align-items-center">
															<div class="frm-slicing-img">
																<!-- <img src="img/flight-plane-svgrepo-com.svg" class="img-fluid" width="15" alt=""> -->
																<img class="img-fluid" :src="'img/AirlineLogo/' + airline.aircode +'.gif'" width="15" alt="image">
															</div>
															<div class="frm-slicing-title ps-2"><span class="text-muted-2">{{ airline.airline }}</span></div>
														</div>
													</div>
												</div>

											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- All Flight Lists -->
				<div class="col-xl-9 col-lg-8 col-md-12">
					<div class="row align-items-center justify-content-between">
						<div class="col-xl-4 col-lg-4 col-md-4">
							<h5 class="fw-bold fs-6 mb-lg-0 mb-3" v-if="isVisible==false">Showing {{ filteredFlightData[1]?.length }} Search Results</h5>
							<h5 class="fw-bold fs-6 mb-lg-0 mb-3" v-else>Showing {{ filteredFlightData[0]?.length }} Search Results</h5>
						</div>
						<div class="col-xl-8 col-lg-8 col-md-12">
							<div class="d-flex align-items-center justify-content-start justify-content-lg-end flex-wrap">
								<div class="flsx-first mt-sm-0 mt-2">
									<ul class="nav nav-pills nav-fill p-1 small lights blukker bg-white rounded-3 shadow-sm"
										id="filtersblocks" role="tablist">
										<li class="nav-item" role="presentation">
											<button class="nav-link active rounded-3" id="trending" data-bs-toggle="tab" type="button"
												role="tab" aria-selected="true" @click.prevent="getPrice('low')">Low Price</button>
										</li>
										<li class="nav-item" role="presentation">
											<button class="nav-link rounded-3" id="mostpopular" data-bs-toggle="tab" type="button"
												role="tab" aria-selected="false"  @click.prevent="getPrice('high')">High Price</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div style="display: none;" id="departure_flight">
						<div class="d-md-flex justify-content-md-between pt-5">
							<span class="sc-fzpans sc-fzplWN dazDxU" data-testid="title" style="font-size: 24px; font-weight: 500; line-height: 24px; text-align: left;">
								Departure to {{ search?.DestinationName }}
							</span>
							<a href="#" @click.prevent="reset_departure_flight()">Change</a>
						</div>
					</div>

					<div v-if="flight_from && search?.JourneyType == 2" style="padding-bottom:10px">
						<div class="flights-accordion pt-3">
							<div class="flights-list-item bg-white rounded-3 p-3">
								<div class="row gy-4 align-items-center justify-content-between">

									<div class="col" v-for="segment,j in flight_from.Segments" :key="j">											
										<div class="row mb-3"  v-for="airline,k in segment" :key="k">
											<div class="col-xl-12 col-lg-12 col-md-12">
												<div class="d-flex align-items-center mb-2">
													<!-- <span class="label bg-light-primary text-primary me-2">Departure</span> -->
													<span class="text-muted text-sm">{{ $formatDate(airline.Origin.DepTime,'long') }}</span>
												</div>
											</div>

											<div class="col-xl-12 col-lg-12 col-md-12">
												<div class="row gx-lg-5 gx-3 gy-4 align-items-center">
													<div class="col-sm-auto">
														<div class="d-flex align-items-center justify-content-start">
															<div class="d-start fl-pic">
																<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="45" alt="image"> -->
																<img class="img-fluid" :src="'img/AirlineLogo/' + flight_from.AirlineCode +'.gif'" width="45" alt="image">
															</div>
															<div class="d-end fl-title ps-2">
																<div class="text-dark fw-medium">{{ airline.Airline.AirlineName }}</div>
																<div class="text-sm text-muted">
																	<span v-if="airline.CabinClass==1">All Class</span>
																	<span v-if="airline.CabinClass==2">Economy Class</span>
																	<span v-if="airline.CabinClass==3">Premium Economy Class</span>
																	<span v-if="airline.CabinClass==4">Business Class</span>
																	<span v-if="airline.CabinClass==5">Premium Business Class</span>
																	<span v-if="airline.CabinClass==6">First Class</span>
																</div>
															</div>
														</div>
													</div>

													<div class="col">
														<div class="row gx-3 align-items-center">
															<div class="col-auto">
																<div class="text-dark fw-bold">{{ $formatDate(airline.Origin.DepTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ airline.Origin.Airport.CityName }} </div>
															</div>

															<div class="col text-center">
																<div class="flightLine departure">
																	<div></div>
																	<div></div>
																</div>
																<div class="text-muted text-sm fw-medium mt-3">Direct</div>
															</div>

															<div class="col-auto">
																<div class="text-dark fw-bold">{{ $formatDate(airline.Destination.ArrTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ airline.Destination.Airport.CityName }} </div>
															</div>
														</div>
													</div>

													<div class="col-md-auto">
														<div class="text-dark fw-medium">{{ Math.floor(airline.Duration / 60) }}H {{ airline.Duration % 60 }}M</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-auto">
										<div class="d-flex items-center h-100">
											<div class="d-lg-block d-none border br-dashed me-4"></div>
											<div>
												<div class="text-start text-md-end">
													<div class="text-dark fs-3 fw-bold lh-base">{{ flight_from.Fare.Currency }} {{ flight_from.Fare.PublishedFare }}</div>
													<div class="text-muted text-sm mb-2" v-if="flight_from.IsRefundable">Refundable</div>
													<div class="text-muted text-sm mb-2" v-else>Non Refundable</div>
												</div>													
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="pt-3" style="display: none;" id="arrival_flight">
						<span class="sc-fzpans sc-fzplWN dazDxU" data-testid="title" style="font-size: 24px; font-weight: 500; line-height: 24px; text-align: left;">
							Select return flight to {{ search?.OriginName }}
						</span>
					</div>
					<div v-if="filteredFlightData[0]?.length == 0 && isVisible == false">
						<span class="sc-fzpans sc-fzplWN dazDxU" data-testid="title" style="font-size: 24px; font-weight: 500; line-height: 24px; text-align: left;">
							No flights available for the selected filters.
						</span>
					</div>
					<div v-if="filteredFlightData[1]?.length == 0 && isVisible == true">
						<span class="sc-fzpans sc-fzplWN dazDxU" data-testid="title" style="font-size: 24px; font-weight: 500; line-height: 24px; text-align: left;">
							No flights available for the selected filters.
						</span>
					</div>

					<div class="row align-items-center g-4 mt-2" v-for="fal, k in filteredFlightData" :key="k" :id="'flight_'+k" :style="k == 1  && !isVisible ? { display: 'none' } : {}">				

						<!-- Single Flight -->
						<div class="col-xl-12 col-lg12 col-md-12" v-for="flight,i in fal" :key="i">
							<!-- <p v-if="flight.IsLCC == false">IsLCC</p> -->
							<div class="flights-accordion">
								<div class="flights-list-item bg-white rounded-3 p-3">
									<div class="row gy-4 align-items-center justify-content-between" v-if="flight.Segments.length == 1">
										<div class="col" v-for="segment,j in flight.Segments" :key="j">
											<div class="col-xl-12 col-lg-12 col-md-12">
												<div class="row gx-lg-5 gx-3 gy-4 align-items-center">
													<div class="col-sm-auto">
														<div class="d-flex align-items-center justify-content-start">
															<div class="d-start fl-pic">
																<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image"> -->
																<img class="img-fluid" :src="'img/AirlineLogo/' + flight.AirlineCode +'.gif'" width="45" alt="image">
															</div>
															<div class="d-end fl-title ps-2">
																<div class="text-dark fw-medium">{{ segment[0].Airline.AirlineName }}</div>																
															</div>
														</div>
													</div>

													<div class="col">
														<div class="row gx-3 align-items-center">
															<div class="col-auto">
																<div class="text-dark fw-bold">{{ $formatDate(segment[0].Origin.DepTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[0].Origin.Airport.CityName }} </div>
															</div>

															<div class="col text-center">
																<!-- <span v-if="segment.length>1"> {{ Math.floor(segment[1].AccumulatedDuration / 60) }}H {{ segment[1].AccumulatedDuration % 60 }}M </span>
																<span v-else> 
																	<span v-if="segment[0].AccumulatedDuration">
																		{{ Math.floor(segment[0].AccumulatedDuration / 60) }}H {{ segment[0].AccumulatedDuration % 60 }}M 
																	</span>
																	<span v-else>
																		{{ Math.floor(segment[0].Duration / 60) }}H {{ segment[0].Duration % 60 }}M 
																	</span>
																</span> -->
																<span>{{  AccumulatedDurationCalculation(segment) }}</span>
																<div class="flightLine">
																	<div></div>
																	<div></div>
																</div>
																<div class="text-muted text-sm fw-medium mt-3" v-if="segment.length>1">{{segment.length -1 }} Stop</div>
																<div class="text-muted text-sm fw-medium mt-3" v-else>Non Stop</div>
															</div>

															<!-- <div class="col-auto" v-if="segment.length>1">
																<div class="text-dark fw-bold">{{ $formatDate(segment[1].Destination.ArrTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[1].Destination.Airport.CityName }} </div>
															</div>
															<div class="col-auto" v-else>
																<div class="text-dark fw-bold">{{ $formatDate(segment[0].Destination.ArrTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[0].Destination.Airport.CityName }} </div>
															</div> -->
															<div class="col-auto" v-if="segment.length>1">
																<div class="text-dark fw-bold">{{ $formatDate(segment[segment.length-1].Destination.ArrTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[segment.length-1].Destination.Airport.CityName }} </div>
															</div>
															<div class="col-auto" v-else>
																<div class="text-dark fw-bold">{{ $formatDate(segment[0]?.Destination?.ArrTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[0]?.Destination?.Airport?.CityName }} </div>
															</div>
														</div>
													</div>
												</div>
											</div>								

										</div>

										<div class="col-md-auto">
											<div class="d-flex items-center h-100">
											<div class="d-lg-block d-none me-5"></div>
												<div class="text-start text-md-end">
													<div class="text-dark fs-5 fw-bold lh-base">{{ flight.Fare.Currency }} {{ flight.Fare.PublishedFare }}</div>
													<div class="text-muted text-sm mb-2" v-if="flight.IsRefundable">Refundable</div>
													<div class="text-muted text-sm mb-2" v-else>Non Refundable</div>
												</div>
												<div class="d-lg-block me-4"></div>
												<div class="flight-button-wrap">
													<button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
														data-bs-target="#bookflight" id="modal_bookflight" style="display: none;">
														Select Flight<i class="fa-solid"></i>
													</button>
													<button class="btn btn-primary btn-md fw-medium full-width" v-if="search.JourneyType == 2 && k==0" @click="Select_flight(flight, k)">
														Select Flight<i class="fa-solid"></i>
													</button>
													<button class="btn btn-primary btn-md fw-medium full-width" v-if="search.JourneyType == 1 && k==0" @click="Select_flight(flight, k)">
														Book<i class="fa-solid"></i>
													</button>
													<button class="btn btn-primary btn-md fw-medium full-width" v-if="search.JourneyType == 2 && k==1" @click="Select_flight(flight, k)">
														Book<i class="fa-solid"></i>
													</button>
												</div>													
											</div>												
										</div>
										<div class="text-start text-md-end" style="font-size: 12px; font-weight: 500; line-height: 14.52px; text-align: left;">
											<a data-bs-toggle="collapse" :href="'#flight_'+k+'_'+i" role="button" aria-expanded="false" :aria-controls="'#flight_'+k+'_'+i"  @click="hideOthers('toggleText_'+k+'_'+i, 'flight_'+k+'_'+i, 'icon_'+k+'_'+i, flight)">
												<span style="color: #1354FE;" :id="'toggleText_'+k+'_'+i">
													Show Flight Details
												</span>
												<span :id="'icon_'+k+'_'+i" style="color: #1354FE;">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
														<g id="chevron_forward">
															<mask id="mask0_86_16" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
															<rect id="Bounding box" x="16" y="16" width="16" height="16" transform="rotate(-180 16 16)" fill="#D9D9D9"/>
															</mask>
															<g mask="url(#mask0_86_16)">
																<path id="chevron_forward_2" d="M8.19922 8.59987L11.2659 5.5332L12.1992 6.46654L8.19922 10.4665L4.19922 6.46654L5.13255 5.5332L8.19922 8.59987Z" fill="#1354FE"/>
															</g>
														</g>
													</svg>
												</span>
											</a>
										</div>
										<div class="collapse" :id="'flight_'+k+'_'+i">
											<div class="card card-body">
												<ul class="nav nav-tabs" :id="'flight_tabs_'+k+'_'+i" role="tablist">
													<li class="nav-item" role="presentation">
														<button class="nav-link active" :id="'flight_details-tab_'+k+'_'+i" 
															data-bs-toggle="tab" :data-bs-target="'#flight_details_'+k+'_'+i" type="button" 
															role="tab" :aria-controls="'flight_details_'+k+'_'+i" aria-selected="true">
															Flight Details
														</button>
													</li>
													<li class="nav-item" role="presentation">
														<button class="nav-link" :id="'fare_details-tab_'+k+'_'+i" 
															data-bs-toggle="tab" :data-bs-target="'#fare_details_'+k+'_'+i" type="button" 
															role="tab" :aria-controls="'fare_details_'+k+'_'+i" aria-selected="false">
															Fare Details
														</button>
													</li>
													<li class="nav-item" role="presentation">
														<button class="nav-link" :id="'baggage-tab_'+k+'_'+i" 
															data-bs-toggle="tab" :data-bs-target="'#baggage_'+k+'_'+i" type="button" 
															role="tab" :aria-controls="'baggage_'+k+'_'+i" aria-selected="false">
															Baggage
														</button>
													</li>
												</ul>
												<div class="tab-content pt-3" id="myTabContent">
													<div  class="tab-pane fade show active" :id="'flight_details_'+k+'_'+i" role="tabpanel" :aria-labelledby="'flight_details-tab_'+k+'_'+i">
														<!-- <div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
															<div  class="pt-3" style="padding: 20px;">
																<div v-if="flight.Segments[0]?.length > 1">
																	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																		{{ flight.Segments[0][0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ flight.Segments[0][1].Destination.Airport.CityName }} 																		
																	</div>
																	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																		<span>{{ $formatDate(flight.Segments[0][0].Origin.DepTime,'long') }} | </span> 
																		<span> 1 Stop | </span>
																		<span> {{ Math.floor(flight.Segments[0][1].AccumulatedDuration / 60) }}H {{ flight.Segments[0][1].AccumulatedDuration % 60 }}M | </span>																		
																		<span> {{ printClass(flight.Segments[0][0].CabinClass) }}</span>
																	</div>
																</div>
																<div v-else>
																	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																		{{ flight.Segments[0][0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ flight.Segments[0][0].Destination.Airport.CityName }} 																		
																	</div>
																	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																		<span>{{ $formatDate(flight.Segments[0][0].Origin.DepTime,'long') }} | </span> 
																		<span> Non Stop | </span>
																		<span v-if="flight.Segments[0][0].AccumulatedDuration">
																			{{ Math.floor(flight.Segments[0][0].AccumulatedDuration / 60) }}H {{ flight.Segments[0][0].AccumulatedDuration % 60 }}M | 
																		</span>
																		<span v-else>
																			{{ Math.floor(flight.Segments[0][0].Duration / 60) }}H {{ flight.Segments[0][0].Duration % 60 }}M |
																		</span>																		
																		<span> {{ printClass(flight.Segments[0][0].CabinClass) }}</span>
																	</div>
																</div>

																<div v-for="segment, index in flight.Segments[0]" :key="index" class="pt-4" >																		
																	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																		<img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="20" alt="image">
																		<span style="padding-left: 6px;">{{ segment.Airline.AirlineName }} | </span> <span>{{ segment.Airline.AirlineCode }}-{{ segment.Airline.FlightNumber }} </span>
																	</div>
																	
																	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
																		<div class="row" style="align-items: center; ">
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 14px; font-weight: 500; line-height:14px">
																						{{ $formatDate(segment.Origin.DepTime,'time') }}
																					</span>
																				</div>	
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:14px">
																						{{ $formatDate(segment.Origin.DepTime,'long') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:16px">
																						{{ segment.Origin.Airport.AirportName }}, {{ segment.Origin.Airport.Terminal ? 'Terminal '+segment.Origin.Airport.Terminal : ''}}
																					</span>
																				</div>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
																				<div class="text-center">
																					<div> 
																						{{ Math.floor(segment.Duration / 60) }}H {{ segment.Duration % 60 }}M 
																					</div>
																					<div class="flightLine departure">
																						<div></div>
																						<div></div>
																					</div>
																				</div>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 14px; font-weight: 500; line-height:14px">
																						{{ $formatDate(segment.Destination.ArrTime,'time') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:14px">
																						{{ $formatDate(segment.Destination.ArrTime,'long') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:16px">
																						{{ segment.Destination.Airport.AirportName }},  {{ segment.Destination.Airport.Terminal ? 'Terminal '+segment.Destination.Airport.Terminal : ''}}
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="row pt-3 justify-content-center" style="align-items:center" v-if="flight.Segments[0]?.length>1 && index==0">
																		<div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
																			padding:6px !important; font-weight:500; font-size:12px;">
																			<span style="color:#5143D9">Change of Planes | </span>
																			<span>
																				{{ Math.floor((flight.Segments[0][1].AccumulatedDuration - (flight.Segments[0][0].Duration + flight.Segments[0][1].Duration))  / 60) }}H 
																				{{ (flight.Segments[0][1].AccumulatedDuration - (flight.Segments[0][0].Duration + flight.Segments[0][1].Duration)) % 60 }}M
																				Layover in 
																				{{ segment.Destination.Airport.CityName }}
																			</span>
																		</div>																		
																	</div>
																</div>
															</div>
														</div>	 -->
														<div v-for="segment, fli in flight.Segments" :key="fli" style="padding-bottom:16px">
															<div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
																<div  class="pt-3" style="padding: 20px;">
																	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																		<span>{{ segment[0]?.Origin?.Airport?.CityName }} </span>
																		<i class="bi bi-arrow-right"></i> 
																		<span v-if="segment.length>1">{{ segment[segment.length-1].Destination.Airport.CityName }}</span>
																		<span v-else>{{ segment[0]?.Destination?.Airport?.CityName }}</span>
																	</div>
																	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																		<span>{{ $formatDate(segment[0].Origin.DepTime,'long') }} | </span> 
																		<span> {{flight.Segments[0]?.length -1 }} Stop | </span>
																		<span>{{  AccumulatedDurationCalculation(segment) }} | </span>
																		<span> {{ printClass(segment[0].CabinClass) }}</span>
																	</div>
																</div>
																<div v-for="seg, index in segment" :key="index" class="pt-4" style="padding: 20px;">																		
																	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																		<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="20" alt="image"> -->
																		<img class="img-fluid" :src="'img/AirlineLogo/' + seg?.Airline?.AirlineCode +'.gif'" width="20" alt="image">
																		<span style="padding-left: 6px;">{{ seg?.Airline?.AirlineName }} | </span> <span>{{ seg?.Airline?.AirlineCode }}-{{ seg?.Airline?.FlightNumber }} </span>
																	</div>
																	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
																		<div class="row" style="align-items: center; ">
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 14px; font-weight: 500; line-height:14px">
																						{{ $formatDate(seg.Origin.DepTime,'time') }}
																					</span>
																				</div>	
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:14px">
																						{{ $formatDate(seg.Origin.DepTime,'long') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:16px">
																						{{ seg?.Origin?.Airport?.AirportName }}, {{ seg?.Origin?.Airport?.Terminal ? 'Terminal '+seg?.Origin?.Airport?.Terminal : ''}}
																					</span>
																				</div>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
																				<div class="text-center">
																					<div> 
																						{{ Math.floor(seg.Duration / 60) }}H {{ seg.Duration % 60 }}M 
																					</div>
																					<div class="flightLine departure">
																						<div></div>
																						<div></div>
																					</div>
																				</div>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 14px; font-weight: 500; line-height:14px">
																						{{ $formatDate(seg.Destination.ArrTime,'time') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:14px">
																						{{ $formatDate(seg.Destination.ArrTime,'long') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:16px">
																						{{ seg?.Destination?.Airport?.AirportName }},  {{ seg?.Destination?.Airport?.Terminal ? 'Terminal '+seg?.Destination?.Airport?.Terminal : ''}}
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="row pt-3 justify-content-center" style="align-items:center" v-if="segment?.length>1 && index!=segment?.length-1">
																		<div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
																			padding:6px !important; font-weight:500; font-size:12px;">
																			<span style="color:#5143D9">Change of Planes | </span>
																			<span>																				
																				{{ calculateLayover(segment[index]?.Destination?.ArrTime, segment[index+1]?.Origin?.DepTime) }}
																				Layover in 
																				{{ segment[index+1]?.Origin?.Airport?.CityName }}
																			</span>
																		</div>																		
																	</div>
																</div>																
															</div>
														</div>
													</div>
													<div class="tab-pane fade" :id="'fare_details_'+k+'_'+i" role="tabpanel" :aria-labelledby="'fare_details-tab_'+k+'_'+i">
														<table class="table table-bordered">
															<tbody v-for="fare, index in flight.FareBreakdown" :key="index">
																<tr>
																	<td v-if="fare.PassengerType==1">Base Fare ({{ search.AdultCount }} Adult)</td>
																	<td v-if="fare.PassengerType==1">{{ fare.Currency }}. {{ fare.BaseFare }}</td>
																	<td v-if="fare.PassengerType==2">Base Fare ({{ search.ChildCount }} Child)</td>
																	<td v-if="fare.PassengerType==2">{{ fare.Currency }}. {{ fare.BaseFare }}</td>
																	<td v-if="fare.PassengerType==3">Base Fare ({{ search.InfantCount }} Infant)</td>
																	<td v-if="fare.PassengerType==3">{{ fare.Currency }}. {{ fare.BaseFare }}</td>
																</tr>
																<tr>
																	<td v-if="fare.PassengerType==1">Taxes and Fees ({{ search.AdultCount }} Adult)</td>
																	<td v-if="fare.PassengerType==1">{{ fare.Currency }}. {{ fare.Tax }}</td>
																	<td v-if="fare.PassengerType==2">Taxes and Fees ({{ search.ChildCount }} Child)</td>
																	<td v-if="fare.PassengerType==2">{{ fare.Currency }}. {{ fare.Tax }}</td>
																	<td v-if="fare.PassengerType==3">Taxes and Fees ({{ search.InfantCount }} Infant)</td>
																	<td v-if="fare.PassengerType==3">{{ fare.Currency }}. {{ fare.Tax }}</td>
																</tr>
															</tbody>
															<tbody>
																<tr>
																	<td>Total Fare</td>
																	<td >{{ flight.Fare.Currency }}. {{ flight.Fare.PublishedFare }}</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div class="tab-pane fade" :id="'baggage_'+k+'_'+i" role="tabpanel" :aria-labelledby="'baggage-tab_'+k+'_'+i">
														<!-- {{ flight }} -->
														<div v-for="segment, index in flight.Segments[0]" :key="index" >
															<div class="frm-slicing-img pt-3">
																<img src="img/flight-plane-svgrepo-com.svg" class="img-fluid" width="20" alt="">&nbsp;
																<span class="text-muted-2">
																	{{ segment.Origin.Airport.AirportCode }} - {{ segment.Destination.Airport.AirportCode }} &nbsp;&nbsp;
																	({{segment.Airline.AirlineCode}} - {{segment.Airline.FlightNumber}})
																</span>
															</div>
															<table class="table table-bordered">
																<tbody>
																	<tr>
																		<td>Baggage</td>																	
																		<td>{{ segment.Baggage }}</td>
																	</tr>
																	<tr>
																		<td>Cabin</td>																	
																		<td>{{ segment.CabinBaggage }}</td>
																	</tr>
																</tbody>																
															</table>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>

									<div class="gy-4 align-items-center justify-content-between row" v-else>
										<div class="col-xl-9 col-lg-9 col-md-9">
											<div class="col-xl-12 col-lg-12 col-md-12" v-for="segment,j in flight.Segments" :key="j" style="padding-bottom: 10px;">											
												<div class="row gx-lg-5 gx-3 gy-4 align-items-center">
													<div class="col-sm-auto">
														<div class="d-flex align-items-center justify-content-start">
															<div class="d-start fl-pic">
																<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image"> -->
																<img class="img-fluid" :src="'img/AirlineLogo/' + segment[0]?.Airline?.AirlineCode +'.gif'" width="20" alt="image">
															</div>

															<div class="d-end fl-title ps-2">
																<div class="text-dark fw-medium">{{ segment[0]?.Airline?.AirlineName }}</div>																
															</div>															
														</div>
													</div>

													<div class="col">
														<div class="row gx-3 align-items-center">
															<div class="col-auto">
																<div class="text-dark fw-bold">{{ $formatDate(segment[0]?.Origin?.DepTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[0]?.Origin?.Airport?.CityName }} </div>
															</div>

															<div class="col text-center">
																<span> {{  AccumulatedDurationCalculation(segment) }}</span>																
																<div class="flightLine">
																	<div></div>
																	<div></div>
																</div>
																<div class="text-muted text-sm fw-medium mt-3" v-if="segment.length>1">{{ segment.length-1 }} Stop</div>
																<div class="text-muted text-sm fw-medium mt-3" v-else>Non Stop</div>
															</div>

															<div class="col-auto" v-if="segment.length>1">
																<div class="text-dark fw-bold">{{ $formatDate(segment[segment.length-1].Destination.ArrTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[segment.length-1].Destination.Airport.CityName }} </div>
															</div>
															<div class="col-auto" v-else>
																<div class="text-dark fw-bold">{{ $formatDate(segment[0]?.Destination?.ArrTime,'time') }}</div>
																<div class="text-muted text-sm fw-medium">{{ segment[0]?.Destination?.Airport?.CityName }} </div>
															</div>
														</div>
													</div>														
												</div>
											</div>
										</div>
										
										<div class="col-xl-3 col-lg-3 col-md-3" v-if="k==0">
											<div class="col-md-auto">
												<div class="d-flex items-center h-100">
													<div class="text-start text-md-start">
														<div class="text-dark fs-5 fw-bold lh-base">{{ flight.Fare.Currency }} {{ flight.Fare.PublishedFare }}</div>
														<div class="text-muted text-sm mb-2" v-if="flight.IsRefundable">Refundable</div>
														<div class="text-muted text-sm mb-2" v-else>Non Refundable</div>
													</div>
													<div class="d-lg-block me-2"></div>
													<div class="flight-button-wrap">
														<button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
															data-bs-target="#bookflight" id="modal_bookflight" style="display: none;">
															Select Flight<i class="fa-solid"></i>
														</button>
														<button class="btn btn-primary btn-md fw-medium full-width"  @click="Select_flight(flight, k)">
															Book<i class="fa-solid"></i>
														</button>
													</div>
												</div>
											</div>											
										</div>
										
										<div class="text-start text-md-end" style="font-size: 12px; font-weight: 500; line-height: 14.52px; text-align: left;">
											<a data-bs-toggle="collapse" :href="'#flight_'+k+'_'+i" role="button" aria-expanded="false" :aria-controls="'#flight_'+k+'_'+i"  @click="hideOthers('toggleText_'+k+'_'+i, 'flight_'+k+'_'+i, 'icon_'+k+'_'+i, flight)">
												<span style="color: #1354FE;" :id="'toggleText_'+k+'_'+i">
													Show Flight Details
												</span>
												<span :id="'icon_'+k+'_'+i" style="color: #1354FE;">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
														<g id="chevron_forward">
															<mask id="mask0_86_16" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
															<rect id="Bounding box" x="16" y="16" width="16" height="16" transform="rotate(-180 16 16)" fill="#D9D9D9"/>
															</mask>
															<g mask="url(#mask0_86_16)">
																<path id="chevron_forward_2" d="M8.19922 8.59987L11.2659 5.5332L12.1992 6.46654L8.19922 10.4665L4.19922 6.46654L5.13255 5.5332L8.19922 8.59987Z" fill="#1354FE"/>
															</g>
														</g>
													</svg>
												</span>
											</a>
										</div>
										<div class="collapse" :id="'flight_'+k+'_'+i">
											<div class="card card-body">
												<ul class="nav nav-tabs" :id="'flight_tabs_'+k+'_'+i" role="tablist">
													<li class="nav-item" role="presentation">
														<button class="nav-link active" :id="'flight_details-tab_'+k+'_'+i" 
															data-bs-toggle="tab" :data-bs-target="'#flight_details_'+k+'_'+i" type="button" 
															role="tab" :aria-controls="'flight_details_'+k+'_'+i" aria-selected="true">
															Flight Details
														</button>
													</li>
													<li class="nav-item" role="presentation">
														<button class="nav-link" :id="'fare_details-tab_'+k+'_'+i" 
															data-bs-toggle="tab" :data-bs-target="'#fare_details_'+k+'_'+i" type="button" 
															role="tab" :aria-controls="'fare_details_'+k+'_'+i" aria-selected="false">
															Fare Details
														</button>
													</li>
													<li class="nav-item" role="presentation">
														<button class="nav-link" :id="'baggage-tab_'+k+'_'+i" 
															data-bs-toggle="tab" :data-bs-target="'#baggage_'+k+'_'+i" type="button" 
															role="tab" :aria-controls="'baggage_'+k+'_'+i" aria-selected="false">
															Baggage
														</button>
													</li>
												</ul>
												<div class="tab-content pt-3" id="myTabContent">
													<div  class="tab-pane fade show active" :id="'flight_details_'+k+'_'+i" role="tabpanel" :aria-labelledby="'flight_details-tab_'+k+'_'+i">
														<!-- {{flight}} -->
														<div v-for="segment, fli in flight.Segments" :key="fli" style="padding-bottom:16px">
															<div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
																<div  class="pt-3" style="padding: 20px;">
																	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																		<span>{{ segment[0]?.Origin?.Airport?.CityName }} </span>
																		<i class="bi bi-arrow-right"></i> 
																		<span v-if="segment.length>1">{{ segment[segment.length-1].Destination.Airport.CityName }}</span>
																		<span v-else>{{ segment[0]?.Destination?.Airport?.CityName }}</span>
																	</div>
																	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																		<span>{{ $formatDate(segment[0].Origin.DepTime,'long') }} | </span> 
																		<span> {{segment?.length -1 == 0 ? 'Non' : segment?.length -1 }} Stop | </span>
																		<span>{{  AccumulatedDurationCalculation(segment) }} | </span>
																		<span> {{ printClass(segment[0].CabinClass) }}</span>
																	</div>
																</div>
																<div v-for="seg, index in segment" :key="index" class="pt-4" style="padding: 20px;">																		
																	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																		<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="20" alt="image"> -->
																		<img class="img-fluid" :src="'img/AirlineLogo/' + seg?.Airline?.AirlineCode +'.gif'" width="20" alt="image">
																		<span style="padding-left: 6px;">{{ seg?.Airline?.AirlineName }} | </span> <span>{{ seg?.Airline?.AirlineCode }}-{{ seg?.Airline?.FlightNumber }} </span>
																	</div>
																	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
																		<div class="row" style="align-items: center; ">
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 14px; font-weight: 500; line-height:14px">
																						{{ $formatDate(seg.Origin.DepTime,'time') }}
																					</span>
																				</div>	
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:14px">
																						{{ $formatDate(seg.Origin.DepTime,'long') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:16px">
																						{{ seg?.Origin?.Airport?.AirportName }}, {{ seg?.Origin?.Airport?.Terminal ? 'Terminal '+seg?.Origin?.Airport?.Terminal : ''}}
																					</span>
																				</div>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
																				<div class="text-center">
																					<div> 
																						{{ Math.floor(seg.Duration / 60) }}H {{ seg.Duration % 60 }}M 
																					</div>
																					<div class="flightLine departure">
																						<div></div>
																						<div></div>
																					</div>
																				</div>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 14px; font-weight: 500; line-height:14px">
																						{{ $formatDate(seg.Destination.ArrTime,'time') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:14px">
																						{{ $formatDate(seg.Destination.ArrTime,'long') }}
																					</span>
																				</div>
																				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																					<span style="font-size: 12px; font-weight: 400; line-height:16px">
																						{{ seg?.Destination?.Airport?.AirportName }},  {{ seg?.Destination?.Airport?.Terminal ? 'Terminal '+seg?.Destination?.Airport?.Terminal : ''}}
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="row pt-3 justify-content-center" style="align-items:center" v-if="segment?.length>1 && index!=segment?.length-1">
																		<div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
																			padding:6px !important; font-weight:500; font-size:12px;">
																			<span style="color:#5143D9">Change of Planes | </span>
																			<span>																				
																				{{ calculateLayover(segment[index]?.Destination?.ArrTime, segment[index+1]?.Origin?.DepTime) }}
																				Layover in 
																				{{ segment[index+1]?.Origin?.Airport?.CityName }}
																			</span>
																		</div>																		
																	</div>
																</div>
															</div>
														</div>	
													</div>
													<div class="tab-pane fade" :id="'fare_details_'+k+'_'+i" role="tabpanel" :aria-labelledby="'fare_details-tab_'+k+'_'+i">
														<table class="table table-bordered">
															<tbody v-for="fare, index in flight.FareBreakdown" :key="index">
																<tr>
																	<td v-if="fare.PassengerType==1">Base Fare ({{ search.AdultCount }} Adult)</td>
																	<td v-if="fare.PassengerType==1">{{ fare.Currency }}. {{ fare.BaseFare }}</td>
																	<td v-if="fare.PassengerType==2">Base Fare ({{ search.ChildCount }} Child)</td>
																	<td v-if="fare.PassengerType==2">{{ fare.Currency }}. {{ fare.BaseFare }}</td>
																	<td v-if="fare.PassengerType==3">Base Fare ({{ search.InfantCount }} Infant)</td>
																	<td v-if="fare.PassengerType==3">{{ fare.Currency }}. {{ fare.BaseFare }}</td>
																</tr>
																<tr>
																	<td v-if="fare.PassengerType==1">Taxes and Fees ({{ search.AdultCount }} Adult)</td>
																	<td v-if="fare.PassengerType==1">{{ fare.Currency }}. {{ fare.Tax }}</td>
																	<td v-if="fare.PassengerType==2">Taxes and Fees ({{ search.ChildCount }} Child)</td>
																	<td v-if="fare.PassengerType==2">{{ fare.Currency }}. {{ fare.Tax }}</td>
																	<td v-if="fare.PassengerType==3">Taxes and Fees ({{ search.InfantCount }} Infant)</td>
																	<td v-if="fare.PassengerType==3">{{ fare.Currency }}. {{ fare.Tax }}</td>
																</tr>
															</tbody>
															<tbody>
																<tr>
																	<td>Total Fare</td>
																	<td >{{ flight.Fare.Currency }}. {{ flight.Fare.PublishedFare }}</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div class="tab-pane fade" :id="'baggage_'+k+'_'+i" role="tabpanel" :aria-labelledby="'baggage-tab_'+k+'_'+i">
														<!-- {{ flight }} -->
														<div v-for="segment, index in flight.Segments[0]" :key="index" >
															<div class="frm-slicing-img pt-3">
																<img src="img/flight-plane-svgrepo-com.svg" class="img-fluid" width="20" alt="">&nbsp;
																<span class="text-muted-2">
																	{{ segment.Origin.Airport.AirportCode }} - {{ segment.Destination.Airport.AirportCode }} &nbsp;&nbsp;
																	({{segment.Airline.AirlineCode}} - {{segment.Airline.FlightNumber}})
																</span>
															</div>
															<table class="table table-bordered">
																<tbody>
																	<tr>
																		<td>Baggage</td>																	
																		<td>{{ segment.Baggage }}</td>
																	</tr>
																	<tr>
																		<td>Cabin</td>																	
																		<td>{{ segment.CabinBaggage }}</td>
																	</tr>
																</tbody>																
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>						
					</div>
				</div>
			</div>
		</div>
	
	<!-- Book Flight -->
	
	<!-- Flight Models for single & return Route -->
	<!-- <div class="modal modal-lg fade" id="bookflight" tabindex="-1" aria-labelledby="bookflightModalLabel"
		aria-hidden="true" ref="bookflight">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title fs-6" id="bookflightModalLabel">Your Flight To {{ search?.DestinationName }}</h4>
					<a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close" id="bookClose"><i
							class="fa-solid fa-square-xmark"></i></a>
				</div>
				<div class="modal-body px-4 py-4 px-xl-5 px-lg-5">
					<div class="airLines-fullsegment">
						<div class="segmentDeparture-wrap">
							<div class="segmentDeparture-head mb-3">
								<h4 class="fs-5 m-0">Flight to {{ search?.DestinationName }}</h4>
							</div>

							<div v-for="segment,j in meta?.SelectedFlight?.Segments" :key="j">

								<div class="row gx-3 align-items-center pb-3">
									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[0].Origin.DepTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[0].Origin.Airport.CityName }} </div>
									</div>

									<div class="col text-center">
										<div class="flightLine departure">
											<div></div>
											<div></div>
										</div>
										<div class="text-muted text-sm fw-medium mt-3">Direct</div>
									</div>

									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[0].Destination.ArrTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[0].Destination.Airport.CityName }} </div>
									</div>
								</div>	
								<div class="segmentDeparture-block" >																						
									<div class="segmentDeparture blockfirst">
										<ul>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[0].Origin.Airport.AirportCode }} . {{ segment[0].Origin.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[0].Origin.DepTime,'long') }} . {{ $formatDate(segment[0].Origin.DepTime,'time') }}</p>
												</div>
											</li>												
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[0].Destination.Airport.AirportCode }} . {{ segment[0].Destination.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[0].Destination.ArrTime,'long') }} . {{ $formatDate(segment[0].Destination.ArrTime,'time') }}</p>
												</div>
											</li>
										</ul>
									</div>
									
									<div class="segmentDeparture-blocklast">
										<div class="d-flex align-items-start timeline-stprs">
											<div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="">
											</div>
											<div class="timeline-stprscaps ps-2">
												<p class="text-sm m-0">
													{{ segment[0].Airline.AirlineName }} -
													{{ segment[0].Airline.AirlineCode }} - 
													{{ segment[0].Airline.FlightNumber }} - 
													<span v-if="segment[0].CabinClass==1">All Class</span>
													<span v-if="segment[0].CabinClass==2">Economy Class</span>
													<span v-if="segment[0].CabinClass==3">Premium Economy Class</span>
													<span v-if="segment[0].CabinClass==4">Business Class</span>
													<span v-if="segment[0].CabinClass==5">Premium Business Class</span>
													<span v-if="segment[0].CabinClass==6">First Class</span> 
													Flight time {{ Math.floor(segment[0].Duration / 60) }}h {{ segment[0].Duration % 60 }}m
												</p>
											</div>
										</div>
									</div>										
								</div>
								<div v-if="segment.length>1" class="segmentDeparture-overlay" style="flex-direction: column !important;">
									<div class="css-1894l3t">
										<span class="text-muted">
											<i class="fa-regular fa-clock me-1"></i>
											Layover {{ Math.floor(segment[0].GroundTime / 60) }}h {{ segment[0].GroundTime % 60 }}m
										</span>
									</div>
								</div>
								<div v-if="segment.length>1" class="row gx-3 align-items-center pb-3">
									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[1].Origin.DepTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[1].Origin.Airport.CityName }} </div>
									</div>

									<div class="col text-center">
										<div class="flightLine departure">
											<div></div>
											<div></div>
										</div>
										<div class="text-muted text-sm fw-medium mt-3">Direct</div>
									</div>

									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[1].Destination.ArrTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[1].Destination.Airport.CityName }} </div>
									</div>
								</div>
								<div v-if="segment.length>1" class="segmentDeparture-block" >													
									<div class="segmentDeparture blockfirst">
										<ul>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[1].Origin.Airport.AirportCode }} . {{ segment[1].Origin.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[1].Origin.DepTime,'long') }} . {{ $formatDate(segment[1].Origin.DepTime,'time') }}</p>
												</div>
											</li>												
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[1].Destination.Airport.AirportCode }} . {{ segment[1].Destination.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[1].Destination.ArrTime,'long') }} . {{ $formatDate(segment[1].Destination.ArrTime,'time') }}</p>
												</div>
											</li>
										</ul>
									</div>
									
									<div class="segmentDeparture-blocklast">
										<div class="d-flex align-items-start timeline-stprs">
											<div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="">
											</div>
											<div class="timeline-stprscaps ps-2">
												<p class="text-sm m-0">
													{{ segment[1].Airline.AirlineName }} -
													{{ segment[1].Airline.AirlineCode }} - 
													{{ segment[1].Airline.FlightNumber }} - 
													<span v-if="segment[1].CabinClass==1">All Class</span>
													<span v-if="segment[1].CabinClass==2">Economy Class</span>
													<span v-if="segment[1].CabinClass==3">Premium Economy Class</span>
													<span v-if="segment[1].CabinClass==4">Business Class</span>
													<span v-if="segment[1].CabinClass==5">Premium Business Class</span>
													<span v-if="segment[1].CabinClass==6">First Class</span> 
													Flight time {{ Math.floor(segment[1].Duration / 60) }}h {{ segment[1].Duration % 60 }}m
												</p>
											</div>
										</div>
									</div>										
								</div>
							</div>
						</div>
						<div class="segmentDeparture-wrap mt-5" v-if="meta?.RoundTripSelectedFlight">
							<div class="segmentDeparture-head mb-3">
								<h4 class="fs-5 m-0">Flight to {{ search?.OriginName }}</h4>
							</div>
							<div v-for="segment,j in meta?.RoundTripSelectedFlight?.Segments" :key="j">
								<div class="row gx-3 align-items-center pb-3">
									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[0].Origin.DepTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[0].Origin.Airport.CityName }} </div>
									</div>

									<div class="col text-center">
										<div class="flightLine departure">
											<div></div>
											<div></div>
										</div>
										<div class="text-muted text-sm fw-medium mt-3">Direct</div>
									</div>

									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[0].Destination.ArrTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[0].Destination.Airport.CityName }} </div>
									</div>
								</div>	
								<div class="segmentDeparture-block" >																						
									<div class="segmentDeparture blockfirst">
										<ul>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[0].Origin.Airport.AirportCode }} . {{ segment[0].Origin.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[0].Origin.DepTime,'long') }} . {{ $formatDate(segment[0].Origin.DepTime,'time') }}</p>
												</div>
											</li>												
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[0].Destination.Airport.AirportCode }} . {{ segment[0].Destination.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[0].Destination.ArrTime,'long') }} . {{ $formatDate(segment[0].Destination.ArrTime,'time') }}</p>
												</div>
											</li>
										</ul>
									</div>
									
									<div class="segmentDeparture-blocklast">
										<div class="d-flex align-items-start timeline-stprs">
											<div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="">
											</div>
											<div class="timeline-stprscaps ps-2">
												<p class="text-sm m-0">
													{{ segment[0].Airline.AirlineName }} -
													{{ segment[0].Airline.AirlineCode }} - 
													{{ segment[0].Airline.FlightNumber }} - 
													<span v-if="segment[0].CabinClass==1">All Class</span>
													<span v-if="segment[0].CabinClass==2">Economy Class</span>
													<span v-if="segment[0].CabinClass==3">Premium Economy Class</span>
													<span v-if="segment[0].CabinClass==4">Business Class</span>
													<span v-if="segment[0].CabinClass==5">Premium Business Class</span>
													<span v-if="segment[0].CabinClass==6">First Class</span> 
													Flight time {{ Math.floor(segment[0].Duration / 60) }}h {{ segment[0].Duration % 60 }}m
												</p>
											</div>
										</div>
									</div>										
								</div>
								<div v-if="segment.length>1" class="segmentDeparture-overlay" style="flex-direction: column !important;">
									<div class="css-1894l3t">
										<span class="text-muted">
											<i class="fa-regular fa-clock me-1"></i>
											Layover {{ Math.floor(segment[0].GroundTime / 60) }}h {{ segment[0].GroundTime % 60 }}m
										</span>
									</div>
								</div>

								<div v-if="segment.length>1" class="row gx-3 align-items-center pb-3">
									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[1].Origin.DepTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[1].Origin.Airport.CityName }} </div>
									</div>

									<div class="col text-center">
										<div class="flightLine departure">
											<div></div>
											<div></div>
										</div>
										<div class="text-muted text-sm fw-medium mt-3">Direct</div>
									</div>

									<div class="col-auto">
										<div class="text-dark fw-bold">{{ $formatDate(segment[1].Destination.ArrTime,'time') }}</div>
										<div class="text-muted text-sm fw-medium">{{ segment[1].Destination.Airport.CityName }} </div>
									</div>
								</div>
								<div v-if="segment.length>1" class="segmentDeparture-block" >													
									<div class="segmentDeparture blockfirst">
										<ul>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[1].Origin.Airport.AirportCode }} . {{ segment[1].Origin.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[1].Origin.DepTime,'long') }} . {{ $formatDate(segment[1].Origin.DepTime,'time') }}</p>
												</div>
											</li>												
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">{{ segment[1].Destination.Airport.AirportCode }} . {{ segment[1].Destination.Airport.CityName }}</h6>
													<p class="text-muted text-md m-0">{{ $formatDate(segment[1].Destination.ArrTime,'long') }} . {{ $formatDate(segment[1].Destination.ArrTime,'time') }}</p>
												</div>
											</li>
										</ul>
									</div>
									
									<div class="segmentDeparture-blocklast">
										<div class="d-flex align-items-start timeline-stprs">
											<div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="">
											</div>
											<div class="timeline-stprscaps ps-2">
												<p class="text-sm m-0">
													{{ segment[1].Airline.AirlineName }} -
													{{ segment[1].Airline.AirlineCode }} - 
													{{ segment[1].Airline.FlightNumber }} - 
													<span v-if="segment[1].CabinClass==1">All Class</span>
													<span v-if="segment[1].CabinClass==2">Economy Class</span>
													<span v-if="segment[1].CabinClass==3">Premium Economy Class</span>
													<span v-if="segment[1].CabinClass==4">Business Class</span>
													<span v-if="segment[1].CabinClass==5">Premium Business Class</span>
													<span v-if="segment[1].CabinClass==6">First Class</span> 
													Flight time {{ Math.floor(segment[1].Duration / 60) }}h {{ segment[1].Duration % 60 }}m
												</p>
											</div>
										</div>
									</div>										
								</div>
							</div>
						</div>
					</div>						

					<div class="airLines-priceinfoy1 pt-4">
						<div class="airLines-flex d-flex align-items-center justify-content-between">
							<div class="airLinesyscb">
								<h4 class="fs-4 m-0">{{ meta?.SelectedFlight?.Fare?.Currency }} 
									<span v-if="meta?.RoundTripSelectedFlight"> {{ meta?.SelectedFlight?.Fare?.PublishedFare + meta?.RoundTripSelectedFlight?.Fare?.PublishedFare }} </span>
									<span v-else> {{ meta?.SelectedFlight?.Fare?.PublishedFare }} </span>
								</h4>
								<p class="text-md m-0">Total price for all travellers</p>
							</div>
							<div class="flds-ytu">
									<button class="btn btn-primary btn-md fw-medium" @click="booknow">Book Now</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>        -->
</div>
</section>
</div>

</template>

<script>
let FlightCity = require('@/components/FlightCity.vue').default;
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
	name: 'FlightList',
	components: { FlightCity,flatPickr },
	data(){
		return {
			search: {
				EndUserIp:'',
				TokenId:'',
				AdultCount:1,
				ChildCount:0,
				InfantCount:0,
				DirectFlight:false,
				OneStopFlight:false,
				JourneyType:1,
				PreferredAirlines:null,
				PreferredCurrency:this.$store.state.currency,
				Segments:[],
				// Sources:['GDS'],
				Sources:null,
				Origin:'',
				OriginName:'',
				Destination:'',
				DestinationName:'',
				FlightCabinClass:1,
				PreferredDepartureTime:moment().format('YYYY-MM-DD'),
				PreferredArrivalTime:moment().add(1, "Days").format('YYYY-MM-DD'),
			},
			meta: {
				EndUserIp:'',
				TokenId:'',
				TraceId:'',
				ResultIndex:'',
				OBResultIndex:'',
				IBResultIndex:'',
				SelectedFlight:null,
				SelectedFare:null,
				RoundTripSelectedFare:null,
				RoundTripSelectedFlight:null,
			},
			search_flights:[],
			filteredFlightData:[],
			errors:[],
			error: false,
			flight_from:'',
			flight_to:'',
			status:false,
			searchStatus:false,
			mindate: moment().format('YYYY-MM-DD'),
			isVisible: false,

			SelectedFare:'',
			RoundTripSelectedFare:'',
			departure_active: [],
			arrival_active: [],
			stops_active: [],
			filterAirlines: [],
			filterAirline:[],
			flatpickrConfig: {
				dateFormat: "Y-m-d",
				minDate: moment().format("YYYY-MM-DD"), // Restrict selection to today or future dates
			},
			multi_city_flights: [
				{
					Origin: '',
					Destination: '',
					OriginName: '',
					DestinationName: '',
					PreferredDepartureTime: moment().format("YYYY-MM-DD"),
					PreferredArrivalTime: moment().format("YYYY-MM-DD"),
					FlightCabinClass: 1,
					errors:[],
				}
			]
		}
	},
	mounted(){
		window.scrollTo(0, 0);    
		this.search = this.$store.getters.flight_search;
        this.meta = this.$store.getters.flight_meta;
		this.reset_data();
		if(this.search?.JourneyType==3){
			this.status = true;
			this.multi_city_flights = []
			this.search?.Segments?.map((array, key) => 
				this.multi_city_flights.push(array)
			)
		}
		if(this.$store.getters.flight_result){
			this.search_flights = this.$store.getters.flight_result;
			this.filteredFlightData = this.search_flights
			this.getAirlines();
		}
	},
	watch:{
		// "$store.getters.flight_meta.SelectedFlight":{
		// 	handler(){
		// 		console.log('insied meta watch')
		// 		console.log(this.$store.getters.flight_meta.SelectedFlight);
		// 	}
		// }
		// "search.JourneyType":{
		// 	handler(){
		// 		this.search_flights=[];
		// 		console.log('test1')
		// 	}
		// },
		// "search.FlightCabinClass":{
		// 	handler(){
		// 		this.search_flights=[];
		// 		console.log('test2')
		// 	}
		// },
		// "search.Origin":{
		// 	handler(){
		// 		this.search_flights=[];
		// 		console.log('test3')
		// 	}
		// },
		// "search.Destination":{
		// 	handler(){
		// 		this.search_flights=[];
		// 		console.log('test4')
		// 	}
		// },

	},

	methods: {
		
		toggleDepartureFilter(keyword) {
			// console.log('toggleDepartureFilter:----', keyword)
			if (this.isSelectedDeparture(keyword)) {
				this.departure_active = this.departure_active.filter((item) => item !== keyword);
			} else {
				this.departure_active.push(keyword);
			}
			this.applyFilters();
		},
		isSelectedDeparture(keyword) {
			return this.departure_active.includes(keyword);
		},

		
		toggleArrivalFilter(keyword) {
			if (this.isSelectedArrival(keyword)) {
				this.arrival_active = this.arrival_active.filter((item) => item !== keyword);
			} else {
				this.arrival_active.push(keyword);
			}
			// this.applyFilters();
		},
		isSelectedArrival(keyword) {
			return this.arrival_active.includes(keyword);
		},


		toggleStopFilter(keyword) {
			if (this.isSelectedStop(keyword)) {
				this.stops_active = this.stops_active.filter((item) => item !== keyword);
			} else {
				this.stops_active.push(keyword);
			}
			this.applyFilters();
		},
		isSelectedStop(keyword) {
			return this.stops_active.includes(keyword);
		},
		toggleFlightFilter(){
			// console.log(this.filterAirline.length)
			this.applyFilters()
		},

		getPrice(keyword) {
			// console.log('getPrice', keyword)
			let vm = this;
			if (keyword == "high") {
				// vm.filteredFlightData.sort((a, b) => b.BusPrice.PublishedPrice - a.BusPrice.PublishedPrice);
				vm.filteredFlightData = vm.filteredFlightData.map((nestedArray, key) => 
					nestedArray.sort( (a, b) => b.Fare.PublishedFare - a.Fare.PublishedFare)
				);
			}
			if (keyword == "low") {
				
				// vm.filteredFlightData.sort((a, b) => a.BusPrice.PublishedPrice - b.BusPrice.PublishedPrice);
				// vm.filteredFlightData[0].map(a => {
				// 	console.log('a:', a);
				// })
				vm.filteredFlightData = vm.filteredFlightData.map((nestedArray, key) => 
					nestedArray.sort( (a, b) => a.Fare.PublishedFare - b.Fare.PublishedFare)
				);
			}
		},

		applyFilters() {
			let vm = this;
			vm.filteredFlightData = vm.search_flights;
			if (vm.departure_active.length > 0) {			
				vm.filteredFlightData = vm.filteredFlightData.map((nestedArray, key) => 
					nestedArray.filter((item) => {
						let eventDate = item.Segments[0][0]?.Origin.DepTime
						return vm.departure_active.some((keyword) => {
							if(key == 0){
								if (keyword === "before6am") {
									const filterDate = moment(vm.search.Segments[0].PreferredDepartureTime).format('YYYY-MM-DDT06:00:00');
									return eventDate <= filterDate;
								} else if (keyword === "6am12pm") {
									const filterFromDate = moment(vm.search.Segments[0].PreferredDepartureTime).format('YYYY-MM-DDT06:00:00');
									const filterToDate = moment(vm.search.Segments[0].PreferredDepartureTime).format('YYYY-MM-DDT12:00:00');
									return eventDate >= filterFromDate && eventDate <= filterToDate;
								} else if (keyword === "12pm6pm") {
									const filterFromDate = moment(vm.search.Segments[0].PreferredDepartureTime).format('YYYY-MM-DDT12:00:00');
									const filterToDate = moment(vm.search.Segments[0].PreferredDepartureTime).format('YYYY-MM-DDT18:00:00');
									return eventDate >= filterFromDate && eventDate <= filterToDate;
								} else if (keyword === "after6pm") {
									const filterDate = moment(vm.search.PreferredDepartureTime).format('YYYY-MM-DDT18:00:00');
									return eventDate >= filterDate;
								}
							}
							if(key == 1){
								if (keyword === "before6am") {
									const filterDate = moment(vm.search.Segments[0].PreferredArrivalTime).format('YYYY-MM-DDT06:00:00');
									return eventDate <= filterDate;
								} else if (keyword === "6am12pm") {
									const filterFromDate = moment(vm.search.Segments[0].PreferredArrivalTime).format('YYYY-MM-DDT06:00:00');
									const filterToDate = moment(vm.search.Segments[0].PreferredArrivalTime).format('YYYY-MM-DDT12:00:00');
									return eventDate >= filterFromDate && eventDate <= filterToDate;
								} else if (keyword === "12pm6pm") {
									const filterFromDate = moment(vm.search.Segments[0].PreferredArrivalTime).format('YYYY-MM-DDT12:00:00');
									const filterToDate = moment(vm.search.Segments[0].PreferredArrivalTime).format('YYYY-MM-DDT18:00:00');
									return eventDate >= filterFromDate && eventDate <= filterToDate;
								} else if (keyword === "after6pm") {
									const filterDate = moment(vm.search.Segments[0].PreferredArrivalTime).format('YYYY-MM-DDT18:00:00');
									return eventDate >= filterDate;
								}
							}
						});
					})
				);
			}			

			if (vm.stops_active.length > 0) {			
				vm.filteredFlightData = vm.filteredFlightData.map((nestedArray, key) => 
					nestedArray.filter((item) => {
						let stops = ''
						if(vm.search.JourneyType==3){
							stops = item.Segments.length
						}
						else{
							stops = item.Segments[0].length
						}
						return vm.stops_active.some((keyword) => {
							if(key == 0){
								if (keyword === "direct") {
									const stop = 1;
									return stops == stop;
								} else if (keyword === "1stop") {
									const stop = 2;
									return stops == stop;
								} else if (keyword === "2stop") {
									const stop = 3;
									return stops == stop;
								}
							}
							if(key == 1){
								if (keyword === "direct") {
									const stop = 1;
									return stops == stop;
								} else if (keyword === "1stop") {
									const stop = 2;
									return stops == stop;
								} else if (keyword === "2stop") {
									const stop = 3;
									return stops == stop;
								}
							}
						});
					})
				);
			}

			if (vm.filterAirline.length > 0){
				vm.filteredFlightData = vm.filteredFlightData.map((nestedArray, key) => 
					nestedArray.filter((item) => {
						let airline_name = item.Segments[0][0]?.Airline?.AirlineName
						return vm.filterAirline.some((keyword) => {
							// console.log('keyword:---', keyword)								
							return keyword == airline_name;
						});
					})
				);
			}
		},
		getAirlines(){
			let vm = this;
			vm.filteredFlightData.map((nestedArray, key) => {
				nestedArray.filter((item) => {
					let airline = item.Segments[0][0]?.Airline.AirlineName;
					let aircode = item.Segments[0][0]?.Airline.AirlineCode;

					// Check if airline and aircode combination doesn't already exist
					const exists = vm.filterAirlines.some(
						airlineObj => airlineObj.airline === airline && airlineObj.aircode === aircode
					);

					if (!exists) {
						vm.filterAirlines.push({ airline: airline, aircode: aircode });
						console.log('vm.filterAirlines:---', vm.filterAirlines);
					}
				});
			});
		},


		reset_data(){
			let vm = this;
			vm.meta.ResultIndex = '';
			vm.meta.OBResultIndex = '';
			vm.meta.IBResultIndex = '';
			vm.meta.SelectedFlight = null;
			vm.meta.SelectedFare = null;
			vm.meta.RoundTripSelectedFare = null;
			vm.meta.RoundTripSelectedFlight = null;
            vm.$store.dispatch("setFlightMeta", vm.meta);
			vm.$store.dispatch("setFlightBooking", null);
			vm.$store.dispatch("setReturnFlightBooking", null);
		},
		selectOrigin(e) {
			const flight = e.target.value;
			this.search.Origin = flight.airport_code;
			this.search.OriginName = flight.city_name +' ('+flight.airport_code+')';
		},

		selectDestination(e) {
			const flight = e.target.value;
			this.search.Destination = flight.airport_code;
			this.search.DestinationName = flight.city_name +' ('+flight.airport_code+')';
		},

		exchangeAirport() {
			let Origin = this.search.Origin;
			let OriginName = this.search.OriginName;

			this.search.Origin = this.search.Destination;
			this.search.OriginName = this.search.DestinationName;

			this.search.Destination = Origin;
			this.search.DestinationName = OriginName;
		},
		showMultiCity(data, value){
			this.isVisible = false;
			this.search.JourneyType = value;
			this.search_flights = [];
			this.filteredFlightData = [];
			if(data=='oneway')
			{
				this.status = false;
			}
			else{
				this.status = true;
			}				
		},
		changePassengersCount(pax_type,sign) {
			if(pax_type=='Adult' && sign=="+") {
				this.search.AdultCount++;
			} 
			if(pax_type=='Adult' && sign=="-") {
				this.search.AdultCount>1 ? this.search.AdultCount-- : this.search.AdultCount
			}
			if(pax_type=='Child' && sign=="+") {
				this.search.ChildCount++;
			} 
			if(pax_type=='Child' && sign=="-") {
				this.search.ChildCount>=1 ? this.search.ChildCount-- : this.search.ChildCount
			}
			if(pax_type=='Infant' && sign=="+") {
				this.search.InfantCount++;
			} 
			if(pax_type=='Infant' && sign=="-") {
				this.search.InfantCount>=1 ? this.search.InfantCount-- : this.search.InfantCount
			}
			this.search_flights=[];
		},

		// Multi city fights
		addFlight() {
			const lastFlight = this.multi_city_flights[this.multi_city_flights.length - 1];
			this.multi_city_flights.push({
				Origin: lastFlight ? lastFlight.Destination : '',
				Destination: '',
				OriginName: lastFlight ? lastFlight.DestinationName : '',
				DestinationName: '',
				PreferredDepartureTime: '',
				PreferredArrivalTime: '',
				FlightCabinClass: 1,
				errors:[],
			});
			// console.log('multi_city_flights:---', this.multi_city_flights)
		},
		removeFlight(index) {
			if (this.multi_city_flights.length > 1) {
				this.multi_city_flights.splice(index, 1);
			}
		},
		selectOriginMultiple(e, index) {
			const flight = e.target.value;
			this.multi_city_flights[index].Origin = flight.airport_code;
			this.multi_city_flights[index].OriginName = flight.city_name +' ('+flight.airport_code+')'

			if (index > 0) {
				this.multi_city_flights[index - 1].DestinationName = this.multi_city_flights[index].OriginName;
				this.multi_city_flights[index - 1].Destination = this.multi_city_flights[index].Origin;
			}
		},
		selectDestinationMultiple(e, index) {
			const flight = e.target.value;
			this.multi_city_flights[index].Destination = flight.airport_code;
			this.multi_city_flights[index].DestinationName = flight.city_name +' ('+flight.airport_code+')';

			if (index < this.multi_city_flights.length - 1) {
				this.multi_city_flights[index + 1].OriginName = this.multi_city_flights[index].DestinationName;
				this.multi_city_flights[index + 1].Origin = this.multi_city_flights[index].Destination;
			}
		},			
		swapLocations(index) {			
			let tempOriginName = this.multi_city_flights[index].OriginName;
			this.multi_city_flights[index].OriginName = this.multi_city_flights[index].DestinationName;
			this.multi_city_flights[index].DestinationName = tempOriginName;

			let tempOrigin = this.multi_city_flights[index].Origin;
			this.multi_city_flights[index].Origin = this.multi_city_flights[index].Destination;
			this.multi_city_flights[index].Destination = tempOrigin;
		
			if (index > 0) {
				this.multi_city_flights[index - 1].DestinationName = this.multi_city_flights[index].OriginName;
				this.multi_city_flights[index - 1].Destination = this.multi_city_flights[index].Origin;
			}

			if (index < this.multi_city_flights.length - 1) {
				this.multi_city_flights[index + 1].OriginName = this.multi_city_flights[index].DestinationName;
				this.multi_city_flights[index + 1].Origin = this.multi_city_flights[index].Destination;
			}
		},
		updateFlightCabinClass(newClass){
			this.multi_city_flights.forEach(flight => {
				flight.FlightCabinClass = newClass;
			});
		},
		updateFlightArrivalTime(departure_time, index){
			this.multi_city_flights[index].PreferredArrivalTime = departure_time;
		},
		
		
		searchFlights() {
                let vm = this;
                vm.search.Segments = [];
                if(vm.search.JourneyType==1){
                    vm.search.Segments.push({
                        'Origin': vm.search.Origin,
                        'Destination': vm.search.Destination,
                        'FlightCabinClass': vm.search.FlightCabinClass,
                        'PreferredDepartureTime': moment(vm.search.PreferredDepartureTime),
                        'PreferredArrivalTime': moment(vm.search.PreferredDepartureTime),
                    })

					if(vm.search.Origin === ''){
                        vm.errors['Origin'] = ['Enter Origin Location'];
                        vm.error = true;
                    }
                    else if(vm.search.Destination === ''){
                        delete vm.errors['Origin'];
                        vm.errors['Destination'] = ['Enter Destination Location'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredDepartureTime < moment().format("YYYY-MM-DD")){
						if(vm.search.Destination !== ''){
							delete vm.errors['Destination'];
						}
                        vm.errors['PreferredDepartureTime'] = ['Departure Date Must Be Grater Than Or Equal To Today'];
                        vm.error = true;
                    }
                    else{                       
                        if(vm.errors['Origin']){
                            delete vm.errors['Origin'];
                        }
                        if(vm.errors['Destination']){
                            delete vm.errors['Destination'];
                        }
                        if(vm.errors['PreferredDepartureTime']){
                            delete vm.errors['PreferredDepartureTime'];
                        }
                        vm.error = false;
                    }
                }
                if(vm.search.JourneyType==2){
                    vm.search.Segments.push({
                        'Origin': vm.search.Origin,
                        'Destination': vm.search.Destination,
                        'FlightCabinClass': vm.search.FlightCabinClass,
                        'PreferredDepartureTime': moment(vm.search.PreferredDepartureTime),
                        'PreferredArrivalTime': moment(vm.search.PreferredDepartureTime),
                    },
                    {
                        'Origin': vm.search.Destination,
                        'Destination': vm.search.Origin,
                        'FlightCabinClass': vm.search.FlightCabinClass,
                        'PreferredDepartureTime': moment(vm.search.PreferredArrivalTime),
                        'PreferredArrivalTime': moment(vm.search.PreferredArrivalTime),
                    })

					if(vm.search.Origin === ''){
                        vm.errors['Origin'] = ['Enter Origin Location '];
                        vm.error = true;
                    }
                    else if(vm.search.Destination === ''){
                        delete vm.errors['Origin'];
                        vm.errors['Destination'] = ['Enter Destination Location'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredDepartureTime < moment().format("YYYY-MM-DD")){
						if(vm.search.Destination !== ''){
							delete vm.errors['Destination'];
						}
                        vm.errors['PreferredDepartureTime'] = ['Departure Date Must Be Grater Than Or Equal To Today'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredArrivalTime < moment().format("YYYY-MM-DD")){
                        vm.errors['PreferredArrivalTime'] = ['Arrival Date Must Be Grater Than Or Equal To Today'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredDepartureTime > vm.search.PreferredArrivalTime){
                        vm.errors['PreferredDepartureTime'] = ['Departure Date Must Be Less Than Arrival'];
                        vm.errors['PreferredArrivalTime'] = ['Arrival Date Must Be Grater Than Departure'];
                        vm.error = true;
                    }
                    else{
                        if(vm.errors){
                            if(vm.errors['Origin']){
                                delete vm.errors['Origin'];
                            }
                            if(vm.errors['Destination']){
                                delete vm.errors['Destination'];
                            }
                            if(vm.errors['PreferredDepartureTime']){
                                delete vm.errors['PreferredDepartureTime'];
                            }
                            if(vm.errors['PreferredArrivalTime']){
                                delete vm.errors['PreferredArrivalTime'];
                            }
                        }                    
                        vm.error = false;
                    }
                }
				if (vm.search.JourneyType == 3) {
                    vm.search.Segments = vm.multi_city_flights;
					console.log('vm.search.Segments:-----', vm.search.Segments)
                    for(let i=0; i<vm.search.Segments.length; i++) {
                        if(vm.search.Segments[i].Origin==""){                            
                            vm.search.Segments[i].errors['Origin'] = ['Enter Origin Location'];
                        }
                        else{
							if('Origin' in vm.search.Segments[i].errors){
								delete vm.search.Segments[i].errors['Origin'];
							}                            
                        }
                        if(vm.search.Segments[i].Destination==""){                           
                            vm.search.Segments[i].errors['Destination'] = ['Enter Destination Location'];
                        }
                        else{
							if('Destination' in vm.search.Segments[i].errors){
								delete vm.search.Segments[i].errors['Destination'];
							}                            
                        }
                        if(vm.search.Segments[i].PreferredDepartureTime < moment().format("YYYY-MM-DD")){
                            vm.search.Segments[i].errors['PreferredDepartureTime'] = ['Must Be Grater Than Or Equal To Today'];                        
                        }
                        else{
							if('PreferredDepartureTime' in vm.search.Segments[i].errors){
								delete vm.search.Segments[i].errors['PreferredDepartureTime'];
							}                            
                        }
						if(vm.search.Segments[i-1]?.PreferredDepartureTime != undefined){
							if(vm.search.Segments[i]?.PreferredDepartureTime < vm.search.Segments[i-1]?.PreferredDepartureTime ){
								vm.search.Segments[i].errors['PreferredDepartureTime'] = ['Must Be Grater Than Above Segment Date'];
							}
						}
                        const hasErrors = Object.keys(vm.search.Segments[i].errors).some(key => vm.search.Segments[i].errors[key].length > 0);
                        if (hasErrors) {
                            vm.error = true;
                        } else {
                            vm.error = false;
                        }
                    }
                }
				vm.search_flights = [];
				vm.filteredFlightData = [];
				if(!vm.error){
					let loader = vm.$loading.show();
					
					vm.search.PreferredCurrency = this.$store.state.currency;
					vm.$store.dispatch("setFlightSearch", vm.search);
					vm.$store.dispatch("post", { uri: "flights/search", data: vm.search})
					.then(function (response) {
						loader.hide();
						vm.meta.TraceId = response.data.Response.TraceId;
						vm.search_flights = response.data.Response.Results;
						vm.filteredFlightData = vm.search_flights					
						vm.$store.dispatch("setFlightMeta", vm.meta);
						vm.$store.dispatch("setFlightResult", vm.search_flights);
						vm.getAirlines();
						// vm.$router.push('/flight_list')
						
					})
					.catch(function (error) {
						loader.hide();
						// console.log('error:---', error)
						vm.errors = error.response.data.error;
						vm.$store.dispatch("error", error.response.data.message);
					});
				}
            },

		Select_flight(flight_data, type){
			let vm = this;
			let arrival = document.getElementById('flight_0');
			let departure = document.getElementById('flight_1');
			let departure_flight = document.getElementById('departure_flight');
			let arrival_flight = document.getElementById('arrival_flight');
			// console.log('type:---', type, vm.search?.JourneyType, departure)
			if(type==0){
				vm.flight_from = flight_data
				if(vm.search?.JourneyType == 2)
				{
					arrival.style.display = 'none';	
					if(vm.search_flights.length!=1){
						departure.style.display = 'block';
						vm.isVisible = true;
						departure_flight.style.display = 'block';
					}				
					
					arrival_flight.style.display = 'block';
				}
			}
			else{
				vm.flight_to = flight_data
				window.scrollTo(0, 0);    
			}

			if(vm.flight_from && vm.flight_to){
				// let loader = vm.$loading.show();
				// vm.getFareQuote(vm.flight_from, 1);
				// await vm.delay(5000);
				// loader.hide();
				vm.meta.SelectedFlight = vm.flight_from;
				vm.meta.RoundTripSelectedFlight = vm.flight_to;
			}
			if(vm.flight_from && vm.flight_to){
				// let loader = vm.$loading.show();
				// vm.getFareQuote(vm.flight_to, 2)
				// await vm.delay(5000);
				// loader.hide();
				// document.getElementById('modal_bookflight').click();
				// console.log('both', vm.flight_to)
				vm.meta.SelectedFlight = vm.flight_from;
				vm.meta.RoundTripSelectedFlight = vm.flight_to;
				
				vm.meta.OBResultIndex = vm.flight_from.ResultIndex;
				vm.meta.IBResultIndex = vm.flight_to.ResultIndex;
				
				vm.$store.dispatch("setFlightSearch", vm.search);
				vm.$store.dispatch("setFlightMeta", vm.meta);
				vm.$router.push('/flight_detail')
			}
			if(vm.flight_from && vm.search_flights.length==1){
				// let loader = vm.$loading.show();
				// vm.getFareQuote(vm.flight_from, 1);
				// await vm.delay(5000);
				// loader.hide();
				// document.getElementById('modal_bookflight').click();
				// console.log('single')
				vm.meta.SelectedFlight = vm.flight_from;
				vm.meta.RoundTripSelectedFlight = null;

				vm.meta.OBResultIndex = vm.flight_from.ResultIndex;
				vm.meta.IBResultIndex = '';

				vm.$store.dispatch("setFlightSearch", vm.search);
				vm.$store.dispatch("setFlightMeta", vm.meta);
				vm.$router.push('/flight_detail')
			}                
		},

		delay(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		getFareQuote(flight, round) {
			let vm = this;
			vm.meta.ResultIndex = flight.ResultIndex;
			let loader = vm.$loading.show();
			vm.$store.dispatch("post", { uri: "flights/fareQuote", data: vm.meta})
			.then(function (response) {
				loader.hide();
				if(round==1){
					vm.meta.OBResultIndex = flight.ResultIndex;
					vm.meta.SelectedFare = response.data.Response.Results
					vm.meta.SelectedFlight = flight;
					vm.meta.IBResultIndex = null;
					vm.meta.RoundTripSelectedFare = null;
					vm.meta.RoundTripSelectedFlight = null;
					vm.$store.dispatch("setFlightSearch", vm.search);
					vm.$store.dispatch("setFlightMeta", vm.meta);
				}
				if(round==2){
					vm.meta.IBResultIndex = flight.ResultIndex;
					vm.meta.RoundTripSelectedFare = response.data.Response.Results
					vm.meta.RoundTripSelectedFlight = flight;
					vm.$store.dispatch("setFlightSearch", vm.search);
					vm.$store.dispatch("setFlightMeta", vm.meta);                           
				}
			})
			.catch(function (error) {
				loader.hide();
				vm.errors = error.response.data.error;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		booknow(){
			document.getElementById('bookClose').click();
			this.$router.push('/flight_detail')
		},
		reset_departure_flight(){
			this.flight_from = ""
			this.flight_to = ""
			let arrival = document.getElementById('flight_0');
			let departure = document.getElementById('flight_1');
			let departure_flight = document.getElementById('departure_flight');
			let arrival_flight = document.getElementById('arrival_flight');
			arrival.style.display = 'block';
			if(departure){
				this.isVisible = false;
				departure.style.display = 'none';
			}
			departure_flight.style.display = 'none';
			arrival_flight.style.display = 'none';
		},

		hideOthers(linkId, collapseId, iconId, flight) {
			// console.log('flight:---', flight)
            const collapseElement = document.getElementById(collapseId);
			const toggleTextElement = document.getElementById(linkId);
			const iconElement = document.getElementById(iconId);

			collapseElement.addEventListener('shown.bs.collapse', () => {
				toggleTextElement.innerHTML = 'Hide Flight Details';
				iconElement.innerHTML = `
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g id="chevron_forward">
							<mask id="mask0_1_5102" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
								<rect id="Bounding box" width="16" height="16" fill="#D9D9D9"/>
							</mask>
							<g mask="url(#mask0_1_5102)">
								<path id="chevron_forward_2" d="M7.80078 7.40013L4.73411 10.4668L3.80078 9.53346L7.80078 5.53346L11.8008 9.53346L10.8674 10.4668L7.80078 7.40013Z" fill="#1354FE"/>
							</g>
						</g>
					</svg>`;
			}, { once: true });

			collapseElement.addEventListener('hidden.bs.collapse', () => {
				toggleTextElement.innerHTML = 'Show Flight Details';
				iconElement.innerHTML = `
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<g id="chevron_forward">
							<mask id="mask0_86_16" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
							<rect id="Bounding box" x="16" y="16" width="16" height="16" transform="rotate(-180 16 16)" fill="#D9D9D9"/>
							</mask>
							<g mask="url(#mask0_86_16)">
								<path id="chevron_forward_2" d="M8.19922 8.59987L11.2659 5.5332L12.1992 6.46654L8.19922 10.4665L4.19922 6.46654L5.13255 5.5332L8.19922 8.59987Z" fill="#1354FE"/>
							</g>
						</g>
					</svg>`;
			}, { once: true });

			const allCollapsibles = document.querySelectorAll('.collapse');
			allCollapsibles.forEach((collapse) => {
				if (collapse.id !== collapseId) {
					const bsCollapse = new bootstrap.Collapse(collapse, {
						toggle: false
					});
					bsCollapse.hide();
					const otherLinkId = `toggleText_${collapse.id.split('_')[1]}_${collapse.id.split('_')[2]}`;
					const otherLink = document.getElementById(otherLinkId);

					const otherIconId = `icon_${collapse.id.split('_')[1]}_${collapse.id.split('_')[2]}`;
					const otherIcon = document.getElementById(otherIconId);
					if (otherLink) {
						otherLink.textContent = 'Show Flight Details';
					}
					if (otherIcon) {
						otherIcon.innerHTML = `
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<g id="chevron_forward">
								<mask id="mask0_86_16" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
								<rect id="Bounding box" x="16" y="16" width="16" height="16" transform="rotate(-180 16 16)" fill="#D9D9D9"/>
								</mask>
								<g mask="url(#mask0_86_16)">
									<path id="chevron_forward_2" d="M8.19922 8.59987L11.2659 5.5332L12.1992 6.46654L8.19922 10.4665L4.19922 6.46654L5.13255 5.5332L8.19922 8.59987Z" fill="#1354FE"/>
								</g>
							</g>
						</svg>`;
					}
				}
			});

        },
		printClass(cls_value){
			if(cls_value == 1){
				return 'All Class'
			}
			else if(cls_value == 2){
				return 'Economy Class'
			}
			else if(cls_value == 3){
				return 'Premium Economy Class'
			}
			else if(cls_value == 4){
				return 'Business Class'
			}
			else if(cls_value == 5){
				return 'Premium Business Class'
			}
			else if(cls_value == 6){
				return 'First Class'
			}			
		},
		AccumulatedDurationCalculation(segement){
			let duration = ""
			
			for(let i = 0; i <segement.length; i++){
				if(segement.length > 1){
					duration = segement[i]?.AccumulatedDuration ? segement[i]?.AccumulatedDuration : 0;
				}
				else{
					duration = segement[i]?.Duration ? segement[i]?.Duration : 0;
				}
			}
			return Math.floor(duration / 60)+ "H " + duration % 60 +"M";
		},
		calculateLayover(arrivalTime, nextDepartureTime) { 
			// console.log("arrivalTime, nextDepartureTime:-----", arrivalTime, nextDepartureTime)
			const arrival = new Date(arrivalTime); 
			const nextDeparture = new Date(nextDepartureTime); 
			const layoverMilliseconds = nextDeparture - arrival; 
			const layoverMinutes = Math.floor(layoverMilliseconds / (1000 * 60)); 
			const hours = Math.floor(layoverMinutes / 60); 
			const minutes = layoverMinutes % 60; 
			return `${hours}h ${minutes}m`; 
		}
	},
}
</script>


<style scoped>
.btn-flip-icon {
    position: absolute;
    left: 100%;
    top: 63%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #e6eaee;
    z-index: 1;
    font-size: 15px;
}
.filter-searchBar {
        position: sticky;
        top: 6rem;
        z-index: 7;
    }
</style>