<template>
    <!-- <div class="image-cover hero-header bg-white" style="background: url(img/banner-7.jpg) no-repeat;" data-overlay="6">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12">
                    <div class="position-relative text-center mb-5">
                        <h1>
                            Starts Your Trip with
                            <span class="position-relative z-4">
                                Fastays
                                <span class="position-absolute top-50 start-50 translate-middle d-none d-md-block mt-4">
                                    <svg width="185px" height="23px" viewBox="0 0 445.5 23">
                                        <path
                                            class="fill-white opacity-7"
                                            d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z"
                                        ></path>
                                    </svg>
                                </span>
                            </span>
                        </h1>
                        <p class="fs-5 fw-light">Take a little break from the work strss of everyday. Discover plan trip and explore beautiful destinations.</p>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="search-wrap bg-white rounded-3 p-3">
                        <div class="search-upper">
                            <div class="d-flex align-items-center justify-content-between flex-wrap">
                                <div class="flx-start mb-sm-0 mb-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" @click="showMultiCity('oneway', 1)" name="trip" id="oneway" value="1" checked />
                                        <label class="form-check-label" for="oneway">One Way</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" @click="showMultiCity('oneway', 2)" name="trip" id="round-trip" value="2" />
                                        <label class="form-check-label" for="round-trip">Round-trip</label>
                                    </div>
                                    <div class="form-check form-check-inline">
					<input class="form-check-input" type="radio" @click="showMultiCity('multitrip', 3)" name="trip" id="multi-city" value="option3">
					<label class="form-check-label" for="multi-city" >Multi-city</label>
					</div>
                                </div>
                                <div class="flx-end d-flex align-items-center flex-wrap">
                                    <div class="px-sm-2 pb-3 pt-0 ps-0 mob-full">
                                        <input
                                            readonly
                                            type="text"
                                            class="leaving form-control fw-bold select2"
                                            :value="search.AdultCount + +search.ChildCount + +search.InfantCount + ' Passengers '"
                                            id="dropdownGuest"
                                            data-bs-auto-close="outside"
                                            data-bs-toggle="dropdown"
                                        />
                                        <ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Adults</h6>
                                                    <small>Ages 13 or above</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="adults-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-adults">{{ search.AdultCount }}</span>
                                                    <button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="adults-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Child</h6>
                                                    <small>Ages Between 2 to 12 Years</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="children-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-children"> {{ search.ChildCount }}</span>
                                                    <button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="children-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Infant</h6>
                                                    <small>Ages Below 2 Years</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="infant-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-room">{{ search.InfantCount }}</span>

                                                    <button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="infant-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="ps-1 pb-3 pt-0 mob-full" v-if="!status">
                                        <div class="form-group hdd-arrow mb-0">
                                            <select class="leaving form-control fw-bold select2" v-model="search.FlightCabinClass">
                                                <option value="1">All Cabin Class</option>
                                                <option value="2">Economy Class</option>
                                                <option value="3">Premium Economy Class</option>
                                                <option value="4">Business Class</option>
                                                <option value="5">Premium Business Class</option>
                                                <option value="6">First Class</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="ps-1 pb-3 pt-0 mob-full" v-if="status">
                                        <div class="form-group hdd-arrow mb-0">
                                            <select class="leaving form-control fw-bold select2" v-model="search.FlightCabinClass" @change="updateFlightCabinClass(search.FlightCabinClass)">
                                                <option value="1">All Cabin Class</option>
                                                <option value="2">Economy Class</option>
                                                <option value="3">Premium Economy Class</option>
                                                <option value="4">Business Class</option>
                                                <option value="5">Premium Business Class</option>
                                                <option value="6">First Class</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!status" class="row gx-lg-2 g-3 mb-2">
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="row gy-3 gx-lg-2 gx-3">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                        <div class="form-group hdd-arrow mb-0">
                                            <FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="search.OriginName" @selectCity="selectOrigin($event)"></FlightCity>
                                        </div>
                                        <div class="btn-flip-icon mt-md-0">
                                            <button class="p-0 m-0 text-primary" @click.prevent="exchangeAirport"><i class="fa-solid fa-right-left"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-groupp hdd-arrow mb-0">
                                            <FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="search.DestinationName" @selectCity="selectDestination($event)"></FlightCity>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="row gy-3 gx-lg-2 gx-3">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group mb-0">
                                            <input class="form-control fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredDepartureTime" placeholder="Departure.." />
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" v-if="search.JourneyType==2">
                                        <div class="form-group mb-0">
                                            <input class="form-control fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredArrivalTime" placeholder="Return.." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <div class="form-group mb-0">
                                    <button ype="button" class="btn btn-primary full-width fw-medium" @click="searchFlights"><i class="fa-solid fa-magnifying-glass me-2"></i>Search</button>
                                </div>
                            </div>
                        </div>
                        <div class="row gx-lg-2 g-3" v-if="status">
                            <div class="row" v-for="(flight, index) in multi_city_flights" :key="index" style="padding-top: 10px;">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 position-relative">
                                    <div class="form-group hdd-arrow mb-0">
                                        <FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="flight.OriginName" @selectCity="selectOriginMultiple($event, index)"></FlightCity>
                                    </div>
                                    <div class="btn-flip-icon mt-md-0">
                                        <button @click="swapLocations(index)" class="p-0 m-0 text-primary"><i class="fa-solid fa-right-left"></i></button>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                    <div class="form-groupp hdd-arrow mb-0">
                                        <FlightCity :customClass="'leaving form-control fw-bold select2'" :initialize="flight.DestinationName" @selectCity="selectDestinationMultiple($event, index)"></FlightCity>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                    <div class="form-group mb-0">
                                        <input
                                            class="form-control fw-bold choosedate"
                                            type="date"
                                            :min="mindate"
                                            v-model="flight.PreferredDepartureTime"
                                            @change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)"
                                            placeholder="Departure.."
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2">
                                    <div class="form-group mb-0">
                                        <button @click="removeFlight(index)" type="button" class="btn fw-medium">
                                            <i class="fa-solid fa-times me-2"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="text-align: right;">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <button style="width: 16%;" @click="addFlight" type="button" class="btn btn-light-primary full-width fw-medium"><i class="fa-solid fa-add me-2"></i>Add Flight</button>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <div class="form-group mb-0">
                                    <button ype="button" class="btn btn-primary full-width fw-medium" @click="searchFlights"><i class="fa-solid fa-magnifying-glass me-2"></i>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    

    <!-- try starts -->
    <div class="image-cover hero-header bg-white" style="background: url(images/Flight.png) no-repeat;" data-overlay="5">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12">
                    <div class="position-relative text-center mb-3">
                        <h1>Book Flight Tickets</h1>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="search-wrap with-label bg-white rounded-3 p-3">
                        <div class="search-upper">
                            <div class="mb-3 text-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" @click="showMultiCity('oneway', 1)" name="trip" id="oneway" value="1" checked />
                                    <label class="form-check-label" for="oneway">One Way</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" @click="showMultiCity('oneway', 2)" name="trip" id="round-trip" value="2" />
                                    <label class="form-check-label" for="round-trip">Round-trip</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" @click="showMultiCity('multitrip', 3)" name="trip" id="multi-city" value="option3">
                                    <label class="form-check-label" for="multi-city" >Multi-city</label>
                                </div>
                            </div>
                        </div>
                        <div class="row gx-lg-2 g-3" v-if="!status">
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="row gy-3 gx-lg-2 gx-3">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                        <div class="form-group hdd-arrow mb-0">
                                            <label>Leaving From</label>
                                            <FlightCity :customClass="['leaving', 'form-control', 'fw-bold', 'select2', errors?.Origin ? 'is-invalid' : '']" :initialize="search.OriginName" @selectCity="selectOrigin($event)"></FlightCity>
                                            <span v-if="errors?.Origin" class="invalid-feedback" :style="errors?.Origin ? 'display:block' : ''">{{ errors?.Origin[0] }}</span>
                                        </div>
                                        <div class="btn-flip-icon mt-md-0">
                                            <button class="p-0 m-0 text-primary"  @click.prevent="exchangeAirport"><i class="fa-solid fa-right-left"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group hdd-arrow mb-0">
                                            <label>Going To</label>
                                            <FlightCity :customClass="['leaving', 'form-control', 'fw-bold', 'select2', errors?.Destination ? 'is-invalid' : '']" :initialize="search.DestinationName" @selectCity="selectDestination($event)"></FlightCity>
                                            <span v-if="errors?.Destination" class="invalid-feedback" :style="errors?.Destination ? 'display:block' : ''">{{ errors?.Destination[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  class="col-xl-3 col-lg-4 col-md-12">
                                <div class="row gy-3 gx-lg-2 gx-3" v-if="search.JourneyType==1">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
                                        <div class="form-group mb-0">
                                            <label>Departure Date</label>
                                            <!-- <input :class="{ 'is-invalid': errors?.PreferredDepartureTime }" class="form-control hgt-62 fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredDepartureTime" placeholder="Departure.." /> -->
                                            <flatPickr 
                                                class="form-control hgt-62 fw-bold choosedate" 
                                                placeholder="Select date" 
                                                :class="{ 'is-invalid': errors?.PreferredDepartureTime }"  
                                                type="date"  
                                                v-model="search.PreferredDepartureTime" 
                                                :config="flatpickrConfig">
                                            </flatPickr>
                                            <span v-if="errors?.PreferredDepartureTime" class="invalid-feedback">{{ errors?.PreferredDepartureTime[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gy-3 gx-lg-2 gx-3" v-if="search.JourneyType==2">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" >
                                        <div class="form-group mb-0">
                                            <label>Departure Date</label>
                                            <!-- <input :class="{ 'is-invalid': errors?.PreferredDepartureTime }" class="form-control hgt-62 fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredDepartureTime" placeholder="Departure.." /> -->
                                            <flatPickr 
                                                class="form-control hgt-62 fw-bold choosedate" 
                                                placeholder="Select date" 
                                                :class="{ 'is-invalid': errors?.PreferredDepartureTime }"  
                                                type="date"  
                                                v-model="search.PreferredDepartureTime" 
                                                :config="flatpickrConfig">
                                            </flatPickr>
                                            <span v-if="errors?.PreferredDepartureTime" class="invalid-feedback">{{ errors?.PreferredDepartureTime[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" >
                                        <div class="form-group mb-0">
                                            <label>Return Date</label>
                                            <!-- <input :class="{ 'is-invalid': errors?.PreferredArrivalTime }" class="form-control hgt-62 fw-bold choosedate" type="date" :min="mindate" v-model="search.PreferredArrivalTime" placeholder="Return.." /> -->
                                            <flatPickr 
                                                class="form-control hgt-62 fw-bold choosedate" 
                                                placeholder="Select date" 
                                                :class="{ 'is-invalid': errors?.PreferredArrivalTime }"  
                                                type="date"  
                                                v-model="search.PreferredArrivalTime" 
                                                :config="flatpickrConfig">
                                            </flatPickr>
                                            <span v-if="errors?.PreferredArrivalTime" class="invalid-feedback">{{ errors?.PreferredArrivalTime[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-2 col-md-12">
                                <div class="form-group mb-0">
                                    <div class="px-sm-2 pb-3 pt-0 ps-0 mob-full">
                                        <label>Travellers & Class</label>
                                        <input
                                            readonly
                                            type="text"
                                            class="leaving form-control fw-bold select2"
                                            :value="search.AdultCount + +search.ChildCount + +search.InfantCount + ' Passengers, ' + printClass(search.FlightCabinClass) "
                                            id="dropdownGuest"
                                            data-bs-auto-close="outside"
                                            data-bs-toggle="dropdown"
                                        />
                                        <ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Adults</h6>
                                                    <small>Ages 13 or above</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="adults-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-adults">{{ search.AdultCount }}</span>
                                                    <button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="adults-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Child</h6>
                                                    <small>Ages Between 2 to 12 Years</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="children-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-children"> {{ search.ChildCount }}</span>
                                                    <button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="children-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Infant</h6>
                                                    <small>Ages Below 2 Years</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="infant-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-room">{{ search.InfantCount }}</span>

                                                    <button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="infant-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li>
                                                <div>
                                                    <h6 class="mb-2" style="color: black;">Class</h6>
                                                </div>
                                                <select class="leaving form-control fw-bold select2" v-model="search.FlightCabinClass">
                                                    <option value="1">All Cabin Class</option>
                                                    <option value="2">Economy Class</option>
                                                    <option value="3">Premium Economy Class</option>
                                                    <option value="4">Business Class</option>
                                                    <option value="5">Premium Business Class</option>
                                                    <option value="6">First Class</option>
                                                </select>
                                            </li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="status" v-for="(flight, index) in multi_city_flights" :key="index" style="padding-top: 10px;">                            
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="row gy-3 gx-lg-2 gx-3">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                        <div class="form-group hdd-arrow mb-0">
                                            <label>Leaving From</label>
                                            <FlightCity  :customClass="['leaving', 'form-control', 'fw-bold', 'select2', flight.errors?.Origin ? 'is-invalid' : '']"  :initialize="flight.OriginName" @selectCity="selectOriginMultiple($event, index)"></FlightCity>
                                            <span v-if="flight.errors?.Origin" class="invalid-feedback" :style="flight.errors?.Origin ? 'display:block' : ''">{{ flight.errors?.Origin[0] }}</span>
                                        </div>
                                        <div class="btn-flip-icon mt-md-0">
                                            <button @click="swapLocations(index)" class="p-0 m-0 text-primary"><i class="fa-solid fa-right-left"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="form-group hdd-arrow mb-0">
                                            <label>Going To</label>
                                            <FlightCity :customClass="['leaving', 'form-control', 'fw-bold', 'select2', flight.errors?.Destination ? 'is-invalid' : '']"  :initialize="flight.DestinationName" @selectCity="selectDestinationMultiple($event, index)"></FlightCity>
                                            <span v-if="flight.errors?.Destination" class="invalid-feedback" :style="flight.errors?.Destination ? 'display:block' : ''">{{ flight.errors?.Destination[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                <div class="form-group mb-0">
                                    <label>Departure</label>
                                    <!-- <input
                                        class="form-control fw-bold choosedate"
                                        :class="{ 'is-invalid': flight.errors?.PreferredDepartureTime }" 
                                        type="date"
                                        :min="mindate"
                                        v-model="flight.PreferredDepartureTime"
                                        @change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)"
                                        placeholder="Departure.."
                                    /> -->
                                    <flatPickr 
                                        class="form-control hgt-62 fw-bold choosedate" 
                                        placeholder="Select date" 
                                        :class="{ 'is-invalid': flight.errors?.PreferredDepartureTime }" 
                                        type="date"  
                                        v-model="flight.PreferredDepartureTime" 
                                        @change="updateFlightArrivalTime(flight.PreferredDepartureTime, index)" 
                                        :config="flatpickrConfig">
                                    </flatPickr>
                                    <span v-if="flight.errors?.PreferredDepartureTime" class="invalid-feedback">{{ flight.errors?.PreferredDepartureTime[0] }}</span>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" v-if="index === 0">
                                <div class="form-group mb-0">
                                    <div class="px-sm-2 pb-3 pt-0 ps-0 mob-full">
                                        <label>Travellers & Class</label>
                                        <input
                                            readonly
                                            type="text"
                                            class="leaving form-control fw-bold select2"
                                            :value="search.AdultCount + +search.ChildCount + +search.InfantCount + ' Passengers, ' + printClass(search.FlightCabinClass) "
                                            id="dropdownGuest"
                                            data-bs-auto-close="outside"
                                            data-bs-toggle="dropdown"
                                        />
                                        <ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Adults</h6>
                                                    <small>Ages 13 or above</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Adult','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="adults-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-adults">{{ search.AdultCount }}</span>
                                                    <button @click="changePassengersCount('Adult','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="adults-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Child</h6>
                                                    <small>Ages Between 2 to 12 Years</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Child','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="children-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-children"> {{ search.ChildCount }}</span>
                                                    <button @click="changePassengersCount('Child','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="children-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li class="d-flex justify-content-between">
                                                <div>
                                                    <h6 class="mb-0" style="color: black;">Infant</h6>
                                                    <small>Ages Below 2 Years</small>
                                                </div>
                                                <div class="hstack gap-1 align-items-center">
                                                    <button @click="changePassengersCount('Infant','-')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl minus" id="infant-subs-btn">
                                                            <i class="fa-solid fa-minus"></i>
                                                        </span>
                                                    </button>
                                                    <span id="guests-count-room">{{ search.InfantCount }}</span>

                                                    <button @click="changePassengersCount('Infant','+')" type="button" class="btn btn-link p-0 mb-0">
                                                        <span class="guests-input__ctrl plus" id="infant-add-btn">
                                                            <i class="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li>
                                                <div>
                                                    <h6 class="mb-2" style="color: black;">Class</h6>
                                                </div>
                                                <select class="leaving form-control fw-bold select2" v-model="search.FlightCabinClass">
                                                    <option value="1">All Cabin Class</option>
                                                    <option value="2">Economy Class</option>
                                                    <option value="3">Premium Economy Class</option>
                                                    <option value="4">Business Class</option>
                                                    <option value="5">Premium Business Class</option>
                                                    <option value="6">First Class</option>
                                                </select>
                                            </li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1">
                                <button v-if="index > 0 && index === multi_city_flights.length - 1" @click="removeFlight(index)" type="button" class="btn fw-medium">
                                    <i class="fa-solid fa-times"></i>
                                </button>                                                                      
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-end" v-if="index === multi_city_flights.length - 1" >
                                <button @click="addFlight" type="button" class="btn btn-light-primary full-width1 fw-medium"><i class="fa-solid fa-add me-2"></i>Add Flight</button>                                
                            </div>
                        </div>                           
                        <div class="col-12 text-center mt-0">
                            <button type="submit" @click="searchFlights" class="btn btn-primary fw-medium w-45" style="height:auto"><i class="fa-solid fa-magnifying-glass me-2"></i>Search</button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


	<!-- try ends -->
    <!-- ============================ Hero Banner End ================================== -->

    <section class="gray-simple" v-if="searchStatus">
        <div class="container">
            <div class="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                <!-- Sidebar Filter Options -->
                <div class="col-xl-3 col-lg-4 col-md-12">
                    <div class="filter-searchBar bg-white rounded-3">
                        <div class="filter-searchBar-head border-bottom">
                            <div class="searchBar-headerBody d-flex align-items-start justify-content-between px-3 py-3">
                                <div class="searchBar-headerfirst">
                                    <h6 class="fw-bold fs-5 m-0">Filters</h6>
                                    <p class="text-md text-muted m-0">Showing {{ search_flights[0]?.length }} Flights</p>
                                </div>
                                <div class="searchBar-headerlast text-end">
                                    <a href="#" class="text-md fw-medium text-primary active">Clear All</a>
                                </div>
                            </div>
                        </div>

                        <div class="filter-searchBar-body">
                            <!-- Departure & Return -->
                            <div class="searchBar-single px-3 py-3 border-bottom">
                                <div class="searchBar-single-title d-flex mb-3">
                                    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Departure</h6>
                                </div>
                                <div class="searchBar-single-wrap mb-4">
                                    <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="before6am" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="before6am">Before 6AM</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="6am12pm" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="6am12pm">6AM - 12PM</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="12pm6pm" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="12pm6pm">12PM - 6PM</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="after6pm" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="after6pm">After 6PM</label>
                                        </li>
                                    </ul>
                                </div>

                                <div class="searchBar-single-title d-flex mb-3">
                                    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Return</h6>
                                </div>
                                <div class="searchBar-single-wrap">
                                    <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="before6am1" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="before6am1">Before 6AM</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="6am12pm1" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="6am12pm1">6AM - 12PM</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="12pm6pm1" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="12pm6pm1">12PM - 6PM</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="after6pm1" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="after6pm1">After 6PM</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Onward Stops -->
                            <div class="searchBar-single px-3 py-3 border-bottom">
                                <div class="searchBar-single-title d-flex mb-3">
                                    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Onward Stops</h6>
                                </div>
                                <div class="searchBar-single-wrap">
                                    <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="direct" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="direct">Direct</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="1stop" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="1stop">1 Stop</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="2stop" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="2stop">2+ Stop</label>
                                        </li>
                                    </ul>
                                </div>

                                <div class="searchBar-single-title d-flex mb-3">
                                    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Return Stops</h6>
                                </div>
                                <div class="searchBar-single-wrap">
                                    <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="direct1" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="direct1">Direct</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="1stop1" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="1stop1">1 Stop</label>
                                        </li>
                                        <li class="col-6">
                                            <input type="checkbox" class="btn-check" id="2stop1" />
                                            <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="2stop1">2+ Stop</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Pricing -->
                            <div class="searchBar-single px-3 py-3 border-bottom">
                                <div class="searchBar-single-title d-flex mb-3">
                                    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Pricing Range in US$</h6>
                                </div>
                                <div class="searchBar-single-wrap">
                                    <input type="text" class="js-range-slider" name="my_range" value="" data-skin="round" data-type="double" data-min="0" data-max="1000" data-grid="false" />
                                </div>
                            </div>

                            <!-- Facilities -->
                            <div class="searchBar-single px-3 py-3 border-bottom">
                                <div class="searchBar-single-title d-flex mb-3">
                                    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Facilities</h6>
                                </div>
                                <div class="searchBar-single-wrap">
                                    <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                        <li class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="baggage" />
                                                <label class="form-check-label" for="baggage">Baggage</label>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="inflightmeal" />
                                                <label class="form-check-label" for="inflightmeal">In-flight Meal</label>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="inflightenter" />
                                                <label class="form-check-label" for="inflightenter">In-flight Entertainment</label>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="flswifi" />
                                                <label class="form-check-label" for="flswifi">WiFi</label>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="flusbport" />
                                                <label class="form-check-label" for="flusbport">Power/USB Port</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Popular Flights -->
                            <div class="searchBar-single px-3 py-3 border-bottom">
                                <div class="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
                                    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Preferred Airlines</h6>
                                    <a href="#" class="text-md fw-medium text-muted active">Reset</a>
                                </div>
                                <div class="searchBar-single-wrap">
                                    <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                        <li class="col-12">
                                            <div class="form-check lg">
                                                <div class="frm-slicing d-flex align-items-center">
                                                    <div class="frm-slicing-first">
                                                        <input class="form-check-input" type="checkbox" id="baggage8" />
                                                        <label class="form-check-label" for="baggage8"></label>
                                                    </div>
                                                    <div class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                        <div class="frms-flex d-flex align-items-center">
                                                            <div class="frm-slicing-img"><img src="img/air-1.png" class="img-fluid" width="25" alt="" /></div>

                                                            <div class="frm-slicing-title ps-2"><span class="text-muted-2">Air India</span></div>
                                                        </div>
                                                        <div class="text-end"><span class="text-md text-muted-2 opacity-75">$390.00</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check lg">
                                                <div class="frm-slicing d-flex align-items-center">
                                                    <div class="frm-slicing-first">
                                                        <input class="form-check-input" type="checkbox" id="baggage1" />
                                                        <label class="form-check-label" for="baggage1"></label>
                                                    </div>
                                                    <div class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                        <div class="frms-flex d-flex align-items-center">
                                                            <div class="frm-slicing-img"><img src="img/air-2.png" class="img-fluid" width="25" alt="" /></div>

                                                            <div class="frm-slicing-title ps-2"><span class="text-muted-2">Jal Airlines</span></div>
                                                        </div>
                                                        <div class="text-end"><span class="text-md text-muted-2 opacity-75">$310.00</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check lg">
                                                <div class="frm-slicing d-flex align-items-center">
                                                    <div class="frm-slicing-first">
                                                        <input class="form-check-input" type="checkbox" id="baggage2" />
                                                        <label class="form-check-label" for="baggage2"></label>
                                                    </div>
                                                    <div class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                        <div class="frms-flex d-flex align-items-center">
                                                            <div class="frm-slicing-img"><img src="img/air-3.png" class="img-fluid" width="25" alt="" /></div>

                                                            <div class="frm-slicing-title ps-2"><span class="text-muted-2">Indigo</span></div>
                                                        </div>
                                                        <div class="text-end"><span class="text-md text-muted-2 opacity-75">$390.00</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check lg">
                                                <div class="frm-slicing d-flex align-items-center">
                                                    <div class="frm-slicing-first">
                                                        <input class="form-check-input" type="checkbox" id="baggage3" />
                                                        <label class="form-check-label" for="baggage3"></label>
                                                    </div>
                                                    <div class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                        <div class="frms-flex d-flex align-items-center">
                                                            <div class="frm-slicing-img"><img src="img/air-4.png" class="img-fluid" width="25" alt="" /></div>

                                                            <div class="frm-slicing-title ps-2"><span class="text-muted-2">Air Asia</span></div>
                                                        </div>
                                                        <div class="text-end"><span class="text-md text-muted-2 opacity-75">$410.00</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="col-12">
                                            <div class="form-check lg">
                                                <div class="frm-slicing d-flex align-items-center">
                                                    <div class="frm-slicing-first">
                                                        <input class="form-check-input" type="checkbox" id="baggage4" />
                                                        <label class="form-check-label" for="baggage4"></label>
                                                    </div>
                                                    <div class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                        <div class="frms-flex d-flex align-items-center">
                                                            <div class="frm-slicing-img"><img src="img/air-5.png" class="img-fluid" width="25" alt="" /></div>

                                                            <div class="frm-slicing-title ps-2"><span class="text-muted-2">Vistara</span></div>
                                                        </div>
                                                        <div class="text-end"><span class="text-md text-muted-2 opacity-75">$370.00</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- All Flight Lists -->
                <div class="col-xl-9 col-lg-8 col-md-12">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-xl-4 col-lg-4 col-md-4">
                            <h5 class="fw-bold fs-6 mb-lg-0 mb-3">Showing {{ search_flights[0]?.length }} Search Results</h5>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-12">
                            <div class="d-flex align-items-center justify-content-start justify-content-lg-end flex-wrap">
                                <div class="flsx-first me-2">
                                    <div class="bg-white rounded py-2 px-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="mapoption" />
                                            <label class="form-check-label ms-1" for="mapoption">Map</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="flsx-first mt-sm-0 mt-2">
                                    <ul class="nav nav-pills nav-fill p-1 small lights blukker bg-primary rounded-3 shadow-sm" id="filtersblocks" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active rounded-3" id="trending" data-bs-toggle="tab" type="button" role="tab" aria-selected="true">Our Trending</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-3" id="mostpopular" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">Most Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-3" id="lowprice" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">Lowest Price</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="display: none;" id="departure_flight">
                        <div class="d-md-flex justify-content-md-between pt-5">
                            <h3 class="sc-fzpans sc-fzplWN dazDxU" data-testid="title">Departure to {{ search?.DestinationName }}</h3>
                            <!-- <a href="#" @click.prevent="reset_departure_flight()">Change</a> -->
                        </div>
                    </div>

                    <div v-if="flight_from && search?.JourneyType == 2" style="padding-bottom: 10px;">
                        <div class="flights-accordion">
                            <div class="flights-list-item bg-white rounded-3 p-3">
                                <div class="row gy-4 align-items-center justify-content-between">
                                    <div class="col" v-for="segment,j in flight_from.Segments" :key="j">
                                        <div class="row mb-3" v-for="airline,k in segment" :key="k">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                    <span class="text-muted text-sm">{{ $formatDate(airline.Origin.DepTime,'long') }}</span>
                                                </div>
                                            </div>

                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                    <div class="col-sm-auto">
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src="img/air-2.png" width="45" alt="image" />
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">{{ airline.Airline.AirlineName }}</div>
                                                                <div class="text-sm text-muted">
                                                                    <span v-if="airline.CabinClass==1">All Class</span>
                                                                    <span v-if="airline.CabinClass==2">Economy Class</span>
                                                                    <span v-if="airline.CabinClass==3">Premium Economy Class</span>
                                                                    <span v-if="airline.CabinClass==4">Business Class</span>
                                                                    <span v-if="airline.CabinClass==5">Premium Business Class</span>
                                                                    <span v-if="airline.CabinClass==6">First Class</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold">{{ $formatDate(airline.Origin.DepTime,'time') }}</div>
                                                                <div class="text-muted text-sm fw-medium">{{ airline.Origin.Airport.CityName }}</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine departure">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold">{{ $formatDate(airline.Destination.ArrTime,'time') }}</div>
                                                                <div class="text-muted text-sm fw-medium">{{ airline.Destination.Airport.CityName }}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">{{ Math.floor(airline.Duration / 60) }}H {{ airline.Duration % 60 }}M</div>
                                                        <!-- <div class="text-muted text-sm fw-medium">2 Stop</div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="row mt-2">
												<div class="col-xl-12 col-lg-12 col-md-12 ">
													<div class="float-end mb-2">
														<button class="btn btn-sm label bg-light-info text-info me-2 " data-bs-toggle="modal" data-bs-target="#flightDetailsModal">Flight Details</button>
														
													</div>
												</div>
											</div>   -->
                                    </div>

                                    <div class="col-md-auto">
                                        <div class="d-flex items-center h-100">
                                            <div class="d-lg-block d-none border br-dashed me-4"></div>
                                            <div>
                                                <div class="text-start text-md-end">
                                                    <!-- <span class="label bg-light-success text-success me-1">15% Off</span> -->
                                                    <div class="text-dark fs-3 fw-bold lh-base">{{ flight_from.Fare.Currency }} {{ flight_from.Fare.PublishedFare }}</div>
                                                    <div class="text-muted text-sm mb-2" v-if="flight_from.IsRefundable">Refundable</div>
                                                    <div class="text-muted text-sm mb-2" v-else>Non Refundable</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="display: none;" id="arrival_flight">
                        <h3 class="sc-fzpans sc-fzplWN dazDxU" data-testid="title">Select return flight to {{ search?.OriginName }}</h3>
                    </div>
                    <div class="row align-items-center g-4 mt-2" v-for="fal, k in search_flights" :key="k" :id="'flight_'+k">
                        <!-- Single Flight -->
                        <div class="col-xl-12 col-lg12 col-md-12" v-for="flight,i in fal" :key="i">
                            <div class="flights-accordion">
                                <div class="flights-list-item bg-white rounded-3 p-3">
                                    <div class="row gy-4 align-items-center justify-content-between">
                                        <div class="col" v-for="segment,j in flight.Segments" :key="j">
                                            <div class="row mb-3" v-for="airline,k in segment" :key="k">
                                                <div class="col-xl-12 col-lg-12 col-md-12">
                                                    <div class="d-flex align-items-center mb-2">
                                                        <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                        <span class="text-muted text-sm">{{ $formatDate(airline.Origin.DepTime,'long') }}</span>
                                                    </div>
                                                </div>

                                                <div class="col-xl-12 col-lg-12 col-md-12">
                                                    <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                        <div class="col-sm-auto">
                                                            <div class="d-flex align-items-center justify-content-start">
                                                                <div class="d-start fl-pic">
                                                                    <img class="img-fluid" src="img/air-2.png" width="45" alt="image" />
                                                                </div>
                                                                <div class="d-end fl-title ps-2">
                                                                    <div class="text-dark fw-medium">{{ airline.Airline.AirlineName }}</div>
                                                                    <div class="text-sm text-muted">
                                                                        <span v-if="airline.CabinClass==1">All Class</span>
                                                                        <span v-if="airline.CabinClass==2">Economy Class</span>
                                                                        <span v-if="airline.CabinClass==3">Premium Economy Class</span>
                                                                        <span v-if="airline.CabinClass==4">Business Class</span>
                                                                        <span v-if="airline.CabinClass==5">Premium Business Class</span>
                                                                        <span v-if="airline.CabinClass==6">First Class</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col">
                                                            <div class="row gx-3 align-items-center">
                                                                <div class="col-auto">
                                                                    <div class="text-dark fw-bold">{{ $formatDate(airline.Origin.DepTime,'time') }}</div>
                                                                    <div class="text-muted text-sm fw-medium">{{ airline.Origin.Airport.CityName }}</div>
                                                                </div>

                                                                <div class="col text-center">
                                                                    <div class="flightLine departure">
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                    <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                                </div>

                                                                <div class="col-auto">
                                                                    <div class="text-dark fw-bold">{{ $formatDate(airline.Destination.ArrTime,'time') }}</div>
                                                                    <div class="text-muted text-sm fw-medium">{{ airline.Destination.Airport.CityName }}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-auto">
                                                            <div class="text-dark fw-medium">{{ Math.floor(airline.Duration / 60) }}H {{ airline.Duration % 60 }}M</div>
                                                            <!-- <div class="text-muted text-sm fw-medium">2 Stop</div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="row mt-2">
													<div class="col-xl-12 col-lg-12 col-md-12 ">
														<div class="float-end mb-2">
															<button class="btn btn-sm label bg-light-info text-info me-2 " data-bs-toggle="modal" data-bs-target="#flightDetailsModal">Flight Details</button>															
														</div>
													</div>
                                                </div>   -->
                                        </div>

                                        <div class="col-md-auto">
                                            <div class="d-flex items-center h-100">
                                                <div class="d-lg-block d-none border br-dashed me-4"></div>
                                                <div>
                                                    <div class="text-start text-md-end">
                                                        <!-- <span class="label bg-light-success text-success me-1">15% Off</span> -->
                                                        <div class="text-dark fs-3 fw-bold lh-base">{{ flight.Fare.Currency }} {{ flight.Fare.PublishedFare }}</div>
                                                        <div class="text-muted text-sm mb-2" v-if="flight.IsRefundable">Refundable</div>
                                                        <div class="text-muted text-sm mb-2" v-else>Non Refundable</div>
                                                    </div>

                                                    <div class="flight-button-wrap">
                                                        <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal" data-bs-target="#bookflight" id="modal_bookflight" style="display: none;">
                                                            Select Flight<i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                        </button>
                                                        <button class="btn btn-primary btn-md fw-medium full-width" @click="Select_flight(flight, k)">Select Flight<i class="fa-solid fa-arrow-trend-up ms-2"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Book Flight -->

    <div class="modal modal-lg fade" id="bookflight" tabindex="-1" aria-labelledby="bookflightModalLabel" aria-hidden="true" ref="bookflight">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title fs-6" id="bookflightModalLabel">Your Flight To {{ search?.DestinationName }}</h4>
                    <a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close" id="bookClose"><i class="fa-solid fa-square-xmark"></i></a>
                </div>
                <div class="modal-body px-4 py-4 px-xl-5 px-lg-5">
                    <!-- <div class="upper-section01 mb-3 mt-3">
							<div class="alert alert-success" role="alert">
								13% lower CO2 emissions than the average of all flights we offer for this route
							</div>
						</div> -->

                    <div class="airLines-fullsegment">
                        <!-- Departure Route -->
                        <div class="segmentDeparture-wrap">
                            <div class="segmentDeparture-head mb-3">
                                <h4 class="fs-5 m-0">Flight to {{ search?.DestinationName }}</h4>
                                <!-- <p class="text-muted-2 fw-medium text-md m-0" v-if="flight_from?.Segments[0]?.length > 1">2 Stop · 19h 46m</p>
									<p class="text-muted-2 fw-medium text-md m-0" v-else>1 Stop · 19h 46m</p> -->
                            </div>
                            <!-- {{meta?.SelectedFlight}} -->
                            <div v-for="segment,j in meta?.SelectedFlight?.Segments" :key="j">
                                <!-- v-for="airline,k in segment" :key="k" :id="'data_segment_'+k" -->
                                <div class="row gx-3 align-items-center pb-3">
                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[0].Origin.DepTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[0].Origin.Airport.CityName }}</div>
                                    </div>

                                    <div class="col text-center">
                                        <div class="flightLine departure">
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                    </div>

                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[0].Destination.ArrTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[0].Destination.Airport.CityName }}</div>
                                    </div>
                                </div>
                                <div class="segmentDeparture-block">
                                    <div class="segmentDeparture blockfirst">
                                        <ul>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[0].Origin.Airport.AirportCode }} . {{ segment[0].Origin.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[0].Origin.DepTime,'long') }} . {{ $formatDate(segment[0].Origin.DepTime,'time') }}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[0].Destination.Airport.AirportCode }} . {{ segment[0].Destination.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[0].Destination.ArrTime,'long') }} . {{ $formatDate(segment[0].Destination.ArrTime,'time') }}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="segmentDeparture-blocklast">
                                        <div class="d-flex align-items-start timeline-stprs">
                                            <div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="" /></div>
                                            <div class="timeline-stprscaps ps-2">
                                                <p class="text-sm m-0">
                                                    {{ segment[0].Airline.AirlineName }} - {{ segment[0].Airline.AirlineCode }} - {{ segment[0].Airline.FlightNumber }} -
                                                    <span v-if="segment[0].CabinClass==1">All Class</span>
                                                    <span v-if="segment[0].CabinClass==2">Economy Class</span>
                                                    <span v-if="segment[0].CabinClass==3">Premium Economy Class</span>
                                                    <span v-if="segment[0].CabinClass==4">Business Class</span>
                                                    <span v-if="segment[0].CabinClass==5">Premium Business Class</span>
                                                    <span v-if="segment[0].CabinClass==6">First Class</span>
                                                    Flight time {{ Math.floor(segment[0].Duration / 60) }}h {{ segment[0].Duration % 60 }}m
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- {{GroundTime for layover}} -->
                                <div v-if="segment.length>1" class="segmentDeparture-overlay" style="flex-direction: column !important;">
                                    <div class="css-1894l3t">
                                        <span class="text-muted">
                                            <i class="fa-regular fa-clock me-1"></i>
                                            Layover {{ Math.floor(segment[0].GroundTime / 60) }}h {{ segment[0].GroundTime % 60 }}m
                                        </span>
                                    </div>
                                </div>

                                <div v-if="segment.length>1" class="row gx-3 align-items-center pb-3">
                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[1].Origin.DepTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[1].Origin.Airport.CityName }}</div>
                                    </div>

                                    <div class="col text-center">
                                        <div class="flightLine departure">
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                    </div>

                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[1].Destination.ArrTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[1].Destination.Airport.CityName }}</div>
                                    </div>
                                </div>
                                <div v-if="segment.length>1" class="segmentDeparture-block">
                                    <div class="segmentDeparture blockfirst">
                                        <ul>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[1].Origin.Airport.AirportCode }} . {{ segment[1].Origin.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[1].Origin.DepTime,'long') }} . {{ $formatDate(segment[1].Origin.DepTime,'time') }}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[1].Destination.Airport.AirportCode }} . {{ segment[1].Destination.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[1].Destination.ArrTime,'long') }} . {{ $formatDate(segment[1].Destination.ArrTime,'time') }}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="segmentDeparture-blocklast">
                                        <div class="d-flex align-items-start timeline-stprs">
                                            <div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="" /></div>
                                            <div class="timeline-stprscaps ps-2">
                                                <p class="text-sm m-0">
                                                    {{ segment[1].Airline.AirlineName }} - {{ segment[1].Airline.AirlineCode }} - {{ segment[1].Airline.FlightNumber }} -
                                                    <span v-if="segment[1].CabinClass==1">All Class</span>
                                                    <span v-if="segment[1].CabinClass==2">Economy Class</span>
                                                    <span v-if="segment[1].CabinClass==3">Premium Economy Class</span>
                                                    <span v-if="segment[1].CabinClass==4">Business Class</span>
                                                    <span v-if="segment[1].CabinClass==5">Premium Business Class</span>
                                                    <span v-if="segment[1].CabinClass==6">First Class</span>
                                                    Flight time {{ Math.floor(segment[1].Duration / 60) }}h {{ segment[1].Duration % 60 }}m
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Returen Route -->
                        <div class="segmentDeparture-wrap mt-5">
                            <div class="segmentDeparture-head mb-3">
                                <h4 class="fs-5 m-0">Flight to {{ search?.OriginName }}</h4>
                                <!-- <p class="text-muted-2 fw-medium text-md m-0" v-if="flight_from?.Segments[0]?.length > 1">2 Stop · 19h 46m</p>
									<p class="text-muted-2 fw-medium text-md m-0" v-else>1 Stop · 19h 46m</p> -->
                            </div>
                            <!-- {{meta?.SelectedFlight}} -->
                            <div v-for="segment,j in meta?.RoundTripSelectedFlight?.Segments" :key="j">
                                <!-- v-for="airline,k in segment" :key="k" :id="'data_segment_'+k" -->
                                <div class="row gx-3 align-items-center pb-3">
                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[0].Origin.DepTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[0].Origin.Airport.CityName }}</div>
                                    </div>

                                    <div class="col text-center">
                                        <div class="flightLine departure">
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                    </div>

                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[0].Destination.ArrTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[0].Destination.Airport.CityName }}</div>
                                    </div>
                                </div>
                                <div class="segmentDeparture-block">
                                    <div class="segmentDeparture blockfirst">
                                        <ul>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[0].Origin.Airport.AirportCode }} . {{ segment[0].Origin.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[0].Origin.DepTime,'long') }} . {{ $formatDate(segment[0].Origin.DepTime,'time') }}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[0].Destination.Airport.AirportCode }} . {{ segment[0].Destination.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[0].Destination.ArrTime,'long') }} . {{ $formatDate(segment[0].Destination.ArrTime,'time') }}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="segmentDeparture-blocklast">
                                        <div class="d-flex align-items-start timeline-stprs">
                                            <div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="" /></div>
                                            <div class="timeline-stprscaps ps-2">
                                                <p class="text-sm m-0">
                                                    {{ segment[0].Airline.AirlineName }} - {{ segment[0].Airline.AirlineCode }} - {{ segment[0].Airline.FlightNumber }} -
                                                    <span v-if="segment[0].CabinClass==1">All Class</span>
                                                    <span v-if="segment[0].CabinClass==2">Economy Class</span>
                                                    <span v-if="segment[0].CabinClass==3">Premium Economy Class</span>
                                                    <span v-if="segment[0].CabinClass==4">Business Class</span>
                                                    <span v-if="segment[0].CabinClass==5">Premium Business Class</span>
                                                    <span v-if="segment[0].CabinClass==6">First Class</span>
                                                    Flight time {{ Math.floor(segment[0].Duration / 60) }}h {{ segment[0].Duration % 60 }}m
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- {{GroundTime for layover}} -->
                                <div v-if="segment.length>1" class="segmentDeparture-overlay" style="flex-direction: column !important;">
                                    <div class="css-1894l3t">
                                        <span class="text-muted">
                                            <i class="fa-regular fa-clock me-1"></i>
                                            Layover {{ Math.floor(segment[0].GroundTime / 60) }}h {{ segment[0].GroundTime % 60 }}m
                                        </span>
                                    </div>
                                </div>

                                <div v-if="segment.length>1" class="row gx-3 align-items-center pb-3">
                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[1].Origin.DepTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[1].Origin.Airport.CityName }}</div>
                                    </div>

                                    <div class="col text-center">
                                        <div class="flightLine departure">
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                    </div>

                                    <div class="col-auto">
                                        <div class="text-dark fw-bold">{{ $formatDate(segment[1].Destination.ArrTime,'time') }}</div>
                                        <div class="text-muted text-sm fw-medium">{{ segment[1].Destination.Airport.CityName }}</div>
                                    </div>
                                </div>
                                <div v-if="segment.length>1" class="segmentDeparture-block">
                                    <div class="segmentDeparture blockfirst">
                                        <ul>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[1].Origin.Airport.AirportCode }} . {{ segment[1].Origin.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[1].Origin.DepTime,'long') }} . {{ $formatDate(segment[1].Origin.DepTime,'time') }}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="segmenttriox">
                                                    <h6 class="fs-6 fw-medium m-0">{{ segment[1].Destination.Airport.AirportCode }} . {{ segment[1].Destination.Airport.CityName }}</h6>
                                                    <p class="text-muted text-md m-0">{{ $formatDate(segment[1].Destination.ArrTime,'long') }} . {{ $formatDate(segment[1].Destination.ArrTime,'time') }}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="segmentDeparture-blocklast">
                                        <div class="d-flex align-items-start timeline-stprs">
                                            <div class="timeline-stprsthumb"><img src="assets/img/air-2.png" class="img-fluid" width="40" alt="" /></div>
                                            <div class="timeline-stprscaps ps-2">
                                                <p class="text-sm m-0">
                                                    {{ segment[1].Airline.AirlineName }} - {{ segment[1].Airline.AirlineCode }} - {{ segment[1].Airline.FlightNumber }} -
                                                    <span v-if="segment[1].CabinClass==1">All Class</span>
                                                    <span v-if="segment[1].CabinClass==2">Economy Class</span>
                                                    <span v-if="segment[1].CabinClass==3">Premium Economy Class</span>
                                                    <span v-if="segment[1].CabinClass==4">Business Class</span>
                                                    <span v-if="segment[1].CabinClass==5">Premium Business Class</span>
                                                    <span v-if="segment[1].CabinClass==6">First Class</span>
                                                    Flight time {{ Math.floor(segment[1].Duration / 60) }}h {{ segment[1].Duration % 60 }}m
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="airLines-priceinfoy1 pt-4">
                        <div class="airLines-flex d-flex align-items-center justify-content-between">
                            <div class="airLinesyscb">
                                <h4 class="fs-4 m-0">
                                    {{ meta?.SelectedFlight?.Fare?.Currency }}
                                    <span v-if="meta?.RoundTripSelectedFlight"> {{ meta?.SelectedFlight?.Fare?.PublishedFare + meta?.RoundTripSelectedFlight?.Fare?.PublishedFare }} </span>
                                    <span v-else> {{ meta?.SelectedFlight?.Fare?.PublishedFare }} </span>
                                </h4>
                                <p class="text-md m-0">Total price for all travellers</p>
                            </div>
                            <div class="flds-ytu">
                                <!-- <router-link to="/flight_detail" class="btn btn-primary btn-md fw-medium">Book Now</router-link> -->
                                <button class="btn btn-primary btn-md fw-medium" @click="booknow">Book Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  
   

    <section class="py-5">
        <div class="container">
            <div class="row align-items-center justify-content-between mb-3">
                <div class="col-8">
                    <div class="upside-heading">
                        <h5 class="fw-bold fs-6 m-0">All International Routes</h5>
                    </div>
                </div>
                <div class="col-4">
                    <div class="text-end grpx-btn">
                        <a href="#" class="btn btn-light-primary btn-md fw-medium">More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="destinationList-wrap">
                        <div class="tabs-control-slider">
                            <ul class="nav nav-pills medium" id="pills-tab" role="tablist">
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="nav-link active" id="pills-flsfirst-tab" data-bs-toggle="pill" data-bs-target="#pills-flsfirst" type="button" role="tab" aria-controls="pills-flsfirst" aria-selected="true">
                                        Flights To Popular Countries
                                    </button>
                                </li>
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="nav-link" id="pills-flssecond-tab" data-bs-toggle="pill" data-bs-target="#pills-flssecond" type="button" role="tab" aria-controls="pills-flssecond" aria-selected="false">
                                        Flights To Popular Destinations
                                    </button>
                                </li>
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="nav-link" id="pills-flsthird-tab" data-bs-toggle="pill" data-bs-target="#pills-flsthird" type="button" role="tab" aria-controls="pills-flsthird" aria-selected="false">
                                        Popular Flights
                                    </button>
                                </li>
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="nav-link" id="pills-flsfourth-tab" data-bs-toggle="pill" data-bs-target="#pills-flsfourth" type="button" role="tab" aria-controls="pills-flsfourth" aria-selected="false">
                                        Popular Airlines
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content pt-2" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-flsfirst" role="tabpanel" aria-labelledby="pills-flsfirst-tab" tabindex="0">
                                <div class="row row-cols-xl-5 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-2 g-3">
                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>France</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Turkey</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Japan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Itly</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Poland</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>South Korea</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Spain</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Maxico</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Austria</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Canada</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Thailand</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>New York</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Russia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Vietnaam</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Denver</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Liverpool</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Indonesia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Chaina</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Zarmeny</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Purtugal</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>India</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Malaysia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Pakistan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Los Vegas</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Singapure</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-flssecond" role="tabpanel" aria-labelledby="pills-flssecond-tab" tabindex="0">
                                <div class="row row-cols-xl-5 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-2 g-3">
                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>France</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Turkey</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Japan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Itly</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Poland</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>South Korea</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Spain</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Maxico</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Austria</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Canada</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Thailand</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>New York</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Russia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Vietnaam</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Denver</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Liverpool</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Indonesia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Chaina</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Zarmeny</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Purtugal</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>India</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Malaysia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Pakistan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Los Vegas</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Singapure</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-flsthird" role="tabpanel" aria-labelledby="pills-flsthird-tab" tabindex="0">
                                <div class="row row-cols-xl-5 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-2 g-3">
                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>France</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Turkey</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Japan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Itly</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Poland</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>South Korea</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Spain</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Maxico</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Austria</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Canada</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Thailand</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>New York</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Russia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Vietnaam</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Denver</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Liverpool</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Indonesia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Chaina</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Zarmeny</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Purtugal</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>India</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Malaysia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Pakistan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Los Vegas</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Singapure</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-flsfourth" role="tabpanel" aria-labelledby="pills-flsfourth-tab" tabindex="0">
                                <div class="row row-cols-xl-5 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-2 g-3">
                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>France</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Turkey</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Japan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Itly</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Poland</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>South Korea</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Spain</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Maxico</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Austria</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Canada</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Thailand</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>New York</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Russia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Vietnaam</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Denver</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Liverpool</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Indonesia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Chaina</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Zarmeny</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Purtugal</span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col">
                                        <ul class="flightsLists-Wraps p-0">
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>India</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Malaysia</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Pakistan</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Los Vegas</span></a>
                                            </li>
                                            <li>
                                                <a href="#" class="text-md text-muted-2"><span>Flight</span> To <span>Singapure</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5">
      <div class="container">

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
            <div class="secHeading-wrap text-center mb-5">
              <h2>Trending & Popular Articles</h2>
              <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
            </div>
          </div>
        </div>


        <div class="row justify-content-center g-4">

          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="blogGrid-wrap d-flex flex-column h-100">
              <div class="blogGrid-pics">
                <a href="#" class="d-block"><img src="img/blog-1.jpg" class="img-fluid rounded"
                    alt="Blog image"></a>
              </div>
              <div class="blogGrid-caps pt-3">
                <div class="d-flex align-items-center mb-1"><span
                    class="label text-success bg-light-success">Destination</span></div>
                <h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in
                    Comfirtable And Best Price</a></h4>
                <p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks
                  to be unintendedly humorous or offensive day of going live.</p>
                <a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="blogGrid-wrap d-flex flex-column h-100">
              <div class="blogGrid-pics">
                <a href="#" class="d-block"><img src="img/blog-2.jpg" class="img-fluid rounded"
                    alt="Blog image"></a>
              </div>
              <div class="blogGrid-caps pt-3">
                <div class="d-flex align-items-center mb-1"><span
                    class="label text-success bg-light-success">Journey</span></div>
                <h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in
                    Comfirtable And Best Price</a></h4>
                <p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks
                  to be unintendedly humorous or offensive day of going live.</p>
                <a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="blogGrid-wrap d-flex flex-column h-100">
              <div class="blogGrid-pics">
                <a href="#" class="d-block"><img src="img/blog-3.jpg" class="img-fluid rounded"
                    alt="Blog image"></a>
              </div>
              <div class="blogGrid-caps pt-3">
                <div class="d-flex align-items-center mb-1"><span
                    class="label text-success bg-light-success">Business</span></div>
                <h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in
                    Comfirtable And Best Price</a></h4>
                <p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks
                  to be unintendedly humorous or offensive day of going live.</p>
                <a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
</template>


<script>
    import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    let FlightCity = require("@/components/FlightCity.vue").default;
    export default {
        name: "",
        components: {flatPickr, FlightCity },
        data() {
            return {
                status: false,
                searchStatus: false,
                search: {
                    EndUserIp: "",
                    TokenId: "",
                    AdultCount: 1,
                    ChildCount: 0,
                    InfantCount: 0,
                    DirectFlight: false,
                    OneStopFlight: false,
                    JourneyType: 1,
                    PreferredAirlines: null,
                    PreferredCurrency: this.$store.state.currency,
                    Segments: [],
                    // Sources:['GDS'],
                    Sources: null,
                    Origin: "",
                    OriginName: "",
                    Destination: "",
                    DestinationName: "",
                    FlightCabinClass: 1,
                    PreferredDepartureTime: moment().format("YYYY-MM-DD"),
                    PreferredArrivalTime: moment().add(1, "Days").format("YYYY-MM-DD"),
                },
                meta: {
                    EndUserIp: "",
                    TokenId: "",
                    TraceId: "",
                    ResultIndex: "",
                    OBResultIndex: "",
                    IBResultIndex: "",
                    SelectedFlight: null,
                    SelectedFare: null,
                    RoundTripSelectedFare: null,
                    RoundTripSelectedFlight: null,
                },
                flights: [],
                search_flights: [],
                errors: [],
                error: false,
                flight_from: "",
                flight_to: "",
                mindate: moment().format("YYYY-MM-DD"),
                flatpickrConfig: {
                    dateFormat: "Y-m-d",
                    minDate: moment().format("YYYY-MM-DD"), // Restrict selection to today or future dates
                },
                multi_city_flights: [
                    {
                        Origin: "",
                        Destination: "",
                        OriginName: "",
                        DestinationName: "",
                        PreferredDepartureTime: moment().format("YYYY-MM-DD"),
                        PreferredArrivalTime: moment().format("YYYY-MM-DD"),
                        FlightCabinClass: 1,
                        errors:[],
                    },
                ],
            };
        },
        mounted() {
            window.scrollTo(0, 0);
            // this.generateToken();
        },
        methods: {
            generateToken() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "generateToken" })
                    .then(function (response) {
                        loader.hide();
                        // console.log('response:---',response)
                        vm.$store.dispatch("setFlightSearch", null);
                        vm.$store.dispatch("setFlightMeta", null);
                        vm.$store.dispatch("setFlightResult", null);
                        vm.$store.dispatch("setFlightBooking", null);
                        vm.$store.dispatch("setReturnFlightBooking", null);
                        vm.search.EndUserIp = response.data.EndUserIp;
                        vm.search.TokenId = response.data.Token;
                        vm.meta.EndUserIp = response.data.EndUserIp;
                        vm.meta.TokenId = response.data.Token;
                        vm.$store.dispatch("setFlightMeta", vm.meta);
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },

            showMultiCity(data, value) {
                this.search.JourneyType = value;
                if (data == "oneway") {
                    this.status = false;
                } else {
                    this.status = true;
                }
            },
            changePassengersCount(pax_type, sign) {
                if (pax_type == "Adult" && sign == "+") {
                    this.search.AdultCount++;
                }
                if (pax_type == "Adult" && sign == "-") {
                    this.search.AdultCount > 1 ? this.search.AdultCount-- : this.search.AdultCount;
                }
                if (pax_type == "Child" && sign == "+") {
                    this.search.ChildCount++;
                }
                if (pax_type == "Child" && sign == "-") {
                    this.search.ChildCount >= 1 ? this.search.ChildCount-- : this.search.ChildCount;
                }
                if (pax_type == "Infant" && sign == "+") {
                    this.search.InfantCount++;
                }
                if (pax_type == "Infant" && sign == "-") {
                    this.search.InfantCount >= 1 ? this.search.InfantCount-- : this.search.InfantCount;
                }
            },

            selectOrigin(e) {
                const flight = e.target.value;
                this.search.Origin = flight.airport_code;
                this.search.OriginName = flight.city_name + " (" + flight.airport_code + ")";
                // +'<br>'+ flight.airport_name;
            },

            selectDestination(e) {
                const flight = e.target.value;
                this.search.Destination = flight.airport_code;
                this.search.DestinationName = flight.city_name + " (" + flight.airport_code + ")";
            },

            exchangeAirport() {
                let Origin = this.search.Origin;
                let OriginName = this.search.OriginName;

                this.search.Origin = this.search.Destination;
                this.search.OriginName = this.search.DestinationName;

                this.search.Destination = Origin;
                this.search.DestinationName = OriginName;
            },

            // Multi city fights
            addFlight() {
                const lastFlight = this.multi_city_flights[this.multi_city_flights.length - 1];
                this.multi_city_flights.push({
                    Origin: lastFlight ? lastFlight.Destination : "",
                    Destination: "",
                    OriginName: lastFlight ? lastFlight.DestinationName : "",
                    DestinationName: "",
                    PreferredDepartureTime: "",
                    PreferredArrivalTime: "",
                    FlightCabinClass: 1,
                    errors:[],
                });
                // console.log("multi_city_flights:---", this.multi_city_flights);
            },
            removeFlight(index) {
                if (this.multi_city_flights.length > 1) {
                    this.multi_city_flights.splice(index, 1);
                }
            },
            selectOriginMultiple(e, index) {
                const flight = e.target.value;
                this.multi_city_flights[index].Origin = flight.airport_code;
                this.multi_city_flights[index].OriginName = flight.city_name + " (" + flight.airport_code + ")";

                if (index > 0) {
                    this.multi_city_flights[index - 1].DestinationName = this.multi_city_flights[index].OriginName;
                    this.multi_city_flights[index - 1].Destination = this.multi_city_flights[index].Origin;
                }
            },
            selectDestinationMultiple(e, index) {
                const flight = e.target.value;
                this.multi_city_flights[index].Destination = flight.airport_code;
                this.multi_city_flights[index].DestinationName = flight.city_name + " (" + flight.airport_code + ")";

                if (index < this.multi_city_flights.length - 1) {
                    this.multi_city_flights[index + 1].OriginName = this.multi_city_flights[index].DestinationName;
                    this.multi_city_flights[index + 1].Origin = this.multi_city_flights[index].Destination;
                }
            },
            swapLocations(index) {
                let tempOriginName = this.multi_city_flights[index].OriginName;
                this.multi_city_flights[index].OriginName = this.multi_city_flights[index].DestinationName;
                this.multi_city_flights[index].DestinationName = tempOriginName;

                let tempOrigin = this.multi_city_flights[index].Origin;
                this.multi_city_flights[index].Origin = this.multi_city_flights[index].Destination;
                this.multi_city_flights[index].Destination = tempOrigin;

                if (index > 0) {
                    this.multi_city_flights[index - 1].DestinationName = this.multi_city_flights[index].OriginName;
                    this.multi_city_flights[index - 1].Destination = this.multi_city_flights[index].Origin;
                }

                if (index < this.multi_city_flights.length - 1) {
                    this.multi_city_flights[index + 1].OriginName = this.multi_city_flights[index].DestinationName;
                    this.multi_city_flights[index + 1].Origin = this.multi_city_flights[index].Destination;
                }
            },
            updateFlightCabinClass(newClass) {
                this.multi_city_flights.forEach((flight) => {
                    flight.FlightCabinClass = newClass;
                });
            },
            updateFlightArrivalTime(departure_time, index) {
                this.multi_city_flights[index].PreferredArrivalTime = departure_time;
            },

            searchFlights() {
                let vm = this;
                vm.search.Segments = [];
                // console.log('vm.search.JourneyType:----', vm.search.JourneyType)
                vm.errors = []
                if (vm.search.JourneyType == 1) {
                    vm.search.Segments.push({
                        Origin: vm.search.Origin,
                        Destination: vm.search.Destination,
                        FlightCabinClass: vm.search.FlightCabinClass,
                        PreferredDepartureTime: moment(vm.search.PreferredDepartureTime),
                        PreferredArrivalTime: moment(vm.search.PreferredDepartureTime),
                    });
                    if(vm.search.Origin === ''){
                        vm.errors['Origin'] = ['Enter Origin Location'];
                        vm.error = true;
                    }
                    else if(vm.search.Destination === ''){
                        delete vm.errors['Origin'];
                        vm.errors['Destination'] = ['Enter Destination Location'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredDepartureTime < moment().format("YYYY-MM-DD")){
                        if(vm.search.Destination !== ''){
							delete vm.errors['Destination'];
						}
                        vm.errors['PreferredDepartureTime'] = ['Departure Date Must Be Grater Than Or Equal To Today'];
                        vm.error = true;
                    }
                    else{                       
                        if(vm.errors['Origin']){
                            delete vm.errors['Origin'];
                        }
                        if(vm.errors['Destination']){
                            delete vm.errors['Destination'];
                        }
                        if(vm.errors['PreferredDepartureTime']){
                            delete vm.errors['PreferredDepartureTime'];
                        }
                        vm.error = false;
                    }

                }
                if (vm.search.JourneyType == 2) {
                    vm.search.Segments.push(
                        {
                            Origin: vm.search.Origin,
                            Destination: vm.search.Destination,
                            FlightCabinClass: vm.search.FlightCabinClass,
                            PreferredDepartureTime: moment(vm.search.PreferredDepartureTime),
                            PreferredArrivalTime: moment(vm.search.PreferredDepartureTime),
                        },
                        {
                            Origin: vm.search.Destination,
                            Destination: vm.search.Origin,
                            FlightCabinClass: vm.search.FlightCabinClass,
                            PreferredDepartureTime: moment(vm.search.PreferredArrivalTime),
                            PreferredArrivalTime: moment(vm.search.PreferredArrivalTime),
                        }
                    );                    
                    if(vm.search.Origin === ''){
                        vm.errors['Origin'] =c
                        vm.error = true;
                    }
                    else if(vm.search.Destination === ''){
                        delete vm.errors['Origin'];
                        vm.errors['Destination'] = ['Enter Destination Location'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredDepartureTime < moment().format("YYYY-MM-DD")){
                        if(vm.search.Destination !== ''){
							delete vm.errors['Destination'];
						}
                        vm.errors['PreferredDepartureTime'] = ['Departure Date Must Be Grater Than Or Equal To Today'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredArrivalTime < moment().format("YYYY-MM-DD")){
                        vm.errors['PreferredArrivalTime'] = ['Arrival Date Must Be Grater Than Or Equal To Today'];
                        vm.error = true;
                    }
                    else if(vm.search.PreferredDepartureTime > vm.search.PreferredArrivalTime){
                        vm.errors['PreferredDepartureTime'] = ['Departure Date Must Be Less Than Arrival'];
                        vm.errors['PreferredArrivalTime'] = ['Arrival Date Must Be Grater Than Departure'];
                        vm.error = true;
                    }
                    else{
                        if(vm.errors){
                            if(vm.errors['Origin']){
                                delete vm.errors['Origin'];
                            }
                            if(vm.errors['Destination']){
                                delete vm.errors['Destination'];
                            }
                            if(vm.errors['PreferredDepartureTime']){
                                delete vm.errors['PreferredDepartureTime'];
                            }
                            if(vm.errors['PreferredArrivalTime']){
                                delete vm.errors['PreferredArrivalTime'];
                            }
                        }                    
                        vm.error = false;
                    }
                }
                if (vm.search.JourneyType == 3) {
                    vm.search.Segments = vm.multi_city_flights;                    
                    for(let i=0; i<vm.search.Segments.length; i++) {
                        if(vm.search.Segments[i].Origin==""){                            
                            vm.search.Segments[i].errors['Origin'] = ['Enter Origin Location'];
                        }
                        else{
                            delete vm.search.Segments[i].errors['Origin'];
                        }
                        if(vm.search.Segments[i].Destination==""){                           
                            vm.search.Segments[i].errors['Destination'] = ['Enter Destination Location'];
                        }
                        else{
                            delete vm.search.Segments[i].errors['Destination'];
                        }
                        if(vm.search.Segments[i].PreferredDepartureTime < moment().format("YYYY-MM-DD")){
                            vm.search.Segments[i].errors['PreferredDepartureTime'] = ['Departure Date Must Be Grater Than Or Equal To Today'];                        
                        }
                        else{
                            delete vm.search.Segments[i].errors['PreferredDepartureTime'];
                        }                        
                        if(vm.search.Segments[i-1]?.PreferredDepartureTime != undefined){
							if(vm.search.Segments[i]?.PreferredDepartureTime < vm.search.Segments[i-1]?.PreferredDepartureTime ){
								vm.search.Segments[i].errors['PreferredDepartureTime'] = ['Must Be Grater Than Above Segment Date'];
							}
						}
                        const hasErrors = Object.keys(vm.search.Segments[i].errors).some(key => vm.search.Segments[i].errors[key].length > 0);
                        if (hasErrors) {
                            vm.error = true;
                        } else {
                            vm.error = false;
                        }
                    }
                }

                if(!vm.error){
                    let loader = vm.$loading.show();
                    vm.search.PreferredCurrency = this.$store.state.currency;
                    vm.$store.dispatch("setFlightSearch", vm.search);
                    vm.$store.dispatch("post", { uri: "flights/search", data: vm.search })
                    .then(function (response) {
                        loader.hide();
                        vm.meta.TraceId = response.data.Response.TraceId;
                        vm.flights = response.data.Response.Results;
                        // vm.search_flights = vm.flights;
                        // vm.searchStatus = true;                        
                        vm.$store.dispatch("setFlightMeta", vm.meta);
                        vm.$store.dispatch("setFlightResult", vm.flights);
                        vm.$router.push("/flight_list");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            async Select_flight(flight_data, type) {
                let vm = this;
                vm.$store.dispatch("setFlightSearch", null);
                vm.$store.dispatch("setFlightMeta", null);
                vm.$store.dispatch("setFlightBooking", null);
                vm.$store.dispatch("setReturnFlightBooking", null);
                let arrival = document.getElementById("flight_0");
                let departure = document.getElementById("flight_1");
                let departure_flight = document.getElementById("departure_flight");
                let arrival_flight = document.getElementById("arrival_flight");
                if (type == 0) {
                    vm.flight_from = flight_data;
                    if (vm.search?.JourneyType == 2) {
                        arrival.style.display = "none";
                        if (departure) {
                            departure.style.display = "block";
                        }
                        departure_flight.style.display = "block";
                        arrival_flight.style.display = "block";
                    }
                } else {
                    vm.flight_to = flight_data;
                }

                if (vm.flight_from && vm.flight_to) {
                    let loader = vm.$loading.show();
                    vm.getFareQuote(vm.flight_from, 1);
                    // console.log('wait for 5 seconds and then go to next line ')
                    await vm.delay(5000);
                    loader.hide();
                }
                if (vm.flight_from && vm.flight_to) {
                    let loader = vm.$loading.show();
                    vm.getFareQuote(vm.flight_to, 2);
                    // console.log('wait for 5 seconds and then go to next line ')
                    await vm.delay(5000);
                    loader.hide();
                    document.getElementById("modal_bookflight").click();
                }
                if (vm.flight_from && vm.flights.length == 1) {
                    let loader = vm.$loading.show();
                    vm.getFareQuote(vm.flight_from, 1);
                    // console.log("wait for 5 seconds and then go to next line ");
                    await vm.delay(5000);
                    loader.hide();
                    document.getElementById("modal_bookflight").click();
                }
            },

            delay(ms) {
                return new Promise((resolve) => setTimeout(resolve, ms));
            },
            getFareQuote(flight, round) {
                let vm = this;
                vm.meta.ResultIndex = flight.ResultIndex;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "flights/fareQuote", data: vm.meta })
                    .then(function (response) {
                        loader.hide();
                        if (round == 1) {
                            vm.meta.OBResultIndex = flight.ResultIndex;
                            vm.meta.SelectedFare = response.data.Response.Results;
                            vm.meta.SelectedFlight = flight;
                            vm.meta.IBResultIndex = null;
                            vm.meta.RoundTripSelectedFare = null;
                            vm.meta.RoundTripSelectedFlight = null;
                            vm.$store.dispatch("setFlightSearch", vm.search);
                            vm.$store.dispatch("setFlightMeta", vm.meta);
                        }
                        if (round == 2) {
                            vm.meta.IBResultIndex = flight.ResultIndex;
                            vm.meta.RoundTripSelectedFare = response.data.Response.Results;
                            vm.meta.RoundTripSelectedFlight = flight;
                            vm.$store.dispatch("setFlightSearch", vm.search);
                            vm.$store.dispatch("setFlightMeta", vm.meta);
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            booknow() {
                document.getElementById("bookClose").click();
                this.$router.push("/flight_detail");
            },

	    printClass(cls_value)
	    {
			if(cls_value == 1){
				return 'All Class'
			}
			else if(cls_value == 2){
				return 'Economy Class'
			}
			else if(cls_value == 3){
				return 'Premium Economy Class'
			}
			else if(cls_value == 4){
				return 'Business Class'
			}
			else if(cls_value == 5){
				return 'Premium Business Class'
			}
			else if(cls_value == 6){
				return 'First Class'
			}			
		},
        },
    };
</script>
<style scoped>
    .search-wrap .form-control {
        height: 62px !important;
    }
    .hgt-62 {
        height: 62px !important;
    }
    .hero-header {
        padding: 5em 0 5em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-height: 400px;
    }
    @media (min-width: 993px) {
        .hero-header {
            min-height: 530px;
            padding: 7rem 0;
            height: 0vh;
        }
    }
</style>