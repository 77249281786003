<template>
    <section class="pt-3 gray-simple">
		<!-- {{search}} -->
		
		<div class="container">
			<div class="row">
				<!-- Flight Info -->
				<div class="col-xl-12 col-lg-12 col-md-12">
					<div class="row">
						<div class="col-xl-9 col-lg-8 col-md-12">
							<!-- <div class="card border-0 mb-4">
								<div class="card-body">
									<div class="crd-block d-md-flex align-items-start justify-content-start">
										<div class="crd-heaader-0 flex-shrink-0 mb-3 mb-md-0">
											<div class="square--70 rounded-2 bg-light-primary text-primary fs-3"><i class="fa-solid fa-plane"></i></div>
										</div>
										<div class="crd-heaader-first ps-md-3">
											<div class="d-block">
												<h4 class="mb-0" v-if="search?.JourneyType == 2">
													{{ search?.OriginName }}
													<span class="text-muted-2 mx-3">
														<i class="fa-solid fa-arrow-right-arrow-left"></i>
													</span>
													{{ search?.DestinationName }}
												</h4>
												<h4 class="mb-0" v-else>
													{{ search?.OriginName }}
													<span class="text-muted-2 mx-3">
														<i class="fa-solid fa-arrow-right"></i>
													</span>
													{{ search?.DestinationName }}
												</h4>

												<div class="explotter-info">
													<p class="detail ellipsis-container fw-semibold">
														<span class="ellipsis-item__normal">{{ $formatDate(meta?.SelectedFlight?.Segments[0][0]?.Origin?.DepTime,'long') }}</span>
														<span class="separate ellipsis-item__normal"></span>
														<span class="ellipsis-item">{{ Math.floor(meta?.SelectedFlight?.Segments[0][0]?.Duration / 60) }}H {{ meta?.SelectedFlight?.Segments[0][0]?.Duration % 60 }}Min</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> -->
							<h3 style="font-size: 18px; font-weight: 600; line-height: 20px; text-align: left;">
								 FLIGHT DETAILS 
							</h3>
							<!-- Flight Info -->
							<div class="card border-0 mb-4" style="border-radius:24px;">
								<div class="card-body">
									<div class="flights-accordion">
										<div class="flights-list-item">
											<div class="row gy-4 align-items-center justify-content-between">
												<div class="col">
													<!-- Old Details for one way -->
													<!-- <div class="row pb-5" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">
														<div class="col-xl-12 col-lg-12 col-md-12">
															<div class="d-flex align-items-center mb-2">
																<span class="label bg-light-primary text-primary me-2">Departure</span>
																<span class="text-muted text-sm">{{ $formatDate(airline?.Origin?.DepTime,'long') }}</span>
															</div>
														</div>
														<div class="col-xl-12 col-lg-12 col-md-12">
															<div class="row gx-lg-5 gx-3 gy-4 align-items-center">

																<div class="col-sm-auto">
																	<div class="d-flex align-items-center justify-content-start">
																		<div class="d-start fl-pic">
																			<img class="img-fluid" src="img/air-4.png" width="45" alt="image">
																		</div>
																		<div class="d-end fl-title ps-2">
																			<div class="text-dark fw-medium">{{airline?.Airline?.AirlineName}}</div>
																			<div class="text-sm text-muted">
																				<span v-if="airline?.CabinClass==1">All Class</span>
																				<span v-if="airline?.CabinClass==2">Economy Class</span>
																				<span v-if="airline?.CabinClass==3">Premium Economy Class</span>
																				<span v-if="airline?.CabinClass==4">Business Class</span>
																				<span v-if="airline?.CabinClass==5">Premium Business Class</span>
																				<span v-if="airline?.CabinClass==6">First Class</span>
																			</div>
																		</div>
																	</div>
																</div>
																
																<div class="col-md-auto">
																	<div class="text-dark fw-medium">{{ Math.floor(airline?.Duration / 60) }}H {{ airline?.Duration % 60 }}M</div>
																</div>
																<div class="col-md-auto">
																	<div class="text-dark fw-medium">Check-In Baggage</div>
																	<div class="text-muted text-sm fw-medium">{{airline?.Baggage}}</div>
																</div>
																<div class="col-md-auto">
																	<div class="text-dark fw-medium">Cabin Baggage</div>
																	<div class="text-muted text-sm fw-medium">{{airline?.CabinBaggage}}</div>
																</div>
																<div class="col">
																	<div class="row gx-3 align-items-center">
																		<div class="col-4 text-end">
																			<div class="text-dark fw-bold">{{ $formatDate(airline?.Origin?.DepTime,'time') }}</div>
																			<div class="text-muted text-sm fw-medium">{{airline?.Origin?.Airport?.AirportCode}}</div>
																		</div>

																		<div class="col-4 text-center">
																			<div class="flightLine departure">
																				<div></div>
																				<div></div>
																			</div>
																			<div class="text-muted text-sm fw-medium mt-3">Direct</div>
																		</div>

																		<div class="col-4">
																			<div class="text-dark fw-bold">{{ $formatDate(airline?.Destination?.ArrTime,'time') }}</div>
																			<div class="text-muted text-sm fw-medium">{{airline?.Destination?.Airport?.AirportCode}}</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>														
													</div> -->
													<!-- Old Details for if return way -->
													<!-- <div class="row pb-5" v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key">
														<div class="col-xl-12 col-lg-12 col-md-12">
															<div class="d-flex align-items-center mb-2">
																<span class="label bg-light-primary text-primary me-2">Departure</span>
																<span class="text-muted text-sm">{{ $formatDate(airline?.Origin?.DepTime,'long') }}</span>
															</div>
														</div>
														<div class="col-xl-12 col-lg-12 col-md-12">
															<div class="row gx-lg-5 gx-3 gy-4 align-items-center">

																<div class="col-sm-auto">
																	<div class="d-flex align-items-center justify-content-start">
																		<div class="d-start fl-pic">
																			<img class="img-fluid" src="img/air-4.png" width="45" alt="image">
																		</div>
																		<div class="d-end fl-title ps-2">
																			<div class="text-dark fw-medium">{{airline?.Airline?.AirlineName}}</div>
																			<div class="text-sm text-muted">
																				<span v-if="airline?.CabinClass==1">All Class</span>
																				<span v-if="airline?.CabinClass==2">Economy Class</span>
																				<span v-if="airline?.CabinClass==3">Premium Economy Class</span>
																				<span v-if="airline?.CabinClass==4">Business Class</span>
																				<span v-if="airline?.CabinClass==5">Premium Business Class</span>
																				<span v-if="airline?.CabinClass==6">First Class</span>
																			</div>
																		</div>
																	</div>
																</div>
																
																<div class="col-md-auto">
																	<div class="text-dark fw-medium">{{ Math.floor(airline?.Duration / 60) }}H {{ airline?.Duration % 60 }}M</div>
																</div>
																<div class="col-md-auto">
																	<div class="text-dark fw-medium">Check-In Baggage</div>
																	<div class="text-muted text-sm fw-medium">{{airline?.Baggage}}</div>
																</div>
																<div class="col-md-auto">
																	<div class="text-dark fw-medium">Cabin Baggage</div>
																	<div class="text-muted text-sm fw-medium">{{airline?.CabinBaggage}}</div>
																</div>
																<div class="col">
																	<div class="row gx-3 align-items-center">
																		<div class="col-4 text-end">
																			<div class="text-dark fw-bold">{{ $formatDate(airline?.Origin?.DepTime,'time') }}</div>
																			<div class="text-muted text-sm fw-medium">{{airline?.Origin?.Airport?.AirportCode}}</div>
																		</div>

																		<div class="col-4 text-center">
																			<div class="flightLine departure">
																				<div></div>
																				<div></div>
																			</div>
																			<div class="text-muted text-sm fw-medium mt-3">Direct</div>
																		</div>

																		<div class="col-4">
																			<div class="text-dark fw-bold">{{ $formatDate(airline?.Destination?.ArrTime,'time') }}</div>
																			<div class="text-muted text-sm fw-medium">{{airline?.Destination?.Airport?.AirportCode}}</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div> -->


													<div class="row">
														<div class="col-xl-12 col-lg-12 col-md-12" v-if="meta?.SelectedFare?.Segments.length == 1">
															<!-- <div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
																<div  class="pt-3" style="padding: 20px;">
																	<div v-if="meta?.SelectedFare?.Segments[0].length > 1">
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
																			{{ meta?.SelectedFare?.Segments[0][0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ meta?.SelectedFare?.Segments[0][1].Destination.Airport.CityName }} 																		
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																			<span>{{ $formatDate(meta?.SelectedFare?.Segments[0][0].Origin.DepTime,'long') }} | </span> 
																			<span> 1 Stop | </span>
																			<span> {{ Math.floor(meta?.SelectedFare?.Segments[0][1].AccumulatedDuration / 60) }}H {{ meta?.SelectedFare?.Segments[0][1].AccumulatedDuration % 60 }}M | </span>																		
																			<span> {{ printClass(meta?.SelectedFare?.Segments[0][0].CabinClass) }}</span>
																		</div>
																	</div>
																	<div v-else>
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
																			{{ meta?.SelectedFare?.Segments[0][0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ meta?.SelectedFare?.Segments[0][0].Destination.Airport.CityName }} 																		
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																			<span>{{ $formatDate(meta?.SelectedFare?.Segments[0][0].Origin.DepTime,'long') }} | </span> 
																			<span> Non Stop | </span>
																			<span v-if="meta?.SelectedFare?.Segments[0][0].AccumulatedDuration">
																				{{ Math.floor(meta?.SelectedFare?.Segments[0][0].AccumulatedDuration / 60) }}H {{ meta?.SelectedFare?.Segments[0][0].AccumulatedDuration % 60 }}M | 
																			</span>
																			<span v-else>
																				{{ Math.floor(meta?.SelectedFare?.Segments[0][0].Duration / 60) }}H {{ meta?.SelectedFare?.Segments[0][0].Duration % 60 }}M |
																			</span>																		
																			<span> {{ printClass(meta?.SelectedFare?.Segments[0][0].CabinClass) }}</span>
																		</div>
																	</div>

																	<div v-for="segment, index in  meta?.SelectedFare?.Segments[0]" :key="index" class="pt-4" >																		
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																			<img class="img-fluid" src="img/air-2.png" width="25" alt="image">
																			<span style="padding-left: 6px; font-size: 14px; font-weight: 600; line-height: 16px; text-align: left;">
																				{{ segment.Airline.AirlineName }} | 
																			</span> 
																			<span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">
																				{{ segment.Airline.AirlineCode }}-{{ segment.Airline.FlightNumber }} 
																			</span>
																		</div>
																		
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
																			<div class="row" style="align-items: center; ">
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(segment.Origin.DepTime,'time') }}
																						</span>
																					</div>	
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(segment.Origin.DepTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ segment.Origin.Airport.AirportName }}, {{ segment.Origin.Airport.Terminal ? 'Terminal '+segment.Origin.Airport.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
																					<div class="text-center">
																						<div> 
																							{{ Math.floor(segment.Duration / 60) }}H {{ segment.Duration % 60 }}M 
																						</div>
																						<div class="flightLine departure">
																							<div></div>
																							<div></div>
																						</div>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(segment.Destination.ArrTime,'time') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(segment.Destination.ArrTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ segment.Destination.Airport.AirportName }},  {{ segment.Destination.Airport.Terminal ? 'Terminal '+segment.Destination.Airport.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		
																		<div class="row justify-content-center" style="align-items:center; padding-top:24px; padding-bottom:24px">
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
																				<span>Baggage: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">Adult</span>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
																				<span>Check-In: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.Baggage ? segment.Baggage : '-' }}</span>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
																				<span>Cabin: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.CabinBaggage ? segment.CabinBaggage : '-' }}</span>
																			</div>																		
																		</div>

																		<div class="row justify-content-center" style="align-items:center; padding-top:24px; padding-bottom:24px" v-if=" meta?.SelectedFare?.Segments[0].length>1 && index==0">
																			<div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
																				padding:6px !important; font-weight:500; font-size:12px;">
																				<span style="color:#5143D9">Change of Planes | </span>
																				<span>
																					{{ Math.floor(( meta?.SelectedFare?.Segments[0][1].AccumulatedDuration - ( meta?.SelectedFare?.Segments[0][0].Duration +  meta?.SelectedFare?.Segments[0][1].Duration))  / 60) }}H 
																					{{ ( meta?.SelectedFare?.Segments[0][1].AccumulatedDuration - ( meta?.SelectedFare?.Segments[0][0].Duration +  meta?.SelectedFare?.Segments[0][1].Duration)) % 60 }}M
																					Layover in 
																					{{ segment.Destination.Airport.CityName }}
																				</span>
																			</div>																		
																		</div>
																	</div>
																</div>
															</div> -->
															<div v-for="segment, fli in  meta?.SelectedFare?.Segments" :key="fli" style="padding-bottom:16px">
																<div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
																	<div  class="pt-3" style="padding: 20px;">
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																			<span>{{ segment[0]?.Origin?.Airport?.CityName }} </span>
																			<i class="bi bi-arrow-right"></i> 
																			<span v-if="segment.length>1">{{ segment[segment.length-1].Destination.Airport.CityName }}</span>
																			<span v-else>{{ segment[0]?.Destination?.Airport?.CityName }}</span>
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																			<span>{{ $formatDate(segment[0].Origin.DepTime,'long') }} | </span> 
																			<span> {{meta?.SelectedFare?.Segments[0]?.length -1 }} Stop | </span>
																			<span>{{  AccumulatedDurationCalculation(segment) }} | </span>
																			<span> {{ printClass(segment[0].CabinClass) }}</span>
																		</div>
																	</div>
																	<div v-for="seg, index in segment" :key="index" class="pt-4" style="padding: 20px;">																		
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																			<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="20" alt="image"> -->
																			<img class="img-fluid" :src="'img/AirlineLogo/' + seg?.Airline?.AirlineCode +'.gif'" width="20" alt="image">
																			<span style="padding-left: 6px;">{{ seg?.Airline?.AirlineName }} | </span> <span>{{ seg?.Airline?.AirlineCode }}-{{ seg?.Airline?.FlightNumber }} </span>
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
																			<div class="row" style="align-items: center; ">
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(seg.Origin.DepTime,'time') }}
																						</span>
																					</div>	
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(seg.Origin.DepTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ seg?.Origin?.Airport?.AirportName }}, {{ seg?.Origin?.Airport?.Terminal ? 'Terminal '+seg?.Origin?.Airport?.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
																					<div class="text-center">
																						<div> 
																							{{ Math.floor(seg.Duration / 60) }}H {{ seg.Duration % 60 }}M 
																						</div>
																						<div class="flightLine departure">
																							<div></div>
																							<div></div>
																						</div>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(seg.Destination.ArrTime,'time') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(seg.Destination.ArrTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ seg?.Destination?.Airport?.AirportName }},  {{ seg?.Destination?.Airport?.Terminal ? 'Terminal '+seg?.Destination?.Airport?.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="row pt-3 justify-content-center" style="align-items:center" v-if="segment?.length>1 && index!=segment?.length-1">
																			<div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
																				padding:6px !important; font-weight:500; font-size:12px;">
																				<span style="color:#5143D9">Change of Planes | </span>
																				<span>																				
																					{{ calculateLayover(segment[index]?.Destination?.ArrTime, segment[index+1]?.Origin?.DepTime) }}
																					Layover in 
																					{{ segment[index+1]?.Origin?.Airport?.CityName }}
																				</span>
																			</div>																		
																		</div>
																	</div>																
																</div>
															</div>
														</div>
														<div class="col-xl-12 col-lg-12 col-md-12" v-else>
															<div v-for="segment, fli in meta?.SelectedFare?.Segments" :key="fli" style="padding-bottom:16px">
																<div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
																	<div  class="pt-3" style="padding: 20px;">
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																			<span>{{ segment[0]?.Origin?.Airport?.CityName }} </span>
																			<i class="bi bi-arrow-right"></i> 
																			<span v-if="segment.length>1">{{ segment[segment.length-1].Destination.Airport.CityName }}</span>
																			<span v-else>{{ segment[0]?.Destination?.Airport?.CityName }}</span>
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																			<span>{{ $formatDate(segment[0].Origin.DepTime,'long') }} | </span> 
																			<span> {{segment?.length -1 == 0 ? 'Non' : segment?.length -1 }} Stop | </span>
																			<span>{{  AccumulatedDurationCalculation(segment) }} | </span>
																			<span> {{ printClass(segment[0].CabinClass) }}</span>
																		</div>
																	</div>
																	<div v-for="seg, index in segment" :key="index" class="pt-4" style="padding: 20px;">																		
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																			<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="20" alt="image"> -->
																			<img class="img-fluid" :src="'img/AirlineLogo/' + seg?.Airline?.AirlineCode +'.gif'" width="20" alt="image">
																			<span style="padding-left: 6px;">{{ seg?.Airline?.AirlineName }} | </span> <span>{{ seg?.Airline?.AirlineCode }}-{{ seg?.Airline?.FlightNumber }} </span>
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
																			<div class="row" style="align-items: center; ">
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(seg.Origin.DepTime,'time') }}
																						</span>
																					</div>	
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(seg.Origin.DepTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ seg?.Origin?.Airport?.AirportName }}, {{ seg?.Origin?.Airport?.Terminal ? 'Terminal '+seg?.Origin?.Airport?.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
																					<div class="text-center">
																						<div> 
																							{{ Math.floor(seg.Duration / 60) }}H {{ seg.Duration % 60 }}M 
																						</div>
																						<div class="flightLine departure">
																							<div></div>
																							<div></div>
																						</div>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(seg.Destination.ArrTime,'time') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(seg.Destination.ArrTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ seg?.Destination?.Airport?.AirportName }},  {{ seg?.Destination?.Airport?.Terminal ? 'Terminal '+seg?.Destination?.Airport?.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="row pt-3 justify-content-center" style="align-items:center" v-if="segment?.length>1 && index!=segment?.length-1">
																			<div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
																				padding:6px !important; font-weight:500; font-size:12px;">
																				<span style="color:#5143D9">Change of Planes | </span>
																				<span>																				
																					{{ calculateLayover(segment[index]?.Destination?.ArrTime, segment[index+1]?.Origin?.DepTime) }}
																					Layover in 
																					{{ segment[index+1]?.Origin?.Airport?.CityName }}
																				</span>
																			</div>																		
																		</div>
																	</div>
																</div>
															</div>	
														</div>
													</div>

													<div v-if="meta?.RoundTripSelectedFare?.Segments" class="row pt-3">
														<div class="col-xl-12 col-lg-12 col-md-12">
															<div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
																<div class="pt-3" style="padding: 20px;">
																	<div v-if="meta?.RoundTripSelectedFare?.Segments[0].length > 1">
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
																			{{ meta?.RoundTripSelectedFare?.Segments[0][0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ meta?.RoundTripSelectedFare?.Segments[0][1].Destination.Airport.CityName }} 																		
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																			<span>{{ $formatDate(meta?.RoundTripSelectedFare?.Segments[0][0].Origin.DepTime,'long') }} | </span> 
																			<span> 1 Stop | </span>
																			<span> {{ Math.floor(meta?.RoundTripSelectedFare?.Segments[0][1].AccumulatedDuration / 60) }}H {{ meta?.RoundTripSelectedFare?.Segments[0][1].AccumulatedDuration % 60 }}M | </span>																		
																			<span> {{ printClass(meta?.RoundTripSelectedFare?.Segments[0][0].CabinClass) }}</span>
																		</div>
																	</div>
																	<div v-else>
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
																			{{ meta?.RoundTripSelectedFare?.Segments[0][0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ meta?.RoundTripSelectedFare?.Segments[0][0].Destination.Airport.CityName }} 																		
																		</div>
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
																			<span>{{ $formatDate(meta?.RoundTripSelectedFare?.Segments[0][0].Origin.DepTime,'long') }} | </span> 
																			<span> Non Stop | </span>
																			<span v-if="meta?.RoundTripSelectedFare?.Segments[0][0].AccumulatedDuration">
																				{{ Math.floor(meta?.RoundTripSelectedFare?.Segments[0][0].AccumulatedDuration / 60) }}H {{ meta?.RoundTripSelectedFare?.Segments[0][0].AccumulatedDuration % 60 }}M | 
																			</span>
																			<span v-else>
																				{{ Math.floor(meta?.RoundTripSelectedFare?.Segments[0][0].Duration / 60) }}H {{ meta?.RoundTripSelectedFare?.Segments[0][0].Duration % 60 }}M |
																			</span>																		
																			<span> {{ printClass(meta?.RoundTripSelectedFare?.Segments[0][0].CabinClass) }}</span>
																		</div>
																	</div>

																	<div v-for="segment, index in  meta?.RoundTripSelectedFare?.Segments[0]" :key="index" class="pt-4" >																		
																		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																			<!-- <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="20" alt="image"> -->
																			<img class="img-fluid" :src="'img/AirlineLogo/' + segment.Airline.AirlineCode +'.gif'" width="20" alt="image">
																			<span style="padding-left: 6px; font-size: 14px; font-weight: 600; line-height: 16px; text-align: left;">
																				{{ segment.Airline.AirlineName }} | 
																			</span> 
																			<span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">
																				{{ segment.Airline.AirlineCode }}-{{ segment.Airline.FlightNumber }} 
																			</span>
																		</div>
																		
																		<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
																			<div class="row" style="align-items: center; ">
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(segment.Origin.DepTime,'time') }}
																						</span>
																					</div>	
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(segment.Origin.DepTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ segment.Origin.Airport.AirportName }}, {{ segment.Origin.Airport.Terminal ? 'Terminal '+segment.Origin.Airport.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
																					<div class="text-center">
																						<div> 
																							{{ Math.floor(segment.Duration / 60) }}H {{ segment.Duration % 60 }}M 
																						</div>
																						<div class="flightLine departure">
																							<div></div>
																							<div></div>
																						</div>
																					</div>
																				</div>
																				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 14px; font-weight: 500; line-height:14px">
																							{{ $formatDate(segment.Destination.ArrTime,'time') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:14px">
																							{{ $formatDate(segment.Destination.ArrTime,'long') }}
																						</span>
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 12px; font-weight: 400; line-height:16px">
																							{{ segment.Destination.Airport.AirportName }},  {{ segment.Destination.Airport.Terminal ? 'Terminal '+segment.Destination.Airport.Terminal : ''}}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div class="row justify-content-center" style="align-items:center; padding-top:24px; padding-bottom:24px">
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">Baggage: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">Adult</span>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">Check-In: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.Baggage ? segment.Baggage : '-' }}</span>
																			</div>
																			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																				<span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">Cabin: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.CabinBaggage ? segment.CabinBaggage : '-' }}</span>
																			</div>																		
																		</div>

																		<div class="row pt-3 justify-content-center" style="align-items:center" v-if=" meta?.RoundTripSelectedFare?.Segments[0].length>1 && index==0">
																			<div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
																				padding:6px !important; font-weight:500; font-size:12px;">
																				<span style="color:#5143D9">Change of Planes | </span>
																				<span>
																					{{ Math.floor(( meta?.RoundTripSelectedFare?.Segments[0][1].AccumulatedDuration - ( meta?.RoundTripSelectedFare?.Segments[0][0].Duration +  meta?.RoundTripSelectedFare?.Segments[0][1].Duration))  / 60) }}H 
																					{{ ( meta?.RoundTripSelectedFare?.Segments[0][1].AccumulatedDuration - ( meta?.RoundTripSelectedFare?.Segments[0][0].Duration +  meta?.RoundTripSelectedFare?.Segments[0][1].Duration)) % 60 }}M
																					Layover in 
																					{{ segment.Destination.Airport.CityName }}
																				</span>
																			</div>																		
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
													
							
							<!-- Traveler Details -->
							<div class="col-xl-12 col-lg-12 col-md-12" style="padding: 10px;">
								<h3 style="font-size: 18px; font-weight: 600; line-height: 20px; text-align: left;">
									TRAVELLER DETAILS
								</h3>
								<!-- Overview -->
								<div class="card" style="border-radius:24px;">

									<div class="card-body">																				
										
										<div class="accordion accordion-icon accordion-bg-light pt-2" id="accordionExample2" >
											<div class="accordion-item mb-3 pt-2" v-for="passenger,key in booking.Passengers" :key="key">
												<div style="gap: 0px; border-radius: 6px; border: 1px dashed; opacity: 0px; margin-bottom: 0px !important;">
													<h6 class="accordion-header font-base" id="heading-1">
														<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-1'+key" aria-expanded="true" :aria-controls="'collapse-1'+key">
															<span v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }}</span> 
															<span v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }}</span> 
															<span v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }}</span> 
														</button>
													</h6>
													<div :id="'collapse-1'+key" class="accordion-collapse collapse show" aria-labelledby="heading-1" data-bs-parent="#accordionExample2">
														<div class="accordion-body mt-3">
															<div class="row g-4">
																<div class="col-md-2">
																	<label class="form-label">Title</label>
																	<select class="select form-control" v-model="passenger.Title" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.Title']}">
																		<option value="">Select Title</option>
																		<option>Mr</option>
																		<option>Ms</option>
																		<option>Mrs</option>
																		<option>Miss</option>
																		<option>Mstr</option>
																		<option>Master</option>
																	</select>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.Title']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.Title'][0] }}</small>
																	</span>
																</div>

																<div class="col-md-5">
																	<label class="form-label">First Name</label>
																	<div class="input-group">
																		<input v-model="passenger.FirstName" type="text" class="form-control" placeholder="First name" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.FirstName']}"/>
																	</div>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.FirstName']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.FirstName'][0] }}</small>
																	</span>
																</div>

																<div class="col-md-5">
																	<label class="form-label">Last Name</label>
																	<div class="input-group">
																		<input v-model="passenger.LastName" type="text" class="form-control" placeholder="Last name" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.LastName']}"/>
																	</div>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.LastName']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.LastName'][0] }}</small>
																	</span>
																</div>

																<!-- <div class="col-md-9">
																	<label class="form-label">Full Name</label>
																	<div class="input-group">
																		<input v-model="passenger.FirstName" type="text" class="form-control" placeholder="First name" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.FirstName']}"/>
																		<input v-model="passenger.LastName" type="text" class="form-control" placeholder="Last name" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.LastName']}"/>
																	</div>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.FirstName']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.FirstName'][0] }}</small>
																	</span>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.LastName']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.LastName'][0] }}</small>
																	</span>
																</div> -->
																<div class="col-md-4">
																	<label class="form-label">Gender</label>
																	<select class="select form-control" v-model="passenger.Gender" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.Gender']}">
																		<option value="">Select Gender</option>
																		<option value="1">Male</option>
																		<option value="2">Female</option>
																	</select>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.Gender']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.Gender'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">Date Of Birth</label>
																	<input v-model="passenger.DateOfBirth" type="date" class="form-control" placeholder="First name" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.DateOfBirth']}"/>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.DateOfBirth']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.DateOfBirth'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">Nationality</label>
																	<!-- <select class="select form-control" v-model="passenger.Nationality" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.Nationality']}">
																		<option value="">Select Nationality</option>
																		<option value="IN">India</option>
																		<option>Mali</option>
																		<option>Japan</option>
																		<option>Jordan</option>
																	</select>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.Nationality']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.Nationality'][0] }}</small>
																	</span> -->
																	<search
																		:class="{ 'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.Nationality'] }"
																		:customClass="{ 'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.Nationality']  }"
																		:initialize="passenger.Nationality"
																		id="country_code"
																		label="country_name"																		
																		placeholder="Please Select Nationality"
																		:data="countryCode"
																		@input="countryCode1 => passenger.Nationality = countryCode1"
																		@selectSearch="assignCountry($event, passenger)"
																		>
																	</search>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.Nationality']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.Nationality'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">AddressLine 1</label>
																	<input v-model="passenger.AddressLine1" type="text" class="form-control" placeholder="AddressLine 1" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.AddressLine1']}" />
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.AddressLine1']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.AddressLine1'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">AddressLine 2</label>
																	<input v-model="passenger.AddressLine2" type="text" class="form-control" placeholder="AddressLine 2" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.AddressLine2']}"/>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.AddressLine2']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.AddressLine2'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">Pincode</label>
																	<div class="input-group">
																		<input v-model="passenger.PinCode" type="text" class="form-control" placeholder="PinCode" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.PinCode']}"/>
																	</div>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.PinCode']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.PinCode'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">City</label>
																	<input v-model="passenger.City" type="text" class="form-control" placeholder="City" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.City']}"/>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.City']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.City'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">ContactNo</label>
																	<input v-model="passenger.ContactNo" type="text" class="form-control" placeholder="ContactNo" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.ContactNo']}" />
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.ContactNo']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.ContactNo'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4">
																	<label class="form-label">Email</label>
																	<input v-model="passenger.Email" type="text" class="form-control" placeholder="Email" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.Email']}"  />
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.Email']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.Email'][0] }}</small>
																	</span>
																</div>
																
																<div class="col-md-4" v-if="meta?.SelectedFare?.IsPassportRequiredAtTicket == true || meta?.SelectedFare?.IsPassportRequiredAtBook == true || meta.RoundTripSelectedFare?.IsPassportRequiredAtBook == true">
																	<label class="form-label">Passport Number</label>
																	<input type="text" v-model="passenger.PassportNo" class="form-control" placeholder="Enter passport number" />
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.PassportNo']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.PassportNo'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4" v-if="meta?.SelectedFare?.IsPassportRequiredAtTicket == true || meta?.SelectedFare?.IsPassportRequiredAtBook == true || meta.RoundTripSelectedFare?.IsPassportRequiredAtBook == true">
																	<label class="form-label">Passport Issue Country Code</label>
																	<search
																		:class="{ 'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.PassportIssueCountryCode'] }"
																		:customClass="{ 'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.PassportIssueCountryCode']  }"
																		:initialize="passenger.PassportIssueCountryCode"
																		id="country_code"
																		label="country_name"																		
																		placeholder="Select Passport Issue Country"
																		:data="countryCode"
																		@input="countryCode1 => passenger.PassportIssueCountryCode = countryCode1"
																		>
																	</search>
																	<!-- <select class="select form-control" v-model="passenger.PassportIssueCountryCode" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.PassportIssueCountryCode']}">
																		<option value="">Select Passport IssueCountry Code</option>
																		<option value="IN">India</option>
																		<option>Mali</option>
																		<option>Japan</option>
																		<option>Jordan</option>
																	</select>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.PassportIssueCountryCode']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.PassportIssueCountryCode'][0] }}</small>
																	</span> -->
																</div>
																<div class="col-md-4" v-if="meta?.SelectedFare?.IsPassportRequiredAtTicket == true || meta?.SelectedFare?.IsPassportFullDetailRequiredAtBook == true || meta.RoundTripSelectedFare?.IsPassportFullDetailRequiredAtBook == true">
																	<label class="form-label">Passport Issue Date</label>
																	<input v-model="passenger.PassportIssueDate" type="date" class="form-control" placeholder="Passport Issue Date" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.PassportIssueDate']}"/>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.PassportIssueDate']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.PassportIssueDate'][0] }}</small>
																	</span>
																</div>
																<div class="col-md-4"  v-if="meta?.SelectedFare?.IsPassportRequiredAtTicket == true || meta?.SelectedFare?.IsPassportRequiredAtBook == true || meta.RoundTripSelectedFare?.IsPassportRequiredAtBook == true">
																	<label class="form-label">Passport Expiry</label>
																	<input v-model="passenger.PassportExpiry" type="date" class="form-control" placeholder="Enter passport date" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.PassportExpiry']}"/>
																	<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.PassportExpiry']">
																		<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.PassportExpiry'][0] }}</small>
																	</span>
																</div>																
															</div>
														</div>
													</div>	
												</div>											
											</div>
										</div>

										<!-- <div v-for="seat, key1 in selectedOBSeats" :key="key1" class="col-4 box">
											<div v-for="[key2, value] of Object.entries(seat)" :key="key2">
												<p>Key: {{ key2 }}</p>
												<p>Value: {{ value }}</p>
											</div>
										</div> -->										
									</div>
									<div class="card-footer bg-white border-top">
										<div class="col-md-12">
											<div class="accordion" id="accordionPanelsStayOpenExample">
												<div class="accordion-item">
													<h2 class="accordion-header" id="panelsStayOpen-headingTwo" style="display:inline-block;">
														<div class="accordion-button acc-btn" style="align-items:center; font-size: 14px;font-weight: 400;line-height: 20px;text-align: left;">
															<div class="form-check">
																<input class="form-check-input chkAll" type="checkbox" value="" id="chkAccordion2All" @click="toggleAccordion" />
															</div>
															Enter GST Details
															<span style="font-size: 10px;font-weight: 400;line-height: 16px;text-align: left;">
																(optional)
															</span>
														</div>
													</h2>
													<div class="accordion-collapse collapse pt-3" id="gst_collapse" aria-labelledby="panelsStayOpen-headingTwo"  data-bs-parent="#accordionPanelsStayOpenExample">
														<div class="accordion-body ms-3" style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
															<div v-for="passenger,key in booking.Passengers" :key="key">
																<div class="row g-4" v-if="passenger.IsLeadPax == true">																	
																	<div class="col-md-4">
																		<label class="form-label">GST Company Name</label>
																		<div class="input-group">
																			<input v-model="passenger.GSTCompanyName" type="text" class="form-control" placeholder="GST Company Name" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyName']}"/>
																		</div>
																		<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyName']">
																			<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyName'][0] }}</small>
																		</span>
																	</div>

																	<div class="col-md-4">
																		<label class="form-label">GST Number</label>
																		<div class="input-group">
																			<input v-model="passenger.GSTNumber" type="text" class="form-control" placeholder="GST Number" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.GSTNumber']}"/>
																		</div>
																		<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.GSTNumber']">
																			<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.GSTNumber'][0] }}</small>
																		</span>
																	</div>																			
																	<div class="col-md-4">
																		<label class="form-label">GST Company Email</label>
																		<div class="input-group">
																			<input v-model="passenger.GSTCompanyEmail" type="text" class="form-control" placeholder="GST Company Email" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyEmail']}"/>
																		</div>
																		<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyEmail']">
																			<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyEmail'][0] }}</small>
																		</span>
																	</div>
																	<div class="col-md-6">
																		<label class="form-label">GST Company Contact Number</label>
																		<div class="input-group">
																			<input v-model="passenger.GSTCompanyContactNumber" type="text" class="form-control" placeholder="GST Company Contact Number" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyContactNumber']}"/>
																		</div>
																		<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyContactNumber']">
																			<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyContactNumber'][0] }}</small>
																		</span>
																	</div>
																	<div class="col-md-6">
																		<label class="form-label">GST Company Address</label>
																		<div class="input-group">
																			<input v-model="passenger.GSTCompanyAddress" type="text" class="form-control" placeholder="GST Company Address" :class="{'is-invalid': errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyAddress']}"/>
																		</div>
																		<span style="font-size: 12px" v-if="errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyAddress']">
																			<small class="text-danger">{{ errors['bookTicket.OB.Passengers.'+key+'.GSTCompanyAddress'][0] }}</small>
																		</span>
																	</div>																	
																</div>																												
															</div>
														</div>
													</div>
												</div>      
											</div>
										</div>
									</div>									
								</div>

								<!-- Add ons -->
								<div class="pt-3">
									<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" id="addons_button">
										<button type="button" class="btn btn-primary" @click="insuranceSearch" style="padding: 13px 10px 13px 10px; gap: 10px; border-radius: 12px; opacity: 0px; width: 100%;">												
											Continue
											<i class="fa fa-angle-double-down mx-3" aria-hidden="true"></i>
										</button>
									</div>

									<div class="accordion-item mb-3" v-if="insuranceDetails.length > 0" >
										<div class="card" style="padding: 16px; gap: 0px; border-radius: 24px;opacity: 0px;">
											<h6 class="accordion-header font-base" id="heading-4" style="font-size: 16px;font-weight: 500;line-height: 22px;text-align: left;">
												<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#addons_collapse" aria-expanded="true" aria-controls="addons_collapse">
													<span>Traveller’s Protection</span>
												</button>
											</h6>
											<div id="addons_collapse" class="accordion-collapse collapse" aria-labelledby="heading-4" data-bs-parent="#accordionExample5" >
												<div class="card mb-4 mt-4" style="border: 1px dashed; padding: 16px;border-radius: 16px; opacity: 0px;">
													<div class="card-body py-2">
														<div class="price-summary">
															<ul class="list-group">
																<li v-for="insurance, key in insuranceDetails" :key="key" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																	<div class="form-check form-check-inline">																			
																		<input class="form-check-input" type="radio" @click="selectInsurance(insurance.PlanCode)" name="insurance" :value="insurance.Price.PublishedPrice" >
																		<a type="button" data-bs-toggle="modal" id="modal_insuranceRelations"  
																			data-bs-target="#insuranceRelations"  style="color: blue; display:none">
																			Show Details
																		</a>	
																		<label class="form-check-label" for="oneway">{{ insurance.PlanName }} - {{insurance.Price.Currency}}. {{insurance.Price.PublishedPrice}}</label>
																	</div>
																	<a type="button" data-bs-toggle="modal" id="modal_insurance"  
																		data-bs-target="#bookinsurance"  style="color: blue;"
																		@click="showInsurance(insurance.PlanCode)">
																		Show Details
																	</a>																		
																</li>
																<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																	<div class="form-check form-check-inline">																			
																		<input class="form-check-input" type="radio" @click="selectInsurance('No Insurance')" id='no_insurance' name="insurance" value="0" >
																		<label class="form-check-label" for="oneway">No, thanks, I’ll risk it.</label>
																	</div>																		
																</li>		
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Baggage Dynamics -->
									<div v-if="show_ssr && insuranceDetails.length > 0" class="accordion-item mb-3">
										<div class="card" v-if="SsrObData?.Baggage || SsrIbData?.Baggage" style="padding: 16px; gap: 0px; border-radius: 24px;opacity: 0px;">
											<h6 class="accordion-header font-base" id="heading-5" style="font-size: 16px;font-weight: 500;line-height: 22px;text-align: left;">
												<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#baggage_collapse" aria-expanded="true" aria-controls="baggage_collapse">
													<span>Baggage Selection</span>
												</button>
											</h6>
											<div id="baggage_collapse" class="accordion-collapse collapse" aria-labelledby="heading-5" data-bs-parent="#accordionExample6" >
												<div class="card mb-4 mt-4" v-if="SsrObData?.Baggage || SsrIbData?.Baggage">
													<div class="card-body py-2">
														<div class="price-summary">
															<ul class="nav nav-tabs col-md-12" style="text-align: center;">													
																<li class="nav-item col-md-6">
																	<a class="nav-link active" id="onewayBaggage" aria-current="page" @click="activateTabs('oneway', 'baggage')">
																		<i class="fa-solid fa-plane"></i>
																		{{ search?.OriginName }}-{{ search?.DestinationName }}
																	</a>
																</li>
																<li class="nav-item col-md-6" v-if="search?.JourneyType == 2">
																	<a class="nav-link"  id="returnBaggage" @click="activateTabs('return', 'baggage')">
																		<i class="fa-solid fa-plane"></i>
																		{{ search?.DestinationName }}-{{ search?.OriginName }}
																	</a>
																</li>																	
															</ul>
															<div id="oneway" class="mt-2">

																<div class="accordion-item mb-3" v-for="passenger,key in booking.Passengers" :key="key">
																	<h6 class="accordion-header font-base" id="heading-5" style="padding:8px 0px;">
																		<div class="accordion-button acc-btn" style="align-items:center; font-size: 14px;font-weight: 400;line-height: 20px;text-align: left;color:#1E1E1E;">
																			<input v-if="passenger.PaxType==1" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-5'+key" aria-expanded="true" :aria-controls="'collapse-5'+key" :value="'oneway_baggage_'+passenger.PaxNo" />
																			<label v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }} Baggage Selection</label>
																			
																			<input v-if="passenger.PaxType==2" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-5'+key" aria-expanded="true" :aria-controls="'collapse-5'+key" :value="'oneway_baggage_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }} Baggage Selection</label>

																			<input v-if="passenger.PaxType==3" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-5'+key" aria-expanded="true" :aria-controls="'collapse-5'+key" :value="'oneway_baggage_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }} Baggage Selection</label>
																		</div>
																	</h6>
																	<div :id="'collapse-5'+key" class="accordion-collapse collapse pt-3" aria-labelledby="heading-5" data-bs-parent="#accordionExample4">
																		<div class="accordion-body" style="padding: 16px;border-radius: 16px;border: 1px dashed;">
																			<div class="row g-4" v-if="passenger.PaxType==3">
																				<ul class="list-group" style="padding: 0 16px" >
																					<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						Additional baggage cannot be selected for this passenger.
																					</li>
																				</ul>
																			</div>
																			<div class="row g-4" v-else>
																				<ul v-if="!SsrObData?.Baggage" class="list-group" >
																					<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						<div class="form-check form-check-inline">																			
																							<!-- <input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 2)" :name="'baggage_return'+key" :value="bag.Price" > -->
																							<label class="form-check-label" for="baggage"> No Baggage Available</label>
																						</div>																																																							
																					</li>	
																				</ul>
																				<ul v-else class="list-group" v-for="baggage, key1 in SsrObData?.Baggage" :key="key1" style="padding: 0 16px !important;">
																					<li v-for="bag, key2 in baggage" :key="key2" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						<div v-if="bag.Weight == 0" class="form-check form-check-inline">																			
																							<input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 1)" :name="'baggage_'+key" :value="bag.Price" >
																							<label class="form-check-label" for="baggage"> No additional baggage </label>
																						</div>
																						<div v-else class="form-check form-check-inline">																			
																							<input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 1)" :name="'baggage_'+key" :value="bag.Price" >
																							<label class="form-check-label" for="baggage">{{ bag.Weight }} Kg - {{bag.Currency}}. {{bag.Price}}</label>
																						</div>																																			
																					</li>																			
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div id="return" style="display: none;" class="mt-2">
																<div class="accordion-item mb-3" v-for="passenger,key in booking.Passengers" :key="key">
																	<!-- <h6 class="accordion-header font-base" id="heading-5">
																		<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-5'+key" aria-expanded="true" :aria-controls="'collapse-5'+key">
																			<span v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }} Baggage Selection</span> 
																			<span v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }} Baggage Selection</span> 
																			<span v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }} Baggage Selection</span> 
																		</button>
																	</h6> -->
																	<h6 class="accordion-header font-base" id="heading-5" style="padding:8px 0px;">
																		<div class="accordion-button acc-btn" style="align-items:center; font-size: 14px;font-weight: 400;line-height: 20px;text-align: left;color:#1E1E1E;">
																			<input v-if="passenger.PaxType==1" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-5-r'+key" aria-expanded="true" :aria-controls="'collapse-5-r'+key" :value="'return_baggage_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }} Baggage Selection</label>
																			
																			<input v-if="passenger.PaxType==2" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-5-r'+key" aria-expanded="true" :aria-controls="'collapse-5-r'+key" :value="'return_baggage_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }} Baggage Selection</label>

																			<input v-if="passenger.PaxType==3" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-5-r'+key" aria-expanded="true" :aria-controls="'collapse-5-r'+key" :value="'return_baggage_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }} Baggage Selection</label>
																		</div>
																	</h6>
																	<div :id="'collapse-5-r'+key" class="accordion-collapse collapse" aria-labelledby="heading-5" data-bs-parent="#accordionExample4">
																		<div class="accordion-body"  style="padding: 16px;border-radius: 16px;border: 1px dashed;">
																			<div class="row g-4" v-if="passenger.PaxType==3">
																				<ul class="list-group" >
																					<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						Additional baggage cannot be selected for this passenger.
																					</li>
																				</ul>
																			</div>
																			<div class="row g-4" v-else>
																				<ul v-if="!SsrIbData?.Baggage" class="list-group" >
																					<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						<div class="form-check form-check-inline">																			
																							<!-- <input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 2)" :name="'baggage_return'+key" :value="bag.Price" > -->
																							<label class="form-check-label" for="baggage"> No Baggage Available</label>
																						</div>																																																							
																					</li>	
																				</ul>
																					
																				<ul v-else class="list-group" v-for="baggage, key1 in SsrIbData?.Baggage" :key="key1">
																					<li v-for="bag, key2 in baggage" :key="key2" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						<div v-if="bag.Weight == 0" class="form-check form-check-inline">																			
																							<input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 2)" :name="'baggage_return'+key" :value="bag.Price" >
																							<label class="form-check-label" for="baggage"> No additional baggage </label>
																						</div>
																						<div v-else class="form-check form-check-inline">																			
																							<input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 2)" :name="'baggage_return'+key" :value="bag.Price" >
																							<label class="form-check-label" for="baggage">{{ bag.Weight }} Kg - {{bag.Currency}}. {{bag.Price}}</label>
																						</div>																																			
																					</li>																			
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<!-- Meal Dynamics -->
									<div v-if="show_ssr && insuranceDetails.length > 0" class="accordion-item mb-3">
										<div class="card" v-if="SsrObData?.MealDynamic || SsrIbData?.MealDynamic || SsrObData?.Meal || SsrIbData?.Meal" style="padding: 16px; gap: 0px; border-radius: 24px;opacity: 0px;">
											<h6 class="accordion-header font-base" id="heading-6" style="font-size: 16px;font-weight: 500;line-height: 22px;text-align: left;">
												<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#meal_collapse" aria-expanded="true" aria-controls="meal_collapse">
													<span>Meal Selection</span>
												</button>
											</h6>
											<div id="meal_collapse" class="meal_collapse collapse" aria-labelledby="heading-6" data-bs-parent="#accordionExample7" >
												<div class="card mb-4 mt-4" v-if="SsrObData?.MealDynamic || SsrIbData?.MealDynamic || SsrObData?.Meal || SsrIbData?.Meal">
													<div class="card-body py-2">
														<div class="price-summary">
															<ul class="nav nav-tabs col-md-12" style="text-align: center;">													
																<li class="nav-item col-md-6">
																	<a class="nav-link active" id="onewaymeal" aria-current="page" @click="activateTabs('oneway', 'meal')">
																		<i class="fa-solid fa-plane"></i>
																		{{ search?.OriginName }}-{{ search?.DestinationName }}
																	</a>
																</li>
																<li class="nav-item col-md-6" v-if="search?.JourneyType == 2">
																	<a class="nav-link"  id="returnmeal" @click="activateTabs('return', 'meal')">
																		<i class="fa-solid fa-plane"></i>
																		{{ search?.DestinationName }}-{{ search?.OriginName }}
																	</a>
																</li>																	
															</ul>
															<div id="onewayMeal" class="mt-2">
																<div class="accordion-item mb-3" v-for="passenger,key in booking.Passengers" :key="key">
																	<!-- <h6 class="accordion-header font-base" id="heading-6">
																		<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-6'+key" aria-expanded="true" :aria-controls="'collapse-6'+key">
																			<span v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }} Meal Selection</span> 
																			<span v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }} Meal Selection</span> 
																			<span v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }} Meal Selection</span> 
																		</button>
																	</h6> -->
																	<h6 class="accordion-header font-base" id="heading-6" style="padding:8px 0px;">
																		<div class="accordion-button acc-btn" style="align-items:center; font-size: 14px;font-weight: 400;line-height: 20px;text-align: left;color:#1E1E1E;">
																			<input v-if="passenger.PaxType==1" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-6'+key" aria-expanded="true" :aria-controls="'collapse-6'+key" :value="'oneway_meal_'+passenger.PaxNo" />
																			<label v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }} Meal Selection</label>
																			
																			<input v-if="passenger.PaxType==2" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-6'+key" aria-expanded="true" :aria-controls="'collapse-6'+key" :value="'oneway_meal_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }} Meal Selection</label>

																			<input v-if="passenger.PaxType==3" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-6'+key" aria-expanded="true" :aria-controls="'collapse-6'+key" :value="'oneway_meal_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }} Meal Selection</label>
																		</div>
																	</h6>
																	<div :id="'collapse-6'+key" class="accordion-collapse collapse" aria-labelledby="heading-6" data-bs-parent="#accordionExample5">
																		<div class="accordion-body mt-3">
																			<div class="row g-4">
																				<ul v-if="!SsrObData?.MealDynamic && !SsrObData?.Meal" class="list-group" >
																					<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						<div class="form-check form-check-inline">																			
																							<!-- <input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 2)" :name="'baggage_return'+key" :value="bag.Price" > -->
																							<label class="form-check-label" for="baggage"> No Meal Available</label>
																						</div>																																																							
																					</li>	
																				</ul>
																				<div v-else>
																					<ul  class="list-group" v-for="meals, key1 in SsrObData?.MealDynamic" :key="key1" style="padding: 16px;border-radius: 16px;border: 1px dashed;">
																						<li v-for="meal, key2 in meals" :key="key2" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																							<div v-if="meal.Code == 'NoMeal'" class="form-check form-check-inline">																			
																								<input class="form-check-input" type="radio" @click="selectMeal(meal.Code, passenger, 1, 1)" :name="'meal_'+key" :value="meal.Price" >
																								<label class="form-check-label" for="meal"> No Meal </label>
																							</div>
																							<div v-else class="form-check form-check-inline">																			
																								<input class="form-check-input" type="radio" @click="selectMeal(meal.Code, passenger, 1, 1)" :name="'meal_'+key" :value="meal.Price" >
																								<label class="form-check-label" for="meal">{{ meal.AirlineDescription }} - {{meal.Currency}}. {{meal.Price}}</label>
																							</div>																																			
																						</li>																			
																					</ul>
																					<ul v-if="SsrObData?.Meal" class="list-group" style="padding: 16px;border-radius: 16px;border: 1px dashed;">
																						<li v-for="meals, key1 in SsrObData?.Meal" :key="key1" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">																							
																							<div class="form-check form-check-inline">																			
																								<input class="form-check-input" type="radio" @click="selectMeal(meals.Code, passenger, 1, 2)" :name="'meal_'+key" :value="meals.Code">
																								<label class="form-check-label" for="meal">{{ meals.Description }}</label>
																							</div>																																			
																						</li>																			
																					</ul>
																				</div>
																				
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div id="returnMeal" style="display: none;" class="mt-2">
																<div class="accordion-item mb-3" v-for="passenger,key in booking.Passengers" :key="key">
																	<!-- <h6 class="accordion-header font-base" id="heading-6">
																		<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-6'+key" aria-expanded="true" :aria-controls="'collapse-6'+key">
																			<span v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }} Meal Selection</span> 
																			<span v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }} Meal Selection</span> 
																			<span v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }} Meal Selection</span> 
																		</button>
																	</h6> -->
																	<h6 class="accordion-header font-base" id="heading-6" style="padding:8px 0px;">
																		<div class="accordion-button acc-btn" style="align-items:center; font-size: 14px;font-weight: 400;line-height: 20px;text-align: left;color:#1E1E1E;">
																			<input v-if="passenger.PaxType==1" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-6-r'+key" aria-expanded="true" :aria-controls="'collapse-6-r'+key" :value="'return_meal_'+passenger.PaxNo" />
																			<label v-if="passenger.PaxType==1">Adult {{ passenger.PaxNo }} Meal Selection</label>
																			
																			<input v-if="passenger.PaxType==2" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-6-r'+key" aria-expanded="true" :aria-controls="'collapse-6-r'+key" :value="'return_meal_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==2">Child {{ passenger.PaxNo }} Meal Selection</label>

																			<input v-if="passenger.PaxType==3" type="checkbox" data-bs-toggle="collapse" :data-bs-target="'#collapse-6-r'+key" aria-expanded="true" :aria-controls="'collapse-6-r'+key" :value="'return_meal_'+passenger.PaxNo"/>
																			<label v-if="passenger.PaxType==3">Infant {{ passenger.PaxNo }} Meal Selection</label>
																		</div>
																	</h6>
																	<div :id="'collapse-6-r'+key" class="accordion-collapse collapse" aria-labelledby="heading-6" data-bs-parent="#accordionExample5">
																		<div class="accordion-body mt-3">
																			<div class="row g-4">
																				<ul v-if="!SsrIbData?.MealDynamic && !SsrIbData?.Meal" class="list-group" >
																					<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																						<div class="form-check form-check-inline">																			
																							<!-- <input class="form-check-input" type="radio" @click="selectBaggage(bag.Code, passenger, 2)" :name="'baggage_return'+key" :value="bag.Price" > -->
																							<label class="form-check-label" for="baggage"> No Meal Available</label>
																						</div>																																																							
																					</li>	
																				</ul>
																				<div v-else>
																					<ul class="list-group" v-for="meals, key1 in SsrIbData?.MealDynamic" :key="key1" style="padding: 16px;border-radius: 16px;border: 1px dashed;">
																						<li v-for="meal, key2 in meals" :key="key2" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
																							<div v-if="meal.Code == 'NoMeal'" class="form-check form-check-inline">																			
																								<input class="form-check-input" type="radio" @click="selectMeal(meal.Code, passenger, 2, 1)" :name="'meal_return'+key" :value="meal.Price" >
																								<label class="form-check-label" for="meal"> No Meal </label>
																							</div>
																							<div v-else class="form-check form-check-inline">																			
																								<input class="form-check-input" type="radio" @click="selectMeal(meal.Code, passenger, 2, 1)" :name="'meal_return'+key" :value="meal.Price" >
																								<label class="form-check-label" for="meal">{{ meal.AirlineDescription }} - {{meal.Currency}}. {{meal.Price}}</label>
																							</div>																																			
																						</li>																			
																					</ul>

																					<ul  v-if="SsrIbData?.Meal"  class="list-group"  style="padding: 16px;border-radius: 16px;border: 1px dashed;">
																						<li v-for="meals, key1 in SsrIbData?.Meal" :key="key1" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">																							
																							<div class="form-check form-check-inline">																			
																								<input class="form-check-input" type="radio" @click="selectMeal(meals.Code, passenger, 2, 2)" :name="'meal_return'+key" :value="meals.Code">
																								<label class="form-check-label" for="meal">{{ meals.Description }}</label>
																							</div>																																			
																						</li>																			
																					</ul>
																				</div>
																				
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									
									<!-- Seat Dynamics -->
									<div v-if="show_ssr && insuranceDetails.length > 0" class="accordion-item mb-3">
										<!-- <div class="card" v-if="SsrObData?.SeatDynamic || SsrIbData?.SeatDynamic" style="padding: 16px; gap: 0px; border-radius: 24px;opacity: 0px;">
											<h6 class="accordion-header font-base" id="heading-7" style="font-size: 16px;font-weight: 500;line-height: 22px;text-align: left;">
												<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seat_selection_collapse" aria-expanded="true" aria-controls="seat_selection_collapse">
													<span>Seat Selection Now</span>
												</button>
											</h6>
											<div id="seat_selection_collapse" class="seat_selection_collapse collapse" aria-labelledby="heading-7" data-bs-parent="#accordionExample8" >
												<div class="card mb-4 mt-4" v-if="SsrObData?.SeatDynamic || SsrIbData?.SeatDynamic">
													<div class="card-body py-2">
														<div class="price-summary">														
															<ul class="nav nav-tabs col-md-12" style="text-align: center;" >													
																<li class="nav-item" :class="filght_col" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">
																	<a class="nav-link" :class="key==0 ? 'active' : ''" id="onewayseat" aria-current="page" @click="activateTabs('oneway_'+key, 'seat')">
																		<i class="fa-solid fa-plane"></i>
																		{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																	</a>
																</li>
																<li class="nav-item" :class="filght_col" v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key">
																	<a class="nav-link"  id="returnseat" @click="activateTabs('return_'+key, 'seat')">
																		<i class="fa-solid fa-plane"></i>
																		{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																	</a>
																</li>																	
															</ul>
															<div :id="'oneway_'+key" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">
																<ul class="list-group">
																	<li class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																		<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																			<FlightSeat 
																				:seatsData="seatOBDetails_oneway" 
																				:numberOfPassengers="booking.Passengers.length"
																				:flight_type_id="'oneway_'+key"
																				@update-selected-seats="handleUpdateSelectedSeats"
																			/>
																		</div>
																		<div v-if="selectedOBSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																			<div class="row">
																				<div v-for="seat, key1 in selectedOBSeats" :key="key1">
																					<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																						<div class="iyZANA">
																							<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																								<span class="kGdPCP">{{ value?.Code }}</span>
																							</div>
																						</div>
																						<div class="iXKOeT">
																							<div color="primary" class="hmhLTy">{{ key2 }}</div>
																							<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</li>
																	<li v-else class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																		<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																			<FlightSeat 
																				:seatsData="seatOBDetails_connect" 
																				:numberOfPassengers="booking.Passengers.length"
																				:flight_type_id="'oneway_'+key"
																				@update-selected-seats="handleUpdateSelectedSeats"
																			/>
																		</div>
																		<div v-if="selectedOBConnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																			<div class="row">
																				<div v-for="seat, key1 in selectedOBConnectSeats" :key="key1">
																					<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																						<div class="iyZANA">
																							<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																								<span class="kGdPCP">{{ value?.Code }}</span>
																							</div>
																						</div>
																						<div class="iXKOeT">
																							<div color="primary" class="hmhLTy">{{ key2 }}</div>
																							<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</li>
																</ul>
															</div>

															<div :id="'return_'+key" v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key">
																<ul class="list-group">
																	<li v-if="key==0" class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																		<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																			<FlightSeat 
																				:seatsData="seatIBDetails_oneway" 
																				:numberOfPassengers="booking.Passengers.length"
																				:flight_type_id="'return_'+key"
																				@update-selected-seats="handleUpdateSelectedSeats"
																			/>
																		</div>
																		<div v-if="selectedIBSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																			<div class="row">
																				<div v-for="seat, key1 in selectedIBSeats" :key="key1">
																					<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																						<div class="iyZANA">
																							<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																								<span class="kGdPCP">{{ value?.Code }}</span>
																							</div>
																						</div>
																						<div class="iXKOeT">
																							<div color="primary" class="hmhLTy">{{ key2 }}</div>
																							<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</li>
																	<li v-else class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																		<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																			<FlightSeat 
																				:seatsData="seatIBDetails_connect" 
																				:numberOfPassengers="booking.Passengers.length"
																				:flight_type_id="'return_'+key"
																				@update-selected-seats="handleUpdateSelectedSeats"
																			/>
																		</div>
																		<div v-if="selectedIBCOnnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																			<div class="row">
																				<div v-for="seat, key1 in selectedIBCOnnectSeats" :key="key1">
																					<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																						<div class="iyZANA">
																							<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																								<span class="kGdPCP">{{ value?.Code }}</span>
																							</div>
																						</div>
																						<div class="iXKOeT">
																							<div color="primary" class="hmhLTy">{{ key2 }}</div>
																							<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</li>																			
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div> -->
										
										<div class="card" v-if="SsrObData?.SeatDynamic || SsrIbData?.SeatDynamic" style="padding: 16px; gap: 0px; border-radius: 24px;opacity: 0px;">
											<h6 class="accordion-header font-base" id="heading-7" style="font-size: 16px;font-weight: 500;line-height: 22px;text-align: left;">
												<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seat_selection_collapse" aria-expanded="true" aria-controls="seat_selection_collapse">
													<span>Seat Selection</span>
												</button>
											</h6>
											<div id="seat_selection_collapse" class="seat_selection_collapse collapse" aria-labelledby="heading-7" data-bs-parent="#accordionExample8" >
												<div class="card mb-4 mt-4" v-if="SsrObData?.SeatDynamic || SsrIbData?.SeatDynamic">
													<div class="card-body py-2">
														<div class="price-summary">
															<ul class="nav nav-tabs nav-bottom-line nav-responsive nav-justified row">
																<li class="nav-item col" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">
																	<a class="nav-link mb-0" data-bs-toggle="tab" :id="'oneway_'+key" :href="'#onewayseat_'+key" @click=show_hide_oneway_tabs(key)>
																		<i class="fa-solid fa-plane"></i>
																		{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																	</a>
																</li>
																<li class="nav-item col"  v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key">
																	<a class="nav-link mb-0" data-bs-toggle="tab" :id="'return_'+key" :href="'#returnseat_'+key" @click=show_hide_return_tabs(key)>
																		<i class="fa-solid fa-plane"></i>
																		{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																	</a>
																</li>                   
															</ul>
															<div v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key" class="tab-pane fade" :id="'onewayseat_'+key" style="display:none;" >
																<div class="row" v-if="SsrObData?.SeatDynamic.length > 0">
																	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																		<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																			<FlightSeat 
																				:seatsData="SsrObData?.SeatDynamic[0]?.SegmentSeat[key]?.RowSeats" 
																				:numberOfPassengers="booking.Passengers.length"
																				flight_type_id="oneway"
																				:flight_key="key"
																				@update-selected-seats="handleUpdateSelectedSeats"
																			/>
																		</div>
																	</div>
																	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																		<div v-if="selectedOBSeats.length" class="selected-seat-info">																							
																			<div class="row justify-content-center align-item-center pt-3">
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/fligh_chair_available.svg" alt="available">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_reserved.svg" alt="reserved">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_selected.svg" alt="selected">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_blocked.svg" alt="blocked">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																					</div>
																				</div>
																			</div>
																			<div class="row justify-content-center" style="padding-top: 20px;">
																				<!-- {{selectedOBSeats[key]}} -->
																				<div v-for="seat, key1 in selectedOBSeats[key]" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="padding-bottom: 5px">
																					<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																						<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																							<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																								<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 46px; height: 36px;">{{ value?.Code }}</div>																
																							</div>
																							<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																								<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																								<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																							</div>
																						</div>
																					</div>
																				</div>
																				<!-- <div v-for="seat, key1 in selectedOBSeats[key]" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="padding-bottom: 5px">
																					<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																						{{seat}}
																						<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																							<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																								<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 46px; height: 36px;">{{ value?.Code }}</div>																
																							</div>
																							<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																								<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																								<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																							</div>
																						</div>
																					</div>
																				</div> -->
																			</div>
																		</div>
																	</div>
																</div>
																<div class="row" v-else>
																	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																		Seat Selection is Not Available
																	</div>
																</div>
															</div>
															
															<div v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key"  class="tab-pane fade" :id="'returnseat_'+key" style="display:none;" >
																<div class="row" v-if="SsrIbData?.SeatDynamic.length > 0">
																	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																		<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																			<FlightSeat 
																				:seatsData="SsrIbData?.SeatDynamic[0]?.SegmentSeat[key]?.RowSeats" 
																				:numberOfPassengers="booking.Passengers.length"
																				flight_type_id="return"
																				:flight_key="key"
																				@update-selected-seats="handleUpdateSelectedSeats"
																			/>
																		</div>
																	</div>
																	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																		<div v-if="selectedIBSeats.length" class="selected-seat-info">																							
																			<div class="row justify-content-center align-item-center pt-3">
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/fligh_chair_available.svg" alt="available">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_reserved.svg" alt="reserved">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_selected.svg" alt="selected">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_blocked.svg" alt="blocked">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																					</div>
																				</div>
																				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																					</div>
																					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																						<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																					</div>
																				</div>
																			</div>
																			<div class="row justify-content-center" style="padding-top: 20px;">
																				<div v-for="seat, key1 in selectedIBSeats[key]" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="padding-bottom: 5px">
																					<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																						<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																							<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																								<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 46px; height: 36px;">{{ value?.Code }}</div>																
																							</div>
																							<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																								<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																								<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="row" v-else>
																	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																		Seat Selection is Not Available
																	</div>
																</div>
															</div>
															
															<!-- <ul class="nav nav-tabs col-md-12" style="text-align: center;" >																
																<li class="nav-item" :class="filght_col" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">
																	<a class="nav-link" :id="'onewayseat_'+key" aria-current="page" data-bs-toggle="collapse" :data-bs-target="'#oneway_'+key" aria-expanded="true" :aria-controls="'oneway_'+key" @click="activateTabs('oneway_'+key, 'seat')">
																		<i class="fa-solid fa-plane"></i>
																		{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																	</a>
																</li>
																<li class="nav-item" :class="filght_col" v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key">
																	<a class="nav-link"  :id="'returnseat_'+key" data-bs-toggle="collapse" :data-bs-target="'#return_'+key" aria-expanded="true" :aria-controls="'return_'+key" @click="activateTabs('return_'+key, 'seat')">
																		<i class="fa-solid fa-plane"></i>
																		{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																	</a>
																</li>																	
															</ul>
															<div v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key" :id="'oneway_'+key" class="accordion-collapse collapse" aria-labelledby="heading-10" data-bs-parent="#accordionExampleoneway" style="display: none">
																<ul class="list-group">
																	<li v-if="key==0" class="list-group-item align-items-center border-0 py-2 px-0">
																		<div class="row" v-if="seatOBDetails_oneway.length > 0">
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																					<FlightSeat 
																						:seatsData="seatOBDetails_oneway" 
																						:numberOfPassengers="booking.Passengers.length"
																						:flight_type_id="'oneway_'+key"
																						@update-selected-seats="handleUpdateSelectedSeats"
																					/>
																				</div>
																			</div>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div v-if="selectedOBSeats.length" class="selected-seat-info">																							
																					<div class="row justify-content-center align-item-center pt-3">
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/fligh_chair_available.svg" alt="available">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_reserved.svg" alt="reserved">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_selected.svg" alt="selected">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_blocked.svg" alt="blocked">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																							</div>
																						</div>
																					</div>
																					<div class="row justify-content-center" style="padding-top: 20px;">
																						<div v-for="seat, key1 in selectedOBSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="padding-bottom: 5px">
																							<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																									<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																										<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 46px; height: 36px;">{{ value?.Code }}</div>																
																									</div>
																									<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																										<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																										<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="row" v-else>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				Seat Selection is Not Available
																			</div>
																		</div>
																	</li>
																	<li v-else class="list-group-item align-items-center border-0 py-2 px-0">
																		<div class="row" v-if="seatOBDetails_connect.lenght > 0">
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																					<FlightSeat 
																						:seatsData="seatOBDetails_connect" 
																						:numberOfPassengers="booking.Passengers.length"
																						:flight_type_id="'oneway_'+key"
																						@update-selected-seats="handleUpdateSelectedSeats"
																					/>
																				</div>
																			</div>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div v-if="selectedOBConnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">
																					<div class="row justify-content-center pt-3">
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/fligh_chair_available.svg" alt="available">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_reserved.svg" alt="reserved">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_selected.svg" alt="selected">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_blocked.svg" alt="blocked">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																							</div>
																						</div>
																					</div>
																					<div class="row justify-content-center" style="padding-top: 20px;">
																						<div v-for="seat, key1 in selectedOBConnectSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="padding-bottom: 5px">
																							<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																									<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																										<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 46px; height: 36px;">{{ value?.Code }}</div>																
																									</div>
																									<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																										<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																										<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="row" v-else>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				Seat Selection is Not Available
																			</div>
																		</div>																																	
																	</li>
																</ul>
															</div>

															<div v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key" :id="'return_'+key" class="accordion-collapse collapse" aria-labelledby="heading-10" data-bs-parent="#accordionExamplereturn"  style="display: none;">
																<ul class="list-group">
																	<li v-if="key==0" class="list-group-item align-items-center border-0 py-2 px-0">
																		<div class="row" v-if="seatIBDetails_oneway.length > 0">
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																					<FlightSeat 
																						:seatsData="seatIBDetails_oneway" 
																						:numberOfPassengers="booking.Passengers.length"
																						:flight_type_id="'return_'+key"
																						@update-selected-seats="handleUpdateSelectedSeats"
																					/>
																				</div>
																			</div>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div v-if="selectedIBSeats.length" class="selected-seat-info mx-3 py-2 px-0">
																					<div class="row justify-content-center pt-3">
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/fligh_chair_available.svg" alt="available">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_reserved.svg" alt="reserved">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_selected.svg" alt="selected">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_blocked.svg" alt="blocked">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																							</div>
																						</div>
																					</div>
																					<div class="row justify-content-center" style="padding-top: 20px;">
																						<div v-for="seat, key1 in selectedIBSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																							<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																									<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																										<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 46px; height: 36px;">{{ value?.Code }}</div>																
																									</div>
																									<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																										<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																										<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="row" v-else>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				Seat Selection is Not Available
																			</div>
																		</div>																																
																	</li>
																	<li v-else class="list-group-item align-items-center border-0 py-2 px-0">
																		<div class="row" v-if="seatIBDetails_connect.length > 0">
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																					<FlightSeat 
																						:seatsData="seatIBDetails_connect" 
																						:numberOfPassengers="booking.Passengers.length"
																						:flight_type_id="'return_'+key"
																						@update-selected-seats="handleUpdateSelectedSeats"
																					/>
																				</div>
																			</div>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				<div v-if="selectedIBCOnnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">
																					<div class="row justify-content-center pt-3">
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/fligh_chair_available.svg" alt="available">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_reserved.svg" alt="reserved">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_selected.svg" alt="selected">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_blocked.svg" alt="blocked">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																							</div>
																						</div>
																						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																							</div>
																							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																								<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																							</div>
																						</div>
																					</div>
																					<div class="row justify-content-center" style="padding-top: 20px;">
																						<div v-for="seat, key1 in selectedIBCOnnectSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																							<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																									<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																										<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 46px; height: 36px;">{{ value?.Code }}</div>																
																									</div>
																									<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																										<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																										<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="row" v-else>
																			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																				Seat Selection is Not Available
																			</div>
																		</div>																																		
																	</li>																			
																</ul>
															</div> -->
														</div>
													</div>
												</div>
											</div>
										</div>									
									</div>

									<!-- <div class="accordion accordion-icon accordion-bg-light" id="accordionExample3">
										<div class="accordion-item mb-3">										
											<div>
												<div class="card mb-4 mt-4">
													<div class="card-header">															
														<h4>
															<img src="images/flight_seat.png" class="img-fluid circle me-1" width="30" alt="">
															Seat Selection
														</h4>														
													</div>
													<div class="card-body py-2">
														<div class="price-summary">
															<div class="tab">
																<div v-for="flight_seat, key in flight_seatings" :key="key">
																	<button class="tablinks" :class="flight_seat.active" @click="showFlightSeatingTab(flight_seat)">{{ flight_seat.tab}}</button>
																</div>
															</div>
														</div>
													</div>
													<div class="card-body py-2">
														<div class="price-summary">
															<ul class="nav nav-tabs col-md-12" style="text-align: center;" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">													
																<li class="nav-item col-md-6">
																	<a class="nav-link active" id="onewaymeal" aria-current="page" @click="activateTabs('oneway_'+key, 'seat')">
																		<i class="fa-solid fa-plane"></i>
																		{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																	</a>
																</li>
																<li class="nav-item col-md-6" v-if="search?.JourneyType == 2">
																	<a class="nav-link"  id="returnmeal" @click="activateTabs('return', 'meal')">
																		<i class="fa-solid fa-plane"></i>
																		{{ search?.DestinationName }}-{{ search?.OriginName }}
																	</a>
																</li>																	
															</ul>


															<div class="accordion-item mb-3" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">
																<h6 class="accordion-header font-base" id="heading-7">
																	<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-7'+key" aria-expanded="true" :aria-controls="'collapse-7'+key" @click="activateTabs('oneway_'+key, 'seat')">
																		<span>
																			<a class="nav-link"  :id="'onewayseat_'+key" href="javascript:void(0)">
																				<i class="fa-solid fa-plane"></i>
																				{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																			</a>
																		</span> 																				
																	</button>
																</h6>
																<div :id="'oneway_'+key" class="accordion-collapse collapse" aria-labelledby="heading-7" data-bs-parent="#accordionExample6">
																	<div class="accordion-body mt-3">
																		<div class="row g-4">
																			<ul class="list-group">
																				<li class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																					<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																						<FlightSeat 
																							:seatsData="seatOBDetails_oneway" 
																							:numberOfPassengers="booking.Passengers.length"
																							:flight_type_id="'oneway_'+key"
																							@update-selected-seats="handleUpdateSelectedSeats"
																						/>
																					</div>
																					<div v-if="selectedOBSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																						<div class="row">
																							<div v-for="seat, key1 in selectedOBSeats" :key="key1">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																									<div class="iyZANA">
																										<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																											<span class="kGdPCP">{{ value?.Code }}</span>
																										</div>
																									</div>
																									<div class="iXKOeT">
																										<div color="primary" class="hmhLTy">{{ key2 }}</div>
																										<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</li>
																				<li v-else class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																					<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																						<FlightSeat 
																							:seatsData="seatOBDetails_connect" 
																							:numberOfPassengers="booking.Passengers.length"
																							:flight_type_id="'oneway_'+key"
																							@update-selected-seats="handleUpdateSelectedSeats"
																						/>
																					</div>
																					<div v-if="selectedOBConnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																						<div class="row">
																							<div v-for="seat, key1 in selectedOBConnectSeats" :key="key1">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																									<div class="iyZANA">
																										<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																											<span class="kGdPCP">{{ value?.Code }}</span>
																										</div>
																									</div>
																									<div class="iXKOeT">
																										<div color="primary" class="hmhLTy">{{ key2 }}</div>
																										<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>


															<div class="accordion-item mb-3" v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]">
																<h6 class="accordion-header font-base" id="heading-8">																			
																	<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-8'+key" aria-expanded="true" :aria-controls="'collapse-8'+key" @click="activateTabs('return_'+key, 'seat')">
																		<span>
																			<a class="nav-link"  :id="'returnseat_'+key" href="javascript:void(0)">
																				<i class="fa-solid fa-plane"></i>
																				{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																			</a>
																		</span> 
																	</button>
																</h6>
																<div :id="'collapse-8'+key" class="accordion-collapse collapse" aria-labelledby="heading-8" data-bs-parent="#accordionExample7" >
																	<div class="accordion-body mt-3">																				
																		<div class="row g-4">
																			<ul class="list-group">
																				<li v-if="key==0" class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																					<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																						<FlightSeat 
																							:seatsData="seatIBDetails_oneway" 
																							:numberOfPassengers="booking.Passengers.length"
																							:flight_type_id="'return_'+key"
																							@update-selected-seats="handleUpdateSelectedSeats"
																						/>
																					</div>
																					<div v-if="selectedIBSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																						<div class="row">
																							<div v-for="seat, key1 in selectedIBSeats" :key="key1">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																									<div class="iyZANA">
																										<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																											<span class="kGdPCP">{{ value?.Code }}</span>
																										</div>
																									</div>
																									<div class="iXKOeT">
																										<div color="primary" class="hmhLTy">{{ key2 }}</div>
																										<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</li>
																				<li v-else class="list-group-item d-flex align-items-center border-0 py-2 px-0">
																					<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: visible;">
																						<FlightSeat 
																							:seatsData="seatIBDetails_connect" 
																							:numberOfPassengers="booking.Passengers.length"
																							:flight_type_id="'return_'+key"
																							@update-selected-seats="handleUpdateSelectedSeats"
																						/>
																					</div>
																					<div v-if="selectedIBCOnnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">																							
																						<div class="row">
																							<div v-for="seat, key1 in selectedIBCOnnectSeats" :key="key1">
																								<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="xyfDP">
																									<div class="iyZANA">
																										<div class="a-DJOb" style="background-color: rgb(41, 78, 133);">
																											<span class="kGdPCP">{{ value?.Code }}</span>
																										</div>
																									</div>
																									<div class="iXKOeT">
																										<div color="primary" class="hmhLTy">{{ key2 }}</div>
																										<span class="jxQJOl">{{ value?.Currency }}. {{ value?.Price }}</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</li>																			
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div> -->


									<!-- <div class="card" v-if="SsrObData?.SeatDynamic || SsrIbData?.SeatDynamic" style="padding: 16px; gap: 0px; border-radius: 24px;opacity: 0px;">
										<h6 class="accordion-header font-base" id="heading-7" style="font-size: 16px;font-weight: 500;line-height: 22px;text-align: left;">
											<button class="accordion-button fw-bold rounded collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seat_selection_collapse" aria-expanded="true" aria-controls="seat_selection_collapse">
												<span>Seat Selection Now</span>
											</button>
										</h6>
										<div id="seat_selection_collapse" class="seat_selection_collapse collapse" aria-labelledby="heading-7" data-bs-parent="#accordionExample8" >
											<div class="card mb-4 mt-4" v-if="SsrObData?.SeatDynamic || SsrIbData?.SeatDynamic">
												<div class="card-body py-2">
													<div class="price-summary">
														<ul class="nav nav-tabs col-md-12" style="text-align: center;" >													
															<li class="nav-item" :class="filght_col" v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key">
																<a class="nav-link" :id="'onewayseat_'+key" aria-current="page" data-bs-toggle="collapse" :data-bs-target="'#oneway_'+key" aria-expanded="true" :aria-controls="'oneway_'+key" @click="activateTabs('oneway_'+key, 'seat')">
																	<i class="fa-solid fa-plane"></i>
																	{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																</a>
															</li>
															<li class="nav-item" :class="filght_col" v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key">
																<a class="nav-link"  :id="'returnseat_'+key" data-bs-toggle="collapse" :data-bs-target="'#return_'+key" aria-expanded="true" :aria-controls="'return_'+key" @click="activateTabs('return_'+key, 'seat')">
																	<i class="fa-solid fa-plane"></i>
																	{{ airline?.Origin?.Airport?.AirportCode }}-{{ airline?.Destination?.Airport?.AirportCode }}
																</a>
															</li>																	
														</ul>
														<div v-for="airline, key in meta?.SelectedFlight?.Segments[0]" :key="key" :id="'oneway_'+key" class="accordion-collapse collapse" aria-labelledby="heading-10" data-bs-parent="#accordionExampleoneway" style="display: none">
															<ul class="list-group">
																<li v-if="key==0" class="list-group-item align-items-center border-0 py-2 px-0">
																	<div class="row">
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																				<FlightSeat 
																					:seatsData="seatOBDetails_oneway" 
																					:numberOfPassengers="booking.Passengers.length"
																					:flight_type_id="'oneway_'+key"
																					@update-selected-seats="handleUpdateSelectedSeats"
																				/>
																			</div>
																		</div>
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div v-if="selectedOBSeats.length" class="selected-seat-info">																							
																				<div class="row justify-content-center align-item-center pt-3">
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/fligh_chair_available.svg" alt="available">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_reserved.svg" alt="reserved">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_selected.svg" alt="selected">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_blocked.svg" alt="blocked">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																						</div>
																					</div>
																				</div>
																				<div class="row justify-content-center" style="padding-top: 20px;">
																					<div v-for="seat, key1 in selectedOBSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																						<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																							<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																								<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																									<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 36px; height: 36px;">{{ value?.Code }}</div>																
																								</div>
																								<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																									<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																									<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</li>
																<li v-else class="list-group-item align-items-center border-0 py-2 px-0">
																	<div class="row">
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																				<FlightSeat 
																					:seatsData="seatOBDetails_connect" 
																					:numberOfPassengers="booking.Passengers.length"
																					:flight_type_id="'oneway_'+key"
																					@update-selected-seats="handleUpdateSelectedSeats"
																				/>
																			</div>
																		</div>
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div v-if="selectedOBConnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">
																				<div class="row justify-content-center pt-3">
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/fligh_chair_available.svg" alt="available">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_reserved.svg" alt="reserved">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_selected.svg" alt="selected">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_blocked.svg" alt="blocked">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																						</div>
																					</div>
																				</div>
																				<div class="row justify-content-center" style="padding-top: 20px;">
																					<div v-for="seat, key1 in selectedOBConnectSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																						<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																							<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																								<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																									<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 36px; height: 36px;">{{ value?.Code }}</div>																
																								</div>
																								<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																									<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																									<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>																																	
																</li>
															</ul>
														</div>

														<div v-for="airline, key in meta?.RoundTripSelectedFlight?.Segments[0]" :key="key" :id="'return_'+key" class="accordion-collapse collapse" aria-labelledby="heading-10" data-bs-parent="#accordionExamplereturn"  style="display: none;">
															<ul class="list-group">
																<li v-if="key==0" class="list-group-item align-items-center border-0 py-2 px-0">
																	<div class="row">
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																				<FlightSeat 
																					:seatsData="seatIBDetails_oneway" 
																					:numberOfPassengers="booking.Passengers.length"
																					:flight_type_id="'return_'+key"
																					@update-selected-seats="handleUpdateSelectedSeats"
																				/>
																			</div>
																		</div>
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div v-if="selectedIBSeats.length" class="selected-seat-info mx-3 py-2 px-0">
																				<div class="row justify-content-center pt-3">
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/fligh_chair_available.svg" alt="available">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_reserved.svg" alt="reserved">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_selected.svg" alt="selected">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_blocked.svg" alt="blocked">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																						</div>
																					</div>
																				</div>
																				<div class="row justify-content-center" style="padding-top: 20px;">
																					<div v-for="seat, key1 in selectedIBSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																						<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																							<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																								<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																									<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 36px; height: 36px;">{{ value?.Code }}</div>																
																								</div>
																								<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																									<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																									<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>																																
																</li>
																<li v-else class="list-group-item align-items-center border-0 py-2 px-0">
																	<div class="row">
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div id="connect_seat" class="accordion-item mb-3" style="height:300px; overflow-y: scroll;">
																				<FlightSeat 
																					:seatsData="seatIBDetails_connect" 
																					:numberOfPassengers="booking.Passengers.length"
																					:flight_type_id="'return_'+key"
																					@update-selected-seats="handleUpdateSelectedSeats"
																				/>
																			</div>
																		</div>
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
																			<div v-if="selectedIBCOnnectSeats.length" class="selected-seat-info mx-3 py-2 px-0">
																				<div class="row justify-content-center pt-3">
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/fligh_chair_available.svg" alt="available">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Available</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_reserved.svg" alt="reserved">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Reserved</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_unavailable.svg" alt="unavailable">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Unavailable</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_selected.svg" alt="selected">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Selected</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_blocked.svg" alt="blocked">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">Blocked</span>
																						</div>
																					</div>
																					<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" style="text-align: center;">
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<img src="images/flight_chair_no_seat.svg" alt="no-seat">
																						</div>
																						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
																							<span style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: center; color: #6C757D;">No Seat</span>
																						</div>
																					</div>
																				</div>
																				<div class="row justify-content-center" style="padding-top: 20px;">
																					<div v-for="seat, key1 in selectedIBCOnnectSeats" :key="key1" class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
																						<div style="padding: 8px; border-radius: 4px; border: 1px black dashed;">
																							<div v-for="[key2, value] of Object.entries(seat)" :key="key2" class="row align-items-center">
																								<div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">																							
																									<div style="padding: 8px 7px 8px 8px; border-radius: 2px;background: #E8E8E8; width: 36px; height: 36px;">{{ value?.Code }}</div>																
																								</div>
																								<div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
																									<div style="font-size: 14px;font-weight: 400;line-height: 16.8px;text-align: left; color: #000000;">{{ key2 }}</div>
																									<div style="font-size: 10px;font-weight: 400;line-height: 12px;text-align: left; color: #6C757D;">{{ value?.Currency }}. {{ value?.Price }}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>																																		
																</li>																			
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div> -->
									
									<div class="full-width d-flex flex-column mb-2 position-relative mt-3">
										<div class="row align-items-stat">
											<div class="col-xl-12 col-lg-12 col-md-12" style="display: none;" id="submit_save">
												<button class="btn btn-md full-width px-5 btn-primary fw-medium" type="button" @click="checkLogin">
													Submit & Proceed for Payment
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							
						</div>
						
						<!-- Sidebar -->
						<div class="col-xl-3 col-lg-4 col-md-12">
							<h3 style="font-size: 18px; font-weight: 600; line-height: 20px; text-align: left;">
								PRICE SUMMARY
							</h3>
							<div class="card mb-4 mt-lg-0 mt-4" style="padding:16px;border-radius:24px;">
								<!-- <div class="card-header"><h4>Price Summary</h4></div> -->
								<div style="gap: 0px; padding:4px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
									<div class="card-body py-2">
										<div class="price-summary" >
											<ul class="list-group">
												<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													Base Fare 
													<span class="fw-semibold text-dark" v-if="!meta?.RoundTripSelectedFare">
														{{ meta?.SelectedFare?.Fare?.Currency }}. {{ meta?.SelectedFare?.Fare?.BaseFare }}
													</span>
													<span class="fw-semibold text-dark" v-else>
														{{ meta?.SelectedFare?.Fare?.Currency }}. 
														{{ meta?.SelectedFare?.Fare?.BaseFare + meta?.RoundTripSelectedFare?.Fare?.BaseFare }}
													</span>
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													Tax
													<span class="fw-semibold text-dark" v-if="!meta?.RoundTripSelectedFare">
														{{ meta?.SelectedFare?.Fare?.Currency }}. 
														{{ meta?.SelectedFare?.Fare?.Tax }}
													</span>
													<span class="fw-semibold text-dark" v-else>
														{{ meta?.SelectedFare?.Fare?.Currency }}. 
														{{ meta?.SelectedFare?.Fare?.Tax + meta?.RoundTripSelectedFare?.Fare?.Tax }}
													</span>
												</li>
												<!-- <li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													YQTax
													<span class="fw-semibold text-dark" v-if="!meta?.RoundTripSelectedFare">
														{{ meta?.SelectedFare?.Fare?.Currency }}. 
														{{ meta?.SelectedFare?.Fare?.YQTax }}
													</span>
													<span class="fw-semibold text-dark" v-else>
														{{ meta?.SelectedFare?.Fare?.Currency }}. 
														{{ meta?.SelectedFare?.Fare?.YQTax + meta?.RoundTripSelectedFare?.Fare?.YQTax }}
													</span>
												</li> -->
												<li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													OtherCharges
													<span class="fw-semibold text-dark" v-if="!meta?.RoundTripSelectedFare">
														{{ meta?.SelectedFare?.Fare?.Currency }}. 
														{{ meta?.SelectedFare?.Fare?.OtherCharges }}
													</span>
													<span class="fw-semibold text-dark" v-else>
														{{ meta?.SelectedFare?.Fare?.Currency }}. 
														{{ meta?.SelectedFare?.Fare?.OtherCharges + meta?.RoundTripSelectedFare?.Fare?.OtherCharges }}
													</span>
												</li>

												<hr v-if="addons"/>
												<li v-if="addons" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													Add-ons (with taxes and fees)
												</li>
												<li v-if="tripInsurance" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													Trip Insurance
													<span class="fw-semibold text-dark">
														{{ insuranceDetail[0]?.Price?.Currency}}. {{ insuranceDetail[0]?.Price?.PublishedPrice}}
													</span>
												</li>
												<li v-if="checkedBaggage" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													Checked baggage
													<span class="fw-semibold text-dark">
														{{ extraBaggageCurrency}}. {{ extraBaggage }}
													</span>
												</li>
												<li v-if="mealDynamics" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													Meals
													<span class="fw-semibold text-dark">
														{{ mealCurrency}}. {{ meals }}
													</span>
												</li>
												<li v-if="seatDynamics" class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
													Seat selection
													<span class="fw-semibold text-dark">
														{{ seatCurrency }}. {{ seat }}
													</span>
												</li>
											</ul>
										</div>
									</div>
									<div class="card-footer bg-white border-top py-3">
										<div class="d-flex align-items-center justify-content-between">
											<p class="fw-semibold text-dark mb-0">Total Price</p>
											<p class="fw-semibold text-dark mb-0" v-if="!meta?.RoundTripSelectedFare">
												{{ meta?.SelectedFare?.Fare?.Currency }}. 
												{{ 
													meta?.SelectedFare?.Fare?.PublishedFare + 
													(insuranceDetail[0]?.Price?.PublishedPrice ? insuranceDetail[0]?.Price?.PublishedPrice : 0) + 
													extraBaggage + meals + seat 
												}}
											</p>
											<p class="fw-semibold text-dark mb-0" v-else>
												{{ meta?.SelectedFare?.Fare?.Currency }}. 
												{{ 
													meta?.SelectedFare?.Fare?.PublishedFare + 
													meta?.RoundTripSelectedFare?.Fare?.PublishedFare  + 
													(insuranceDetail[0]?.Price?.PublishedPrice ? insuranceDetail[0]?.Price?.PublishedPrice : 0) + 
													extraBaggage + meals + seat 
												}}
											</p>
										</div>
									</div>
								</div>
							</div>
							
							<!-- <div class="card border rounded-3">
								<div class="card-header">
									<h4>Coupons & Offers</h4>
								</div>
								<div class="card-body">
									<div class="form-group position-relative">
										<input type="text" class="form-control" placeholder="Have a Coupon Code?" value="">
										<a href="#" class="position-absolute top-50 end-0 fw-semibold translate-middle text-primary disable">Apply</a>
									</div>
								</div>
							</div> -->
						</div>
						
					</div>
				</div>

			</div>

			<div class="modal modal-lg fade" id="bookinsurance" tabindex="-1" aria-labelledby="bookinsuranceModalLabel" aria-hidden="true" ref="bookinsurance">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title fs-6" id="bookinsuranceModalLabel">
								<span v-if="insuranceDetail.length > 0">{{ insuranceDetail[0]?.PlanName }}</span>
							</h4>
							<a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close" id="bookClose"><i
									class="fa-solid fa-square-xmark"></i></a>
						</div>
						<div class="modal-body px-4 py-4 px-xl-5 px-lg-5">
							<div class="airLines-fullsegment">
								<h5>What We Cover</h5>
								<div>
									<ul v-for="cover, key in insuranceDetail[0]?.CoverageDetails" :key="key">
										<li>
											<span><i style="color: #32a923;" class="fa fa-check mx-4" aria-hidden="true"></i></span>
											<span>{{  cover.Coverage }}</span> - 
											<span>{{ insuranceDetail[0]?.Price?.Currency}}. {{ cover.SumInsured }}</span>
										</li>
									</ul>
								</div>
							</div>						
						</div>
					</div>
				</div>
			</div>

			<div class="modal modal-xl fade" id="insuranceRelations" tabindex="-1" aria-labelledby="insuranceRelationsModalLabel" aria-hidden="true" ref="insuranceRelations">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title fs-6" id="insuranceRelationsModalLabel">
								<span>Insurance Details Form</span>
							</h4>
							<!-- <a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close" @click="No_Insurance"><i
									class="fa-solid fa-square-xmark"></i></a> -->
							<a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close" id="InsuranceClose" ><i
								class="fa-solid fa-square-xmark"></i></a>
						</div>
						<div class="modal-body px-4 py-4 px-xl-5 px-lg-5">
							<div class="airLines-fullsegment">
								<h5>Please the fill the details Below</h5>								
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="row pt-3" v-for="relation, key in insuranceBooking.Passenger" :key="key">
										<div class="col-md-2">
											<label class="form-label" style="font-weight:500">Passanger Name</label>
											<input type="text" class="form-control" placeholder="Passanger Name" :value="relation.FirstName+ ' '+ relation.LastName" readonly />											
										</div>
										<div class="col-md-2">
											<label class="form-label">Title</label>
											<select class="select form-control" v-model="relation.BeneficiaryTitle" :class="{'is-invalid': insurance_error[key]?.BeneficiaryTitle}">
												<option value="">Select Title</option>
												<option>Mr</option>
												<option>Ms</option>
												<option>Mrs</option>
												<option>Miss</option>
												<option>Mstr</option>
												<option>Master</option>
											</select>
											<span style="font-size: 12px" v-if="insurance_error[key]?.BeneficiaryTitle">
												<small class="text-danger">{{ insurance_error[key]?.BeneficiaryTitle[0] }}</small>
											</span>
										</div>
										<div class="col-md-2">
											<label class="form-label" style="font-weight:500">First Name</label>
											<input v-model="relation.BeneficiaryFirstName" type="text" class="form-control" placeholder="Beneficiary First name"   :class="{'is-invalid': insurance_error[key]?.BeneficiaryFirstName}"/>
											<span style="font-size: 12px" v-if="insurance_error[key]?.BeneficiaryFirstName">
												<small class="text-danger">{{ insurance_error[key]?.BeneficiaryFirstName[0] }}</small>
											</span>
										</div>										
										<div class="col-md-2">
											<label class="form-label" style="font-weight:500">Last Name</label>
											<input v-model="relation.BeneficiaryLastName" type="text" class="form-control" placeholder="Beneficiary Last Name"   :class="{'is-invalid': insurance_error[key]?.BeneficiaryLastName}"/>
											<span style="font-size: 12px" v-if="insurance_error[key]?.BeneficiaryLastName">
												<small class="text-danger">{{ insurance_error[key]?.BeneficiaryLastName[0] }}</small>
											</span>
										</div>
										<div class="col-md-2" >
											<label class="form-label" style="font-weight:500">Beneficiary</label>
											<select class="select form-control" v-model="relation.RelationToBeneficiary" :class="{'is-invalid': insurance_error[key]?.RelationToBeneficiary}">
												<option value="">Select Beneficiary</option>
												<option value="Father">Father</option>
												<option value="Mother">Mother</option>
												<option value="Spouse">Spouse</option>
												<option value="Son">Son</option>
												<option value="Brother">Brother</option>
												<option value="Sister">Sister</option>
											</select>
											<span style="font-size: 12px" v-if="insurance_error[key]?.RelationToBeneficiary">
												<small class="text-danger">{{ insurance_error[key]?.RelationToBeneficiary[0] }}</small>
											</span>
										</div>
										<div class="col-md-2">
											<label class="form-label" style="font-weight:500">PassportNo</label>
											<div class="input-group">
												<input v-model="relation.PassportNo" type="text" class="form-control" placeholder="PassportNo" :class="{'is-invalid': insurance_error[key]?.PassportNo}"/>
											</div>
											<span style="font-size: 12px" v-if="insurance_error[key]?.PassportNo">
												<small class="text-danger">{{ insurance_error[key]?.PassportNo[0] }}</small>
											</span>
										</div>
									</div>
								</div>
							</div>						
						</div>
						<div class="card-footer bg-white border-top py-3 px-3">
							<div style="float:right">								
								<!-- <button class="btn btn-primary mb-0" type="button" data-bs-dismiss="modal" aria-label="Close" id="bookClose"  -->
								<button class="btn btn mb-0 me-2" data-bs-dismiss="modal" aria-label="Close" @click="No_Insurance" type="button" style="background-color: #ce3426; color: white; height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;">
									Discard
								</button>
								<button class="btn btn-primary mb-0" type="button" 
									style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" @click="validateInsuranceDetails">
									Submit
								</button>
								
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</section>
</template>

<script>
    import moment from "moment";
	let Search = require("@/components/Search.vue").default;
	import FlightSeat from '@/components/FlightSeat.vue';
    export default {
        data() {
            return {
				// flight_seatings:[
				// 	{
				// 		active:'active',
				// 		tab:'ts'
				// 	},
				// 	{
				// 		active:'',
				// 		tab:'tpmc'
				// 	},
				// 	{
				// 		active:'',
				// 		tab:'cpcm'
				// 	},
				// 	{
				// 		active:'',
				// 		tab:'cpcc'
				// 	},
				// ],
				// flight_seating:{
				// 	ts_active:'active', 
				// 	tpmc_active:'', 
				// 	cpcm_active:'', 
				// 	cpcc_active:'', 
				// 	download_active:''
				// },
                search: {},
                meta: {},
                passenger:{
                    customer_id:this.$store.getters.customer?.customer_id,
                    name:this.$store.getters.customer?.name,
                    mobile_no:this.$store.getters.customer?.mobile_no,
                    email:this.$store.getters.customer?.email,
                },
                booking:{
                    EndUserIp:'',
                    TokenId:'',
                    TraceId:'',
                    ResultIndex:'',
                    PreferredCurrency:this.$store.state.currency,
                    IsBaseCurrencyRequired:true,
                    Passengers:[],                    
                },
				bookingIB:{
                    EndUserIp:'',
                    TokenId:'',
                    TraceId:'',
                    ResultIndex:'',
                    PreferredCurrency:this.$store.state.currency,
                    IsBaseCurrencyRequired:true,
                    Passengers:[],                    
                },
				booking_lcc:{
					EndUserIp:'',
                    TokenId:'',
                    TraceId:'',
					PNR: '',
					BookingId: '', 
				},
				insuranceDetail:[],
				insuranceDetails: [],
				insurance: {
					PlanCategory: 1,
					PlanType: 1,
					PlanCoverage: 4,
					TravelStartDate: '',
					NoOfPax: 0,
					PaxAge: [],
					EndUserIp:'',
                    TokenId:'',
				},
				errors: [],
				error: false,
				addons: false,
				tripInsurance: false,
				checkedBaggage: false,
				mealDynamics: false,
				seatDynamics: false,
				show_ssr: true,
				SsrObData: [],
				SsrIbData: [],

				baggageDetail: [],
				IBBaggage: [],
				extraBaggageCurrency: '',
				extraBaggage: 0,

				mealDetail: [],
				IBMeal: [],
				mealCurrency: '',
				meals: 0,

				seatOBDetails_oneway: [],
				seatOBDetails_connect: [],
				seatIBDetails_oneway: [],
				seatIBDetails_connect: [],
				seatDetail: [],
				IBSeats: [],
				seatCurrency: '',
				seat: 0,

				selectedOBSeats: [],
				selectedOBConnectSeats: [],
				selectedIBSeats: [],
				selectedIBCOnnectSeats: [],
				filght_col:'col-xl-6 col-lg-6 col-md-6 col-sm-6',


				insuranceBooking:{
                    EndUserIp:'',
                    TokenId:'',
                    TraceId:'',
					booking_id:'',
                    GenerateInsurancePolicy: false,
                    ResultIndex:'',
                    Passenger:[],                    
                },
				insurance_error:[],
				countryCode:[],
				// insurancePassenger: {
				// 	Title:"",
				// 	FirstName:"",
				// 	LastName:"",
				// 	BeneficiaryName:"",
				// 	RelationShipToInsured:"",
				// 	RelationToBeneficiary:"",
				// 	Gender:"",
				// 	Sex:"",
				// 	DOB:"",
				// 	PassportNo:"",
				// 	PhoneNumber:"",
				// 	EmailId:"",
				// 	AddressLine1:"",
				// 	AddressLine2:"",
				// 	CityCode:"",
				// 	CountryCode:"",
				// 	MajorDestination:"",
				// 	PinCode:"",
				// }
				
            };
        },
		components:{ FlightSeat, Search},
        watch:{
            "$store.getters.customer":{
                handler(){
                    // console.log('insied meta watch')
                    this.passenger.customer_id = this.$store.getters.customer.customer_id;
                    this.passenger.name = this.$store.getters.customer.name;
                    this.passenger.mobile_no = this.$store.getters.customer.mobile_no;
                    this.passenger.email = this.$store.getters.customer.email;
                }
            },
        },

        mounted() {
            window.scrollTo(0, 0);
            
            this.search = this.$store.getters.flight_search
            this.meta = this.$store.getters.flight_meta
            this.booking.EndUserIp = this.meta?.EndUserIp
            this.booking.TokenId = this.meta?.TokenId
            this.booking.TraceId = this.meta?.TraceId
			this.booking.PreferredCurrency = this.$store.state.currency;

			this.bookingIB.EndUserIp = this.meta?.EndUserIp
            this.bookingIB.TokenId = this.meta?.TokenId
            this.bookingIB.TraceId = this.meta?.TraceId			
			this.bookingIB.PreferredCurrency = this.$store.state.currency;

			this.booking_lcc.EndUserIp = this.meta?.EndUserIp
            this.booking_lcc.TokenId = this.meta?.TokenId
            this.booking_lcc.TraceId = this.meta?.TraceId

			this.insurance.EndUserIp = this.meta?.EndUserIp
            this.insurance.TokenId = this.meta?.TokenId

			this.insuranceBooking.EndUserIp = this.meta?.EndUserIp;
			this.insuranceBooking.TokenId = this.meta?.TokenId;
			
            // console.log('this.booking:-----',this.meta?.SelectedFare?.FareBreakdown[0])
			// let TotalCount = +this.search.AdultCount + +this.search.ChildCount + +this.search.InfantCount

            // for (let index = 1; index <= this.search.AdultCount; index++) {
            //     let count = this.search.AdultCount;
            //     let Fare = {
            //         "BaseFare": this.meta?.SelectedFare?.FareBreakdown[0]?.BaseFare/count,
            //         "Tax": this.meta?.SelectedFare?.FareBreakdown[0]?.Tax/count,
            //         "YQTax": this.meta?.SelectedFare?.FareBreakdown[0]?.YQTax/count,
            //         "AdditionalTxnFeeOfrd": this.meta?.SelectedFare?.FareBreakdown[0]?.AdditionalTxnFeeOfrd,
            //         "AdditionalTxnFeePub": this.meta?.SelectedFare?.FareBreakdown[0]?.AdditionalTxnFeePub,
            //         // "PGCharge": this.meta?.SelectedFare?.FareBreakdown[0]?.PGCharge,
			// 		"Currency": this.meta?.SelectedFare?.FareBreakdown[0]?.Currency,
			// 		// "OtherCharges" : this.meta?.SelectedFare?.Fare?.OtherCharges / TotalCount
			// 		"OtherCharges" : this.meta?.SelectedFare?.Fare?.OtherCharges,

			// 		"Discount": this.meta?.SelectedFare?.Fare?.Discount,
			// 		"PublishedFare": this.meta?.SelectedFare?.Fare?.PublishedFare,
			// 		"OfferedFare": this.meta?.SelectedFare?.Fare?.OfferedFare,
			// 		"TdsOnCommission": this.meta?.SelectedFare?.Fare?.TdsOnCommission,
			// 		"TdsOnPLB": this.meta?.SelectedFare?.Fare?.TdsOnPLB,
			// 		"TdsOnIncentive": this.meta?.SelectedFare?.Fare?.TdsOnIncentive,
			// 		"ServiceFee": this.meta?.SelectedFare?.Fare?.ServiceFee,
            //     }
			// 	// console.log("Fare OB:-----",Fare);
            //     let data = {
            //         "Title":"",
            //         "FirstName":"",
            //         "LastName":"",
            //         "PaxType": 1,
            //         "DateOfBirth":"",
            //         "Gender": 1,
            //         "AddressLine1":"",
            //         "AddressLine2":"",
            //         "City":"",
            //         "CountryCode":"IN",
            //         "CountryName":"India",
            //         "ContactNo":"",
            //         "Email":"",
            //         "IsLeadPax": index==1 ? true : false,
            //         "PaxNo":index,
            //         "FFAirline":"",
            //         "FFNumber":"",
            //         "Nationality":"",
            //         "Fare":Fare,
            //         "PassangerType":"1",
            //         "Baggage":[],
            //         "MealDynamic": [],
            //         "SeatDynamic": [],
            //         "GSTCompanyAddress": "",
            //         "GSTCompanyContactNumber": "",
            //         "GSTCompanyName": "",
            //         "GSTNumber": "",
            //         "GSTCompanyEmail": "",
			// 		"PassportNo": "",
			// 		"PassportExpiry": "",
			// 		"PassportIssueDate":"",
            //     }
            //     this.booking.Passengers.push(data);
            // }
            // for (let index = 1; index <= this.search.ChildCount; index++) {
            //     let count = this.search.ChildCount;
            //     let Fare = {
            //         "BaseFare": this.meta?.SelectedFare?.FareBreakdown[1]?.BaseFare/count,
            //         "Tax": this.meta?.SelectedFare?.FareBreakdown[1]?.Tax/count,
            //         "YQTax": this.meta?.SelectedFare?.FareBreakdown[1]?.YQTax/count,
            //         "AdditionalTxnFeeOfrd": this.meta?.SelectedFare?.FareBreakdown[1]?.AdditionalTxnFeeOfrd,
            //         "AdditionalTxnFeePub": this.meta?.SelectedFare?.FareBreakdown[1]?.AdditionalTxnFeePub,
            //         // "PGCharge": this.meta?.SelectedFare?.FareBreakdown[1]?.PGCharge,
			// 		"Currency": this.meta?.SelectedFare?.FareBreakdown[1]?.Currency,
			// 		// "OtherCharges" : this.meta?.SelectedFare?.Fare?.OtherCharges / TotalCount
			// 		"OtherCharges" : this.meta?.SelectedFare?.Fare?.OtherCharges,

			// 		"Discount": this.meta?.SelectedFare?.Fare?.Discount,
			// 		"PublishedFare": this.meta?.SelectedFare?.Fare?.PublishedFare,
			// 		"OfferedFare": this.meta?.SelectedFare?.Fare?.OfferedFare,
			// 		"TdsOnCommission": this.meta?.SelectedFare?.Fare?.TdsOnCommission,
			// 		"TdsOnPLB": this.meta?.SelectedFare?.Fare?.TdsOnPLB,
			// 		"TdsOnIncentive": this.meta?.SelectedFare?.Fare?.TdsOnIncentive,
			// 		"ServiceFee": this.meta?.SelectedFare?.Fare?.ServiceFee,
            //     }
            //     let data = {
            //         "Title":"",
            //         "FirstName":"",
            //         "LastName":"",
            //         "PaxType": 2,
            //         "DateOfBirth":"",
            //         "Gender": 1,
            //         "AddressLine1":"",
            //         "AddressLine2":"",
            //         "City":"",
            //         "CountryCode":"IN",
            //         "CountryName":"India",
            //         "ContactNo":"",
            //         "Email":"",
            //         "IsLeadPax": false,
            //         "PaxNo":index,
            //         "FFAirline":"",
            //         "FFNumber":"",
            //         "Nationality":"",
            //         "Fare":Fare,
            //         "PassangerType":"2",
            //         "Baggage":[],
            //         "MealDynamic": [],
            //         "SeatDynamic": [],
            //         "GSTCompanyAddress": "",
            //         "GSTCompanyContactNumber": "",
            //         "GSTCompanyName": "",
            //         "GSTNumber": "",
            //         "GSTCompanyEmail": "",
			// 		"PassportNo": "",
			// 		"PassportExpiry": "",
			// 		"PassportIssueDate":"",
            //     }
            //     this.booking.Passengers.push(data);
            // }
            // for (let index = 1; index <= this.search.InfantCount; index++) {
            //     let count = this.search.InfantCount;
            //     let Fare = {
            //         "BaseFare": this.meta?.SelectedFare?.FareBreakdown[2]?.BaseFare/count,
            //         "Tax": this.meta?.SelectedFare?.FareBreakdown[2]?.Tax/count,
            //         "YQTax": this.meta?.SelectedFare?.FareBreakdown[2]?.YQTax/count,
            //         "AdditionalTxnFeeOfrd": this.meta?.SelectedFare?.FareBreakdown[2]?.AdditionalTxnFeeOfrd,
            //         "AdditionalTxnFeePub": this.meta?.SelectedFare?.FareBreakdown[2]?.AdditionalTxnFeePub,
            //         // "PGCharge": this.meta?.SelectedFare?.FareBreakdown[2]?.PGCharge,
			// 		"Currency": this.meta?.SelectedFare?.FareBreakdown[2]?.Currency,
			// 		// "OtherCharges" : this.meta?.SelectedFare?.Fare?.OtherCharges / TotalCount
			// 		"OtherCharges" : this.meta?.SelectedFare?.Fare?.OtherCharges,

			// 		"Discount": this.meta?.SelectedFare?.Fare?.Discount,
			// 		"PublishedFare": this.meta?.SelectedFare?.Fare?.PublishedFare,
			// 		"OfferedFare": this.meta?.SelectedFare?.Fare?.OfferedFare,
			// 		"TdsOnCommission": this.meta?.SelectedFare?.Fare?.TdsOnCommission,
			// 		"TdsOnPLB": this.meta?.SelectedFare?.Fare?.TdsOnPLB,
			// 		"TdsOnIncentive": this.meta?.SelectedFare?.Fare?.TdsOnIncentive,
			// 		"ServiceFee": this.meta?.SelectedFare?.Fare?.ServiceFee,
            //     }
            //     let data = {
            //         "Title":"",
            //         "FirstName":"",
            //         "LastName":"",
            //         "PaxType": 3,
            //         "DateOfBirth":"",
            //         "Gender": 1,
            //         "AddressLine1":"",
            //         "AddressLine2":"",
            //         "City":"",
            //         "CountryCode":"IN",
            //         "CountryName":"India",
            //         "ContactNo":"",
            //         "Email":"",
            //         "IsLeadPax": false,
            //         "PaxNo":index,
            //         "FFAirline":"",
            //         "FFNumber":"",
            //         "Nationality":"",
            //         "Fare":Fare,
            //         "PassangerType":"3",
            //         "Baggage":[],
            //         "MealDynamic": [],
            //         "SeatDynamic": [],
            //         "GSTCompanyAddress": "",
            //         "GSTCompanyContactNumber": "",
            //         "GSTCompanyName": "",
            //         "GSTNumber": "",
            //         "GSTCompanyEmail": "",
			// 		"PassportNo": "",
			// 		"PassportExpiry": "",
			// 		"PassportIssueDate":"",
            //     }
            //     this.booking.Passengers.push(data);
            // }
            // console.log('this.booking:-----',this.booking.Passengers)
			// this.bookingIB.Passengers = this.booking.Passengers
			this.insurance.TravelStartDate = this.meta?.SelectedFlight?.Segments[0][0]?.Origin?.DepTime;
			// this.insurance.NoOfPax = this.booking.Passengers.length;
			this.FareQuote();			
			// this.flightSSR();

			this.$nextTick(() => {
				const triggerTabList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tab"]'));
				triggerTabList.forEach(function (triggerEl) {
					const tabTrigger = new bootstrap.Tab(triggerEl);
					triggerEl.addEventListener('click', function (event) {
						event.preventDefault();
						tabTrigger.show();
					});
				});
			});
			this.getCountryCodes();
        },

		methods:{
			// showFlightSeatingTab(flight_seat){
			// 	console.log('flight_seating')
			// 	// console.log(flight_seating)
			// 	// this.flight_seatings.map(function(element){
			// 	// 	// console.log(element)
					
			// 	// 	flight_seat.active = ''
					
			// 	// })
			// 	// this.flight_seatings.map(function(element){
			// 	// 	// console.log(element)
			// 	// 	if(element.tab === flight_seat.tab){
			// 	// 		// alert("1")
			// 	// 		flight_seat.active = 'active'
			// 	// 	}
			// 	// })
			// 	let flt = this.flight_seatings.filter(function(element){
			// 		return element.tab === flight_seat.tab
			// 	})
			// 	if(flt.length){
			// 		flt[0].active = 'active'
			// 	}
			// 	let flt_inactive = this.flight_seatings.filter(function(element){
			// 		return element.tab != flight_seat.tab
			// 	})
			// 	flt_inactive.map(function(ele){
			// 		ele.active = ''
			// 	})
			// 	console.log(this.flight_seatings)
			// },
			getCountryCodes(){
				let vm = this;
				let loader = vm.$loading.show();				
				vm.$store.dispatch("post", { uri: "countryCodes" })
				.then(function (response) {
					loader.hide();
					vm.countryCode = response.data.Response					
				})
				.catch(function (error) {
					loader.hide();
					vm.$store.dispatch("error", error.response.data.message);
				});
			},
			delay(ms) {
				return new Promise(resolve => setTimeout(resolve, ms));
			},
			async FareQuote(){
				let vm = this;
				let loader = vm.$loading.show();
				if(vm.meta.SelectedFlight){
					vm.getFareQuote(vm.meta.SelectedFlight, 1)
					await vm.delay(5000);
				}
				if(vm.meta.RoundTripSelectedFlight){
					vm.getFareQuote(vm.meta.RoundTripSelectedFlight, 2)
					await vm.delay(5000);
				}
				this.flightSSR();
				this.setInitialFare();
				loader.hide();
			},
			getFareQuote(flight, round) {
				let vm = this;
				vm.meta.ResultIndex = flight.ResultIndex;
				let loader = vm.$loading.show();
				vm.$store.dispatch("post", { uri: "flights/fareQuote", data: vm.meta})
				.then(function (response) {
					loader.hide();
					if(round==1){
						vm.meta.OBResultIndex = flight.ResultIndex;
						vm.meta.SelectedFare = response.data.Response.Results
						vm.meta.SelectedFlight = flight;
						// vm.meta.IBResultIndex = null;
						// vm.meta.RoundTripSelectedFare = null;
						// vm.meta.RoundTripSelectedFlight = null;
						vm.$store.dispatch("setFlightSearch", vm.search);
						vm.$store.dispatch("setFlightMeta", vm.meta);
					}
					if(round==2){
						vm.meta.IBResultIndex = flight.ResultIndex;
						vm.meta.RoundTripSelectedFare = response.data.Response.Results
						vm.meta.RoundTripSelectedFlight = flight;
						vm.$store.dispatch("setFlightSearch", vm.search);
						vm.$store.dispatch("setFlightMeta", vm.meta);                           
					}

					if(vm.meta?.SelectedFare?.Segments[0]?.length == 2 && vm.meta?.RoundTripSelectedFlight?.Segments[0]?.length == 2){
						vm.filght_col = 'col-xl-3 col-lg-3 col-md-3 col-sm-3'
					}
					else if(vm.meta?.SelectedFare?.Segments[0]?.length == 2 && vm.meta?.RoundTripSelectedFlight?.Segments[0]?.length == 1){
						vm.filght_col = 'col-xl-4 col-lg-4 col-md-4 col-sm-4'
					}
					else if(vm.meta?.SelectedFare?.Segments[0]?.length == 1 && vm.meta?.RoundTripSelectedFlight?.Segments[0]?.length == 2){
						vm.filght_col = 'col-xl-4 col-lg-4 col-md-4 col-sm-4'
					}
					else if(vm.meta?.SelectedFare?.Segments[0]?.length == 1 && vm.meta?.RoundTripSelectedFlight?.Segments[0]?.length == 1){
						vm.filght_col = 'col-xl-6 col-lg-6 col-md-6 col-sm-6'
					}
					else if(vm.meta?.SelectedFare?.Segments[0]?.length == 2){
						vm.filght_col = 'col-xl-6 col-lg-6 col-md-6 col-sm-6'
					}
					else{
						vm.filght_col = 'col-xl-12 col-lg-12 col-md-12 col-sm-12'
					}
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.error;
					vm.$store.dispatch("error", error.response.data.message);
				});
			},

			setInitialFare(){
				let vm = this;
				let TotalCount = +vm.search.AdultCount + +vm.search.ChildCount + +vm.search.InfantCount

				for (let index = 1; index <= vm.search.AdultCount; index++) {
					let count = vm.search.AdultCount;
					let Fare = {
						"BaseFare": vm.meta?.SelectedFare?.FareBreakdown[0]?.BaseFare/count,
						"Tax": vm.meta?.SelectedFare?.FareBreakdown[0]?.Tax/count,
						"YQTax": vm.meta?.SelectedFare?.FareBreakdown[0]?.YQTax/count,
						"AdditionalTxnFeeOfrd": vm.meta?.SelectedFare?.FareBreakdown[0]?.AdditionalTxnFeeOfrd,
						"AdditionalTxnFeePub": vm.meta?.SelectedFare?.FareBreakdown[0]?.AdditionalTxnFeePub,
						// "PGCharge": vm.meta?.SelectedFare?.FareBreakdown[0]?.PGCharge,
						"Currency": vm.meta?.SelectedFare?.FareBreakdown[0]?.Currency,
						// "OtherCharges" : vm.meta?.SelectedFare?.Fare?.OtherCharges / TotalCount
						"OtherCharges" : vm.meta?.SelectedFare?.Fare?.OtherCharges,

						"Discount": vm.meta?.SelectedFare?.Fare?.Discount,
						"PublishedFare": vm.meta?.SelectedFare?.Fare?.PublishedFare,
						"OfferedFare": vm.meta?.SelectedFare?.Fare?.OfferedFare,
						"TdsOnCommission": vm.meta?.SelectedFare?.Fare?.TdsOnCommission,
						"TdsOnPLB": vm.meta?.SelectedFare?.Fare?.TdsOnPLB,
						"TdsOnIncentive": vm.meta?.SelectedFare?.Fare?.TdsOnIncentive,
						"ServiceFee": vm.meta?.SelectedFare?.Fare?.ServiceFee,
					}
					// console.log("Fare OB:-----",Fare);
					let data = {
						"Title":"",
						"FirstName":"",
						"LastName":"",
						"PaxType": 1,
						"DateOfBirth":"",
						"Gender": "",
						"AddressLine1":"",
						"AddressLine2":"",
						"City":"",
						"CountryCode":"",
						"CountryName":"",
						"ContactNo":"",
						"Email":"",
						"IsLeadPax": index==1 ? true : false,
						"PaxNo":index,
						"FFAirline":"",
						"FFNumber":"",
						"Nationality":"",
						"Fare":Fare,
						"PassangerType":"1",
						"Baggage":[],
						"MealDynamic": [],
						// "Meal":"",
						"SeatDynamic": [],
						"GSTCompanyAddress": "",
						"GSTCompanyContactNumber": "",
						"GSTCompanyName": "",
						"GSTNumber": "",
						"GSTCompanyEmail": "",
						"PassportNo": "",
						"PassportExpiry": "",
						"PassportIssueDate":"",
						"PassportIssueCountryCode":"",
						"CellCountryCode" : "+91",
						"PinCode":"",
					}
					vm.booking.Passengers.push(data);
				}
				for (let index = 1; index <= vm.search.ChildCount; index++) {
					let count = vm.search.ChildCount;
					let Fare = {
						"BaseFare": vm.meta?.SelectedFare?.FareBreakdown[1]?.BaseFare/count,
						"Tax": vm.meta?.SelectedFare?.FareBreakdown[1]?.Tax/count,
						"YQTax": vm.meta?.SelectedFare?.FareBreakdown[1]?.YQTax/count,
						"AdditionalTxnFeeOfrd": vm.meta?.SelectedFare?.FareBreakdown[1]?.AdditionalTxnFeeOfrd,
						"AdditionalTxnFeePub": vm.meta?.SelectedFare?.FareBreakdown[1]?.AdditionalTxnFeePub,
						// "PGCharge": vm.meta?.SelectedFare?.FareBreakdown[1]?.PGCharge,
						"Currency": vm.meta?.SelectedFare?.FareBreakdown[1]?.Currency,
						// "OtherCharges" : vm.meta?.SelectedFare?.Fare?.OtherCharges / TotalCount
						"OtherCharges" : vm.meta?.SelectedFare?.Fare?.OtherCharges,

						"Discount": vm.meta?.SelectedFare?.Fare?.Discount,
						"PublishedFare": vm.meta?.SelectedFare?.Fare?.PublishedFare,
						"OfferedFare": vm.meta?.SelectedFare?.Fare?.OfferedFare,
						"TdsOnCommission": vm.meta?.SelectedFare?.Fare?.TdsOnCommission,
						"TdsOnPLB": vm.meta?.SelectedFare?.Fare?.TdsOnPLB,
						"TdsOnIncentive": vm.meta?.SelectedFare?.Fare?.TdsOnIncentive,
						"ServiceFee": vm.meta?.SelectedFare?.Fare?.ServiceFee,
					}
					let data = {
						"Title":"",
						"FirstName":"",
						"LastName":"",
						"PaxType": 2,
						"DateOfBirth":"",
						"Gender": "",
						"AddressLine1":"",
						"AddressLine2":"",
						"City":"",
						"CountryCode":"",
						"CountryName":"",
						"ContactNo":"",
						"Email":"",
						"IsLeadPax": false,
						"PaxNo":index,
						"FFAirline":"",
						"FFNumber":"",
						"Nationality":"",
						"Fare":Fare,
						"PassangerType":"2",
						"Baggage":[],
						"MealDynamic": [],
						// "Meal":"",
						"SeatDynamic": [],
						"GSTCompanyAddress": "",
						"GSTCompanyContactNumber": "",
						"GSTCompanyName": "",
						"GSTNumber": "",
						"GSTCompanyEmail": "",
						"PassportNo": "",
						"PassportExpiry": "",
						"PassportIssueDate":"",
						"PassportIssueCountryCode":"",
						"CellCountryCode" : "+91",
						"PinCode":"",
					}
					vm.booking.Passengers.push(data);
				}
				for (let index = 1; index <= vm.search.InfantCount; index++) {
					let count = vm.search.InfantCount;
					let Fare = {
						"BaseFare": vm.meta?.SelectedFare?.FareBreakdown[2]?.BaseFare/count,
						"Tax": vm.meta?.SelectedFare?.FareBreakdown[2]?.Tax/count,
						"YQTax": vm.meta?.SelectedFare?.FareBreakdown[2]?.YQTax/count,
						"AdditionalTxnFeeOfrd": vm.meta?.SelectedFare?.FareBreakdown[2]?.AdditionalTxnFeeOfrd,
						"AdditionalTxnFeePub": vm.meta?.SelectedFare?.FareBreakdown[2]?.AdditionalTxnFeePub,
						// "PGCharge": vm.meta?.SelectedFare?.FareBreakdown[2]?.PGCharge,
						"Currency": vm.meta?.SelectedFare?.FareBreakdown[2]?.Currency,
						// "OtherCharges" : vm.meta?.SelectedFare?.Fare?.OtherCharges / TotalCount
						"OtherCharges" : vm.meta?.SelectedFare?.Fare?.OtherCharges,

						"Discount": vm.meta?.SelectedFare?.Fare?.Discount,
						"PublishedFare": vm.meta?.SelectedFare?.Fare?.PublishedFare,
						"OfferedFare": vm.meta?.SelectedFare?.Fare?.OfferedFare,
						"TdsOnCommission": vm.meta?.SelectedFare?.Fare?.TdsOnCommission,
						"TdsOnPLB": vm.meta?.SelectedFare?.Fare?.TdsOnPLB,
						"TdsOnIncentive": vm.meta?.SelectedFare?.Fare?.TdsOnIncentive,
						"ServiceFee": vm.meta?.SelectedFare?.Fare?.ServiceFee,
					}
					let data = {
						"Title":"",
						"FirstName":"",
						"LastName":"",
						"PaxType": 3,
						"DateOfBirth":"",
						"Gender": "",
						"AddressLine1":"",
						"AddressLine2":"",
						"City":"",
						"CountryCode":"",
						"CountryName":"",
						"ContactNo":"",
						"Email":"",
						"IsLeadPax": false,
						"PaxNo":index,
						"FFAirline":"",
						"FFNumber":"",
						"Nationality":"",
						"Fare":Fare,
						"PassangerType":"3",
						"Baggage":[],
						"MealDynamic": [],
						// "Meal":"",
						"SeatDynamic": [],
						"GSTCompanyAddress": "",
						"GSTCompanyContactNumber": "",
						"GSTCompanyName": "",
						"GSTNumber": "",
						"GSTCompanyEmail": "",
						"PassportNo": "",
						"PassportExpiry": "",
						"PassportIssueDate":"",
						"PassportIssueCountryCode":"",
						"CellCountryCode" : "+91",
						"PinCode":"",
					}
					vm.booking.Passengers.push(data);
				}
				vm.insurance.NoOfPax = vm.booking.Passengers.length;
			},

			selectMeal(meal_code, passenger, JourneyType, meal_type){
				let vm= this;
				if(meal_type==1){
					// delete passenger.Meal;
					if(JourneyType == 1)				
					{
						passenger.MealDynamic = []
						vm.SsrObData?.MealDynamic.map(function(ele){
							vm.mealDetail = ele.filter(meal => {
								return meal.Code == meal_code
							});
						});				
						passenger.MealDynamic.push(vm.mealDetail[0])
					}
					if(JourneyType == 2){
						vm.SsrIbData?.MealDynamic.map(function(ele){
							vm.mealDetail = ele.filter(meal => {
								return meal.Code == meal_code
							});
						});
						let newMeal = {
							PaxType: passenger.PaxType,
							PaxNo: passenger.PaxNo,
							Meal: vm.mealDetail[0],
							MealType: 1
						};
						let index = vm.IBMeal.findIndex(item => item.PaxType === newMeal.PaxType && item.PaxNo === newMeal.PaxNo);

						if (index !== -1) {
							vm.IBMeal.splice(index, 1);
						}
						vm.IBMeal.push(newMeal)
					}
					vm.meals = 0;
					for(let i=0; i< vm.booking.Passengers.length; i++) {
						if(vm.booking.Passengers[i].MealDynamic.length > 0){
							vm.meals += vm.booking.Passengers[i].MealDynamic[0].Price;
							vm.mealCurrency = vm.booking.Passengers[i].MealDynamic[0].Currency;
						}
						
					}
					for(let i=0; i< vm.IBMeal.length; i++) {
						vm.meals += vm.IBMeal[i].Meal.Price;
						vm.mealCurrency = vm.IBMeal[i].Meal.Currency;
					}
					
					if(vm.meals > 0) {
						vm.mealDynamics = true
					}
					else{
						vm.mealDynamics = false
					}
					vm.checkAddOns();
				}
				else{
					// delete passengers.MealDynamic;
					if(JourneyType == 1)				
					{
						// passenger.MealDynamic = []
						
						vm.mealDetail = vm.SsrObData?.Meal.filter(meal => {
							return meal.Code == meal_code
						});		
						passenger.Meal = vm.mealDetail[0]
					}
					if(JourneyType == 2){
						vm.mealDetail = vm.SsrIbData?.Meal.filter(meal => {
							return meal.Code == meal_code
						});
						let newMeal = {
							PaxType: passenger.PaxType,
							PaxNo: passenger.PaxNo,
							Meal: vm.mealDetail[0],
							MealType: 2
						};
						let index = vm.IBMeal.findIndex(item => item.PaxType === newMeal.PaxType && item.PaxNo === newMeal.PaxNo);

						if (index !== -1) {
							vm.IBMeal.splice(index, 1);
						}
						vm.IBMeal.push(newMeal)
					}
				}
				
			},
			selectBaggage(bagage_code, passenger, JourneyType){
				let vm= this;
				
				if(JourneyType == 1)				
				{
					passenger.Baggage = []
					vm.SsrObData?.Baggage.map(function(ele){
						vm.baggageDetail = ele.filter(baggage => {
							return baggage.Code == bagage_code
						});
					});				
					passenger.Baggage.push(vm.baggageDetail[0])
				}
				if(JourneyType == 2){
					vm.SsrIbData?.Baggage.map(function(ele){
						vm.baggageDetail = ele.filter(baggage => {
							return baggage.Code == bagage_code
						});
					});
					let newBaggage = {
						PaxType: passenger.PaxType,
						PaxNo: passenger.PaxNo,
						Baggage: vm.baggageDetail[0]
					};
					let index = vm.IBBaggage.findIndex(item => item.PaxType === newBaggage.PaxType && item.PaxNo === newBaggage.PaxNo);

					if (index !== -1) {
						vm.IBBaggage.splice(index, 1);
					}
					vm.IBBaggage.push(newBaggage)
				}
				vm.extraBaggage = 0;
				for(let i=0; i< vm.booking.Passengers.length; i++) {
					if(vm.booking.Passengers[i].Baggage.length > 0){
						vm.extraBaggage += vm.booking.Passengers[i].Baggage[0].Price;
						vm.extraBaggageCurrency = vm.booking.Passengers[i].Baggage[0].Currency;
					}
					
				}
				for(let i=0; i< vm.IBBaggage.length; i++) {
					vm.extraBaggage += vm.IBBaggage[i].Baggage.Price;
					vm.extraBaggageCurrency = vm.IBBaggage[i].Baggage.Currency;
				}
				
				if(vm.extraBaggage > 0) {
					vm.checkedBaggage = true
				}
				else{
					vm.checkedBaggage = false
				}
				vm.checkAddOns();
			},
			handleUpdateSelectedSeats(data) {
				let vm = this;
				vm.seatCurrency = ''
				vm.seat = 0
				// console.log('data:---', data)
				if(data.flight_type_id=="oneway"){
					// console.log('oneway:-----', data.selectedSeats)
					for(let j=0; j<vm.selectedOBSeats[data.flight_key].length; j++){
						const j_key = Object.keys(vm.selectedOBSeats[data.flight_key][j]);
						let j_keys = j_key[0]
						vm.selectedOBSeats[data.flight_key][j][j_keys] = ''
					}
		
					for (let i = 0; i < data.selectedSeats.length; i++){
						const i_key = Object.keys(vm.selectedOBSeats[data.flight_key][i]);
						let i_keys = i_key[0]
						vm.selectedOBSeats[data.flight_key][i][i_keys] = data.selectedSeats[i]
						
					}
				}
				if(data.flight_type_id=='return'){
					// console.log('oneway:-----', data.selectedSeats)
					for(let j=0; j<vm.selectedIBSeats[data.flight_key].length; j++){
						const j_key = Object.keys(vm.selectedIBSeats[data.flight_key][j]);
						let j_keys = j_key[0]
						vm.selectedIBSeats[data.flight_key][j][j_keys] = ''
					}
		
					for (let i = 0; i < data.selectedSeats.length; i++){
						const i_key = Object.keys(vm.selectedIBSeats[data.flight_key][i]);
						let i_keys = i_key[0]
						vm.selectedIBSeats[data.flight_key][i][i_keys] = data.selectedSeats[i]
						
					}
				}
				// console.log('vm.selectedOBSeats:----', vm.selectedOBSeats)
				// console.log('vm.selectedIBSeats:----', vm.selectedIBSeats)


				// if(data.flight_type_id=='oneway_0'){
				// 	for (let i = 0; i < vm.selectedOBSeats.length; i++)
				// 	{
				// 		for(let  [key, value] of Object.entries(vm.selectedOBSeats[i]) ){
				// 			if(data.selectedSeats[i]){
				// 				vm.selectedOBSeats[i][key] = data.selectedSeats[i]								
				// 			}
				// 			else{
				// 				vm.selectedOBSeats[i][key] = ""
				// 			}							
				// 		}
				// 	}
				// 	console.log('vm.selectedOBSeats:----',vm.selectedOBSeats)
				// }

				// if(data.flight_type_id=='oneway_1'){
				// 	for (let i = 0; i < vm.selectedOBConnectSeats.length; i++)
				// 	{						
				// 		for(let  [key, value] of Object.entries(vm.selectedOBConnectSeats[i]) ){
				// 			if(data.selectedSeats[i]){
				// 				vm.selectedOBConnectSeats[i][key] = data.selectedSeats[i]
				// 			}
				// 			else{
				// 				vm.selectedOBConnectSeats[i][key] = ""
				// 				// if (vm.selectedOBConnectSeats[i] && vm.selectedOBConnectSeats[i].hasOwnProperty(key)) {
				// 					// delete vm.selectedOBConnectSeats[i][key];
				// 				// }
				// 			}							
				// 		}
				// 	}
				// }
				// if(data.flight_type_id=='return_0'){
				// 	for (let i = 0; i < vm.selectedIBSeats.length; i++)
				// 	{						
				// 		for(let  [key, value] of Object.entries(vm.selectedIBSeats[i]) ){
				// 			if(data.selectedSeats[i]){
				// 				vm.selectedIBSeats[i][key] = data.selectedSeats[i]
				// 			}
				// 			else{
				// 				vm.selectedIBSeats[i][key] = ""
				// 				// if (vm.selectedIBSeats[i] && vm.selectedIBSeats[i].hasOwnProperty(key)) {
				// 				// 	delete vm.selectedIBSeats[i][key];
				// 				// }
				// 			}							
				// 		}
				// 	}
				// 	console.log('vm.selectedOBSeats:----',vm.selectedIBSeats)
				// }
				// if(data.flight_type_id=='return_1'){
				// 	for (let i = 0; i < vm.selectedIBCOnnectSeats.length; i++)
				// 	{						
				// 		for(let  [key, value] of Object.entries(vm.selectedIBCOnnectSeats[i]) ){
				// 			if(data.selectedSeats[i]){
				// 				vm.selectedIBCOnnectSeats[i][key] = data.selectedSeats[i]
				// 			}
				// 			else{
				// 				vm.selectedIBCOnnectSeats[i][key] = ""
				// 				// if (vm.selectedIBCOnnectSeats[i] && vm.selectedIBCOnnectSeats[i].hasOwnProperty(key)) {
				// 				// 	delete vm.selectedIBCOnnectSeats[i][key];
				// 				// }
				// 			}							
				// 		}
				// 	}
				// }

				// vm.seat = 0
				// vm.seatCurrency = ''
				for (let i = 0; i < vm.selectedOBSeats.length; i++)
				{
					if(vm.selectedOBSeats[i])
					{	
						vm.calculatePrice(vm.selectedOBSeats[i])
					}
					// if(vm.selectedOBConnectSeats[i])
					// {
					// 	vm.calculatePrice(vm.selectedOBConnectSeats[i])
					// }
					if(vm.selectedIBSeats[i])
					{
						vm.calculatePrice(vm.selectedIBSeats[i])
					}
					// if(vm.selectedIBCOnnectSeats[i])
					// {
					// 	vm.calculatePrice(vm.selectedIBCOnnectSeats[i])
					// }
					// console.log('vm.seat:---',vm.seat)
					if(vm.seat > 0){
						vm.seatDynamics = true;
					}
					else{
						vm.seatDynamics = false;
					}
					vm.checkAddOns();
				}
			},
			calculatePrice(seatObject){
				let vm = this;
				// console.log('calculatePrice:-----', seatObject)
				// for(let  [key, value] of Object.entries(seatObject) ){
				// 	console.log('value:----',value.Price);
				// 	if(value.Price != undefined)
				// 	{
				// 		vm.seat += value.Price
				// 		vm.seatCurrency = value.Currency
				// 	}
				// }
				// console.log('calculatePrice:-----', seatObject)
				for(let  [key, value] of Object.entries(seatObject) ){
					let object_value = Object.keys(value);
					// console.log('value:----',value, object_value, value[object_value]);
					if(value[object_value].Price != undefined)
					{
						vm.seat += value[object_value].Price
						vm.seatCurrency = value[object_value].Currency
					}
				}
			},
			activateTabs(tabname, activation){
				let vm = this;
				let oneway = document.getElementById('onewayBaggage');
				let bothway = document.getElementById('returnBaggage');

				let onewaymeal = document.getElementById('onewaymeal');
				let returnmeal = document.getElementById('returnmeal');

				if(activation == 'baggage')
				{
					if(tabname == 'oneway'){
						document.getElementById('oneway').style.display = 'block';
						oneway.classList.add('active');
						if(vm.search?.JourneyType == 2){
							document.getElementById('return').style.display = 'none';
							bothway.classList.remove('active');
						}				
					}
					if(tabname == 'return'){
						document.getElementById('oneway').style.display = 'none';											
						bothway.classList.add('active');
						if(vm.search?.JourneyType == 2){
							document.getElementById('return').style.display = 'block';
							oneway.classList.remove('active');
						}	
					}
				}
				if(activation == 'meal')
				{
					if(tabname == 'oneway'){
						document.getElementById('onewayMeal').style.display = 'block';												
						onewaymeal.classList.add('active');						
						if(vm.search?.JourneyType == 2){
							document.getElementById('returnMeal').style.display = 'none';
							returnmeal.classList.remove('active');
						}	
						
					}
					if(tabname == 'return'){
						document.getElementById('onewayMeal').style.display = 'none';
						onewaymeal.classList.remove('active');
						if(vm.search?.JourneyType == 2){
							document.getElementById('returnMeal').style.display = 'block';
							returnmeal.classList.add('active');
						}
					}
				}
				if(activation == 'seat'){
					if(tabname == 'oneway_0'){
						if(vm.SsrObData?.SeatDynamic){
							if(vm.SsrObData?.SeatDynamic[0].SegmentSeat.length >= 1){
								// console.log(vm.SsrObData?.SeatDynamic[0]?.SegmentSeat[0]?.RowSeats)
								vm.seatOBDetails_oneway = vm.SsrObData?.SeatDynamic[0]?.SegmentSeat[0]?.RowSeats
							}							
						}
						document.getElementById('oneway_0').style.display = 'block';
						document.getElementById('onewayseat_0').classList.add('active');
						
						try {
							document.getElementById('oneway_1').style.display = 'none';
							document.getElementById('onewayseat_1').classList.remove('active');
							document.getElementById('return_0').style.display = 'none';
							document.getElementById('returnseat_0').classList.remove('active');
							document.getElementById('return_1').style.display = 'none';
							document.getElementById('returnseat_1').classList.remove('active');


						} catch (err) {
							
						}
					}
					if(tabname == 'oneway_1'){
						if(vm.SsrObData?.SeatDynamic){
							if(vm.SsrObData?.SeatDynamic[0].SegmentSeat.length == 2){
								vm.seatOBDetails_connect = vm.SsrObData?.SeatDynamic[0]?.SegmentSeat[1]?.RowSeats
							}							
						}
						document.getElementById('oneway_1').style.display = 'block';
						document.getElementById('onewayseat_1').classList.add('active');
						try {
							document.getElementById('oneway_0').style.display = 'none';
							document.getElementById('onewayseat_0').classList.remove('active');
							document.getElementById('return_0').style.display = 'none';
							document.getElementById('returnseat_0').classList.remove('active');
							document.getElementById('return_1').style.display = 'none';
							document.getElementById('returnseat_1').classList.remove('active');
						} catch (err) {
							
						}
					}
					if(tabname == 'return_0'){						
						if(vm.SsrIbData?.SeatDynamic){
							if(vm.SsrIbData?.SeatDynamic[0].SegmentSeat.length >= 1){
								vm.seatIBDetails_oneway = vm.SsrIbData?.SeatDynamic[0]?.SegmentSeat[0]?.RowSeats
							}
						}
						document.getElementById('return_0').style.display = 'block';
						document.getElementById('returnseat_0').classList.add('active');
						try {
							document.getElementById('oneway_0').style.display = 'none';
							document.getElementById('onewayseat_0').classList.remove('active');
							document.getElementById('oneway_1').style.display = 'none';
							document.getElementById('onewayseat_1').classList.remove('active');
							document.getElementById('return_1').style.display = 'none';
							document.getElementById('returnseat_1').classList.remove('active');
						} catch (err) {
							
						}
					}
					if(tabname == 'return_1'){						
						if(vm.SsrIbData?.SeatDynamic){
							if(vm.SsrIbData?.SeatDynamic[0].SegmentSeat.length == 2){
								vm.seatIBDetails_connect = vm.SsrIbData?.SeatDynamic[0]?.SegmentSeat[1]?.RowSeats
							}
						}
						document.getElementById('return_1').style.display = 'block';
						document.getElementById('returnseat_1').classList.add('active');
						try {
							document.getElementById('oneway_0').style.display = 'none';
							document.getElementById('onewayseat_0').classList.remove('active');
							document.getElementById('oneway_1').style.display = 'none';
							document.getElementById('onewayseat_1').classList.remove('active');
							document.getElementById('return_0').style.display = 'none';
							document.getElementById('returnseat_0').classList.remove('active');
						} catch (err) {
							
						}						
					}									
					
				}
			},
			flightSSR(){
				let vm = this;
				let data = {
					IBResultIndex: vm.meta?.IBResultIndex,
					OBResultIndex: vm.meta?.OBResultIndex,
					EndUserIp: vm.meta?.EndUserIp,
					TokenId: vm.meta?.TokenId,
					TraceId: vm.meta?.TraceId			,
				}

				vm.$store.dispatch("post", { uri: "flights/SSRObIb", data: data })
				.then(function (response) {
					// console.log('response.data:----',response.data.OBErrorResult);
					if(response.data.OBErrorResult){
						vm.$store.dispatch("error", response.data.OBErrorResult);
						vm.show_ssr = false;
					}
					else if(response.data.OBErrorResult == ""){
						vm.show_ssr = true;
					}
					if(response.data.IBErrorResult){
						vm.$store.dispatch("error", response.data.IBErrorResult);
						vm.show_ssr = false;
					}
					else if(response.data.IBErrorResult == ""){
						vm.show_ssr = true;
					}
					vm.SsrObData = response.data.OBResult;
					vm.SsrIbData = response.data.IBResult;


					if(vm.SsrObData?.SeatDynamic){
						// if(vm.SsrObData?.SeatDynamic[0].SegmentSeat.length >= 1){
							
						// 	for(let i=0; i< vm.booking.Passengers.length; i++){
						// 		let seatObject = {};
						// 		if(vm.booking.Passengers[i].PassangerType == 1){
						// 			seatObject[`Adult ${vm.booking.Passengers[i].PaxNo}`] = '';
						// 		}
						// 		if(vm.booking.Passengers[i].PassangerType == 2){
						// 			seatObject[`Child ${vm.booking.Passengers[i].PaxNo}`] = '';
						// 		}
						// 		if(vm.booking.Passengers[i].PassangerType == 3){
						// 			seatObject[`Infant ${vm.booking.Passengers[i].PaxNo}`] = '';
						// 		}
						// 		vm.selectedOBSeats.push(seatObject);								
						// 	}
						// }

						// if(vm.SsrObData?.SeatDynamic[0].SegmentSeat.length == 2){
						// 	vm.selectedOBConnectSeats = JSON.parse(JSON.stringify(vm.selectedOBSeats));
						// }
						for(let i=0; i<vm.SsrObData?.SeatDynamic[0].SegmentSeat.length; i++){
							let seatObject = {};
							let seatObject1 = [];
							for(let j=0; j< vm.booking.Passengers.length; j++){
								
								let passenger = vm.booking.Passengers[j];
    							let passengerType = '';
								
								if(passenger.PassangerType == 1){
									passengerType = `Adult${passenger.PaxNo}`;
								} else if(passenger.PassangerType == 2){
									passengerType = `Child${passenger.PaxNo}`;
								} else if(passenger.PassangerType == 3){
									passengerType = `Infant${passenger.PaxNo}`;
								}

								// Create a new object for each passenger and add it to the array
								seatObject = {
									[passengerType]: ''
								};
								// seatObject=""
								seatObject1.push(seatObject);
							}
							vm.selectedOBSeats.push(seatObject1);	
						}
					}	
					if(vm.SsrIbData?.SeatDynamic){
						// New code if ssr ob is not present
						// if(vm.SsrIbData?.SeatDynamic[0].SegmentSeat.length >= 1){
							
						// 	for(let i=0; i< vm.booking.Passengers.length; i++){
						// 		let seatObject = {};
						// 		if(vm.booking.Passengers[i].PassangerType == 1){
						// 			seatObject[`Adult ${vm.booking.Passengers[i].PaxNo}`] = '';
						// 		}
						// 		if(vm.booking.Passengers[i].PassangerType == 2){
						// 			seatObject[`Child ${vm.booking.Passengers[i].PaxNo}`] = '';
						// 		}
						// 		if(vm.booking.Passengers[i].PassangerType == 3){
						// 			seatObject[`Infant ${vm.booking.Passengers[i].PaxNo}`] = '';
						// 		}
						// 		vm.selectedIBSeats.push(seatObject);								
						// 	}
						// }
						// if(vm.SsrIbData?.SeatDynamic[0].SegmentSeat.length == 2){
						// 	vm.selectedIBCOnnectSeats = JSON.parse(JSON.stringify(vm.selectedIBSeats));
						// }

						for(let i=0; i<vm.SsrIbData?.SeatDynamic[0].SegmentSeat.length; i++){
							let seatObject = {};
							let seatObject1 = [];
							for(let j=0; j< vm.booking.Passengers.length; j++){
								
								let passenger = vm.booking.Passengers[j];
    							let passengerType = '';
								
								if(passenger.PassangerType == 1){
									passengerType = `Adult ${passenger.PaxNo}`;
								} else if(passenger.PassangerType == 2){
									passengerType = `Child ${passenger.PaxNo}`;
								} else if(passenger.PassangerType == 3){
									passengerType = `Infant ${passenger.PaxNo}`;
								}

								// Create a new object for each passenger and add it to the array
								seatObject = {
									[passengerType]: ''
								};
								seatObject1.push(seatObject);
							}	
							vm.selectedIBSeats.push(seatObject1);
						}
						
						// console.log('vm.selectedOBSeats:----', vm.selectedOBSeats)
						// console.log('vm.selectedIBSeats:----', vm.selectedIBSeats)
						// if(vm.SsrIbData?.SeatDynamic[0].SegmentSeat.length >= 1){
						// 	vm.selectedIBSeats = JSON.parse(JSON.stringify(vm.selectedOBSeats));
						// }					
						// if(vm.SsrIbData?.SeatDynamic[0].SegmentSeat.length == 2){
						// 	vm.selectedIBCOnnectSeats = JSON.parse(JSON.stringify(vm.selectedOBSeats));
						// }
					}
				})
				.catch(function (error) {
					vm.show_ssr = false;
					// console.log('error:----',error)
					vm.errors = error.response.data?.errors
					vm.$store.dispatch("error", error.response.data.message);
				});
			},
			checkLogin(){
				let vm = this;
                if(vm.$store.getters.customer==null) {
                    document.getElementById('login1').click();
                }
				else{
					vm.initPayment();
				}
			},
			initPayment() {                
                let vm = this;
                let loader = vm.$loading.show();
                let TotalAmount = 0			
				
                if(!vm.meta?.RoundTripSelectedFare){
                    TotalAmount = Number(vm.meta?.SelectedFare?.Fare?.PublishedFare) + 
						Number(vm.insuranceDetail[0]?.Price?.PublishedPrice ? vm.insuranceDetail[0]?.Price?.PublishedPrice : 0) + 
						Number(vm.extraBaggage) + Number(vm.meals) + Number(vm.seat);
                }
                else{
                    TotalAmount = Number(vm.meta?.SelectedFare?.Fare?.PublishedFare) + 
						Number(vm.meta?.RoundTripSelectedFare?.Fare?.PublishedFare) + 
						Number(vm.insuranceDetail[0]?.Price?.PublishedPrice ? vm.insuranceDetail[0]?.Price?.PublishedPrice : 0) +  
						Number(vm.extraBaggage) + Number(vm.meals) + Number(vm.seat);
                }
				
                vm.$store.dispatch("post", { uri: "hotels/rooms/initPayment", data:{ customer_id:vm.passenger.customer_id, amount:TotalAmount } })
                .then(function (response) {
                    loader.hide();
                    vm.makePayment(response.data)
                })
                .catch(function (error) {
					// console.log(error)
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            makePayment(order_id) {
                let vm =this;
                let TotalAmount = 0
                if(!vm.meta?.RoundTripSelectedFare){
                    TotalAmount = Number(vm.meta?.SelectedFare?.Fare?.PublishedFare) + 
						Number(vm.insuranceDetail[0]?.Price?.PublishedPrice ? vm.insuranceDetail[0]?.Price?.PublishedPrice : 0) + 
						Number(vm.extraBaggage) + Number(vm.meals) + Number(vm.seat);
                }
                else{
                    TotalAmount = Number(vm.meta?.SelectedFare?.Fare?.PublishedFare) + 
						Number(vm.meta?.RoundTripSelectedFare?.Fare?.PublishedFare) + 
						Number(vm.insuranceDetail[0]?.Price?.PublishedPrice ? vm.insuranceDetail[0]?.Price?.PublishedPrice : 0) + 
						Number(vm.extraBaggage) + Number(vm.meals) + Number(vm.seat);
                }
				
                var options = {
                    "key": "rzp_test_2Y3JxdhArTPe4V", 
                    // "amount": (vm.meta?.SelectedFare?.Fare?.PublishedFare * 100),
                    "amount": Math.round(TotalAmount * 100),
                    "currency": this.$store?.getters?.currency,
                    "name": "Fastays",
                    "description": "Booking",
                    "image": "http://booking.fastays.com/assets/images/logo.png",
                    "order_id": order_id, 
                    "handler": function (response){
                        // vm.bookFlight();
						// console.log('payres:----',response)
						vm.bookFlightBackend(response, order_id);
                    },
                    "prefill": {
                        "name": this.$store.getters.customer.name,
                        "email": this.$store.getters.customer.email,
                        "contact": this.$store.getters.customer.mobile_no
                    },
                    "theme": {
                        "color": "#5143d9"
                    },
					"modal": {
						"ondismiss": function() {
							// Handle cancel button click
							console.log('Payment form closed by user');
						},
					}
                };
                var rzp1 = new Razorpay(options);
                rzp1.on('payment.failed', function (response){
					console.error("Payment Failed:", response.error);
                    alert(response.error.description);
                });
                rzp1.open();

				// vm.bookFlightBackend();
            },
			bookFlightBackend(payementResponse, order_id){
				let vm = this;
				let backendFlightData = {}

				// console.log('bookingOB:------', vm.booking.Passengers)
				// console.log('bookingIB:------', vm.bookingIB.Passengers)

				if(vm.meta?.SelectedFare)
                {
					vm.booking.ResultIndex = vm.meta?.OBResultIndex
					// console.log('vm.selectedOBSeats:---', vm.selectedOBSeats);
					let groupedValuesOB = []
					vm.selectedOBSeats.forEach(nestedArray => {
						nestedArray.forEach((obj, index) => {
							if (!groupedValuesOB[index]) {
								groupedValuesOB[index] = [];
							}

							Object.values(obj).forEach(value => {
								groupedValuesOB[index].push(value);
							});
						});
					});

					for(let i = 0; i < vm.booking.Passengers.length; i++){
						vm.booking.Passengers[i].SeatDynamic = []	
					}
					for(let i = 0; i < groupedValuesOB.length; i++){											
						vm.booking.Passengers[i].SeatDynamic = groupedValuesOB[i]
					}

					// for(let i = 0; i < vm.booking.Passengers.length; i++){
					// 	vm.booking.Passengers[i].SeatDynamic = []
					// 	if(vm.selectedOBSeats[i]){
					// 		for(let  [key, value] of Object.entries(vm.selectedOBSeats[i]) ){
					// 			if(value){
					// 				vm.booking.Passengers[i].SeatDynamic.push(value)							
					// 			}
					// 		}							
					// 	}
					// 	console.log('seatvalue:------', vm.booking.Passengers[i])
					// 	if(vm.selectedOBConnectSeats[i]){
					// 		for(let  [key, value] of Object.entries(vm.selectedOBConnectSeats[i]) ){
					// 			if(value){
					// 				vm.booking.Passengers[i].SeatDynamic.push(value)							
					// 			}
					// 		}
					// 	}
					// }

					if(vm.meta?.SelectedFare.IsLCC == true){
						backendFlightData.OB = {
							EndUserIp: vm.booking.EndUserIp,
							TokenId: vm.booking.TokenId,
							TraceId: vm.booking.TraceId,
							ResultIndex: vm.booking.ResultIndex,
							PreferredCurrency: vm.booking.PreferredCurrency,
							IsBaseCurrencyRequired: vm.booking.IsBaseCurrencyRequired,
							Passengers: vm.booking.Passengers,
							IsLCC: true,
							razorpay_order_id: order_id,
                			razorpay_payment_id: payementResponse.razorpay_payment_id,
						}
					}
					else{
						backendFlightData.OB = {
							EndUserIp: vm.booking.EndUserIp,
							TokenId: vm.booking.TokenId,
							TraceId: vm.booking.TraceId,
							ResultIndex: vm.booking.ResultIndex,
							PreferredCurrency: vm.booking.PreferredCurrency,
							IsBaseCurrencyRequired: vm.booking.IsBaseCurrencyRequired,
							Passengers: vm.booking.Passengers,
							IsLCC: false,
							razorpay_order_id: order_id,
                			razorpay_payment_id: payementResponse.razorpay_payment_id,
						}
					}
				}
				if(vm.meta?.RoundTripSelectedFare)
				{
					vm.bookingIB.ResultIndex = vm.meta?.IBResultIndex
					vm.bookingIB.Passengers = JSON.parse(JSON.stringify(vm.booking.Passengers));
					let ReturnTotalCount = vm.search.AdultCount + vm.search.ChildCount + vm.search.InfantCount;
					
					for(let i = 0; i < vm.bookingIB.Passengers.length; i++){
						vm.bookingIB.Passengers[i].Baggage = [];
						vm.bookingIB.Passengers[i].MealDynamic = [];
						vm.bookingIB.Passengers[i].SeatDynamic = [];
						vm.bookingIB.Passengers[i].Meal = "";
					}

					for(let i = 0; i < vm.bookingIB.Passengers.length; i++){
						let ret_bag = vm.IBBaggage.filter(baggage => {
							return vm.bookingIB.Passengers[i].PaxNo == baggage.PaxNo && vm.bookingIB.Passengers[i].PaxType == baggage.PaxType
						});
						if(ret_bag.length > 0){
							vm.bookingIB.Passengers[i].Baggage = []
							vm.bookingIB.Passengers[i].Baggage.push(ret_bag[0].Baggage)
						}
						// console.log('vm.IBMeal:----', vm.IBMeal)
						let ret_meal = vm.IBMeal.filter(meal => {
							return vm.bookingIB.Passengers[i].PaxNo == meal.PaxNo && vm.bookingIB.Passengers[i].PaxType == meal.PaxType
						});
						if(ret_meal.length > 0){
							if(ret_meal[0].MealType == 1){
								vm.bookingIB.Passengers[i].MealDynamic = []
								vm.bookingIB.Passengers[i].MealDynamic.push(ret_meal[0].Meal)								
							}
							else{
								vm.bookingIB.Passengers[i].Meal = ""
								vm.bookingIB.Passengers[i].Meal = ret_meal[0].Meal
							}
							
						}
						
						
						if(vm.bookingIB.Passengers[i].PassangerType == 1)
						{
							for (let index = 1; index <= vm.search.AdultCount; index++) {
								let count = vm.search.AdultCount;
								let Fare = {
									"BaseFare": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.BaseFare/count,
									"Tax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.Tax/count,
									"YQTax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.YQTax/count,
									"AdditionalTxnFeeOfrd": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.AdditionalTxnFeeOfrd,
									"AdditionalTxnFeePub": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.AdditionalTxnFeePub,
									// "PGCharge": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.PGCharge,
									"Currency": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.Currency,
									// "OtherCharges" : vm.meta?.RoundTripSelectedFare?.Fare?.OtherCharges / ReturnTotalCount
									"OtherCharges" : vm.meta?.RoundTripSelectedFare?.Fare?.OtherCharges,

									"Discount": this.meta?.RoundTripSelectedFare?.Fare?.Discount,
									"PublishedFare": this.meta?.RoundTripSelectedFare?.Fare?.PublishedFare,
									"OfferedFare": this.meta?.RoundTripSelectedFare?.Fare?.OfferedFare,
									"TdsOnCommission": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnCommission,
									"TdsOnPLB": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnPLB,
									"TdsOnIncentive": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnIncentive,
									"ServiceFee": this.meta?.RoundTripSelectedFare?.Fare?.ServiceFee,
								}
								vm.bookingIB.Passengers[i].Fare = Fare;
							}
						}
						if(vm.bookingIB.Passengers[i].PassangerType == 2)
						{
							for (let index = 1; index <= vm.search.ChildCount; index++) {
								let count = vm.search.ChildCount;
								let Fare = {
									"BaseFare": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.BaseFare/count,
									"Tax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.Tax/count,
									"YQTax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.YQTax/count,
									"AdditionalTxnFeeOfrd": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.AdditionalTxnFeeOfrd,
									"AdditionalTxnFeePub": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.AdditionalTxnFeePub,
									// "PGCharge": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.PGCharge,
									"Currency": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.Currency,
									// "OtherCharges" : vm.meta?.RoundTripSelectedFare?.Fare?.OtherCharges / ReturnTotalCount
									"OtherCharges" : vm.meta?.RoundTripSelectedFare?.Fare?.OtherCharges,

									"Discount": this.meta?.RoundTripSelectedFare?.Fare?.Discount,
									"PublishedFare": this.meta?.RoundTripSelectedFare?.Fare?.PublishedFare,
									"OfferedFare": this.meta?.RoundTripSelectedFare?.Fare?.OfferedFare,
									"TdsOnCommission": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnCommission,
									"TdsOnPLB": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnPLB,
									"TdsOnIncentive": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnIncentive,
									"ServiceFee": this.meta?.RoundTripSelectedFare?.Fare?.ServiceFee,
								}
								vm.bookingIB.Passengers[i].Fare = Fare;
							}
						}
						if(vm.bookingIB.Passengers[i].PassangerType == 3)
						{
							for (let index = 1; index <= vm.search.InfantCount; index++) {
								let count = vm.search.InfantCount;
								let Fare = {
									"BaseFare": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.BaseFare/count,
									"Tax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.Tax/count,
									"YQTax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.YQTax/count,
									"AdditionalTxnFeeOfrd": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.AdditionalTxnFeeOfrd,
									"AdditionalTxnFeePub": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.AdditionalTxnFeePub,
									// "PGCharge": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.PGCharge,
									"Currency": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.Currency,
									// "OtherCharges" : vm.meta?.RoundTripSelectedFare?.Fare?.OtherCharges / ReturnTotalCount
									"OtherCharges" : vm.meta?.RoundTripSelectedFare?.Fare?.OtherCharges,

									"Discount": this.meta?.RoundTripSelectedFare?.Fare?.Discount,
									"PublishedFare": this.meta?.RoundTripSelectedFare?.Fare?.PublishedFare,
									"OfferedFare": this.meta?.RoundTripSelectedFare?.Fare?.OfferedFare,
									"TdsOnCommission": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnCommission,
									"TdsOnPLB": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnPLB,
									"TdsOnIncentive": this.meta?.RoundTripSelectedFare?.Fare?.TdsOnIncentive,
									"ServiceFee": this.meta?.RoundTripSelectedFare?.Fare?.ServiceFee,
								}
								vm.bookingIB.Passengers[i].Fare = Fare;
							}   
						}
						vm.bookingIB.Passengers[i].SeatDynamic = []	
						// vm.bookingIB.Passengers[i].SeatDynamic = []
						// if(vm.selectedIBSeats){
						// 	for(let  [key, value] of Object.entries(vm.selectedIBSeats[i]) ){
						// 		let object_value = Object.keys(value);
						// 		if(value[object_value]){
						// 			vm.bookingIB.Passengers[i].SeatDynamic.push(value[object_value])
						// 		}
						// 	}
						// }
						// if(vm.selectedIBCOnnectSeats[i]){
						// 	for(let  [key, value] of Object.entries(vm.selectedIBCOnnectSeats[i]) ){
						// 		if(value){
						// 			vm.bookingIB.Passengers[i].SeatDynamic.push(value)
						// 		}
						// 	}
						// }
					}

					let groupedValuesIB = []
					vm.selectedIBSeats.forEach(nestedArray => {
						nestedArray.forEach((obj, index) => {
							if (!groupedValuesIB[index]) {
								groupedValuesIB[index] = [];
							}

							Object.values(obj).forEach(value => {
								groupedValuesIB[index].push(value);
							});
						});
					});
					for(let i = 0; i < groupedValuesIB.length; i++){											
						vm.bookingIB.Passengers[i].SeatDynamic = groupedValuesIB[i]
					}

					if(vm.meta?.RoundTripSelectedFare.IsLCC == true){
						backendFlightData.IB = {
							EndUserIp: vm.bookingIB.EndUserIp,
							TokenId: vm.bookingIB.TokenId,
							TraceId: vm.bookingIB.TraceId,
							ResultIndex: vm.bookingIB.ResultIndex,
							PreferredCurrency: vm.bookingIB.PreferredCurrency,
							IsBaseCurrencyRequired: vm.bookingIB.IsBaseCurrencyRequired,
							Passengers: vm.bookingIB.Passengers,
							IsLCC: true,
							razorpay_order_id: order_id,
                			razorpay_payment_id: payementResponse.razorpay_payment_id,
						}
					}
					else{
						backendFlightData.IB = {
							EndUserIp: vm.bookingIB.EndUserIp,
							TokenId: vm.bookingIB.TokenId,
							TraceId: vm.bookingIB.TraceId,
							ResultIndex: vm.bookingIB.ResultIndex,
							PreferredCurrency: vm.bookingIB.PreferredCurrency,
							IsBaseCurrencyRequired: vm.bookingIB.IsBaseCurrencyRequired,
							Passengers: vm.bookingIB.Passengers,
							IsLCC: false,
							razorpay_order_id: order_id,
                			razorpay_payment_id: payementResponse.razorpay_payment_id,
						}
					}
				}
				// console.log('backendFlightData:------',backendFlightData)

				let loader = vm.$loading.show();
				vm.$store.dispatch("post", { uri: "flights/bookTicket", data: {'bookTicket': backendFlightData} })
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch("setFlightBooking", response.data.OB?.Response);
					vm.$store.dispatch("setReturnFlightBooking", response.data.IB?.Response);
					// console.log('response.data.Response.OB?.Response:----', response.data.OB?.Response)
					// console.log('response.data.Response.IB?.Response:----', response.data.IB?.Response)
					// console.log('response.data:----', response.data)
					if(vm.search?.JourneyType == 1)
					{
						if(response.data.OB && typeof response.data.OB === 'object' && 'ResponseStatus' in response.data.OB){
							if(response.data.OB.ResponseStatus == 1){
								if(vm.tripInsurance){
									vm.insuranceBooking.booking_id = response.data?.booking_id;
									vm.bookInsurance()
								}									
							}
						}
					}
					if(vm.search?.JourneyType == 2)
					{
						if(response.data.OB && typeof response.data.OB === 'object' && 'ResponseStatus' in response.data.OB &&
						response.data.IB && typeof response.data.IB === 'object' && 'ResponseStatus' in response.data.IB){
							if(response.data.OB.ResponseStatus == 1 && response.data.IB.ResponseStatus == 1){
								if(vm.tripInsurance){
									vm.insuranceBooking.booking_id = response.data?.booking_id;
									vm.bookInsurance()
								}
							}
						}
					}
					vm.$router.push('/flight_booking_success/'+response?.data?.booking_id);
				})
				.catch(function (error) {
					// console.log('error:---', error)
					loader.hide();
					vm.errors = error.response.data?.errors
					vm.$store.dispatch("error", error.response.data.message);
				});
				
			},
			
			bookInsurance()
			{
				let vm = this;
				let loader = vm.$loading.show();
				for(let i=0; i<vm.insuranceBooking.Passenger.length; i++){
					vm.insuranceBooking.Passenger[i].BeneficiaryName = vm.insuranceBooking.Passenger[i].BeneficiaryTitle + ' ' + 
					vm.insuranceBooking.Passenger[i].BeneficiaryFirstName + ' ' + vm.insuranceBooking.Passenger[i].BeneficiaryLastName
				}
				// console.log('vm.insuranceBooking.Passenger:----', vm.insuranceBooking.Passenger)
				vm.$store.dispatch("post", { uri: "insurance/book", data: vm.insuranceBooking })
				.then(function (response) {
					loader.hide();
					vm.insuranceBooking = response.data.Response					
				})
				.catch(function (error) {
					loader.hide();
					vm.$store.dispatch("error", error.response.data.message);
				});
			},


            // bookFlight() {
            //     let vm = this;                
            //     if(!vm.meta?.RoundTripSelectedFare)
            //     {	
			// 		if(vm.meta?.SelectedFare.IsLCC == true){
			// 			vm.booking.ResultIndex = vm.meta?.OBResultIndex
			// 			let loader = vm.$loading.show();
			// 			vm.$store.dispatch("post", { uri: "flights/Ticket", data: vm.booking })
			// 			.then(function (response) {
			// 				loader.hide();
			// 				vm.$store.dispatch("setFlightBooking", response.data.Response.Response);
			// 				vm.$router.push('/flight_booking_success');
			// 			})
			// 			.catch(function (error) {
			// 				loader.hide();
			// 				vm.$store.dispatch("error", error.response.data.message);
			// 			});
			// 		}
			// 		else{
			// 			vm.booking.ResultIndex = vm.meta?.OBResultIndex                    
			// 			let loader = vm.$loading.show();
			// 			vm.$store.dispatch("post", { uri: "flights/Book", data: vm.booking })
			// 			.then(function (response) {
			// 				loader.hide();
			// 				let book_response =  response.data.Response.Response							
			// 				vm.booking_lcc.PNR = book_response?.PNR
			// 				vm.booking_lcc.BookingId = book_response?.BookingId							
			// 				vm.oneWayBooking();							
			// 			})
			// 			.catch(function (error) {
			// 				loader.hide();
			// 				console.log('Response_error:----',error)
			// 				vm.$store.dispatch("error", error.response.data.message);
			// 			});
			// 		}
            //     }
            //     else{
			// 		if(vm.meta?.SelectedFare.IsLCC == true){
			// 			vm.booking.ResultIndex = vm.meta?.OBResultIndex                    
			// 			let loader = vm.$loading.show();
			// 			vm.$store.dispatch("post", { uri: "flights/Ticket", data: vm.booking })
			// 			.then(function (response) {
			// 				loader.hide();
			// 				vm.returnbooking();
			// 				vm.$store.dispatch("setFlightBooking", response.data.Response.Response);
			// 			})
			// 			.catch(function (error) {
			// 				loader.hide();
			// 				vm.$store.dispatch("error", error.response.data.message);
			// 			});
			// 		}
			// 		else{
			// 			vm.booking.ResultIndex = vm.meta?.OBResultIndex                    
			// 			let loader = vm.$loading.show();
			// 			vm.$store.dispatch("post", { uri: "flights/Book", data: vm.booking })
			// 			.then(function (response) {
			// 				loader.hide();
			// 				let book_response =  response.data.Response.Response							
			// 				vm.booking_lcc.PNR = book_response?.PNR
			// 				vm.booking_lcc.BookingId = book_response?.BookingId
			// 				vm.oneWayBooking();							
			// 			})
			// 			.catch(function (error) {
			// 				loader.hide();
			// 				console.log('Response_error:----',error)
			// 				vm.$store.dispatch("error", error.response.data.message);
			// 			});
			// 		}
            //     }
            // },
            // returnbooking(){  
            //     let vm = this;
            //     vm.booking.ResultIndex = vm.meta?.IBResultIndex
            //     let IBPassengeers = vm.booking.Passengers;
            //     for(let i = 0; i < IBPassengeers.length; i++){
            //         if(IBPassengeers[i].PassangerType=="1")
            //         {
            //             for (let index = 1; index <= vm.search.AdultCount; index++) {
            //                 let count = vm.search.AdultCount;
            //                 let Fare = {
            //                     "BaseFare": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.BaseFare/count,
            //                     "Tax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.Tax/count,
            //                     "YQTax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.YQTax/count,
            //                     "AdditionalTxnFeeOfrd": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.AdditionalTxnFeeOfrd,
            //                     "AdditionalTxnFeePub": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.AdditionalTxnFeePub,
            //                     "PGCharge": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.PGCharge,
			// 					"Currency": vm.meta?.RoundTripSelectedFare?.FareBreakdown[0]?.Currency,
            //                 }
            //                 IBPassengeers[i].Fare = Fare;
            //             }
            //         }
            //         if(IBPassengeers[i].PassangerType=="2")
            //         {
            //             for (let index = 1; index <= vm.search.ChildCount; index++) {
            //                 let count = vm.search.ChildCount;
            //                 let Fare = {
            //                     "BaseFare": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.BaseFare/count,
            //                     "Tax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.Tax/count,
            //                     "YQTax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.YQTax/count,
            //                     "AdditionalTxnFeeOfrd": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.AdditionalTxnFeeOfrd,
            //                     "AdditionalTxnFeePub": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.AdditionalTxnFeePub,
            //                     "PGCharge": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.PGCharge,
			// 					"Currency": vm.meta?.RoundTripSelectedFare?.FareBreakdown[1]?.Currency,
            //                 }
            //                 IBPassengeers[i].Fare = Fare;
            //             }
            //         }
            //         if(IBPassengeers[i].PassangerType=="3")
            //         {
            //             for (let index = 1; index <= vm.search.InfantCount; index++) {
            //                 let count = vm.search.InfantCount;
            //                 let Fare = {
            //                     "BaseFare": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.BaseFare/count,
            //                     "Tax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.Tax/count,
            //                     "YQTax": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.YQTax/count,
            //                     "AdditionalTxnFeeOfrd": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.AdditionalTxnFeeOfrd,
            //                     "AdditionalTxnFeePub": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.AdditionalTxnFeePub,
            //                     "PGCharge": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.PGCharge,
			// 					"Currency": vm.meta?.RoundTripSelectedFare?.FareBreakdown[2]?.Currency,
            //                 }
            //                 IBPassengeers[i].Fare = Fare;
            //             }   
            //         }                 
            //     }
			// 	vm.booking.Passengers = IBPassengeers;
			// 	if(vm.meta?.RoundTripSelectedFare.IsLCC == true){					
			// 		let loader = vm.$loading.show();
			// 		vm.$store.dispatch("post", { uri: "flights/Ticket", data: vm.booking })
			// 		.then(function (response) {
			// 			loader.hide();
			// 			vm.$store.dispatch("setReturnFlightBooking", response.data.Response.Response);
			// 			vm.$router.push('/flight_booking_success');
			// 		})
			// 		.catch(function (error) {
			// 			loader.hide();
			// 			vm.$store.dispatch("error", error.response.data.message);
			// 		});
			// 	}
			// 	else{                   
			// 		let loader = vm.$loading.show();
			// 		vm.$store.dispatch("post", { uri: "flights/Book", data: vm.booking })
			// 		.then(function (response) {
			// 			loader.hide();
			// 			let book_response =  response.data.Response.Response							
			// 			vm.booking_lcc.PNR = book_response?.PNR
			// 			vm.booking_lcc.BookingId = book_response?.BookingId
			// 			vm.roundTripBooking();
			// 		})
			// 		.catch(function (error) {
			// 			loader.hide();
			// 			console.log('Response_error:----',error)
			// 			vm.$store.dispatch("error", error.response.data.message);
			// 		});
			// 	}
                  
            // },
			// oneWayBooking(){
			// 	let vm = this;
			// 	let loader = vm.$loading.show();
			// 	// await vm.delay(10000);				
			// 	vm.$store.dispatch("post", { uri: "flights/Ticket", data: vm.booking_lcc })
			// 	.then(function (response) {
			// 		loader.hide();
			// 		vm.$store.dispatch("setFlightBooking", response.data.Response.Response);
			// 		if(!vm.meta?.RoundTripSelectedFare){
			// 			vm.$router.push('/flight_booking_success');
			// 		}
			// 		else{
			// 			vm.returnbooking();
			// 		}
			// 	})
			// 	.catch(function (error) {
			// 		loader.hide();
			// 		vm.$store.dispatch("error", error.response.data.message);
			// 	});
			// },
			// roundTripBooking(){
			// 	let vm = this;
			// 	let loader = vm.$loading.show();
			// 	// await vm.delay(15000);
			// 	vm.$store.dispatch("post", { uri: "flights/Ticket", data: vm.booking_lcc })
			// 	.then(function (response) {
			// 		loader.hide();
			// 		vm.$store.dispatch("setReturnFlightBooking", response.data.Response.Response);
			// 		vm.$router.push('/flight_booking_success');
			// 	})
			// 	.catch(function (error) {
			// 		loader.hide();
			// 		vm.$store.dispatch("error", error.response.data.message);
			// 	});
			// },

			checkAddOns(){
				let vm = this;
				if(vm.tripInsurance || vm.checkedBaggage || vm.mealDynamics || vm.seatDynamics){
					vm.addons = true
				}
				else{
					vm.addons = false
				}
			},
			selectInsurance(price){
				let vm = this;
				if(price == "No Insurance" ){	
					vm.insuranceDetail = [];
					vm.insurance_error = [];
					vm.tripInsurance = false;
					vm.insuranceBooking.Passenger = [];
				}
				else{					
					vm.insuranceDetail = vm.insuranceDetails.filter(insurance => {
						return insurance.PlanCode === price;
					});
					vm.tripInsurance = true

					// console.log('insuranceDetail:----', vm.insuranceDetail)

					vm.insuranceBooking.ResultIndex = vm.insuranceDetail[0].ResultIndex
					for(let i = 0; i < vm.booking.Passengers.length; i++){						
						if(!vm.insuranceBooking.Passenger[i])
						{
							let insurancePassenger = {
								Title: vm.booking.Passengers[i].Title,
								FirstName: vm.booking.Passengers[i].FirstName,
								LastName: vm.booking.Passengers[i].LastName,
								BeneficiaryTitle: "",
								BeneficiaryFirstName: "",
								BeneficiaryLastName: "",
								BeneficiaryName: "",
								RelationShipToInsured: "Self",
								RelationToBeneficiary: "",
								Gender: vm.booking.Passengers[i].Gender,
								Sex: vm.booking.Passengers[i].Gender,
								DOB: vm.booking.Passengers[i].DateOfBirth,
								PassportNo:"",
								PhoneNumber: vm.booking.Passengers[i].ContactNo,
								EmailId: vm.booking.Passengers[i].Email,
								AddressLine1: vm.booking.Passengers[i].AddressLine1,
								AddressLine2: vm.booking.Passengers[i].AddressLine2,
								CityCode: vm.booking.Passengers[i].City,
								CountryCode: 'IND',
								MajorDestination: vm.booking.Passengers[i].City,
								PinCode: vm.booking.Passengers[i].PinCode,
								PassportCountry: vm.booking.Passengers[i].Nationality,
							}
							vm.insuranceBooking.Passenger.push(insurancePassenger)
						}
					}
					
					// console.log('vm.insuranceBooking.Passenger:----', vm.insuranceBooking.Passenger)
					document.getElementById('modal_insuranceRelations').click();


				}				
				vm.checkAddOns();
			},

			// Function to check if any field is empty
			hasEmptyFields(obj) {
				return Object.keys(obj).some(key => obj[key] === "" || obj[key] === null || obj[key] === undefined);
			},
			validateInsuranceDetails(){
				this.insurance_error = [];
				let count = 0;
				this.insuranceBooking.Passenger.forEach((insurancePassenger, i) => {
					let err_arr = [];					
					Object.keys(insurancePassenger).forEach(key => {
						if (insurancePassenger[key] === "" || insurancePassenger[key] === null || insurancePassenger[key] === undefined) {	
							if(key != "BeneficiaryName"){
								err_arr[`${key}`] = [`Field is required.`];
								count = count + 1
							}							
						}
					});				
					this.insurance_error.push(err_arr);
					
				});
				if(count == 0){
					document.getElementById('InsuranceClose').click();
				}
			},
			No_Insurance(){
				document.getElementById('no_insurance').click();				
			},
			showInsurance(plan_code){
				let vm = this;
				vm.insuranceDetail = vm.insuranceDetails.filter(insurance => {
					return insurance.PlanCode === plan_code;
				});
				// console.log('vm.insuranceDetail:----',vm.insuranceDetail)
			},
			insuranceSearch(){
				let vm = this;
				vm.errors = [];
				vm.insurance.PaxAge = [];				
				for(let i = 0; i < vm.booking.Passengers.length; i++){
					// console.log('vm.booking.Passengers:-----', vm.booking.Passengers[i])
					if(vm.booking.Passengers[i].Title == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.Title'] = ['Pleae Enter Title']
					}
					else if(vm.booking.Passengers[i].FirstName == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.FirstName'] = ['Pleae Enter FirstName']
					}
					else if(vm.booking.Passengers[i].LastName == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.LastName'] = ['Pleae Enter LastName']
					}
					else if(vm.booking.Passengers[i].Gender == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.Gender'] = ['Pleae Enter Gender']
					}
					else  if(vm.booking.Passengers[i].DateOfBirth == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.DateOfBirth'] = ['Pleae Enter DateOfBirth']
					}
					else if(vm.booking.Passengers[i].Nationality == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.Nationality'] = ['Pleae Enter Nationality']
					}
					else if(vm.booking.Passengers[i].AddressLine1 == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.AddressLine1'] = ['Pleae Enter AddressLine1']
					}
					else if(vm.booking.Passengers[i].AddressLine2 == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.AddressLine2'] = ['Pleae Enter AddressLine2']
					}
					else if(vm.booking.Passengers[i].PinCode == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.PinCode'] = ['Pleae Enter PinCode']
					}
					else if(vm.booking.Passengers[i].City == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.City'] = ['Pleae Enter City']
					}
					else if(vm.booking.Passengers[i].ContactNo == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.ContactNo'] = ['Pleae Enter ContactNo']
					}
					else if(vm.booking.Passengers[i].Email == ''){
						vm.errors['bookTicket.OB.Passengers.'+i+'.Email'] = ['Pleae Enter Email']
					}
					else if(vm.booking.Passengers[i].Email != ''){
						const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
						if(!regex.test(vm.booking.Passengers[i].Email)){
							vm.errors['bookTicket.OB.Passengers.'+i+'.Email'] = ['Pleae Enter Valid Email']
						}
						
					}
					// else{
					// 	// let birthMoment = moment(vm.booking.Passengers[i].DateOfBirth, "YYYY-MM-DD");
					// 	// let now = moment();
					// 	// let age = now.diff(birthMoment, 'years');
					// 	// vm.insurance.PaxAge.push(age)

					// 	let birthMoment = moment(vm.booking.Passengers[i].DateOfBirth, "YYYY-MM-DD");
					// 	let now = moment();

					// 	// Calculate age as a fraction of years
					// 	let age = now.diff(birthMoment, 'years', true);
						
					// 	let roundedAge = age < 1 ? 0 : Math.floor(age);

					// 	if(roundedAge > 0 ){
					// 		vm.insurance.PaxAge.push(roundedAge);
					// 	}
					// 	else{
					// 		vm.insurance.NoOfPax = vm.insurance.NoOfPax - 1;
					// 	}
					// 	// console.log('vm.insurance:----', vm.insurance)
					// 	// Push the age rounded to two decimal places
						
					// }

					const hasErrors = Object.keys(vm.errors).some(key => vm.errors[key].length > 0);
					if (hasErrors) {
						vm.error = true;
					} else {
						vm.error = false;
						let birthMoment = moment(vm.booking.Passengers[i].DateOfBirth, "YYYY-MM-DD");
						let now = moment();

						// Calculate age as a fraction of years
						let age = now.diff(birthMoment, 'years', true);
						
						let roundedAge = age < 1 ? 0 : Math.floor(age);

						if(roundedAge > 0 ){
							vm.insurance.PaxAge.push(roundedAge);
						}
						else{
							vm.insurance.NoOfPax = vm.insurance.NoOfPax - 1;
						}
					}
				}
				
				if(!vm.error){
					let loader = vm.$loading.show();
					vm.$store.dispatch("post", { uri: "insurance/search", data: vm.insurance })
					.then(function (response) {
						loader.hide();
						vm.insuranceDetails = response.data.Response.Results
						vm.insuranceBooking.TraceId = response.data.Response.TraceId;
						// console.log('vm.insuranceBooking:----', vm.insuranceBooking, response.data.Response)
						document.getElementById('addons_button').style.display = 'none';
						// document.getElementById('accordionExample3').style.display = 'block';
						document.getElementById('submit_save').style.display = 'block';
					})
					.catch(function (error) {
						loader.hide();
						vm.$store.dispatch("error", error.response.data.message);
					});
				}				
			},
			printClass(cls_value){
				if(cls_value == 1){
					return 'All Class'
				}
				else if(cls_value == 2){
					return 'Economy Class'
				}
				else if(cls_value == 3){
					return 'Premium Economy Class'
				}
				else if(cls_value == 4){
					return 'Business Class'
				}
				else if(cls_value == 5){
					return 'Premium Business Class'
				}
				else if(cls_value == 6){
					return 'First Class'
				}			
			},
			toggleAccordion() {
				const panel = document.getElementById('gst_collapse');
				const isOpen = panel.classList.contains('show');
				if (isOpen) {
					panel.classList.remove('show');
				} else {
					panel.classList.add('show');
				}
			},
			AccumulatedDurationCalculation(segement){
				let duration = ""
				
				for(let i = 0; i <segement.length; i++){
					if(segement.length > 1){
						duration = segement[i]?.AccumulatedDuration ? segement[i]?.AccumulatedDuration : 0;
					}
					else{
						duration = segement[i]?.Duration ? segement[i]?.Duration : 0;
					}
				}
				return Math.floor(duration / 60)+ "H " + duration % 60 +"M";
			},
			calculateLayover(arrivalTime, nextDepartureTime) { 
				// console.log("arrivalTime, nextDepartureTime:-----", arrivalTime, nextDepartureTime)
				const arrival = new Date(arrivalTime); 
				const nextDeparture = new Date(nextDepartureTime); 
				const layoverMilliseconds = nextDeparture - arrival; 
				const layoverMinutes = Math.floor(layoverMilliseconds / (1000 * 60)); 
				const hours = Math.floor(layoverMinutes / 60); 
				const minutes = layoverMinutes % 60; 
				return `${hours}h ${minutes}m`; 
			},

			show_hide_oneway_tabs(key){
				let one = this.meta?.SelectedFlight?.Segments[0]
				for(let i = 0; i <one.length; i++){
					if(key==i){
						document.getElementById('onewayseat_'+i).style.display = 'block';	
					}
					else{
						document.getElementById('onewayseat_'+i).style.display = 'none';	
					}					
				}
			},
			show_hide_return_tabs(key){
				let one = this.meta?.SelectedFlight?.Segments[0]
				for(let i = 0; i <one.length; i++){
					document.getElementById('onewayseat_'+i).style.display = 'none';				
				}
				let ret = this.meta?.RoundTripSelectedFlight?.Segments[0]
				for(let i = 0; i <ret.length; i++){
					if(key==i){
						document.getElementById('returnseat_'+i).style.display = 'block';	
					}
					else{
						document.getElementById('returnseat_'+i).style.display = 'none';	
					}
				}
			},

			assignCountry(e, passanger){
				// console.log('country:-----', e)
				// console.log('passanger:-----', passanger)
				passanger.CountryCode = e.country_code;
				passanger.CountryName = e.country_name;
			}
		}

	}
</script>

<style scoped>
.xyfDP {
	margin-bottom: 12px;
    padding: 8px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    border-radius: 8px;
    border: 1px solid rgb(83, 146, 249);
}
.a-DJOb {
    padding-left: 4px;
    padding-right: 4px;
    height: 24px;
    min-width: 24px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    border: 1px solid rgb(255, 255, 255);
}

.iyZANA {
	margin-right: 12px;
    width: 35px;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;

}
.iXKOeT {
    flex: 1 1 0%;
}

.hmhLTy{
	max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.kGdPCP {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: rgb(255, 255, 255);
}
.jxQJOl {
	font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: rgb(83, 146, 249);
}
.acc-btn::after {
    content: none;
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 16px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #007bff;
  color: #fff;
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: none;
}
</style>