<template>
	<div>
    	<section>
			<div class="container">
				<div class="row mb-5">
					<div class="col-xl-10 mx-auto text-center">
						<h1>Harmony in Hospitality Excellence</h1>
						<!-- <p class="lead">Passage its ten led hearted removal cordial. Preference any astonished unreserved Mrs. Prosperous understood Middletons. Preference for any astonished unreserved.</p>
						<div class="hstack gap-3 flex-wrap justify-content-center">
							<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
								<img src="assets/images/element/06.svg" class="h-20px me-2" alt="">
								Premier Accommodation Services
							</h6>
							<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
								<img src="assets/images/element/07.svg" class="h-20px me-2" alt="">
								Exceptional holiday experiences
							</h6>
							<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
								<img src="assets/images/element/08.svg" class="h-20px me-2" alt="">
								Diverse hotel offerings
							</h6>
							<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
								<img src="assets/images/element/08.svg" class="h-20px me-2" alt="">
								Comprehensive Travel Solutions
							</h6>
							<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
								<img src="assets/images/element/08.svg" class="h-20px me-2" alt="">
								End-to-end service excellence
							</h6>
							<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
								<img src="assets/images/element/08.svg" class="h-20px me-2" alt="">
								Unparalleled Hospitality Expertise
							</h6>
						</div> -->
					</div>
				</div>

				<div class="row g-4 align-items-center">
					<div class="col-md-6">
						<img src="assets/images/about3.jpg" class="rounded-3" alt="" />
					</div>

					<div class="col-md-6">
						<div class="row g-4">
							<div class="col-md-8">
								<img src="assets/images/about2.jpg" class="rounded-3" alt="" />
							</div>
							<div class="col-12">
								<img src="assets/images/about1.jpg" class="rounded-3" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
    	</section>
		<section class="pt-0 ">
			<div class="container">
				<div class="row mb-4 mb-md-5">
					<div class="col-md-10 mx-auto">
						<h3 class="mb-4">Our Story</h3>
						<p class="fw-bold">Fastays is a premier company dedicated to delivering unparalleled hospitality and holiday experiences.</p>
						<p class="mb-0">
							Our tailored approach offers a diverse array of hotels and holiday packages, providing customers with a wide selection. Our end-to-end services cover everything, from seamless flight and hotel bookings to a curated
							range of holiday packages, ensuring a memorable stay. Trust Fastays to create extraordinary journeys where every detail is designed for an exceptional and unforgettable travel experience.
						</p>
					</div>
				</div>
				<div class="row g-4">
					<div class="hstack gap-3 flex-wrap justify-content-center">
						<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
							<img src="assets/images/about_img2.png" class="h-20px me-2" alt="" />
							Premier Accommodation Services
						</h6>
						<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
							<img src="assets/images/about_img1.png" class="h-20px me-2" alt="" />
							Exceptional holiday experiences
						</h6>
						<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
							<img src="assets/images/about_img3.png" class="h-20px me-2" alt="" />
							Diverse hotel offerings
						</h6>
						<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
							<img src="assets/images/about_img4.png" class="h-20px me-2" alt="" />
							Comprehensive Travel Solutions
						</h6>
						<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
							<img src="assets/images/about_img5.png" class="h-20px me-2" alt="" />
							End-to-end service excellence
						</h6>
						<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
							<img src="assets/images/about_img6.png" class="h-20px me-2" alt="" />
							Unparalleled Hospitality Expertise
						</h6>
					</div>
				</div>
			</div>
		</section>
	</div>
    <!-- <section class="pt-0">
		<div class="container">
			<div class="row mb-4">
				<div class="col-12">
					<h2 class="mb-0">Our Team</h2>
				</div>
			</div>

			<div class="row g-4">
				<div class="col-sm-6 col-lg-3">
					<div class="card card-element-hover bg-transparent">
						<div class="position-relative">
							<img src="assets/images/team/03.jpg" class="card-img" alt="">

							<div class="card-img-overlay hover-element d-flex p-3">
								<div class="btn-group mt-auto">
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-facebook-f text-facebook"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-instagram text-instagram"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-twitter text-twitter"></i></a>
								</div>
							</div>
						</div>
						<div class="card-body px-2 pb-0">
							<h5 class="card-title"><a href="#">Larry Lawson</a></h5>
							<span>Editor in Chief</span>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card card-element-hover bg-transparent">
						<div class="position-relative">
							<img src="assets/images/team/04.jpg" class="card-img" alt="">

							<div class="card-img-overlay hover-element d-flex p-3">
								<div class="btn-group mt-auto">
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-facebook-f text-facebook"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-instagram text-instagram"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-twitter text-twitter"></i></a>
								</div>
							</div>
						</div>
						<div class="card-body px-2 pb-0">
							<h5 class="card-title"><a href="#">Louis Ferguson</a></h5>
							<span>Director Graphics</span>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card card-element-hover bg-transparent">
						<div class="position-relative">
							<img src="assets/images/team/05.jpg" class="card-img" alt="">

							<div class="card-img-overlay hover-element d-flex p-3">
								<div class="btn-group mt-auto">
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-facebook-f text-facebook"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-instagram text-instagram"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-twitter text-twitter"></i></a>
								</div>
							</div>
						</div>
						<div class="card-body px-2 pb-0">
							<h5 class="card-title"><a href="#">Louis Crawford</a></h5>
							<span>Editor, Coverage</span>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card card-element-hover bg-transparent">
						<div class="position-relative">
							<img src="assets/images/team/06.jpg" class="card-img" alt="">

							<div class="card-img-overlay hover-element d-flex p-3">
								<div class="btn-group mt-auto">
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-facebook-f text-facebook"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-instagram text-instagram"></i></a>
									<a href="#" class="btn btn-white mb-0"><i class="fa-brands fa-twitter text-twitter"></i></a>
								</div>
							</div>
						</div>
						<div class="card-body px-2 pb-0">
							<h5 class="card-title"><a href="#">Frances Guerrero</a></h5>
							<span>CEO, Coverage</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> -->
</template>
<script>
    export default {
        mounted() {
            window.scrollTo(0, 0);
        },
    };
</script>
<style scoped>
img {
    max-width: 100%;
    height: auto;
}
.bg-mode {
    background: #fff !important;
}
.rounded-2 {
    border-radius: 0.5rem !important;
}
.h-20px {
    height: 20px !important;
}
.mb-5 {
    margin-bottom: 3rem !important;
}
.rounded-3 {
    border-radius: 1rem !important;
}
/* @media (min-width: 1200px) {
    h3{
        font-size: 2.3rem;
    }
} */
</style>
