<template>
    <section class="pt-3 gray-simple" >
        <div class="container">
            <!-- Bus Booking Details -->
            <div class="row g-2 g-lg-4" v-if="traveller_type=='Bus'">
                <!-- BOOKING INFORMATION -->
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        BOOKING INFORMATION
                    </span>

                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div style=" border-radius: 16px; padding: 20px;">
                                    <div class="d-md-flex justify-content-md-between align-items-center">
                                        <div class="d-flex align-items-center">
                                            <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-bus"></i></div>
                                            <div class="ms-2">
                                                <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">Travelling Information</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-3 pt-2 align-items-center">                                        
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                            <div style="padding: 15px 0px 15px 15px;  border-radius: 16px; border: 1px dashed; height: 100px;">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                        {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin }}
                                                    </span> 
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span style="font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color: black;">
                                                        {{$formatDate(busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.DepartureTime, 'long')}} - 
                                                        {{$formatDate(busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.DepartureTime, 'time')}}
                                                    </span> 
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" >
                                            <div style="padding: 15px 0px 15px 15px;  border-radius: 16px; border: 1px dashed; height: 100px;">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                        {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination }}
                                                    </span>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span style="font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color: black;">
                                                        {{$formatDate(busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.ArrivalTime, 'long')}} - 
                                                        {{$formatDate(busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.ArrivalTime, 'time')}}
                                                    </span> 
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" >
                                            <div style="padding: 15px 0px 15px 15px; border-radius: 16px; border: 1px dashed; height: 100px;">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span  style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">Traveller's</span>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                        {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TravelName }}
                                                    </span>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <p style="font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color: black;">
                                                        {{busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusType}}
                                                    </p>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-3 align-items-center" style="padding-top: 15px;">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div style="padding: 15px; border-radius: 16px; border: 1px dashed;">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <span style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">Bus Details</span>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                            Ticket No: {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TicketNo }}
                                                        </span> | 
                                                        <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                            BusId: {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId }}
                                                        </span> | 
                                                        <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                            Passangers: {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Passenger.length }}
                                                        </span> 
                                                    </div>                                                                                                    
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">                                                    
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <span style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">Bording Point Details</span>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                            Address: {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointAddress }},
                                                            {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointLandmark }}, 
                                                            {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointLocation }}, 
                                                            {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointName }}
                                                        </span>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 row">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                Contact Number:  {{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointContactNumber }}
                                                            </span>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" style="text-align: right;" v-if="busBooking?.booking_status == 'Cancelled'">
                                                            <span style="color:  #ce3426;">
                                                                Ticket Cancelled
                                                            </span>                                                            
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" style="text-align: right;" v-else-if="bus_upcomming==true">
                                                            <button class="btn btn mb-0"  data-bs-toggle="modal" data-bs-target="#BusCancelationModal" type="button" style="background-color: #ce3426; color: white; height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;">
                                                                Cancel Ticket
                                                            </button>                                                          
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" style="text-align: right;" v-else-if="bus_upcomming==false">
                                                            <span style="color: green; height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;">
                                                                Trip Is Completed
                                                            </span>                                                          
                                                        </div>
                                                        
                                                    </div>
                                                </div>                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               

                <!-- PRICE SUMMARY -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" v-if="busBooking?.booking_status != 'Cancelled'">
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        PRICE SUMMARY
                    </span>
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body">
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Base Price</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.CurrencyCode }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.BasePrice }}</span>
                                        </div>
                                                                                
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Tax</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.CurrencyCode }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.Tax }}</span>
                                        </div>     

                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Other Charges</span>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.CurrencyCode }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.OtherCharges }}</span>
                                        </div>
                                        <div  class="pt-2">
                                            <hr>
                                        </div>
                                        
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Total Amount</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.CurrencyCode }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.PublishedPrice }}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" v-else>
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        PRICE SUMMARY
                    </span>
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body">
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Total Price</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.CurrencyCode }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ bus_cancellation_brakup.TotalPrice }}</span>
                                        </div>
                                                                                
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Cancellation Charge</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.CurrencyCode }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ bus_cancellation_brakup.CancellationCharge }}</span>
                                        </div>     
                                        
                                        <div  class="pt-2">
                                            <hr>
                                        </div>
                                        
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;"><b>Refundable Amount</b></span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">                                            
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;"><b>{{ busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Price?.CurrencyCode }}</b></span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;"><b>{{ bus_cancellation_brakup.RefundedAmount }}</b></span>                                        
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Passengers Info -->
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8" v-if="busBooking?.booking_status != 'Cancelled'">
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        Passengers
                    </span>
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row" style="font-size: 16px;font-weight: 600;line-height: 20px;text-align: left;">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            Name
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            Seat
                                        </div>
                                    </div>
                                    <div class="row pt-2" v-for="pass, key in busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Passenger">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <i class="bi bi-person" style="width: 20px; height: 20px;"></i>&nbsp;
                                            <span style="font-size: 14px;font-weight: 500;line-height: 20px;text-align: left;"> {{ pass.FirstName }}&nbsp;{{ pass.LastName }}</span>&nbsp;&nbsp;
                                            <span style="font-size: 10px;font-weight: 400;line-height: 12.1px;text-align: left;"> {{ pass.Age }} yrs, &nbsp;{{ pass.Gender == 1 ? 'Male' : 'Female' }}</span>    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" style="font-size: 14px;font-weight: 400;line-height: 20px;text-align: left; color: #4D4D4D;">
                                            <img class="img-fluid" src="images/bus_seats.svg" alt="image" />
                                            <span v-if="pass.Seat.SeatType==1">
                                                Seat &nbsp; {{ pass.Seat.SeatName }}
                                            </span>
                                            <span v-else-if="pass.Seat.SeatType==2">
                                                Sleeper &nbsp; {{ pass.Seat.SeatName }}
                                            </span>
                                            <span v-else-if="pass.Seat.SeatType==3">
                                                Seat Cum Sleeper &nbsp; {{ pass.Seat.SeatName }}
                                            </span>
                                            <span v-else-if="pass.Seat.SeatType==4">
                                                Upper Berth &nbsp; {{ pass.Seat.SeatName }}
                                            </span>
                                            <span v-else-if="pass.Seat.SeatType==5">
                                                Lower Berth &nbsp; {{ pass.Seat.SeatName }}
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  CONTACT INFORMATION -->
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                            CONTACT INFORMATION
                        </span>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <span style="font-size: 12px;font-weight: 400;line-height: 14.52px;text-align: left;">
                            Bus operator or our services experts might connect with you on below contact details.
                        </span>
                    </div>                    
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row pt-2" style="color:#4D4D4D;" v-for="pass, key in busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Passenger" :key="key">
                                        <div v-if="pass.LeadPassenger == true">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <i class="bi bi-person" style="width: 24px; height: 24px; padding:16px;"></i>
                                                <span style="font-size: 16px;font-weight: 500;line-height: 20px;text-align: left;"> {{ pass.FirstName }}&nbsp;{{ pass.LastName }}</span> 
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <i class="bi bi-telephone" style="width: 24px; height: 24px; padding:16px;"></i>
                                                <span style="font-size: 16px;font-weight: 500;line-height: 20px;text-align: left;"> {{ pass.Email }}</span>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <i class="bi bi-envelope" style="width: 24px; height: 24px; padding:16px;"></i>
                                                <span style="font-size: 16px;font-weight: 500;line-height: 20px;text-align: left;"> {{ pass.Phoneno }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>

            <!-- Flight  Booking Details -->
            <div class="row g-2 g-lg-4" v-if="traveller_type=='Flight'">
                <!-- BOOKING INFORMATION -->
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        BOOKING INFORMATION
                    </span>

                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                        <div class="d-md-flex justify-content-md-between align-items-center" style="padding-bottom: 20px;">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-plane"></i></div>
                                                <div class="ms-2">
                                                    <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">Travelling Information</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px; ">
                                            <div  class="pt-3" style="padding: 20px;">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                    <span> BookingId: {{ flightOB?.Response?.BookingId }} | </span>
                                                    <span> PNR: {{ flightOB?.Response?.PNR }} | </span>
                                                    <span> Passangers: {{ flightOB?.Response?.FlightItinerary?.Passenger.length }} | </span>
                                                    <span> {{ printClass(flightOB?.Response?.FlightItinerary?.Segments[0].CabinClass) }} | </span>
                                                    <span v-if="flightOB?.Response?.FlightItinerary?.JourneyType == 1"> One Way Trip</span> 
                                                    <span v-if="flightOB?.Response?.FlightItinerary?.JourneyType == 2"> Return Trip</span> 
                                                    <span v-if="flightOB?.Response?.FlightItinerary?.JourneyType == 3"> Multi City Trip</span> 
                                                    <span v-if="flightOB?.Response?.FlightItinerary?.JourneyType == 5"> Special Return</span> 
                                                </div>
                                                <!-- <div v-if="flightOB?.Response?.FlightItinerary?.Segments.length > 1">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                        {{ flightOB?.Response?.FlightItinerary?.Segments[0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ flightOB?.Response?.FlightItinerary?.Segments[1].Destination.Airport.CityName }} 																		
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
                                                        <span>{{ $formatDate(flightOB?.Response?.FlightItinerary?.Segments[0].Origin.DepTime,'long') }} | </span> 
                                                        <span> 1 Stop | </span>
                                                        <span> {{ Math.floor(flightOB?.Response?.FlightItinerary?.Segments[1].AccumulatedDuration / 60) }}H {{ flightOB?.Response?.FlightItinerary?.Segments[1].AccumulatedDuration % 60 }}M | </span>																		
                                                        <span> {{ printClass(flightOB?.Response?.FlightItinerary?.Segments[0].CabinClass) }} | </span>
                                                        <span> PNR: {{ flightOB?.Response?.PNR }} | </span>
                                                        <span> BookingId: {{ flightOB?.Response?.BookingId }} | </span>
                                                        <span> Passangers: {{ flightOB?.Response?.FlightItinerary?.Passenger.length }} </span> 
                                                    </div>
                                                </div> -->
                                                <!-- <div v-else>
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                        {{ flightOB?.Response?.FlightItinerary?.Segments[0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ flightOB?.Response?.FlightItinerary?.Segments[0].Destination.Airport.CityName }} 																		
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
                                                        <span>{{ $formatDate(flightOB?.Response?.FlightItinerary?.Segments[0].Origin.DepTime,'long') }} | </span> 
                                                        <span> Non Stop | </span>
                                                        <span v-if="flightOB?.Response?.FlightItinerary?.Segments[0].AccumulatedDuration">
                                                            {{ Math.floor(flightOB?.Response?.FlightItinerary?.Segments[0].AccumulatedDuration / 60) }}H {{ flightOB?.Response?.FlightItinerary?.Segments[0].AccumulatedDuration % 60 }}M | 
                                                        </span>
                                                        <span v-else>
                                                            {{ Math.floor(flightOB?.Response?.FlightItinerary?.Segments[0].Duration / 60) }}H {{ flightOB?.Response?.FlightItinerary?.Segments[0].Duration % 60 }}M |
                                                        </span>																		
                                                        <span> {{ printClass(flightOB?.Response?.FlightItinerary?.Segments[0].CabinClass) }} | </span>
                                                        <span> PNR: {{ flightOB?.Response?.PNR }} | </span>
                                                        <span> BookingId: {{ flightOB?.Response?.BookingId }} | </span>
                                                        <span> Passangers: {{ flightOB?.Response?.FlightItinerary?.Passenger.length }} </span> 
                                                    </div>
                                                </div> -->

                                                <div v-for="segment, index in  flightOB?.Response?.FlightItinerary?.Segments" :key="index" class="pt-4" >																		
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
                                                        <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                        &nbsp;
                                                        <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            {{ segment?.Origin?.Airport?.CityName }}
                                                        </span>
                                                        <i class="bi bi-arrow-right"></i>
                                                        <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            {{ segment?.Destination?.Airport?.CityName }} | 
                                                        </span>
                                                        <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            {{ segment.Airline.AirlineName }} | 
                                                        </span> 
                                                        <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">
                                                            {{ segment.Airline.AirlineCode }}-{{ segment.Airline.FlightNumber }} 
                                                        </span>
                                                    </div>
                                                    
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
                                                        <div class="row" style="align-items: center; ">
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 14px; font-weight: 500; line-height:14px">
                                                                        {{ $formatDate(segment.Origin.DepTime,'time') }}
                                                                    </span>
                                                                </div>	
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:14px">
                                                                        {{ $formatDate(segment.Origin.DepTime,'long') }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:16px">
                                                                        {{ segment.Origin.Airport.AirportName }}, {{ segment.Origin.Airport.Terminal ? 'Terminal '+segment.Origin.Airport.Terminal : ''}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
                                                                <div class="text-center">
                                                                    <div> 
                                                                        {{ Math.floor(segment.Duration / 60) }}H {{ segment.Duration % 60 }}M 
                                                                    </div>
                                                                    <div class="flightLine departure">
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 14px; font-weight: 500; line-height:14px">
                                                                        {{ $formatDate(segment.Destination.ArrTime,'time') }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:14px">
                                                                        {{ $formatDate(segment.Destination.ArrTime,'long') }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:16px">
                                                                        {{ segment.Destination.Airport.AirportName }},  {{ segment.Destination.Airport.Terminal ? 'Terminal '+segment.Destination.Airport.Terminal : ''}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row justify-content-center" style="align-items:center; padding-top:24px; padding-bottom:24px">
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            <span>Baggage: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">Adult</span>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            <span>Check-In: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.Baggage ? segment.Baggage : '-' }}</span>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            <span>Cabin: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.CabinBaggage ? segment.CabinBaggage : '-' }}</span>
                                                        </div>																		
                                                    </div>

                                                    <div class="row pt-3 justify-content-center" style="align-items:center" v-if="segment?.length>1 && index!=segment?.length-1">
                                                        <div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
                                                            padding:6px !important; font-weight:500; font-size:12px;">
                                                            <span style="color:#5143D9">Change of Planes | </span>
                                                            <span>																				
                                                                {{ calculateLayover(segment[index]?.Destination?.ArrTime, segment[index+1]?.Origin?.DepTime) }}
                                                                Layover in 
                                                                {{ segment[index+1]?.Origin?.Airport?.CityName }}
                                                            </span>
                                                        </div>																		
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-3 justify-content-center" style="align-items:center" v-if="flightIB?.Response?.FlightItinerary?.Segments">
                                    <div class="text-center col-xl-3 col-lg-3 col-md-3 col-sm-3" style="border:1px dashed black; border-radius:48px; 
                                        padding:6px !important; font-weight:500; font-size:12px;">                                                
                                        <span>
                                            Return Flight
                                        </span>
                                    </div>																		
                                </div>
                                <div v-if="flightIB?.Response?.FlightItinerary?.Segments" class="row pt-3">
                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                        <div style="gap: 0px; border-radius: 16px; border: 1px dashed; opacity: 0px;">
                                            <div class="pt-3" style="padding: 20px;">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                    <span> BookingId: {{ flightIB?.Response?.BookingId }} | </span>
                                                    <span> PNR: {{ flightIB?.Response?.PNR }} | </span>
                                                    <span> Passangers: {{ flightIB?.Response?.FlightItinerary?.Passenger.length }} | </span>
                                                    <span> {{ printClass(flightIB?.Response?.FlightItinerary?.Segments[0].CabinClass) }} | </span>
                                                    <span v-if="flightIB?.Response?.FlightItinerary?.JourneyType == 1"> One Way Trip</span> 
                                                    <span v-if="flightIB?.Response?.FlightItinerary?.JourneyType == 2"> Return Trip</span> 
                                                    <span v-if="flightIB?.Response?.FlightItinerary?.JourneyType == 3"> Multi City Trip</span> 
                                                    <span v-if="flightIB?.Response?.FlightItinerary?.JourneyType == 5"> Special Return</span> 
                                                </div>

                                                <!-- <div v-if="flightIB?.Response?.FlightItinerary?.Segments.length > 1">
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                        {{ flightIB?.Response?.FlightItinerary?.Segments[0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ flightIB?.Response?.FlightItinerary?.Segments[1].Destination.Airport.CityName }} 																		
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
                                                        <span>{{ $formatDate(flightIB?.Response?.FlightItinerary?.Segments[0].Origin.DepTime,'long') }} | </span> 
                                                        <span> 1 Stop | </span>
                                                        <span> {{ Math.floor(flightIB?.Response?.FlightItinerary?.Segments[1].AccumulatedDuration / 60) }}H {{ flightIB?.Response?.FlightItinerary?.Segments[1].AccumulatedDuration % 60 }}M | </span>																		
                                                        <span> {{ printClass(flightIB?.Response?.FlightItinerary?.Segments[0].CabinClass) }} | </span>
                                                        <span> PNR: {{ flightIB?.Response?.PNR }} | </span>
                                                        <span> BookingId: {{ flightIB?.Response?.BookingId }} | </span>
                                                        <span> Passangers: {{ flightIB?.Response?.FlightItinerary?.Passenger.length }} </span> 
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                        {{ flightIB?.Response?.FlightItinerary?.Segments[0].Origin.Airport.CityName }} <i class="bi bi-arrow-right"></i> {{ flightIB?.Response?.FlightItinerary?.Segments[0].Destination.Airport.CityName }} 																		
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color:#6C757D;">
                                                        <span>{{ $formatDate(flightIB?.Response?.FlightItinerary?.Segments[0].Origin.DepTime,'long') }} | </span> 
                                                        <span> Non Stop | </span>
                                                        <span v-if="flightIB?.Response?.FlightItinerary?.Segments[0].AccumulatedDuration">
                                                            {{ Math.floor(flightIB?.Response?.FlightItinerary?.Segments[0].AccumulatedDuration / 60) }}H {{ flightIB?.Response?.FlightItinerary?.Segments[0].AccumulatedDuration % 60 }}M | 
                                                        </span>
                                                        <span v-else>
                                                            {{ Math.floor(flightIB?.Response?.FlightItinerary?.Segments[0].Duration / 60) }}H {{ flightIB?.Response?.FlightItinerary?.Segments[0].Duration % 60 }}M |
                                                        </span>																		
                                                        <span> {{ printClass(flightIB?.Response?.FlightItinerary?.Segments[0].CabinClass) }} | </span>
                                                        <span> PNR: {{ flightIB?.Response?.PNR }} | </span>
                                                        <span> BookingId: {{ flightIB?.Response?.BookingId }} | </span>
                                                        <span> Passangers: {{ flightIB?.Response?.FlightItinerary?.Passenger.length }} </span> 
                                                    </div>
                                                </div> -->

                                                <div v-for="segment, index in  flightIB?.Response?.FlightItinerary?.Segments" :key="index" class="pt-4" >																		
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
                                                        <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                        &nbsp;
                                                        <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            {{ segment?.Origin?.Airport?.CityName }}
                                                        </span>
                                                        <i class="bi bi-arrow-right"></i>
                                                        <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            {{ segment?.Destination?.Airport?.CityName }} | 
                                                        </span>
                                                        <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                            {{ segment.Airline.AirlineName }} | 
                                                        </span> 
                                                        <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">
                                                            {{ segment.Airline.AirlineCode }}-{{ segment.Airline.FlightNumber }} 
                                                        </span>
                                                    </div>
                                                    
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-3">
                                                        <div class="row" style="align-items: center; ">
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 14px; font-weight: 500; line-height:14px">
                                                                        {{ $formatDate(segment.Origin.DepTime,'time') }}
                                                                    </span>
                                                                </div>	
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:14px">
                                                                        {{ $formatDate(segment.Origin.DepTime,'long') }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:16px">
                                                                        {{ segment.Origin.Airport.AirportName }}, {{ segment.Origin.Airport.Terminal ? 'Terminal '+segment.Origin.Airport.Terminal : ''}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pb-3" >
                                                                <div class="text-center">
                                                                    <div> 
                                                                        {{ Math.floor(segment.Duration / 60) }}H {{ segment.Duration % 60 }}M 
                                                                    </div>
                                                                    <div class="flightLine departure">
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 14px; font-weight: 500; line-height:14px">
                                                                        {{ $formatDate(segment.Destination.ArrTime,'time') }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:14px">
                                                                        {{ $formatDate(segment.Destination.ArrTime,'long') }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <span style="font-size: 12px; font-weight: 400; line-height:16px">
                                                                        {{ segment.Destination.Airport.AirportName }},  {{ segment.Destination.Airport.Terminal ? 'Terminal '+segment.Destination.Airport.Terminal : ''}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row justify-content-center" style="align-items:center; padding-top:24px; padding-bottom:24px">
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                            <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">Baggage: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">Adult</span>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                            <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">Check-In: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.Baggage ? segment.Baggage : '-' }}</span>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                            <span style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">Cabin: </span> <span style="font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;">{{ segment.CabinBaggage ? segment.CabinBaggage : '-' }}</span>
                                                        </div>																		
                                                    </div>

                                                    <div class="row pt-3 justify-content-center" style="align-items:center" v-if=" flightIB?.Response?.FlightItinerary?.Segments[0].length>1 && index==0">
                                                        <div class="text-center col-xl-5 col-lg-5 col-md-5 col-sm-5" style="border:1px dashed black; border-radius:48px; 
                                                            padding:6px !important; font-weight:500; font-size:12px;">
                                                            <span style="color:#5143D9">Change of Planes | </span>
                                                            <span>
                                                                {{ Math.floor(( flightIB?.Response?.FlightItinerary?.Segments[1].AccumulatedDuration - ( flightIB?.Response?.FlightItinerary?.Segments[0].Duration +  flightIB?.Response?.FlightItinerary?.Segments[1].Duration))  / 60) }}H 
                                                                {{ ( flightIB?.Response?.FlightItinerary?.Segments[1].AccumulatedDuration - ( flightIB?.Response?.FlightItinerary?.Segments[0].Duration +  flightIB?.Response?.FlightItinerary?.Segments[1].Duration)) % 60 }}M
                                                                Layover in 
                                                                {{ segment.Destination.Airport.CityName }}
                                                            </span>
                                                        </div>																		
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12" style="padding-top: 10px;">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="text-align: right;" v-if="flightBooking?.booking_status == 'Cancelled'">
                                        <span style="color:  #ce3426;">
                                            Ticket Cancelled
                                        </span>                                                            
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="text-align: right;" v-else-if="flight_upcomming==true">
                                        <button class="btn btn mb-0"  data-bs-toggle="modal" data-bs-target="#FlightCancelationModal" type="button" style="background-color: #ce3426; color: white; height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;">
                                            Cancel Ticket
                                        </button>                                                          
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="text-align: right;" v-else-if="flight_upcomming==false">
                                        <span style="color: green; height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;">
                                            Trip Is Completed
                                        </span>                                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>               

                <!-- PRICE SUMMARY -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" v-if="flightBooking?.booking_status != 'Cancelled'">
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        PRICE SUMMARY
                    </span>
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body">
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">                                                                                                                                                            
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Base Price</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_summary.base_price }}</span>
                                        </div>
                                                                                
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Tax</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_summary.tax }}</span>
                                        </div>     

                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Other Charges</span>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_summary.other_charges }}</span>
                                        </div>

                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 pt-2" v-if="flight_price_summary.baggage_charges != 0">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Baggage Charges</span>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-2" style="text-align: right;" v-if="flight_price_summary.baggage_charges != 0">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_summary.baggage_charges }}</span>
                                        </div>

                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 pt-2" v-if="flight_price_summary.meal_charges != 0">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Meal Charges</span>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-2" style="text-align: right;" v-if="flight_price_summary.meal_charges != 0">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_summary.meal_charges }}</span>
                                        </div>

                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 pt-2" v-if="flight_price_summary.seat_charges != 0">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Seat Charges</span>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-2" style="text-align: right;" v-if="flight_price_summary.seat_charges != 0">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_summary.seat_charges }}</span>
                                        </div>
                                        <div  class="pt-2">
                                            <hr>
                                        </div>
                                        
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Total Amount</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ total_flight_price }}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" v-else>
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        PRICE SUMMARY
                    </span>
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body">
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Total Price</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_cancellation_brakup.TotalPrice }}</span>
                                        </div>
                                                                                
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">Cancellation Charge</span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_price_currency }}</span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;">{{ flight_cancellation_brakup.CancellationCharge }}</span>
                                        </div>     
                                        
                                        <div  class="pt-2">
                                            <hr>
                                        </div>
                                        
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                                            <span style="color: #4D4D4D; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;"><b>Refundable Amount</b></span>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 pt-2" style="text-align: right;">                                            
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;"><b>{{ flight_price_currency }}</b></span>&nbsp;
                                            <span style="color:#000000; font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;"><b>{{ flight_cancellation_brakup.RefundedAmount }}</b></span>                                        
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Passengers Info -->
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8" v-if="flightBooking?.booking_status != 'Cancelled'">
                    <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                        Passengers
                    </span>
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div class="" style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row " style="font-size: 16px;font-weight: 600;line-height: 20px;text-align: left;">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                            Name
                                        </div>                                        
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                            Seat
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                            Flight Route
                                        </div>
                                    </div>
                                    <div class="row pt-2 align-items-center" v-for="pass, key in flightOB?.Response?.FlightItinerary?.Passenger">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                            <i class="bi bi-person" style="width: 20px; height: 20px;"></i>&nbsp;
                                            <span style="font-size: 14px;font-weight: 500;line-height: 20px;text-align: left;"> {{ pass.Title }}&nbsp;{{ pass.FirstName }}&nbsp;{{ pass.LastName }}</span>&nbsp;&nbsp;
                                            <span style="font-size: 10px;font-weight: 400;line-height: 12.1px;text-align: left;"> {{ calculateAge(pass.DateOfBirth) }} yrs, &nbsp;{{ pass.Gender == 1 ? 'Male' : 'Female' }}</span>    
                                        </div>                                        
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 row" v-if="pass?.SeatDynamic" style="font-size: 14px;font-weight: 400;line-height: 20px;text-align: left; color: #4D4D4D;">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pb-2" v-for="seat, s in pass?.SeatDynamic">
                                                <img class="img-fluid" src="images/bus_seats.svg" alt="image" /> &nbsp;
                                                <span>
                                                    <b>Deck:</b> {{ seat?.Deck }}, &nbsp;
                                                    <b>Code:</b> {{ seat?.Code }}, &nbsp;
                                                    <b>RowNo:</b> {{ seat?.RowNo }}, &nbsp;
                                                    <b>SeatNo:</b> {{ seat?.SeatNo }}, 
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" v-else style="font-size: 14px;font-weight: 400;line-height: 20px;text-align: left; color: #4D4D4D;">
                                            <img class="img-fluid" src="images/bus_seats.svg" alt="image" /> &nbsp;
                                            <span>
                                                No Seat Allocated
                                            </span>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4" v-if="pass?.SeatDynamic" style="font-size: 14px;font-weight: 400;line-height: 20px;text-align: left; color: #4D4D4D;">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pb-3" v-for="seat, s in pass?.SeatDynamic" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                                <span>
                                                    {{ seat?.Origin }} <i class="bi bi-arrow-right"></i>{{ seat?.Destination }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  CONTACT INFORMATION -->
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <span style="font-size: 18px;font-weight: 600;line-height: 20px;text-align: left;color: #1C1B1F;">
                            CONTACT INFORMATION
                        </span>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <span style="font-size: 12px;font-weight: 400;line-height: 14.52px;text-align: left;">
                            Flight staff or our services experts might connect with you on below contact details.
                        </span>
                    </div>                    
                    <div class="pt-3">
                        <div class="card border" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="row pt-2" style="color:#4D4D4D;" v-for="pass, key in flightOB?.Response?.FlightItinerary?.Passenger" :key="key">
                                        <div v-if="pass.IsLeadPax == true">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <i class="bi bi-person" style="width: 24px; height: 24px; padding:16px;"></i>
                                                <span style="font-size: 16px;font-weight: 500;line-height: 20px;text-align: left;">{{ pass.Title }}&nbsp;{{ pass.FirstName }}&nbsp;{{ pass.LastName }}</span> 
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <i class="bi bi-telephone" style="width: 24px; height: 24px; padding:16px;"></i>
                                                <span style="font-size: 16px;font-weight: 500;line-height: 20px;text-align: left;"> {{ pass.Email }}</span>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <i class="bi bi-envelope" style="width: 24px; height: 24px; padding:16px;"></i>
                                                <span style="font-size: 16px;font-weight: 500;line-height: 20px;text-align: left;"> {{ pass.ContactNo }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bus Cancelation Modal -->
            <div class="modal fade" id="BusCancelationModal" tabindex="-1" aria-labelledby="BusCancelationModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="BusCancelationModalLabel">Cancelation Policies</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="bus_cancelation"></button>
                        </div>
                        <div class="modal-body">
                            <div class="d-md-flex justify-content-md-center align-items-center">
                                <div class="d-flex align-items-center"> 
                                    <table class="table table-striped" style="border: 1px solid black">
                                        <tr style="border:1px solid black;padding:5px">
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">Cancellation Time</td>
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">Charges</td>
                                        </tr>                                    	
                                        <tr style="border:1px solid black;padding:5px" v-for="policy, key in busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.CancelPolicy">
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">{{ policy.PolicyString }}</td>
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">{{ policy.CancellationCharge }}%</td>
                                        </tr>
                                    </table>
                                </div>                                
                            </div>
                            <div class="d-md-flex justify-content-md-center align-items-center">
                                <button class="btn btn mb-0" @click="cancelPolicies(busBooking, 'bus', 'bus_cancelation')" type="button" style="background-color: #ce3426; color: white; height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;">
                                    Cancel Ticket
                                </button>
                            </div>
                        </div>
                        <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- Flight Cancelation Modal -->
            <div class="modal fade" id="FlightCancelationModal" tabindex="-1" aria-labelledby="FlightCancelationModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="FlightCancelationModalLabel">Cancelation Policies</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="flight_cancelation"></button>
                        </div>
                        <div class="modal-body">
                            <div class="d-md-flex justify-content-md-center align-items-center">
                                <div class=""> 
                                    <!-- <table class="table table-striped" style="border: 1px solid black">
                                        <tr style="border:1px solid black;padding:5px">
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">Cancellation Time</td>
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">Charges</td>
                                        </tr>                                    	
                                        <tr style="border:1px solid black;padding:5px" v-for="policy, key in busBooking?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.CancelPolicy">
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">{{ policy.PolicyString }}</td>
                                            <td style="text-align:left;padding:10px;border-right:1px solid black">{{ policy.CancellationCharge }}%</td>
                                        </tr>
                                    </table> -->
                                    <div v-for="ob, key in flightOB?.Response?.FlightItinerary?.FareRules" :key="key" style="padding-top: 20px;">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                            {{ ob?.Origin }} <i class="bi bi-arrow-right"></i> {{ ob?.Destination }} 																		
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding-top: 10px;">
                                            <div v-html="ob?.FareRuleDetail"></div>
                                        </div>                                        
                                    </div>
                                    <div v-for="ib, key in flightIB?.Response?.FlightItinerary?.FareRules" :key="key"  style="padding-top: 20px;">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="font-size: 14px; font-weight: 500; line-height: 16px; text-align: left;">
                                            {{ ib?.Origin }} <i class="bi bi-arrow-right"></i> {{ ib?.Destination }} 																		
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding-top: 10px;">
                                            <div v-html="ib?.FareRuleDetail"></div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div class="d-md-flex justify-content-md-center align-items-center">
                                <button class="btn btn mb-0" @click="cancelPolicies(flightBooking, 'flight', 'flight_cancelation')" type="button" style="background-color: #ce3426; color: white; height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;">
                                    Cancel Ticket
                                </button>
                            </div>
                        </div>
                        <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
 import moment from "moment";
 export default {
    data(){
        return {
            booking_id:'',
            traveller_type:'',
            busBooking:[],                        
            busCancelation:{
                EndUserIp:'',
                TokenId:'',
                RequestType:11,
                BusId:'',
                AgencyId:57662,
                booking_id:'',
                Remarks:'Personal Reason',
            },
            bus_cancellation_brakup:{
                TotalPrice:0,
                RefundedAmount:0,
                CancellationCharge:0,
            },
            bus_upcomming: true,
            
            flightBooking:[],
            flightOB:[],
            flightIB:[],
            flight_price_summary:{
                base_price:'',
                tax:'',
                other_charges:'',
                baggage_charges:'',
                meal_charges:'',
                seat_charges:'',
                OfferedFare:'',
                TdsOnCommission:'',
                TdsOnIncentive:'',
                TdsOnPLB:'',
            },
            flight_price_currency: '',
            total_flight_price: '',
            flight_upcomming: true,
            flightCancelation:{
                EndUserIp:'',
                TokenId:'',
                RequestType:1,
                BookingId:'',
                booking_id:'',
                CancellationType:3, //full cancellation
                Remarks:'Personal Reason',
                flight_type:'',
                flights:'',
                Sectors:"",
                TicketId:"",
                insurance_booking_id:'',
            },
            flight_cancellation_brakup:{
                TotalPrice:0,
                RefundedAmount:0,
                CancellationCharge:0,
            },
            errors:[],

        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.busCancelation.EndUserIp = this.$store.getters.bus_search?.EndUserIp
        this.busCancelation.TokenId = this.$store.getters.bus_search?.TokenId

        this.flightCancelation.EndUserIp = this.$store.getters.flight_meta?.EndUserIp
        this.flightCancelation.TokenId = this.$store.getters.flight_meta?.TokenId
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.booking_id = to.params.booking_id;
            vm.traveller_type = to.params.traveller_type;
            // console.log('traveller_type:---', vm.traveller_type)
            vm.index(vm.booking_id)
            
        });
    },    
    methods:{
        changeFormatdate(date) {
            return moment(String(date)).format("DD-MM-YYYY HH:mm:ss");
        },

        // Bus Related Code
        cancelPolicies(details,ticket_type, modal_id){
            if (confirm('Are you sure you want to Cancel your Ticket?')) {
                if(ticket_type=='bus'){
                    this.cancelationBus(details)
                }
                if(ticket_type=='flight'){
                    this.cancelationFlight(details)
                }
                
                // console.log('Your Ticket Canceled Successfully.');
                document.getElementById(modal_id).click()
            } else {
                document.getElementById(modal_id).click()
            }
        },

        cancelationBus(details){
            let vm = this;
            let loader = vm.$loading.show();
            vm.busCancelation.booking_id = details?.booking_id;
            vm.busCancelation.BusId = details?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId;
            vm.$store.dispatch("post", { uri: "bus/busCancellationRequest", data: vm.busCancelation })
            .then(function (response) {            
                loader.hide();            
                vm.$store.dispatch("success", response.data?.message);
                vm.$router.push('/bookings')
            })
            .catch(function (error) {
                loader.hide();
                if(error.response.data.errors){
                    vm.errors = error?.response?.data?.errors;
                }
                vm.$store.dispatch("error", error?.response?.data?.message);
            });
        },

        index(booking_id){
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "getBooking", data:{ 'booking_id': booking_id } })
            .then(function (response) {            
                loader.hide();                
                if(response.data?.message== "Booking Detail Not Found" || vm.traveller_type != response.data?.data?.booking_type){
                    vm.$router.push('/bookings')
                }
                if(response.data?.data?.booking_type == "Bus"){
                    vm.busBooking = response.data?.data;               
                    vm.busBooking?.cancellation_breakup?.GetChangeRequestStatusResult?.BusCRInfo?.map(function (ele){
                        vm.bus_cancellation_brakup.TotalPrice = vm.bus_cancellation_brakup.TotalPrice + Number(ele.TotalPrice);
                        vm.bus_cancellation_brakup.RefundedAmount = vm.bus_cancellation_brakup.RefundedAmount + Number(ele.RefundedAmount);
                        vm.bus_cancellation_brakup.CancellationCharge = vm.bus_cancellation_brakup.CancellationCharge + Number(ele.CancellationCharge);
                    });
                    if(vm.busBooking.booking_status == 'Completed'){
                        vm.bus_upcomming = false
                    }
                    
                }
                if(response.data?.data?.booking_type == "Flight"){
                    // console.log("Flight");
                    vm.flightBooking = response.data?.data;
                    vm.flightOB = response.data?.data?.ob_ticket_response;
                    vm.flightIB = response.data?.data?.ib_ticket_response;
                    
                    vm.flight_price_currency = vm.flightOB?.Response?.FlightItinerary?.Fare?.Currency;
                    vm.flight_price_summary.base_price = vm.flightOB?.Response?.FlightItinerary?.Fare?.BaseFare;
                    vm.flight_price_summary.tax = vm.flightOB?.Response?.FlightItinerary?.Fare?.Tax;
                    vm.flight_price_summary.other_charges = vm.flightOB?.Response?.FlightItinerary?.Fare?.OtherCharges;
                    vm.flight_price_summary.baggage_charges = vm.flightOB?.Response?.FlightItinerary?.Fare?.TotalBaggageCharges;
                    vm.flight_price_summary.meal_charges = vm.flightOB?.Response?.FlightItinerary?.Fare?.TotalMealCharges;
                    vm.flight_price_summary.seat_charges = vm.flightOB?.Response?.FlightItinerary?.Fare?.TotalSeatCharges;
                    vm.flight_price_summary.OfferedFare = vm.flightOB?.Response?.FlightItinerary?.Fare?.OfferedFare;
                    vm.flight_price_summary.TdsOnCommission = vm.flightOB?.Response?.FlightItinerary?.Fare?.TdsOnCommission
                    vm.flight_price_summary.TdsOnIncentive = vm.flightOB?.Response?.FlightItinerary?.Fare?.TdsOnIncentive
                    vm.flight_price_summary.TdsOnPLB = vm.flightOB?.Response?.FlightItinerary?.Fare?.TdsOnPLB
                    
                    vm.flight_price_summary.base_price = vm.flight_price_summary.base_price + (vm.flightIB?.Response?.FlightItinerary?.Fare?.BaseFare ? vm.flightIB?.Response?.FlightItinerary?.Fare?.BaseFare : 0 );
                    vm.flight_price_summary.tax = vm.flight_price_summary.tax + (vm.flightIB?.Response?.FlightItinerary?.Fare?.Tax ? vm.flightIB?.Response?.FlightItinerary?.Fare?.Tax : 0 );
                    vm.flight_price_summary.other_charges = vm.flight_price_summary.other_charges + (vm.flightIB?.Response?.FlightItinerary?.Fare?.OtherCharges ? vm.flightIB?.Response?.FlightItinerary?.Fare?.OtherCharges : 0 )                    ;
                    vm.flight_price_summary.baggage_charges = vm.flight_price_summary.baggage_charges + (vm.flightIB?.Response?.FlightItinerary?.Fare?.TotalBaggageCharges ? vm.flightIB?.Response?.FlightItinerary?.Fare?.TotalBaggageCharges : 0 );
                    vm.flight_price_summary.meal_charges = vm.flight_price_summary.meal_charges + (vm.flightIB?.Response?.FlightItinerary?.Fare?.TotalMealCharges ? vm.flightIB?.Response?.FlightItinerary?.Fare?.TotalMealCharges : 0 );
                    vm.flight_price_summary.seat_charges = vm.flight_price_summary.seat_charges + (vm.flightIB?.Response?.FlightItinerary?.Fare?.TotalSeatCharges ? vm.flightIB?.Response?.FlightItinerary?.Fare?.TotalSeatCharges : 0 );
                    vm.flight_price_summary.OfferedFare = vm.flight_price_summary.OfferedFare +  (vm.flightIB?.Response?.FlightItinerary?.Fare?.OfferedFare ? vm.flightIB?.Response?.FlightItinerary?.Fare?.OfferedFare : 0 );
                    vm.flight_price_summary.TdsOnCommission = vm.flight_price_summary.TdsOnCommission +  (vm.flightIB?.Response?.FlightItinerary?.Fare?.TdsOnCommission ? vm.flightIB?.Response?.FlightItinerary?.Fare?.TdsOnCommission : 0);
                    vm.flight_price_summary.TdsOnIncentive = vm.flight_price_summary.TdsOnIncentive +  (vm.flightIB?.Response?.FlightItinerary?.Fare?.TdsOnIncentive ? vm.flightIB?.Response?.FlightItinerary?.Fare?.TdsOnIncentive : 0);
                    vm.flight_price_summary.TdsOnPLB = vm.flight_price_summary.TdsOnPLB +  (vm.flightIB?.Response?.FlightItinerary?.Fare?.TdsOnPLB ? vm.flightIB?.Response?.FlightItinerary?.Fare?.TdsOnPLB : 0);

                    vm.total_flight_price = vm.flight_price_summary.base_price + vm.flight_price_summary.tax + vm.flight_price_summary.other_charges + 
                        vm.flight_price_summary.baggage_charges + vm.flight_price_summary.meal_charges + vm.flight_price_summary.seat_charges;

                    if(vm.flightBooking.booking_status == 'Completed'){
                        vm.flight_upcomming = false
                    }

                    if(vm.flightBooking.booking_status == 'Cancelled'){
                        for(let i=0; i<vm.flightBooking.ob_change_status_response.length; i++){
                            vm.flight_cancellation_brakup.CancellationCharge = Math.floor( vm.flight_cancellation_brakup.CancellationCharge + (vm.flightBooking.ob_change_status_response[i]?.CancellationCharge ? vm.flightBooking.ob_change_status_response[i]?.CancellationCharge : 0) )
                            vm.flight_cancellation_brakup.RefundedAmount =  Math.floor( vm.flight_cancellation_brakup.RefundedAmount + (vm.flightBooking.ob_change_status_response[i]?.RefundedAmount ? vm.flightBooking.ob_change_status_response[i]?.RefundedAmount : 0) )                            
                        }
                        if(vm.flightBooking.ib_change_status_response){
                            for(let i=0; i<vm.flightBooking.ib_change_status_response?.length; i++){
                                vm.flight_cancellation_brakup.CancellationCharge = Math.floor( vm.flight_cancellation_brakup.CancellationCharge + (vm.flightBooking.ib_change_status_response[i]?.CancellationCharge ? vm.flightBooking.ib_change_status_response[i]?.CancellationCharge : 0 ) )
                                vm.flight_cancellation_brakup.RefundedAmount =  Math.floor( vm.flight_cancellation_brakup.RefundedAmount + (vm.flightBooking.ib_change_status_response[i]?.RefundedAmount ? vm.flightBooking.ib_change_status_response[i]?.RefundedAmount : 0 ) )
                            }
                        }
                        
                        vm.flight_cancellation_brakup.TotalPrice = Math.floor(vm.flight_price_summary.OfferedFare - 
                            vm.flight_price_summary.other_charges + vm.flight_price_summary.TdsOnCommission + 
                            vm.flight_price_summary.TdsOnIncentive + vm.flight_price_summary.TdsOnPLB);                    
                    }
                    // console.log('flightIB:-----', vm.flight_price_summary);
                }
                
                
            })
            .catch(function (error) {
                // console.log('error:---', error)
                loader.hide();
                if(error.response.data.errors){
                    vm.errors = error?.response?.data?.errors;
                }
                vm.$store.dispatch("error", error?.response?.data?.message);
            });
        },
        

        // Flight Related code
        printClass(cls_value){
            if(cls_value == 1){
                return 'All Class'
            }
            else if(cls_value == 2){
                return 'Economy Class'
            }
            else if(cls_value == 3){
                return 'Premium Economy Class'
            }
            else if(cls_value == 4){
                return 'Business Class'
            }
            else if(cls_value == 5){
                return 'Premium Business Class'
            }
            else if(cls_value == 6){
                return 'First Class'
            }			
        },

        calculateAge(dob){
            let birthMoment = moment(dob, "YYYY-MM-DD");
            let now = moment();
            // Calculate age as a fraction of years
            let age = now.diff(birthMoment, 'years', true);
            let roundedAge = age < 1 ? 0 : Math.floor(age);
            return roundedAge;            
        },

        cancelationFlight(details){
            let vm = this;
            
            if(details.ob_ticket_response && details.ib_ticket_response)
            {   let loader = vm.$loading.show();
                vm.flightCancelation.booking_id = details?.booking_id;
                vm.flightCancelation.BookingId = details?.ob_ticket_response?.Response?.BookingId;            
                vm.flightCancelation.flight_type = 'OB';
                vm.flightCancelation.flights = '2'
                if(details.is_insured){
                    if(details?.insurance_response?.Response?.ResponseStatus==1){
                        vm.flightCancelation.insurance_booking_id = details?.insurance_response?.Response?.Itinerary?.BookingId
                    }                    
                }                
                vm.$store.dispatch("post", { uri: "flights/flightCancellationRequest", data: vm.flightCancelation })
                .then(function (response) {            
                    loader.hide();            
                    vm.$store.dispatch("success", response.data?.message);
                })
                .catch(function (error) {
                    loader.hide();
                    if(error.response.data.errors){
                        vm.errors = error?.response?.data?.errors;
                    }
                    vm.$store.dispatch("error", error?.response?.data?.message);
                });

                let loader1 = vm.$loading.show();
                vm.flightCancelation.booking_id = details?.booking_id;
                vm.flightCancelation.BookingId = details?.ib_ticket_response?.Response?.BookingId;
                vm.flightCancelation.flight_type = 'IB';
                vm.flightCancelation.flights = '2'
                vm.$store.dispatch("post", { uri: "flights/flightCancellationRequest", data: vm.flightCancelation })
                .then(function (response) {            
                    loader1.hide();            
                    vm.$store.dispatch("success", response.data?.message);
                    vm.$router.push('/bookings')
                })
                .catch(function (error) {
                    loader1.hide();
                    if(error.response.data.errors){
                        vm.errors = error?.response?.data?.errors;
                    }
                    vm.$store.dispatch("error", error?.response?.data?.message);
                });
            }             
            else
            {
                let loader = vm.$loading.show();
                vm.flightCancelation.booking_id = details?.booking_id;
                vm.flightCancelation.BookingId = details?.ob_ticket_response?.Response?.BookingId;
                vm.flightCancelation.flight_type = 'OB'
                vm.flightCancelation.flights = '1'
                if(details.is_insured){
                    if(details?.insurance_response?.Response?.ResponseStatus==1){
                        vm.flightCancelation.insurance_booking_id = details?.insurance_response?.Response?.Itinerary?.BookingId
                    }                    
                }      
                // vm.flightCancelation.Sectors = [];
                // let ob_data_seg = details?.ob_ticket_response?.Response?.FlightItinerary?.Segments                
                // for(let i=0; i<ob_data_seg.length; i++){
                //     vm.flightCancelation.Sectors.push({
                //         Origin: ob_data_seg[i]?.Origin?.Airport?.AirportCode,
                //         Destination: ob_data_seg[i]?.Destination?.Airport?.AirportCode
                //     });
                // }
                // vm.flightCancelation.TicketId = [];
                // let ob_data_pas = details?.ob_ticket_response?.Response?.FlightItinerary?.Passenger
                // for(let i=0; i<ob_data_pas.length; i++){
                //     vm.flightCancelation.TicketId.push(ob_data_pas[i]?.Ticket?.TicketId)
                // }

                vm.$store.dispatch("post", { uri: "flights/flightCancellationRequest", data: vm.flightCancelation })
                .then(function (response) {            
                    loader.hide();            
                    vm.$store.dispatch("success", response.data?.message);
                    vm.$router.push('/bookings')
                })
                .catch(function (error) {
                    loader.hide();
                    if(error.response.data.errors){
                        vm.errors = error?.response?.data?.errors;
                    }
                    vm.$store.dispatch("error", error?.response?.data?.message);
                });
            }
                
        }

    },
 }
</script>
