<template>
    <div>
        <section class="pt-4 gray-simple position-relative">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="row align-items-start">
                            <div class="col-xl-8 col-lg-8 col-md-12">
                                <h5 class="fw-bold fs-6 text-uppercase">Property Information</h5>
                                <div class="card p-3 mb-xl-4 mb-lg-4 mb-4">
                                    <div class="card-box list-layout-block border br-dashed rounded-3 p-2">
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-3 col-md">
                                                <div class="cardImage__caps rounded-2 overflow-hidden h-100" v-if="hotel_store?.hotel_details?.Images?.length">
                                                    <img class="img-fluid h-100 object-fit" :src="hotel_store?.hotel_details?.Images[0]" alt="image" />
                                                </div>
                                            </div>
                                            <div class="col-xl col-lg col-md">
                                                <div class="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-inline-block">
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                        </div>
                                                        <div class="square--40 rounded-2 bg-success text-light fw-semibold">4.8</div>
                                                    </div>
                                                    <h4 class="fs-5 fw-bold mb-1">{{  hotel_store.hotel_name }}</h4>
                                                    <ul class="row g-2 p-0">
                                                        <li class="col-auto">
                                                            <span class="text-muted-2 text-md">{{ hotel_store.hotel_details?.Address }}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="row align-items-center justify-content-between g-2 my-4">
                                        <div class="col-4">
                                            <div class="gray rounded-2 p-2 border br-dashed">
                                                <span class="d-block text-muted-3 text-sm fw-medium text-uppercase mb-2">Check-In</span>
                                                <p class="text-dark fw-semibold lh-base text-md mb-0">{{ changeDateFormat(hotel_search.CheckIn) }}</p>
                                                <span class="text-dark text-md">From 14:40</span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-4">
                                            <div class="gray rounded-2 p-2 border br-dashed">
                                                <span class="d-block text-muted-3 text-sm fw-medium text-uppercase mb-2">Check-Out</span>
                                                <p class="text-dark fw-semibold lh-base text-md mb-0">{{ changeDateFormat(hotel_search.CheckOut) }}</p>
                                                <span class="text-dark text-md">By 11:50</span>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-xl-12 col-lg-12 col-md-12 mt-4">
                                        <div class="card border-1 border-secondary br-dashed">
                                            <div class="card-body py-md-2 d-flex flex-column h-100 position-relative">
                                                <div class="d-flex justify-content-between align-items-center" v-if="selected_room?.Name?.length">
                                                    <h6 class="card-title mb-1">{{ selected_room?.Name[0] }}</h6>
                                                    <div class="badge bg-success bg-opacity-10 text-success" v-if="selected_room.IsRefundable">Refundable</div>
                                                </div>
                                                <ul class="nav small nav-divider gap-2 mb-0">
                                                    <li class="nav-item mb-0"><i class="fa-solid fa-bed me-1"></i>{{ hotel_search.NoOfRooms }} Room</li>
                                                    <li class="nav-item mb-0" v-if="hotel_search.NoOfAdults">|</li>
                                                    <li class="nav-item mb-0" v-if="hotel_search.NoOfAdults==1"><i class="fa-solid fa-user me-1"></i>{{ hotel_search.NoOfAdults }} Adult</li>
                                                    <li class="nav-item mb-0" v-if="hotel_search.NoOfAdults>1"><i class="fa-solid fa-user me-1"></i>{{ hotel_search.NoOfAdults }} Adults</li>
                                                    <li class="nav-item mb-0" v-if="hotel_search.NoOfChild">|</li>
                                                    <li class="nav-item mb-0" v-if="hotel_search.NoOfChild==1"><i class="fa-solid fa-user me-1"></i>{{ hotel_search.NoOfChild }} Child</li>
                                                    <li class="nav-item mb-0" v-if="hotel_search.NoOfChild>1"><i class="fa-solid fa-user me-1"></i>{{ hotel_search.NoOfChild }} Children</li>
                                                </ul>
                                                <ul class="list-group1 list-group-borderless small mb-0 mt-3 p-0">
                                                    <li class="list-group-item d-flex text-success mb-1" v-for="attributes,key in room_attributes" :key="key"><i class="bi bi-shield-fill me-2"></i>{{ attributes }}</li>
                                                    <li class="d-flex justify-content-between align-items-center">
                                                        <a href="javascript:void(0)" class="text-primary mb-0 fs-12 text-info" data-bs-toggle="modal" data-bs-target="#amenities">See all amenities</a>
                                                        <a href="javascript:void(0)" class="mb-0 fs-12 text-info" data-bs-toggle="modal" data-bs-target="#cancellationPolicies">Cancellation Policies</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 class="fw-bold fs-6 text-uppercase" id="passenger_details">Guest Details</h5>
                                <div class="bg-success bg-opacity-10 rounded-2 p-3 mb-3">
                                    <p class="h6 text-md mb-0"><span class="badge bg-success me-2">Note</span>Please enter name as per Government photo id.</p>
                                </div>
                                <div v-for="room, room_key in hotel.HotelRoomsDetails" :key="room_key">
                                    <div v-for="passenger, key in room.HotelPassenger" :key="key">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="full-width d-flex flex-column mb-4 position-relative" v-if="passenger.LeadPassenger">
                                                    <div class="row align-items-stat mb-2">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 mb-2">
                                                            <h6 class="fw-bold fs-6 text-uppercase">Room {{ room_key + 1 }} Guest {{ key + 1 }} ({{ passenger.PaxType === 1 ? 'Adult' : 'Child' }})</h6>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-label">Title <span style="color:red">*</span></label>
                                                                <select class="form-control" v-model="passenger.Title" :class="{ 'is-invalid': passenger?.error?.Title }" :ref="(room_key === 0 && key === 0) ? 'firstPassengerTitle' : ''">
                                                                    <option value=null>Select Title</option>
                                                                    <option>Mr.</option>
                                                                    <option>Miss.</option>
                                                                    <option>Ms.</option>
                                                                    <option>Mrs.</option>                                                            
                                                                </select>
                                                                <span><small class="text-danger">{{ passenger?.error?.Title }}</small></span>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-3 col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-label">First Name <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="First Name" v-model="passenger.FirstName" :class="{ 'is-invalid': passenger?.error?.FirstName }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.FirstName }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-label">Middle Name</label>
                                                                <input type="text" class="form-control" placeholder="Middle Name" v-model="passenger.MiddleName" :class="{ 'is-invalid': passenger?.error?.MiddleName }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.MiddleName }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label class="form-label">Last Name <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Last Name" v-model="passenger.LastName" :class="{ 'is-invalid': passenger?.error?.LastName }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.LastName }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-label">Age <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Age" v-model="passenger.Age" :class="{ 'is-invalid': passenger?.error?.Age }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.Age }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3" v-if="ValidationInfo?.PanMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">Email <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Email" v-model="passenger.Email" :class="{ 'is-invalid': passenger?.error?.Email }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.Email }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-5 col-lg-5 col-md-5" v-if="!ValidationInfo?.PanMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">Email <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Email" v-model="passenger.Email" :class="{ 'is-invalid': passenger?.error?.Email }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.Email }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3" v-if="ValidationInfo?.PanMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">PhoneNo <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Phone No" v-model="passenger.Phoneno" :class="{ 'is-invalid': passenger?.error?.Phoneno }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.Phoneno }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-5 col-lg-5 col-md-5" v-if="!ValidationInfo?.PanMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">PhoneNo <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Phone No" v-model="passenger.Phoneno" :class="{ 'is-invalid': passenger?.error?.Phoneno }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.Phoneno }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="ValidationInfo?.PanMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">PAN <span>* </span>(e.g.ABCDE1234F)</label>
                                                                <input type="text" class="form-control" placeholder="PAN" v-model="passenger.PAN" :class="{ 'is-invalid': passenger?.error?.PAN }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.PAN }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="ValidationInfo?.PassportMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">Passport No </label>
                                                                <input type="text" class="form-control" placeholder="Passport No" v-model="passenger.PassportNo" :class="{ 'is-invalid': passenger?.error?.PassportNo }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.PassportNo }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="ValidationInfo?.PassportMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">Passport Issue Date</label>
                                                                <input type="date" class="form-control" v-model="passenger.PassportIssueDate" :class="{ 'is-invalid': passenger?.error?.PassportIssueDate }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.PassportIssueDate }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="ValidationInfo?.PassportMandatory">
                                                            <div class="form-group">
                                                                <label class="form-label">Passport Expiry Date</label>
                                                                <input type="date" class="form-control" v-model="passenger.PassportExpDate" :class="{ 'is-invalid': passenger?.error?.PassportExpDate }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.PassportExpDate }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12 col-lg-12 col-md-12" v-if="passenger.LeadPassenger && ValidationInfo?.GSTAllowed">
                                                            <input type="checkbox" v-model="passenger.gst_details"><span style="margin-left: 10px;">GST Details</span>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="passenger.gst_details">
                                                            <div class="form-group">
                                                                <label class="form-label">Company Name</label>
                                                                <input type="text" class="form-control" placeholder="Company Name" v-model="passenger.GSTCompanyName" :class="{ 'is-invalid': passenger?.error?.GSTCompanyName }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.GSTCompanyName }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-8 col-lg-8 col-md-8" v-if="passenger.gst_details">
                                                            <div class="form-group">
                                                                <label class="form-label">Company Address</label>
                                                                <input type="text" class="form-control" placeholder="Company Address" v-model="passenger.GSTCompanyAddress" :class="{ 'is-invalid': passenger?.error?.GSTCompanyAddress }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.GSTCompanyAddress }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="passenger.gst_details">
                                                            <div class="form-group">
                                                                <label class="form-label">Company Contact Number</label>
                                                                <input type="text" class="form-control" placeholder="Company Contact Number" v-model="passenger.GSTCompanyContactNumber" :class="{ 'is-invalid': passenger?.error?.GSTCompanyContactNumber }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.GSTCompanyContactNumber }}</small></span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="passenger.gst_details">
                                                            <div class="form-group">
                                                                <label class="form-label">GST Number</label>
                                                                <input type="text" class="form-control" placeholder="GST No" v-model="passenger.GSTNumber" :class="{ 'is-invalid': passenger?.error?.GSTNumber }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.GSTNumber }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4" v-if="passenger.gst_details">
                                                            <div class="form-group">
                                                                <label class="form-label">Company Email</label>
                                                                <input type="text" class="form-control" placeholder="Company Email" v-model="passenger.GSTCompanyEmail" :class="{ 'is-invalid': passenger?.error?.GSTCompanyEmail }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.GSTCompanyEmail }}</small></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="full-width d-flex flex-column mb-4 position-relative" v-else>
                                                    <h6 class="fw-bold fs-6 text-uppercase">Room {{ room_key + 1 }} Guest {{ key + 1 }} ({{ passenger.PaxType === 1 ? 'Adult' : 'Child' }})</h6>
                                                    <div class="row align-items-stat mb-2">
                                                        <div class="col-md-2 col-lg-2 col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="form-label">Title <span style="color:red">*</span></label>
                                                                <select class="form-control" v-if="passenger.PaxType==1" v-model="passenger.Title" :class="{ 'is-invalid': passenger?.error?.Title }">
                                                                    <option value=null>Select Title</option>
                                                                    <option>Mr.</option>
                                                                    <option>Miss.</option>
                                                                    <option>Ms.</option>
                                                                    <option>Mrs.</option>                                                            
                                                                </select>
                                                                <select class="form-control" v-if="passenger.PaxType==2" v-model="passenger.Title" :class="{ 'is-invalid': passenger?.error?.Title }">
                                                                    <option value="">Select Title</option>
                                                                    <option>Mr.</option>
                                                                    <option>Miss.</option>                                                         
                                                                </select>
                                                                <span><small class="text-danger">{{ passenger?.error?.Title }}</small></span>
                                                            </div>
                                                        </div>

                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label class="form-label">First Name <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="First Name" v-model="passenger.FirstName" :class="{ 'is-invalid': passenger?.error?.FirstName }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.FirstName }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label class="form-label">Middle Name</label>
                                                                <input type="text" class="form-control" placeholder="Middle Name" v-model="passenger.MiddleName" :class="{ 'is-invalid': passenger?.error?.MiddleName }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.MiddleName }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="form-group">
                                                                <label class="form-label">Last Name <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Last Name" v-model="passenger.LastName" :class="{ 'is-invalid': passenger?.error?.LastName }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.LastName }}</small></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2 col-lg-2 col-xs-12 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="form-label">Age <span style="color:red">*</span></label>
                                                                <input type="text" class="form-control" placeholder="Age" v-model="passenger.Age" :class="{ 'is-invalid': passenger?.error?.Age }"/>
                                                                <span><small class="text-danger">{{ passenger?.error?.Age }}</small></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12">
                                <h5 class="fw-bold fs-6 text-uppercase">Price Summary</h5>
                                <div class="side-block card rounded-2 p-2 mb-4">
                                    <div class="mid-block rounded-2 p-2">
                                        <ul class="list-group list-group-borderless border-width-0 border br-dashed rounded-2 p-2">
                                            <li class="list-group-item px-2 d-flex justify-content-between">
                                                <span class="h6 fw-light mb-0">Base Price<small>(1 Room x 1 Night)</small></span>
                                                <span class="fw-normal mb-0">{{ selected_room.BasePrice }}
                                            </span>
                                            </li>
                                            <hr class="m-1" />
                                            <li class="list-group-item px-2 d-flex justify-content-between">
                                                <span class="h6 fw-light mb-0">Taxes & Service Fees</span>
                                                <span class="h6 fw-normal mb-0">{{ selected_room.TotalTax }}</span>
                                            </li>
                                            <hr class="m-1" />
                                            <li class="list-group-item bg-light d-flex justify-content-between rounded-2 px-2 mt-2">
                                                <span class="h5 fw-normal mb-0 ps-1">Total</span>
                                                <span class="h5 fw-normal mb-0"><span class="text-muted" style="font-size:12pt">{{ selected_room.Currency }}</span>&nbsp;{{ selected_room.TotalFare }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-check ms-2 mb-2">
                                    <input type="checkbox" class="form-check-input" v-model="agree_to_terms" />
                                    <p class="font12">By proceeding, I agree to Fastay's 
                                        <a href="javascript:void(0)">User Agreement</a>, 
                                        <a href="javascript:void(0)">Terms of Service</a> and
                                        <a href="javascript:void(0);">Cancellation &amp; Property Booking Policies</a>.</p>
                                    <p v-if="errors.agree_to_terms" class="tncErrorBox ">Please accept the terms &amp; conditions to continue with the booking</p>
                                </div>
                                <button class="btn fw-medium btn-primary full-width" type="button" @click="initPayment()">Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="amenities" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="baggageFarelabel">Amenities</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3">
                        <div style="height: 300px; overflow-y: auto;">
                            <ul class="list-group" style="list-style-type: disc;">
                                <li v-for="amenity, key in amenities" :key="key" class="list-group-item">
                                    {{ amenity }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="cancellationPolicies" tabindex="-1" aria-labelledby="cancellationPoliciesLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="cancellationPoliciesLabel">Cancelation Policies</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="bus_cancelation"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-striped table-bordered border-dark">
                            <thead>
                                    <tr>
                                        <th class="text-center">From Date</th>
                                        <th class="text-center">Charge Type</th>
                                        <th class="text-center">Cancellation Charge</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!cancellation_policies?.length">
                                        <td colspan="3">No records found</td>
                                    </tr>
                                    <tr v-for="policy,key in cancellation_policies" :key="key">
                                        <td>{{ policy.FromDate }}</td>
                                        <td>{{ policy.ChargeType }}</td>
                                        <td class="text-center">{{ policy.CancellationCharge }}</td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Search from "@/components/Search.vue";
    let City = require("@/components/City.vue").default;
    export default {
        components: { flatPickr, Search, City },
        data() {
            return {
                gst_details:'',
                agree_to_terms:true,
                guests:'',
                hotel_store:'',
                hotel_search:'',
                selected_room:'',
                hotel_pre_book:{
                    BookingCode:'',
                    PaymentMode: 'Limit',
                },
                hotel:{
                    BookingCode:'',
                    IsVoucherBooking: true,
                    GuestNationality: 'IN',
                    EndUserIp: '35.154.18.213',
                    RequestedBookingMode: 1,
                    NetAmount: '',
                    TokenId:'',
                    TraceId:'',
                    AgencyId:'',
                    HotelRoomsDetails: []
                },
                custumer:'',
                errors: [],
                booking:{
                    customer_id:'',
                    amount:0
                },
                
                passengers:[],
                rooms:{
                    BasePrice:null,
                    TotalTax:null,
                    TotalFare:null
                },
                pre_book_rooms:null,
                room_attributes:[],
                allocatedRooms:[],
                validation_errors: false,
                HotelResult:'',
                ValidationInfo:'',
                amenities:[],
                cancellation_policies:[]
            };
        },

        mounted(){
            this.getHotelDetails()
            this.preBook()
        },

        methods:{

            changeDateFormat(date){
                return moment(date).format('DD-MM-yyyy')
            },
            getHotelDetails(){
                this.hotel_store = this.$store.getters.hotel
                this.hotel_search = this.$store.getters.hotel_search
                this.selected_room = this.$store.getters.selected_room
                this.guests = this.hotel_search.NoOfAdults + +this.hotel_search.NoOfChild
                console.log('selected room')
                console.log(this.selected_room)
                this.updatePassengers()
            },
            
            updatePassengers(){
                let vm = this
                let rooms = vm.hotel_search.PaxRooms
                if(rooms.length){
                    let roomDetails = {}
                    rooms.map(function(room){
                        roomDetails = { HotelPassenger: [] }
                        if(room.Adults){
                            let lead_passenger = false
                            for (let i = 0; i < room.Adults; i++) {
                                if(i==0){
                                    lead_passenger = true
                                }else{
                                    lead_passenger = false
                                }
                                roomDetails.HotelPassenger.push({
                                    Title: null,
                                    FirstName: null,
                                    MiddleName: null,
                                    LastName: null,
                                    Email: null,
                                    PaxType: 1,
                                    LeadPassenger: lead_passenger,
                                    Age: null,
                                    PassportNo: null,
                                    PassportIssueDate: null,
                                    PassportExpDate: null,
                                    Phoneno: null,
                                    PaxId: 0,
                                    GSTCompanyAddress: null,
                                    GSTCompanyContactNumber: null,
                                    GSTCompanyName: null,
                                    GSTNumber: null,
                                    GSTCompanyEmail: null,
                                    PAN: null,
                                    error:null,
                                    gst_details:false
                                })
                            }
                        }
                        if(room.Children){
                            for (let j = 0; j < room.Children; j++) {
                                if(room.ChildrenAges.length){
                                    console.log(room.ChildrenAges[j])
                                    roomDetails.HotelPassenger.push({
                                        Title: null,
                                        FirstName: null,
                                        MiddleName: null,
                                        LastName: null,
                                        Email: null,
                                        PaxType: 2,
                                        LeadPassenger: false,
                                        Age: room.ChildrenAges[j],
                                        PassportNo: null,
                                        PassportIssueDate: null,
                                        PassportExpDate: null,
                                        Phoneno: null,
                                        PaxId: 0,
                                        GSTCompanyAddress: null,
                                        GSTCompanyContactNumber: null,
                                        GSTCompanyName: null,
                                        GSTNumber: null,
                                        GSTCompanyEmail: null,
                                        PAN: null,
                                        error:null,
                                        gst_details:false
                                    })
                                }
                                
                            }
                        }
                        vm.hotel.HotelRoomsDetails.push(roomDetails);
                    })
                }
                console.log(vm.hotel.HotelRoomsDetails)
            },

            validateBooking(){
                let vm = this
                if(!vm.agree_to_terms){
                    vm.errors = {
                        agree_to_terms : ['Agree to terms to continue']
                    } 
                }else{
                    delete vm.errors.agree_to_terms
                }
                vm.validation_errors = false
                if('HotelRoomsDetails'in vm.hotel && vm.hotel.HotelRoomsDetails.length){
                    vm.hotel.HotelRoomsDetails.map(function(room_details){
                        if('HotelPassenger' in room_details && room_details.HotelPassenger.length){
                            room_details.HotelPassenger.map(function(passenger){
                                if (!passenger.error) {
                                    passenger.error = {};
                                }
                                if(passenger.Title){
                                    delete passenger.error.Title
                                }else{
                                    passenger.error.Title = "Title field is required";
                                }

                                const nameRegex = /^[A-Za-z]{2,}$/;
                                if(passenger.FirstName){
                                    if (!nameRegex.test(passenger.FirstName)) {
                                        passenger.error.FirstName = "First name must have at least 2 characters"
                                    }else{
                                        delete passenger.error.FirstName
                                    }
                                }else{
                                    passenger.error.FirstName = "FirstName field is required"
                                }

                                if(passenger.LastName){
                                    if (!nameRegex.test(passenger.LastName)) {
                                        passenger.error.LastName = "Last name must have at least 2 characters"
                                    }else{
                                        delete passenger.error.LastName
                                    }
                                }else{
                                    passenger.error.LastName = "LastName field is required"
                                }

                                if (passenger.Age) {
                                    // Age validation logic
                                    if (isNaN(passenger.Age) || passenger.Age <= 0) {
                                        passenger.error.Age = "Age must be a positive number";
                                    } else if (passenger.PaxType === 2 && passenger.Age > 12) {
                                        passenger.error.Age = "Child's age must be 12 or under";
                                    } else if (passenger.PaxType === 1 && (passenger.Age < 13 || passenger.Age > 100)) {
                                        passenger.error.Age = "Adult's age must be between 13 to 100";
                                    } else {
                                        delete passenger.error.Age;
                                    }
                                } else {
                                    passenger.error.Age = "Age field is required";
                                }

                                if (passenger.Email) {
                                    // Email validation logic
                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    if (!emailRegex.test(passenger.Email)) {
                                        passenger.error.Email = "Invalid email format";
                                    } else {
                                        delete passenger.error.Email;
                                    }
                                } else {
                                    passenger.error.Email = "Email field is required";
                                }

                                if (passenger.Phoneno) {
                                    // Phone number validation logic
                                    const phoneRegex = /^[0-9]{10}$/;
                                    if (!phoneRegex.test(passenger.Phoneno)) {
                                        passenger.error.Phoneno = "Phone number must be 10 digits long and contain only numbers";
                                    } else {
                                        delete passenger.error.Phoneno;
                                    }
                                } else {
                                    passenger.error.Phoneno = "Phoneno field is required";
                                }
                                if(vm.ValidationInfo.PanMandatory){
                                    if (passenger.PAN) {
                                        // PAN validation logic
                                        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                                        if (!panRegex.test(passenger.PAN)) {
                                            passenger.error.PAN = "Invalid PAN format";
                                        } else {
                                            delete passenger.error.PAN;
                                        }
                                    } else {
                                        passenger.error.PAN = "PAN field is required";
                                    }
                                }

                                if (Object.keys(passenger.error).length > 0) {
                                    vm.validation_errors = true;
                                }
                            })
                        }
                    })
                }

            },
            preBook(){
                let vm = this
                if(vm.$store.getters.customer){
                    vm.hotel_pre_book.BookingCode = vm.selected_room.BookingCode
                    vm.hotel_pre_book.customer_id = vm.$store.getters.customer.customer_id
                    let loader = vm.$loading.show();
                    vm.$store
                    .dispatch("post", { uri: "hotels/preBook", data: vm.hotel_pre_book })
                    .then(function (response) {
                        loader.hide();
                        vm.HotelResult = response.data.HotelResult
                        vm.ValidationInfo = response.data.ValidationInfo

                        if(vm.HotelResult?.length){
                            if(vm.HotelResult[0].Rooms.length){
                                vm.hotel.NetAmount = vm.HotelResult[0].Rooms[0].NetAmount
                                vm.amenities = vm.HotelResult[0].Rooms[0].Amenities
                                vm.cancellation_policies = vm.HotelResult[0].Rooms[0].CancelPolicies
                            }
                        }
                        console.log('ValidationInfo')
                        console.log(vm.ValidationInfo)
                        
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
                }else{
                    alert('Please Sign In to continue')
                }
            },

            initPayment() {
                let vm = this;
                vm.validateBooking()
                if(!vm.validation_errors && !Object.keys(vm.errors).length){
                    vm.booking.amount = vm.hotel.NetAmount
                    vm.booking.customer_id = vm.$store.getters.customer.customer_id
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("post", { uri: "hotels/rooms/initPayment", data: vm.booking })
                    .then(function (response) {
                        loader.hide();
                        vm.makePayment(response.data)
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error)
                    });
                    
                }else{
                    const element = document.getElementById('passenger_details');
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            },
            makePayment(order_id) {
                let vm =this;
                var options = {
                    "key": "rzp_test_2Y3JxdhArTPe4V", 
                    "amount": (vm.booking.amount * 100),
                    "currency": "INR",
                    "name": "Fastays",
                    "description": "Booking",
                    "image": "http://booking.fastays.com/assets/images/logo.png",
                    "order_id": order_id, 
                    "handler": function (response){
                        vm.book(response,order_id);
                    },
                    "prefill": {
                        "name": this.$store.getters.customer.name,
                        "email": this.$store.getters.customer.email,
                        "contact": this.$store.getters.customer.mobile_no
                    },
                    "theme": {
                        "color": "#5143d9"
                    }
                };
                
                var rzp1 = new Razorpay(options);

                rzp1.on('payment.failed', function (response){
                    alert(response.error.description);
                });
                rzp1.open();
            },
            book(response,order_id){
                let vm = this;   
                vm.hotel.razorpay_order_id = response.razorpay_order_id
                vm.hotel.razorpay_payment_id = response.razorpay_payment_id
                vm.hotel.BookingCode = vm.hotel_pre_book.BookingCode 
                vm.hotel.TokenId = vm.$store.getters.hotel_search.TokenId            
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotels/book", data: vm.hotel })
                    .then(function (response) {
                        loader.hide();
                        if('BookResult' in response.data){
                            vm.$store.dispatch("setHotelBooking", response.data.BookResult)
                            if('BookingId' in response.data.BookResult){
                                vm.$router.push("/booking_success/" + response.data?.BookResult?.BookingId + "/hotel")
                            }
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            }
        }
    }
</script>
<style scoped>
    .border-width-0 {
        --bs-list-group-border-width: 0px;
    }
    .font12{
        font-size: 12px;
        line-height: 16px !important;
    }
    .tncErrorBox {
        background-color: #fcdadb;
        border-radius: 4px;
        color: #ec2127;
        font-size: 10px;
        margin-bottom: 10px;
        padding: 4px 8px;
    }
    a {
        color: #0084ff;
        text-decoration: none;
        cursor: pointer;
    }
</style>
