<template>
    	<section class="py-4 gray-simple position-relative">
			<div class="container">
				<div class="row align-items-start">
					<div class="col-xl-12 col-lg-12 col-md-12">
						<div class="card mb-3">
							<div class="car-body px-xl-5 px-lg-4 py-lg-5 py-4 px-2">
								<div class="d-flex align-items-center justify-content-center mb-3">
									<div class="square--80 circle text-light bg-success"><i class="fa-solid fa-check-double fs-1"></i>
									</div>
								</div>
								<div class="d-flex align-items-center justify-content-center flex-column text-center mb-5">
									<h3 class="mb-0">Your Hotel Booking Has Been Confirmed Successfully!</h3>
								</div>
								<div class="d-flex align-items-center justify-content-center flex-column mb-4">
									<div class="border br-dashed full-width rounded-2 p-3 pt-0">
										<ul class="row align-items-center justify-content-start g-3 m-0 p-0">
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Order Invoice</p>
													<p class="text-muted mb-0 lh-2">{{ booking_result?.InvoiceNo }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Date</p>
													<p class="text-muted mb-0 lh-2">{{ booking_result?.BookingDate }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Total Amount</p>
													<p class="text-muted mb-0 lh-2">{{ booking_result?.InvoiceAmount }}</p>
												</div>
											</li>
											<li class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
												<div class="d-block">
													<p class="text-dark fw-medium lh-2 mb-0">Booking Status</p>
													<p class="text-muted mb-0 lh-2">{{ booking_result?.HotelBookingStatus }}</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div class="text-center d-flex align-items-center justify-content-center">
									<a href="/bookings" class="btn btn-md btn-light-seegreen fw-semibold mx-2">My Bookings</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
</template>
<script>
export default {
	data(){
		return{
			hotel:{
				BookingId:'',
				EndUserIp:'',
				TokenId:''
			},
			booking_result:'',
			lead_passenger:{
				first_name:'',
				last_name:'',
				email:'',
				phone_no:''
			}

		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.hotel.BookingId = to.params.BookingId
			vm.hotel.EndUserIp = '35.154.18.213',
            vm.hotel.TokenId = vm.$store.getters.hotel_search?.TokenId
            vm.updateLeadPassenger()
		});
	},
	methods:{
		
		updateLeadPassenger(){
			let vm = this
			let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotels/getBookingDetails", data: vm.hotel })
                    .then(function (response) {
                        loader.hide();
                        console.log(response)
                        if ("GetBookingDetailResult" in response.data) {
                            vm.booking_result = response.data.GetBookingDetailResult
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error)
                    });
		}
	}
}
</script>