<template>
	<div>
		<div class="py-5 bg-primary">
			<div class="container">
				<div class="row align-items-center justify-content-between">

				<div class="col-xl-4 col-lg-4 col-md-6">
					<h4 class="text-light fw-bold lh-base m-0">Join our Newsletter To Keep Up To Date With Us!</h4>
				</div>

				<div class="col-xl-5 col-lg-5 col-md-6">
					<div class="newsletter-forms mt-md-0 mt-4">
					<form>
						<div class="row align-items-center justify-content-between bg-white rounded-3 p-2 gx-0">

						<div class="col-xl-9 col-lg-8 col-md-8">
							<div class="form-group m-0">
							<input type="text" class="form-control bold ps-1 border-0" placeholder="Enter Your Mail!">
							</div>
						</div>
						<div class="col-xl-3 col-lg-4 col-md-4">
							<div class="form-group m-0">
							<button type="button" class="btn btn-dark fw-medium full-width">Submit<i
								class="fa-solid fa-arrow-trend-up ms-2"></i></button>
							</div>
						</div>

						</div>
					</form>
					</div>
				</div>

				</div>
			</div>
		</div>
    <footer class="footer skin-light-footer">
		<div>
			<div class="container">
			<div class="row">

				<div class="col-lg-3 col-md-4">
				<div class="footer-widget">
					<div class="d-flex align-items-start flex-column mb-3">
					<div class="d-inline-block"><img src="images/logo.png" class="img-fluid" width="160"
						alt="Footer Logo">
					</div>
					</div>
					<div class="footer-add pe-xl-3">
					<p>We make your dream more beautiful & enjoyful with lots of happiness.</p>
					</div>
					<div class="foot-socials">
					<ul>
						<li><a href="JavaScript:void(0);"><i class="fa-brands fa-facebook"></i></a></li>
						<li><a href="JavaScript:void(0);"><i class="fa-brands fa-linkedin"></i></a></li>
						<li><a href="JavaScript:void(0);"><i class="fa-brands fa-google-plus"></i></a></li>
						<li><a href="JavaScript:void(0);"><i class="fa-brands fa-twitter"></i></a></li>
						<li><a href="JavaScript:void(0);"><i class="fa-brands fa-dribbble"></i></a></li>
					</ul>
					</div>
				</div>
				</div>
				<div class="col-lg-2 col-md-4">
				<div class="footer-widget">
					<h4 class="widget-title">Page</h4>
					<ul class="footer-menu">
					<li><router-link to="/">Home</router-link></li>
					<li><router-link to="/aboutus">About Us</router-link></li>
					<li><router-link to="/contactus">Contact Us</router-link></li>
					<li><router-link to="/aboutus">Gallery</router-link></li>
					</ul>
				</div>
				</div>

				<div class="col-lg-2 col-md-4">
				<div class="footer-widget">
					<h4 class="widget-title">Resources</h4>
					<ul class="footer-menu">
					<li><a href="JavaScript:void(0);">Revenue Management for Hotels</a></li>
					<li><a href="JavaScript:void(0);">Travel Packages</a></li>
					</ul>
				</div>
				</div>

				<div class="col-lg-2 col-md-6">
					<div class="footer-widget">
					
					</div>
				</div>

				<div class="col-lg-3 col-md-6">
				<div class="footer-widget">
					<h4 class="widget-title">Address</h4>
					<span>Office No. 2545, 4th B Main Road, 3rd Stage, LIG Yelahanka 560064.</span>
					<h4 class="widget-title mt-5">Payment Methods</h4>
					<div class="pmt-wrap">
					<img src="img/payment.png" class="img-fluid" alt="">
					</div>
					
				</div>
				</div>

			</div>
			</div>
		</div>

		<div class="footer-bottom border-top">
			<div class="container">
			<div class="row align-items-center justify-content-between">
				<div class="col-xl-6 col-lg-6 col-md-6">
				<p class="mb-0">Copyright © 2024. All rights reserved.</p>
				</div>
				<div class="col-xl-6 col-lg-6 col-md-6">
				<ul class="p-0 d-flex justify-content-start justify-content-md-end text-start text-md-end m-0">
					<li><router-link to="/terms_of_services">Terms of services </router-link></li>
					<li class="ms-3"><router-link to="/privacy_policies">Privacy Policies</router-link></li>
					<li class="ms-3"><a href="javascript:void(0)">Cookies</a></li>
				</ul>
				</div>

			</div>
			</div>
		</div>
	</footer>
</div>
</template>