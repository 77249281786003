import { createStore } from 'vuex';
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";

export default createStore({
  state: {
    apiUrl:"https://api.fastays.com/",
    // apiUrl:"http://192.168.0.213/fastays.com/laravel/public/",
    authenticated:false,
    customer: null,
    token: '',
    currency: 'INR',
    bus_search:null,
    bus_booking:null,
    flight_search:null,
		flight_meta:null,
    flight_result:null,
		flight_booking:null,
		return_flight_booking:null,
    hotel_search:null,
    hotel_meta:null,
    hotel_booking:null,
    hotel:null,
    selected_room:null,
    hotel_booking:null
   
},
mutations: {
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setHotelBooking(state, hotel_booking) {
      state.hotel_booking = hotel_booking;
    },
    setToken(state, token) {
      state.token = token;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setUrl(state, url) {
			state.apiUrl = url;
		},
    setSelectedRoom(state, selected_room) {
			state.selected_room = selected_room;
		},
    setBusSearch(state, bus_search) {
			state.bus_search = bus_search;
		},
    setBusBooking(state, bus_booking) {
			state.bus_booking = bus_booking;
		},
    setFlightSearch(state, flight_search) {
			state.flight_search = flight_search;
		},
		setFlightMeta(state, flight_meta) {
			state.flight_meta = flight_meta;
		},
    setFlightResult(state, flight_result) {
			state.flight_result = flight_result;
		},    
		setFlightBooking(state, flight_booking) {
			state.flight_booking = flight_booking;
		},
		setReturnFlightBooking(state, return_flight_booking) {
			state.return_flight_booking = return_flight_booking;
		},
    setHotelSearch(state, hotel_search) {
			state.hotel_search = hotel_search;
		},
    setHotelBooking(state, hotel_booking) {
			state.hotel_booking = hotel_booking;
		},
    setHotel(state, hotel) {
			state.hotel = hotel;
		},
},
getters: {
    hotel_booking(state) {
      return state.hotel_booking;
    },
    hotel(state) {
      return state.hotel;
    },
    selected_room(state) {
      return state.selected_room;
    },
    customer(state) {
      return state.customer;
    },
    token(state) {
      return state.token;
    },
    currency(state) {
      return state.currency;
    },
    apiUrl(state) {
			return state.apiUrl;
		},
    bus_search(state) {
			return state.bus_search;
		},
    bus_booking(state) {
			return state.bus_booking;
		},
    flight_search(state) {
			return state.flight_search;
		},
		flight_meta(state) {
			return state.flight_meta;
		},
    flight_result(state) {
			return state.flight_result;
		},
    
		flight_booking(state) {
			return state.flight_booking;
		},
		return_flight_booking(state) {
			return state.return_flight_booking;
		},
    hotel_search(state) {
			return state.hotel_search;
		},
    hotel_booking(state) {
			return state.hotel_booking;
		},
	},

 
actions: {
    async setHotelBooking(context, payload) {
      await context.commit("setHotelBooking", payload);
    },
    async setSelectedRoom(context, payload) {
      await context.commit("setSelectedRoom", payload);
    },
    async setHotel(context, payload) {
      await context.commit("setHotel", payload);
    },
    async setCustomer(context, payload) {
      await context.commit("setCustomer", payload);
    },
    async setToken(context, payload) {
      // sessionStorage.setItem("token", payload)
      await context.commit('setToken', payload);
    },
    async setCurrency(context, payload) {
      await context.commit('setCurrency', payload);
    },
    async setBusSearch(context, payload) {
			localStorage.setItem("BusSearch", JSON.stringify(payload));
			await context.commit("setBusSearch", payload);
		},
    async setBusBooking(context, payload) {
			localStorage.setItem("BusBooking", JSON.stringify(payload));
			await context.commit("setBusBooking", payload);
		},


	
    async logout(context) {
      // sessionStorage.removeItem('user');
      // sessionStorage.removeItem('token');
      await context.commit('setCustomer', null);
      await context.commit('setToken', "");
    },


    async setFlightSearch(context, payload) {
			localStorage.setItem("FlightSearch", JSON.stringify(payload));
			await context.commit("setFlightSearch", payload);
		},

		async setFlightMeta(context, payload) {
			localStorage.setItem("FlightMeta", JSON.stringify(payload));
			await context.commit("setFlightMeta", payload);
		},

    async setFlightResult(context, payload) {
			// localStorage.setItem("FlightResult", JSON.stringify(payload));
			await context.commit("setFlightResult", payload);
		},

		async setFlightBooking(context, payload) {
			localStorage.setItem("FlightBooking", JSON.stringify(payload));
			await context.commit("setFlightBooking", payload);
		},

		async setReturnFlightBooking(context, payload) {
			localStorage.setItem("ReturnFlightBooking", JSON.stringify(payload));
			await context.commit("setReturnFlightBooking", payload);
		},

    async setHotelSearch(context, payload) {
			localStorage.setItem("HotelSearch", JSON.stringify(payload));
			await context.commit("setHotelSearch", payload);
		},
    async setHotelBooking(context, payload) {
			localStorage.setItem("HotelBooking", JSON.stringify(payload));
			await context.commit("setHotelBooking", payload);
		},

		async getFlightBooking(context) {
      let FlightSearch = JSON.parse(localStorage.getItem("FlightSearch"));
      if(FlightSearch) {
        await context.commit("setFlightSearch", FlightSearch);
      }
      let FlightMeta = JSON.parse(localStorage.getItem("FlightMeta"));
      if(FlightMeta) {
        await context.commit("setFlightMeta", FlightMeta);
      }
      let FlightBooking = JSON.parse(localStorage.getItem("FlightBooking"));
      if(FlightBooking) {
        await context.commit("setFlightBooking", FlightBooking);
      }
    },




    auth(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(this.state.apiUrl + 'api/v1/customer/' + payload.uri, payload.data)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    get(context,payload) {
			return new Promise((resolve, reject) => {
				axios.get(this.state.apiUrl + 'api/v1/customer/' + payload.uri,payload.data, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

    post(context, payload) {
      // console.log("token",context.getters.token)
      return new Promise((resolve, reject) => {
        axios.post(this.state.apiUrl + 'api/v1/customer/' + payload.uri, payload.data, {
          	headers: {
            	'Authorization': 'Bearer' + ' ' + context.getters.token
          	}
        }).then(function (response) {
          	resolve(response);
        })
          .catch(function (error) {
            reject(error);
            if (error.response.data.message == "Unauthenticated.") {
              	context.dispatch('logout');
              	window.location.href = "/#/"
              	window.location.reload();
            }
          });
      });
    },

    async error(context, description) {
      await createToast(
        {
          	title: "Error",
          	description: description || "The given data was invalid.",
        },
        {
          	showIcon: true,
          	hideProgressBar: true,
          	type: "danger",
          	position: "top-right",
        }
      );
    },

    async success(context, description) {
      await createToast(
        {
          	title: "Success",
          	description: description || "Data is successfuly subbmited.",
        },
        {
          	showIcon: true,
          	hideProgressBar: true,
          	type: "success",
          	position: "top-right",
        }
      );
    },

    async info(context, description) {
      	await createToast(
			{
				title: "Info",
				description: description || "Not enoguh input data",
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: "info",
				position: "top-right",
			}
      	);
    },
},
modules: {
}
})