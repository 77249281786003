<template>
    <section class="pt-3 gray-simple" >
        <div class="container">
            <div class="row g-2 g-lg-4">
                <div class="py-2 bg-search position-relative mb-3 justify-content-center align-items-center" style="text-align: center;">
                    <div style="padding: 15px 0px;">
                        <span style="color: white; font-size: 32px; font-weight: 600; line-height: 24px; text-align: left;">MY BOOKINGS</span>
                    </div>                    
                </div>
                <div class="col-12">
                    <ul class="nav nav-tabs nav-bottom-line nav-responsive nav-justified">
                        <li class="nav-item">
                            <a class="nav-link mb-0 active" data-bs-toggle="tab" id="tab-1-btn" href="#tab-1"><i class="bi bi-briefcase-fill fa-fw me-1"></i>Upcoming Booking</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-3"><i class="bi bi-patch-check fa-fw me-1"></i>Completed Booking</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-2"><i class="bi bi-x-octagon fa-fw me-1"></i>Canceled Booking</a>
                        </li>                        
                    </ul>
                    <div class="tab-content p-2 p-sm-4" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="tab-1">

                            <!-- Upcomming Bus -->
                            <div class="card border mb-3" v-for="upcoming, key in upcoming_bus_bookings" :key="key" style="border-radius: 24px;">                                
                                <div class="card-body" >
                                    <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                        <div class="d-md-flex justify-content-md-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-bus"></i></div>

                                                <div class="ms-2">
                                                    <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">
                                                        {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}}
                                                        &nbsp;
                                                        <i class="bi bi-arrow-right"></i>
                                                        &nbsp;
                                                        {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}
                                                    </span>
                                                    <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                        <li class="nav-item">Booking ID: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId}}</li>&nbsp;|&nbsp;
                                                        <li class="nav-item">TicketNo: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TicketNo}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row g-3 pt-2 align-items-center">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                    {{$formatDate(upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.DepartureTime, 'long')}} - 
                                                    {{$formatDate(upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.DepartureTime, 'time')}}
                                                </p>                                                    
                                                <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}}</span>
                                                
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                    {{$formatDate(upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.ArrivalTime, 'long')}} - 
                                                    {{$formatDate(upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.ArrivalTime, 'time')}}
                                                </p>
                                                <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</span>
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">Traveller's</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                    {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TravelName}}
                                                </p>
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 pt-3" style="text-align: right;">
                                                <router-link :to="'/bookingDetails/Bus/'+ upcoming.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                    MANAGE BOOKINGS
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Upcomming Flight -->
                            <div v-for="upcoming, key in upcoming_flight_bookings" :key="key" >
                                <!-- {{upcoming.booking_id}} -->
                                <div class="card border mb-3" style="border-radius: 24px;" v-if="upcoming.ob_ticket_response?.ResponseStatus == 1" >
                                    <div class="card-body">
                                        <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                            <div class="pb-4">
                                                <div class="row ">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <div class="row" style="font-size: 16px; font-weight: 400; line-height: 16px; text-align: left; padding:10px">
                                                            <ul class="nav nav-divider small">
                                                                <li class="nav-item">Booking ID: 
                                                                    {{upcoming.ob_ticket_response?.Response?.BookingId}}
                                                                </li>&nbsp;|&nbsp;
                                                                <li class="nav-item">PNR: 
                                                                    {{upcoming.ob_ticket_response?.Response?.PNR}}
                                                                </li>&nbsp;|&nbsp;
                                                                <li class="nav-item">
                                                                    <span v-if="upcoming.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 1"> One Way Trip</span> 
                                                                    <span v-if="upcoming.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 2"> Return Trip</span> 
                                                                    <span v-if="upcoming.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 3"> Multi City Trip</span> 
                                                                    <span v-if="upcoming.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 5"> Special Return</span> 
                                                                </li>                                                            
                                                                <li v-if="upcoming.ob_ticket_response?.Response?.FlightItinerary?.JourneyType!=2" class="nav-item">
                                                                    &nbsp;|&nbsp;  <span>{{ AccumulatedDurationCalculation(upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments) }}</span>
                                                                    <!-- <span v-if="upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                        {{ Math.floor(upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                        {{ upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                                    </span>
                                                                    <span v-else> 
                                                                        <span v-if="upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                            {{ Math.floor(upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                            {{ upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                        </span>
                                                                        <span v-else>
                                                                            {{ Math.floor(upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                            {{ upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                        </span>
                                                                    </span> -->
                                                                </li>                                                            
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9" v-for="segment,j in upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments" :key="j">
                                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                                            <div class="row gx-lg-5 gx-3 py-2 gy-2 align-items-center">
                                                                <div class="col-xl-12 col-lg-12 col-md-12" >
                                                                    <div class="d-flex align-items-center justify-content-start">
                                                                        <div class="d-start fl-pic">
                                                                            <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                                        </div>
                                                                        <div class="d-end fl-title ps-2">                                                                    
                                                                            <span class="text-dark fw-medium">
                                                                                {{ segment?.Origin?.Airport?.CityName }}
                                                                            </span>
                                                                            &nbsp;
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            &nbsp;
                                                                            <span class="text-dark fw-medium">
                                                                                {{ segment?.Destination?.Airport?.CityName }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                                        {{ $formatDate(segment?.Origin?.DepTime,'long') }}
                                                                        {{ $formatDate(segment?.Origin?.DepTime,'time') }}
                                                                    </p>                                                    
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                        {{ segment?.Origin?.Airport?.AirportName }}, 
                                                                        {{ segment?.Origin?.Airport?.Terminal ? 'Terminal '+segment?.Origin?.Airport?.Terminal : ''}}
                                                                    </span>                                                    
                                                                </div>

                                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                                        <span>                                                            
                                                                            {{ $formatDate(segment?.Destination.ArrTime,'long') }}
                                                                            {{ $formatDate(segment?.Destination.ArrTime,'time') }}
                                                                        </span>
                                                                    </p>
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                        <span>
                                                                            {{ segment?.Destination?.Airport?.AirportName }},  
                                                                            {{ segment?.Destination?.Airport?.Terminal ? 'Terminal '+segment?.Destination?.Airport?.Terminal : ''}}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- {{segment?.TripIndicator }} - {{ upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[j+1]?.TripIndicator }} -->
                                                        <!-- <div class="row pt-3 justify-content-center" style="align-items:center" v-if="segment?.TripIndicator != upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[j+1]?.TripIndicator && upcoming.ob_ticket_response?.Response?.FlightItinerary?.Segments[j+1]?.TripIndicator">
                                                            <div class="text-center col-xl-3 col-lg-3 col-md-3 col-sm-3" style="border:1px dashed black; border-radius:48px; 
                                                                padding:6px !important; font-weight:500; font-size:12px;">                                                
                                                                <span>
                                                                    Return Flight
                                                                </span>
                                                            </div>																		
                                                        </div> -->
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end align-self-end" v-if="!upcoming.ib_ticket_response">
                                                        <router-link :to="'/bookingDetails/Flight/'+ upcoming.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                            MANAGE BOOKINGS
                                                        </router-link>
                                                    </div>
                                                </div>                                                                                            
                                            </div>

                                            <div class="row pt-3 justify-content-center" style="align-items:center" v-if="upcoming.ib_ticket_response">
                                                <div class="text-center col-xl-3 col-lg-3 col-md-3 col-sm-3" style="border:1px dashed black; border-radius:48px; 
                                                    padding:6px !important; font-weight:500; font-size:12px;">                                                
                                                    <span>
                                                        Return Flight
                                                    </span>
                                                </div>																		
                                            </div>

                                            <div v-if="upcoming.ib_ticket_response?.ResponseStatus == 1" class="pt-4">
                                                <div class="row ">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <div class="row" style="font-size: 16px; font-weight: 400; line-height: 16px; text-align: left; padding:10px">
                                                            <ul class="nav nav-divider small">
                                                                <li class="nav-item">Booking ID: 
                                                                    {{upcoming.ib_ticket_response?.Response?.BookingId}}
                                                                </li>&nbsp;|&nbsp;
                                                                <li class="nav-item">PNR: 
                                                                    {{upcoming.ib_ticket_response?.Response?.PNR}}
                                                                </li>&nbsp;|&nbsp;
                                                                <li class="nav-item">
                                                                    <span v-if="upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">1 Stop</span> 
                                                                    <span v-else>Non Stop</span>
                                                                </li>&nbsp;|&nbsp;
                                                                <li class="nav-item">
                                                                    <span v-if="upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                        {{ Math.floor(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                        {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                                    </span>
                                                                    <span v-else> 
                                                                        <span v-if="upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                            {{ Math.floor(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                            {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                        </span>
                                                                        <span v-else>
                                                                            {{ Math.floor(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                            {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                        </span>
                                                                    </span>
                                                                </li>                                                          
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                                            <div class="row gx-lg-5 gx-3 py-2 gy-2 align-items-center">
                                                                <div class="col-xl-12 col-lg-12 col-md-12" >
                                                                    <div class="d-flex align-items-center justify-content-start">
                                                                        <div class="d-start fl-pic">
                                                                            <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                                        </div>
                                                                        <div class="d-end fl-title ps-2">
                                                                            <!-- <div class="text-dark fw-medium">{{ segment.Airline.AirlineName }}</div>																 -->
                                                                            <span class="text-dark fw-medium">
                                                                                {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.CityName }}
                                                                            </span>
                                                                            &nbsp;
                                                                            <i class="bi bi-arrow-right"></i>
                                                                            &nbsp;
                                                                            <span class="text-dark fw-medium" v-if="upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                                                {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.CityName }}
                                                                            </span>
                                                                            <span class="text-dark fw-medium" v-else>
                                                                                {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.CityName }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                                        {{ $formatDate(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'long') }}
                                                                        {{ $formatDate(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'time') }}
                                                                    </p>                                                    
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                        {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.AirportName }}, 
                                                                        {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal ? 'Terminal '+upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal : ''}}
                                                                    </span>    
                                                                </div>

                                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                                        <span v-if="upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">                                                            
                                                                            {{ $formatDate(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'long') }}
                                                                            {{ $formatDate(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'time') }}
                                                                        </span> 
                                                                        <span v-else>                                                            
                                                                            {{ $formatDate(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'long') }}
                                                                            {{ $formatDate(upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'time') }}
                                                                        </span>
                                                                    </p>
                                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                        <span v-if="upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                                            {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.AirportName }},  
                                                                            {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal ? 'Terminal '+upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal : ''}}
                                                                        </span> 
                                                                        <span v-else>
                                                                            {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.AirportName }},  
                                                                            {{ upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal ? 'Terminal '+upcoming.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal : ''}}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end align-self-end">
                                                        <router-link :to="'/bookingDetails/Flight/'+ upcoming.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                            MANAGE BOOKINGS
                                                        </router-link>
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                           

                            <!-- Upcomming Hotel -->
                            <div v-if="upcoming_hotel_bookings.length">    
                                <div class="card border mb-3" style="border-radius: 24px;"  v-for="hotel, key in upcoming_hotel_bookings" :key="key">
                                    <div class="card-body" >
                                    <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                        <div class="pb-4">
                                            <div class="d-md-flex justify-content-md-between align-items-center">
                                                <div class="d-flex align-items-center">
                                                    <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-hotel fa-xl"></i></div>

                                                    <div class="ms-2">
                                                        <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">
                                                            <span>
                                                                {{ hotel.detail_booking_response?.GetBookingDetailResult?.HotelName }}
                                                            </span>
                                                        </span>
                                                        <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                            <li class="nav-item">Booking ID: 
                                                                {{hotel.detail_booking_response?.GetBookingDetailResult?.BookingId}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">Hotel in  
                                                                {{hotel.detail_booking_response?.GetBookingDetailResult?.City}}
                                                            </li>                                                          
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row g-3 pt-2 align-items-center" v-if="hotel.detail_booking_response?.GetBookingDetailResult">
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <span>Check-In</span>
                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                        {{ getDate(hotel.detail_booking_response?.GetBookingDetailResult?.CheckInDate) }}
                                                    </p>                                                 
                                                </div>

                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;">Check-Out</span>
                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;color: black;">
                                                        <span>                                                            
                                                            {{ getDate(hotel.detail_booking_response?.GetBookingDetailResult?.CheckOutDate) }}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;">
                                                            {{ hotel.detail_booking_response?.GetBookingDetailResult?.NoOfRooms }} Room | 
                                                            {{ numberofDays(hotel.detail_booking_response?.GetBookingDetailResult) }} Night
                                                    </span>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 pt-3" style="text-align: right;">
                                                    <router-link :to="'/bookingDetails/Hotel/'+ hotel.detail_booking_response?.GetBookingDetailResult?.BookingId+'/'+hotel.booking_id" style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                        MANAGE BOOKINGS
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="tab-3">
                        
                        <!-- Completed bus -->                        
                        <div class="card border mb-4" v-for="complete, key in completed_bus_bookings" :key="key" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="d-md-flex justify-content-md-between align-items-center">
                                        <div class="d-flex align-items-center">
                                            <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-bus"></i></div>

                                            <div class="ms-2">
                                                <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">{{complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}} <i class="bi bi-arrow-right"></i> {{complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</span>
                                                <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                    <li class="nav-item">Booking ID: {{complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId}}</li>&nbsp;|&nbsp;
                                                    <li class="nav-item">TicketNo: {{complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TicketNo}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-3 pt-2 align-items-center">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                            <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                            <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                {{$formatDate(complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.DepartureTime, 'long')}}
                                            </p>                                                    
                                            <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;">{{complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}}</span>
                                            
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                            <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                            <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                {{$formatDate(complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.ArrivalTime, 'long')}}
                                            </p>
                                            <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;">{{complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</span>
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                            <span>Traveller's</span>
                                            <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                {{complete?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TravelName}}
                                            </p>
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 pt-3" style="text-align: right;">
                                            <router-link :to="'/bookingDetails/Bus/'+ complete.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                MANAGE BOOKINGS
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Completed Flight -->
                         <div v-for="complete, key in completed_flight_bookings" :key="key" >
                            <div class="card border mb-3" style="border-radius: 24px;" v-if="complete.ob_ticket_response?.ResponseStatus == 1" >
                                <div class="card-body">
                                    <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                        <div class="pb-4">
                                            <div class="row ">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div class="row" style="font-size: 16px; font-weight: 400; line-height: 16px; text-align: left; padding:10px">
                                                        <ul class="nav nav-divider small">
                                                            <li class="nav-item">Booking ID: 
                                                                {{complete.ob_ticket_response?.Response?.BookingId}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">PNR: 
                                                                {{complete.ob_ticket_response?.Response?.PNR}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">
                                                                <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 1"> One Way Trip</span> 
                                                                <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 2"> Return Trip</span> 
                                                                <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 3"> Multi City Trip</span> 
                                                                <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 5"> Special Return</span> 
                                                            </li>                                                            
                                                            <li v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.JourneyType!=2" class="nav-item">
                                                                &nbsp;|&nbsp;  <span>{{ AccumulatedDurationCalculation(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments) }}</span>
                                                                <!-- <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                    {{ Math.floor(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                    {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                                </span>
                                                                <span v-else> 
                                                                    <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                        {{ Math.floor(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                        {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                    </span>
                                                                    <span v-else>
                                                                        {{ Math.floor(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                        {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                    </span>
                                                                </span> -->
                                                            </li>                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9" v-for="segment,j in complete.ob_ticket_response?.Response?.FlightItinerary?.Segments" :key="j">
                                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                                        <div class="row gx-lg-5 gx-3 py-2 gy-2 align-items-center">
                                                            <div class="col-xl-12 col-lg-12 col-md-12" >
                                                                <div class="d-flex align-items-center justify-content-start">
                                                                    <div class="d-start fl-pic">
                                                                        <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                                    </div>
                                                                    <div class="d-end fl-title ps-2">                                                                    
                                                                        <span class="text-dark fw-medium">
                                                                            {{ segment?.Origin?.Airport?.CityName }}
                                                                        </span>
                                                                        &nbsp;
                                                                        <i class="bi bi-arrow-right"></i>
                                                                        &nbsp;
                                                                        <span class="text-dark fw-medium">
                                                                            {{ segment?.Destination?.Airport?.CityName }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                                    {{ $formatDate(segment?.Origin?.DepTime,'long') }}
                                                                    {{ $formatDate(segment?.Origin?.DepTime,'time') }}
                                                                </p>                                                    
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    {{ segment?.Origin?.Airport?.AirportName }}, 
                                                                    {{ segment?.Origin?.Airport?.Terminal ? 'Terminal '+segment?.Origin?.Airport?.Terminal : ''}}
                                                                </span>                                                    
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                                    <span>                                                            
                                                                        {{ $formatDate(segment?.Destination.ArrTime,'long') }}
                                                                        {{ $formatDate(segment?.Destination.ArrTime,'time') }}
                                                                    </span>
                                                                </p>
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    <span>
                                                                        {{ segment?.Destination?.Airport?.AirportName }},  
                                                                        {{ segment?.Destination?.Airport?.Terminal ? 'Terminal '+segment?.Destination?.Airport?.Terminal : ''}}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end align-self-end" v-if="!complete.ib_ticket_response">
                                                    <router-link :to="'/bookingDetails/Flight/'+ complete.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                        MANAGE BOOKINGS
                                                    </router-link>
                                                </div>
                                            </div>                                                                                            
                                        </div>

                                        <div class="row pt-3 justify-content-center" style="align-items:center" v-if="complete.ib_ticket_response">
                                            <div class="text-center col-xl-3 col-lg-3 col-md-3 col-sm-3" style="border:1px dashed black; border-radius:48px; 
                                                padding:6px !important; font-weight:500; font-size:12px;">                                                
                                                <span>
                                                    Return Flight
                                                </span>
                                            </div>																		
                                        </div>

                                        <div v-if="complete.ib_ticket_response?.ResponseStatus == 1" class="pt-4">
                                            <div class="row ">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div class="row" style="font-size: 16px; font-weight: 400; line-height: 16px; text-align: left; padding:10px">
                                                        <ul class="nav nav-divider small">
                                                            <li class="nav-item">Booking ID: 
                                                                {{complete.ib_ticket_response?.Response?.BookingId}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">PNR: 
                                                                {{complete.ib_ticket_response?.Response?.PNR}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">
                                                                <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">1 Stop</span> 
                                                                <span v-else>Non Stop</span>
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">
                                                                <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                    {{ Math.floor(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                    {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                                </span>
                                                                <span v-else> 
                                                                    <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                        {{ Math.floor(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                    </span>
                                                                    <span v-else>
                                                                        {{ Math.floor(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                    </span>
                                                                </span>
                                                            </li>                                                          
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                                        <div class="row gx-lg-5 gx-3 py-2 gy-2 align-items-center">
                                                            <div class="col-xl-12 col-lg-12 col-md-12" >
                                                                <div class="d-flex align-items-center justify-content-start">
                                                                    <div class="d-start fl-pic">
                                                                        <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                                    </div>
                                                                    <div class="d-end fl-title ps-2">
                                                                        <!-- <div class="text-dark fw-medium">{{ segment.Airline.AirlineName }}</div>																 -->
                                                                        <span class="text-dark fw-medium">
                                                                            {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.CityName }}
                                                                        </span>
                                                                        &nbsp;
                                                                        <i class="bi bi-arrow-right"></i>
                                                                        &nbsp;
                                                                        <span class="text-dark fw-medium" v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                                            {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.CityName }}
                                                                        </span>
                                                                        <span class="text-dark fw-medium" v-else>
                                                                            {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.CityName }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                                    {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'long') }}
                                                                    {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'time') }}
                                                                </p>                                                    
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.AirportName }}, 
                                                                    {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal ? 'Terminal '+complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal : ''}}
                                                                </span>    
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                                    <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">                                                            
                                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'long') }}
                                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'time') }}
                                                                    </span> 
                                                                    <span v-else>                                                            
                                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'long') }}
                                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'time') }}
                                                                    </span>
                                                                </p>
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.AirportName }},  
                                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal ? 'Terminal '+complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal : ''}}
                                                                    </span> 
                                                                    <span v-else>
                                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.AirportName }},  
                                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal ? 'Terminal '+complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal : ''}}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end align-self-end">
                                                    <router-link :to="'/bookingDetails/Flight/'+ complete.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                        MANAGE BOOKINGS
                                                    </router-link>
                                                </div>
                                            </div>                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </div>
                        <!-- <div class="card border mb-4" v-for="complete, key in completed_flight_bookings" :key="key" style="border-radius: 24px;">
                            <div class="card-body" >
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div v-if="complete.ob_ticket_response?.ResponseStatus == 1" class="pb-4">
                                        <div class="d-md-flex justify-content-md-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-plane"></i></div>

                                                <div class="ms-2">
                                                    <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">
                                                        <span>
                                                            {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.CityName }}
                                                        </span>
                                                        &nbsp;
                                                        <i class="bi bi-arrow-right"></i>
                                                        &nbsp;
                                                        <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                            {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.CityName }}
                                                        </span>
                                                        <span  v-else>
                                                            {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.CityName }}
                                                        </span>
                                                    </span>
                                                    <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                        <li class="nav-item">Booking ID: 
                                                            {{complete.ob_ticket_response?.Response?.BookingId}}
                                                        </li>&nbsp;|&nbsp;
                                                        <li class="nav-item">PNR: 
                                                            {{complete.ob_ticket_response?.Response?.PNR}}
                                                        </li>&nbsp;|&nbsp;
                                                        <li class="nav-item">
                                                            <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">1 Stop</span> 
                                                            <span v-else>Non Stop</span>
                                                        </li>&nbsp;|&nbsp;
                                                        <li class="nav-item">
                                                            <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                {{ Math.floor(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                            </span>
                                                            <span v-else> 
                                                                <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                    {{ Math.floor(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                    {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                </span>
                                                                <span v-else>
                                                                    {{ Math.floor(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                    {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                </span>
                                                            </span>
                                                        </li>                                                            
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row g-3 pt-2 align-items-center">
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                    {{ $formatDate(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'long') }}
                                                    {{ $formatDate(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'time') }}
                                                </p>                                                    
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                    {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.AirportName }}, 
                                                    {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal ? 'Terminal '+complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal : ''}}
                                                </span>                                                    
                                            </div>

                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                    <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">                                                            
                                                        {{ $formatDate(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'long') }}
                                                        {{ $formatDate(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'time') }}
                                                    </span> 
                                                    <span v-else>                                                            
                                                        {{ $formatDate(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'long') }}
                                                        {{ $formatDate(complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'time') }}
                                                    </span>
                                                </p>
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                    <span v-if="complete.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                        {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.AirportName }},  
                                                        {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal ? 'Terminal '+complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal : ''}}
                                                    </span> 
                                                    <span v-else>
                                                        {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.AirportName }},  
                                                        {{ complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal ? 'Terminal '+complete.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal : ''}}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-3" style="text-align: right;" v-if="!complete.ib_ticket_response">
                                                <router-link :to="'/bookingDetails/Flight/'+ complete.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                    MANAGE BOOKINGS
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row pt-3 justify-content-center" style="align-items:center" v-if="complete.ib_ticket_response">
                                        <div class="text-center col-xl-3 col-lg-3 col-md-3 col-sm-3" style="border:1px dashed black; border-radius:48px; 
                                            padding:6px !important; font-weight:500; font-size:12px;">                                                
                                            <span>
                                                Return Flight
                                            </span>
                                        </div>																		
                                    </div>

                                    <div v-if="complete.ib_ticket_response?.ResponseStatus == 1" class="pt-4">
                                        <div class="d-md-flex justify-content-md-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-plane"></i></div>

                                                <div class="ms-2">
                                                    <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">
                                                        <span>
                                                            {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.CityName }}
                                                        </span>
                                                        &nbsp;
                                                        <i class="bi bi-arrow-right"></i>
                                                        &nbsp;
                                                        <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                            {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.CityName }}
                                                        </span>
                                                        <span  v-else>
                                                            {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.CityName }}
                                                        </span>
                                                    </span>
                                                    <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                        <li class="nav-item">Booking ID: 
                                                            {{complete.ib_ticket_response?.Response?.BookingId}}
                                                        </li>&nbsp;|&nbsp;
                                                        <li class="nav-item">PNR: 
                                                            {{complete.ib_ticket_response?.Response?.PNR}}
                                                        </li>&nbsp;|&nbsp;
                                                        <li class="nav-item">
                                                            <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">1 Stop</span> 
                                                            <span v-else>Non Stop</span>
                                                        </li>&nbsp;|&nbsp;
                                                        <li class="nav-item">
                                                            <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                {{ Math.floor(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                            </span>
                                                            <span v-else> 
                                                                <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                    {{ Math.floor(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                    {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                </span>
                                                                <span v-else>
                                                                    {{ Math.floor(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                    {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                </span>
                                                            </span>
                                                        </li>                                                            
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row g-3 pt-2 align-items-center">
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                    {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'long') }}
                                                    {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'time') }}
                                                </p>                                                    
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                    {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.AirportName }}, 
                                                    {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal ? 'Terminal '+complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal : ''}}
                                                </span>                                                    
                                            </div>

                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                    <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">                                                            
                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'long') }}
                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'time') }}
                                                    </span> 
                                                    <span v-else>                                                            
                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'long') }}
                                                        {{ $formatDate(complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'time') }}
                                                    </span>
                                                </p>
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                    <span v-if="complete.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.AirportName }},  
                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal ? 'Terminal '+complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal : ''}}
                                                    </span> 
                                                    <span v-else>
                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.AirportName }},  
                                                        {{ complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal ? 'Terminal '+complete.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal : ''}}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 pt-3" style="text-align: right;">
                                                <router-link :to="'/bookingDetails/Flight/'+ complete.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                    MANAGE BOOKINGS
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div> -->

                        <!-- Completed Hotel -->
                        <div v-if="completed_hotel_bookings.length">  
                            <div class="card border mb-4" v-for="hotel_completed, key in completed_hotel_bookings" :key="key">
                                <div class="card-body" >
                                    <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                        <div class="pb-4">
                                            <div class="d-md-flex justify-content-md-between align-items-center">
                                                <div class="d-flex align-items-center">
                                                    <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-hotel fa-xl"></i></div>

                                                    <div class="ms-2">
                                                        <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">
                                                            <span>
                                                                {{ hotel_completed.detail_booking_response?.GetBookingDetailResult?.HotelName }}
                                                            </span>
                                                        </span>
                                                        <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                            <li class="nav-item">Booking ID: 
                                                                {{hotel_completed.detail_booking_response?.GetBookingDetailResult?.BookingId}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">Hotel in  
                                                                {{hotel_completed.detail_booking_response?.GetBookingDetailResult?.City}}
                                                            </li>                                                          
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row g-3 pt-2 align-items-center" v-if="hotel_completed.detail_booking_response?.GetBookingDetailResult">
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <span>Check-In</span>
                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                        {{ getDate(hotel_completed.detail_booking_response?.GetBookingDetailResult?.CheckInDate) }}
                                                    </p>                                                 
                                                </div>

                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;">Check-Out</span>
                                                    <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;color: black;">
                                                        <span>                                                            
                                                            {{ getDate(hotel_completed.detail_booking_response?.GetBookingDetailResult?.CheckOutDate) }}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <span style="font-size: 14px;font-weight: 400;line-height: 16px;">
                                                            {{ hotel_completed.detail_booking_response?.GetBookingDetailResult?.NoOfRooms }} Room | 
                                                            {{ numberofDays(hotel_completed.detail_booking_response?.GetBookingDetailResult) }} Night
                                                    </span>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 pt-3" style="text-align: right;">
                                                    <router-link :to="'/bookingDetails/Hotel/'+ hotel_completed.detail_booking_response?.GetBookingDetailResult?.BookingId+'/'+hotel_completed.booking_id" style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                        MANAGE BOOKINGS
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="tab-pane fade" id="tab-2">
                        
                        <!-- Canceled bus -->                        
                        <div class="card border mb-4" v-for="canceled, key in canceled_bus_bookings" :key="key" style="border-radius: 24px;">                                
                            <div class="card-body" >
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="d-md-flex justify-content-md-between align-items-center">
                                        <div class="d-flex align-items-center">
                                            <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-bus"></i></div>

                                            <div class="ms-2">
                                                <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">{{canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}} <i class="bi bi-arrow-right"></i> {{canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</span>
                                                <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                    <li class="nav-item">Booking ID: {{canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId}}</li>&nbsp;|&nbsp;
                                                    <li class="nav-item">TicketNo: {{canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TicketNo}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-3 pt-2 align-items-center">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                            <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                            <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                {{$formatDate(canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.DepartureTime, 'long')}}
                                            </p>                                                    
                                            <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;">{{canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}}</span>
                                            
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                            <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                            <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                {{$formatDate(canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.ArrivalTime, 'long')}}
                                            </p>
                                            <span style="font-size: 14px;font-weight: 500;line-height: 16px;text-align: left;">{{canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</span>
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                                            <span>Traveller's</span>
                                            <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                {{canceled?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TravelName}}
                                            </p>
                                        </div>

                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 pt-3" style="text-align: right;">
                                            <router-link :to="'/bookingDetails/Bus/'+ canceled.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                MANAGE BOOKINGS
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Canceled Flight -->
                        <div v-for="canceled, key in canceled_flight_bookings" :key="key" >
                            <div class="card border mb-4" v-if="canceled.ob_ticket_response?.ResponseStatus == 1"  style="border-radius: 24px;">
                                <div class="card-body" >
                                    <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                        <div class="pb-4">
                                            <div class="row ">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div class="row" style="font-size: 16px; font-weight: 400; line-height: 16px; text-align: left; padding:10px">
                                                        <ul class="nav nav-divider small">
                                                            <li class="nav-item">Booking ID: 
                                                                {{canceled.ob_ticket_response?.Response?.BookingId}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">PNR: 
                                                                {{canceled.ob_ticket_response?.Response?.PNR}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">
                                                                <span v-if="canceled.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 1"> One Way Trip</span> 
                                                                <span v-if="canceled.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 2"> Return Trip</span> 
                                                                <span v-if="canceled.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 3"> Multi City Trip</span> 
                                                                <span v-if="canceled.ob_ticket_response?.Response?.FlightItinerary?.JourneyType == 5"> Special Return</span> 
                                                            </li>
                                                            <li v-if="canceled.ob_ticket_response?.Response?.FlightItinerary?.JourneyType!=2" class="nav-item">
                                                                &nbsp;|&nbsp;  <span>{{ AccumulatedDurationCalculation(canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments) }}</span>
                                                                <!-- <span v-if="canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                    {{ Math.floor(canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                    {{ canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                                </span>
                                                                <span v-else> 
                                                                    <span v-if="canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                        {{ Math.floor(canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                        {{ canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                    </span>
                                                                    <span v-else>
                                                                        {{ Math.floor(canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                        {{ canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                    </span>
                                                                </span> -->
                                                            </li>                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9" v-for="segment,j in canceled.ob_ticket_response?.Response?.FlightItinerary?.Segments" :key="j">
                                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                                        <div class="row gx-lg-5 gx-3 py-2 gy-2 align-items-center">
                                                            <div class="col-xl-12 col-lg-12 col-md-12" >
                                                                <div class="d-flex align-items-center justify-content-start">
                                                                    <div class="d-start fl-pic">
                                                                        <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                                    </div>
                                                                    <div class="d-end fl-title ps-2">
                                                                        <span class="text-dark fw-medium">
                                                                            {{ segment?.Origin?.Airport?.CityName }}
                                                                        </span>
                                                                        &nbsp;
                                                                        <i class="bi bi-arrow-right"></i>
                                                                        &nbsp;
                                                                        <span class="text-dark fw-medium">
                                                                            {{ segment?.Destination?.Airport?.CityName }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                                    {{ $formatDate(segment?.Origin?.DepTime,'long') }}
                                                                    {{ $formatDate(segment?.Origin?.DepTime,'time') }}
                                                                </p>                                                    
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    {{ segment?.Origin?.Airport?.AirportName }}, 
                                                                    {{ segment?.Origin?.Airport?.Terminal ? 'Terminal '+segment?.Origin?.Airport?.Terminal : ''}}
                                                                </span>                                                    
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                                    <span>                                                            
                                                                        {{ $formatDate(segment?.Destination.ArrTime,'long') }}
                                                                        {{ $formatDate(segment?.Destination.ArrTime,'time') }}
                                                                    </span>
                                                                </p>
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    <span>
                                                                        {{ segment?.Destination?.Airport?.AirportName }},  
                                                                        {{ segment?.Destination?.Airport?.Terminal ? 'Terminal '+segment?.Destination?.Airport?.Terminal : ''}}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pt-3 justify-content-center" style="align-items:center" v-if="canceled.ib_ticket_response">
                                            <div class="text-center col-xl-3 col-lg-3 col-md-3 col-sm-3" style="border:1px dashed black; border-radius:48px; 
                                                padding:6px !important; font-weight:500; font-size:12px;">                                                
                                                <span>
                                                    Return Flight
                                                </span>
                                            </div>																		
                                        </div>

                                        <div v-if="canceled.ib_ticket_response?.ResponseStatus == 1" class="pt-4">
                                            <div class="row ">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div class="row" style="font-size: 16px; font-weight: 400; line-height: 16px; text-align: left; padding:10px">
                                                        <ul class="nav nav-divider small">                                                       
                                                            <li class="nav-item">Booking ID: 
                                                                {{canceled.ib_ticket_response?.Response?.BookingId}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">PNR: 
                                                                {{canceled.ib_ticket_response?.Response?.PNR}}
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">
                                                                <!-- <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">1 Stop</span> 
                                                                <span v-else>Non Stop</span> -->
                                                                <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.JourneyType == 1"> One Way Trip</span> 
                                                                <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.JourneyType == 2"> Return Trip</span> 
                                                                <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.JourneyType == 3"> Multi City Trip</span> 
                                                                <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.JourneyType == 5"> Special Return</span> 
                                                            </li>&nbsp;|&nbsp;
                                                            <li class="nav-item">
                                                                <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1"> 
                                                                    {{ Math.floor(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration / 60) }}H 
                                                                    {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.AccumulatedDuration % 60 }}M 
                                                                </span>
                                                                <span v-else> 
                                                                    <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration">
                                                                        {{ Math.floor(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration / 60) }}H 
                                                                        {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.AccumulatedDuration % 60 }}M 
                                                                    </span>
                                                                    <span v-else>
                                                                        {{ Math.floor(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration / 60) }}H 
                                                                        {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Duration % 60 }}M 
                                                                    </span>
                                                                </span>
                                                            </li>                                                          
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                                        <div class="row gx-lg-5 gx-3 py-2 gy-2 align-items-center">
                                                            <div class="col-xl-12 col-lg-12 col-md-12" >
                                                                <div class="d-flex align-items-center justify-content-start">
                                                                    <div class="d-start fl-pic">
                                                                        <img class="img-fluid" src="img/flight-plane-svgrepo-com.svg" width="25" alt="image">
                                                                    </div>
                                                                    <div class="d-end fl-title ps-2">
                                                                        <span class="text-dark fw-medium">
                                                                            {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.CityName }}
                                                                        </span>
                                                                        &nbsp;
                                                                        <i class="bi bi-arrow-right"></i>
                                                                        &nbsp;
                                                                        <span class="text-dark fw-medium" v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                                            {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.CityName }}
                                                                        </span>
                                                                        <span class="text-dark fw-medium" v-else>
                                                                            {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.CityName }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">From</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                                    {{ $formatDate(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'long') }}
                                                                    {{ $formatDate(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.DepTime,'time') }}
                                                                </p>                                                    
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.AirportName }}, 
                                                                    {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal ? 'Terminal '+canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Origin?.Airport?.Terminal : ''}}
                                                                </span>    
                                                            </div>

                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">To</span>
                                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">
                                                                    <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">                                                            
                                                                        {{ $formatDate(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'long') }}
                                                                        {{ $formatDate(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.ArrTime,'time') }}
                                                                    </span> 
                                                                    <span v-else>                                                            
                                                                        {{ $formatDate(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'long') }}
                                                                        {{ $formatDate(canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination.ArrTime,'time') }}
                                                                    </span>
                                                                </p>
                                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;text-align: left;">
                                                                    <span v-if="canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments?.length>1">
                                                                        {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.AirportName }},  
                                                                        {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal ? 'Terminal '+canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[1]?.Destination?.Airport?.Terminal : ''}}
                                                                    </span> 
                                                                    <span v-else>
                                                                        {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.AirportName }},  
                                                                        {{ canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal ? 'Terminal '+canceled.ib_ticket_response?.Response?.FlightItinerary?.Segments[0]?.Destination?.Airport?.Terminal : ''}}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end align-self-end">
                                                    <router-link :to="'/bookingDetails/Flight/'+ canceled.booking_id " style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                        MANAGE BOOKINGS
                                                    </router-link>
                                                </div>
                                            </div>                                                
                                        </div>                                    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <!-- Canceled Hotel -->
                        <!-- <div class="card border mb-4" v-for="canceled, key in canceled_hotel_bookings" :key="key">
                        </div> -->
                        <div v-if="cancelled_hotel_bookings.length">    
                            <div class="card border mb-4" v-for="canceled_hotel, key in cancelled_hotel_bookings" :key="key" style="border-radius: 24px;">
                                <div class="card-body" >
                                <div style="border: 1px dashed; border-radius: 16px; padding: 20px;">
                                    <div class="pb-4">
                                        <div class="d-md-flex justify-content-md-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-hotel fa-xl"></i></div>

                                                <div class="ms-2">
                                                    <span style="font-size: 20px;font-weight: 500;line-height: 22px;text-align: left;">
                                                        <span>
                                                            {{ canceled_hotel.detail_booking_response?.GetBookingDetailResult?.HotelName }}
                                                        </span>
                                                    </span>
                                                    <ul class="nav nav-divider small" style="font-size: 14px; font-weight: 400; line-height: 16px; text-align: left;">
                                                        <li class="nav-item">Booking ID: 
                                                            {{canceled_hotel.detail_booking_response?.GetBookingDetailResult?.BookingId}}
                                                        </li>&nbsp;|&nbsp;
                                                        <li class="nav-item">Hotel in  
                                                            {{canceled_hotel.detail_booking_response?.GetBookingDetailResult?.City}}
                                                        </li>                                                          
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row g-3 pt-2 align-items-center" v-if="canceled_hotel.detail_booking_response?.GetBookingDetailResult">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <span>Check-In</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;text-align: left;color: black;">                                                    
                                                    {{ getDate(canceled_hotel.detail_booking_response?.GetBookingDetailResult?.CheckInDate) }}
                                                </p>                                                 
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;">Check-Out</span>
                                                <p class="mb-0" style="font-size: 16px;font-weight: 500;line-height: 16px;color: black;">
                                                    <span>                                                            
                                                        {{ getDate(canceled_hotel.detail_booking_response?.GetBookingDetailResult?.CheckOutDate) }}
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <span style="font-size: 14px;font-weight: 400;line-height: 16px;">
                                                        {{ canceled_hotel.detail_booking_response?.GetBookingDetailResult?.NoOfRooms }} Room | 
                                                        {{ numberofDays(canceled_hotel.detail_booking_response?.GetBookingDetailResult) }} Night
                                                </span>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 pt-3" style="text-align: right;">
                                                <router-link :to="'/bookingDetails/Hotel/'+ canceled_hotel.detail_booking_response?.GetBookingDetailResult?.BookingId+'/'+canceled_hotel.booking_id" style="height: 40px; padding: 8px 16px 8px 16px;gap: 11px;border-radius: 8px;border: 1px;" class="btn btn-primary mb-0" type="button">
                                                    MANAGE BOOKINGS
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>   
                            </div>
                        </div>
                    </div>


                </div>



                <!-- <div class="col-lg-8 col-xl-9 ps-xl-5">
                    <div class="d-grid mb-0 d-lg-none w-100">
                        <button class="btn btn-primary mb-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar"><i class="fas fa-sliders-h"></i> Menu</button>
                    </div>
                    <div class="card border bg-transparent">
                        <div class="card-header bg-transparent border-bottom">
                            <h6 class="card-header-title">My Bookings</h6>
                        </div>
                        <div class="card-body p-0">
                            <ul class="nav nav-tabs nav-bottom-line nav-responsive nav-justified">
                                <li class="nav-item">
                                    <a class="nav-link mb-0 active" data-bs-toggle="tab" href="#tab-1"><i class="bi bi-briefcase-fill fa-fw me-1"></i>Upcoming Booking</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-2"><i class="bi bi-x-octagon fa-fw me-1"></i>Canceled Booking</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-3"><i class="bi bi-patch-check fa-fw me-1"></i>Completed Booking</a>
                                </li>
                            </ul>
                            <div class="tab-content p-2 p-sm-4" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="tab-1">
                                    <div class="card border mb-4" v-for="upcoming, key in upcoming_bus_bookings" :key="key">
                                        <div class="card-header border-bottom d-md-flex justify-content-md-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-bus"></i></div>

                                                <div class="ms-2">
                                                    <h6 class="card-title mb-0">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}} to {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</h6>
                                                    <ul class="nav nav-divider small">
                                                        <li class="nav-item">Booking ID: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId}}</li>&nbsp;&nbsp;
                                                        <li class="nav-item">TicketNo: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TicketNo}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="mt-2 mt-md-0">
                                                <a class="nav-link mb-0 text-danger" type="button" @click="cancelationBus(upcoming)">
                                                    <i class="bi bi-x-octagon fa-fw me-1"></i>Cancellation
                                                </a>
                                            </div>
                                            <div class="mt-2 mt-md-0">
                                                <router-link :to="'/bookingDetails/'+ upcoming.booking_id "  class="btn btn-md full-width px-5 btn-primary fw-medium" type="button">View Booking</router-link>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row g-3">
                                                <div class="col-sm-6 col-md-4">
                                                    <span>Pickup address</span>
                                                    <h6 class="mb-0">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointName}}</h6>                                                    
                                                    
                                                </div>

                                                <div class="col-sm-6 col-md-4">
                                                    <span>Contact</span>
                                                    <h6 class="mb-0">Contact: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointContactNumber}}</h6>
                                                </div>

                                                <div class="col-md-4">
                                                    <span>Departure Time</span>
                                                    <h6 class="mb-0">{{changeFormatdate(upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointTime)}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>
                                <div class="tab-pane fade" id="tab-2">
                                    <div class="card border mb-4" v-for="upcoming, key in canceled_bus_bookings" :key="key">
                                        <div class="card-header border-bottom d-md-flex justify-content-md-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-bus"></i></div>

                                                <div class="ms-2">
                                                    <h6 class="card-title mb-0">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}} to {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</h6>
                                                    <ul class="nav nav-divider small">
                                                        <li class="nav-item">Booking ID: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId}}</li>&nbsp;&nbsp;
                                                        <li class="nav-item">TicketNo: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TicketNo}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="mt-2 mt-md-0">
                                                <router-link :to="'/bookingDetails/'+ upcoming.booking_id "  class="btn btn-md full-width px-5 btn-primary fw-medium" type="button">View Booking</router-link>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row g-3">
                                                <div class="col-sm-6 col-md-4">
                                                    <span>Pickup address</span>
                                                    <h6 class="mb-0">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointName}}</h6>                                                    
                                                    
                                                </div>

                                                <div class="col-sm-6 col-md-4">
                                                    <span>Contact</span>
                                                    <h6 class="mb-0">Contact: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointContactNumber}}</h6>
                                                </div>

                                                <div class="col-sm-6 col-md-4">
                                                    <span>Departure Time</span>
                                                    <h6 class="mb-0">{{changeFormatdate(upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointTime)}}</h6>
                                                </div>

                                                <div class="col-sm-12" >
                                                    <h6 class="mb-0" style="float: right;">
                                                        <span class="text-danger"> 
                                                            <i class="bi bi-x-octagon fa-fw me-1"></i>Bus Canceled
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div class="tab-pane fade" id="tab-3">
                                    <div class="card border mb-4" v-for="upcoming, key in completed_bus_bookings" :key="key">
                                        <div class="card-header border-bottom d-md-flex justify-content-md-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="icon-lg bg-light rounded-circle flex-shrink-0"><i class="fa-solid fa-bus"></i></div>

                                                <div class="ms-2">
                                                    <h6 class="card-title mb-0">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Origin}} to {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.Destination}}</h6>
                                                    <ul class="nav nav-divider small">
                                                        <li class="nav-item">Booking ID: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BusId}}</li>&nbsp;&nbsp;
                                                        <li class="nav-item">TicketNo: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.TicketNo}}</li>
                                                    </ul>
                                                </div>
                                            </div>                                            
                                            <div class="mt-2 mt-md-0">
                                                <router-link :to="'/bookingDetails/'+ upcoming.booking_id "  class="btn btn-md full-width px-5 btn-primary fw-medium" type="button">View Booking</router-link>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row g-3">
                                                <div class="col-sm-6 col-md-4">
                                                    <span>Pickup address</span>
                                                    <h6 class="mb-0">{{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointName}}</h6>                                                    
                                                    
                                                </div>

                                                <div class="col-sm-6 col-md-4">
                                                    <span>Contact</span>
                                                    <h6 class="mb-0">Contact: {{upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointContactNumber}}</h6>
                                                </div>

                                                <div class="col-md-4">
                                                    <span>Departure Time</span>
                                                    <h6 class="mb-0">{{changeFormatdate(upcoming?.detail_booking_response?.GetBookingDetailResult?.Itinerary?.BoardingPointdetails?.CityPointTime)}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>
<script>
 import ProfileSidebar from "@/components/ProfileSidebar.vue";
 import moment from "moment";
 export default {
    data(){
        return {
            booking:[],
            upcoming_bus_bookings:[],
            canceled_bus_bookings:[],
            completed_bus_bookings:[],
            upcoming_flight_bookings:[],
            completed_flight_bookings:[],
            canceled_flight_bookings:[],
            upcoming_hotel_bookings:[],
            cancelled_hotel_bookings:[],
            completed_hotel_bookings:[],
            details:'',
            errors:[],
            meta: {
                EndUserIp:'',
                TokenId:'',                    
            },
            
        }
    },
    mounted() {
        window.scrollTo(0, 0);     
        this.index();
        // this.generateToken();
    },
    methods:{
        getDate(date){
            return moment(String(date)).format("DD-MM-YYYY");
        },
        numberofDays(hotel){
            const checkIn = moment(hotel.CheckInDate);
            const checkOut = moment(hotel.CheckOutDate);
            return checkOut.diff(checkIn, 'days');
        },
        changeFormatdate(date) {
            return moment(String(date)).format("DD-MM-YYYY HH:mm:ss");
        },
        generateToken(){
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "generateToken" })
            .then(function (response) {
                loader.hide();
                // console.log('response:---',response)
                vm.$store.dispatch("setFlightSearch", null);
                vm.$store.dispatch("setFlightMeta", null);           
                vm.meta.EndUserIp = response.data.EndUserIp
                vm.meta.TokenId = response.data.Token
                vm.$store.dispatch("setFlightMeta", vm.meta);
                vm.$store.dispatch("setBusSearch", vm.meta);
            })
            .catch(function (error) {
                loader.hide();
                console.log(error)
            });
        },
        index(){
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "getBookings", data:{ 'customer_id': vm.$store.getters?.customer?.customer_id } })
            .then(function (response) {            
                loader.hide();
                vm.booking = response.data?.booking;
                vm.upcoming_bus_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Bus' && ele.booking_status == 'UpComing'
                })

                vm.completed_bus_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Bus' &&  ele.booking_status == 'Completed'
                })

                vm.canceled_bus_bookings = vm.booking.filter(function(ele){
                    return ele.booking_status == 'Cancelled' && ele.booking_type =='Bus'
                })

                vm.upcoming_flight_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Flight' &&  ele.booking_status == 'UpComing'
                })

                vm.completed_flight_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Flight' &&  ele.booking_status == 'Completed'
                })
                
                vm.canceled_flight_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Flight' &&  ele.booking_status == 'Cancelled'
                })

                vm.upcoming_hotel_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Hotel' &&  ele.booking_status == 'UpComing'
                })

                vm.cancelled_hotel_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Hotel' &&  ele.booking_status == 'Cancelled'
                })

                vm.completed_hotel_bookings = vm.booking.filter(function(ele){
                    return ele.booking_type =='Hotel' &&  ele.booking_status == 'Completed'
                })

                // console.log(vm.completed_bus_bookings);
                
                document.getElementById('tab-1-btn').click();
                window.scrollTo(0, 0);     
                // vm.canceled_bookings = response.data?.canceled_bookings;
                // vm.completed_bookings = response.data?.completed;
            })
            .catch(function (error) {
                loader.hide();
                if(error.response.data.errors){
                    vm.errors = error?.response?.data?.errors;
                }
                vm.$store.dispatch("error", error?.response?.data?.message);
            });
        },
        AccumulatedDurationCalculation(segement){
			let duration = ""
            // console.log('segement.length:----', segement)
            if(segement.length){
                for(let i = 0; i <segement.length; i++){
                    if(segement.length > 1){
                        duration = segement[i]?.AccumulatedDuration ? segement[i]?.AccumulatedDuration : 0;
                    }
                    else{
                        duration = segement[i]?.Duration ? segement[i]?.Duration : 0;
                    }
			    }
            }
            else{
                duration = segement?.Duration ? segement?.Duration : 0;
            }
			
			return Math.floor(duration / 60)+ "H " + duration % 60 +"M";
		},
		calculateLayover(arrivalTime, nextDepartureTime) { 
			// console.log("arrivalTime, nextDepartureTime:-----", arrivalTime, nextDepartureTime)
			const arrival = new Date(arrivalTime); 
			const nextDeparture = new Date(nextDepartureTime); 
			const layoverMilliseconds = nextDeparture - arrival; 
			const layoverMinutes = Math.floor(layoverMilliseconds / (1000 * 60)); 
			const hours = Math.floor(layoverMinutes / 60); 
			const minutes = layoverMinutes % 60; 
			return `${hours}h ${minutes}m`; 
		}
    },
 }
</script>
