<template>
    <div>
        <section class="pt-4 gray-simple position-relative">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="row align-items-start">
                            <div class="col-xl-8 col-lg-8 col-md-12">
                                <h5 class="fw-bold fs-6 text-uppercase">Property Information</h5>
                                <div class="card p-3 mb-xl-4 mb-lg-4 mb-4">
                                    <div class="card-box list-layout-block border br-dashed rounded-3 p-2">
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-3 col-md">
                                                <div class="cardImage__caps rounded-2 overflow-hidden h-100">
                                                    <img class="img-fluid h-100 object-fit" src="img/hotel/hotel-1.jpg" alt="image" />
                                                </div>
                                            </div>
                                            <div class="col-xl col-lg col-md">
                                                <div class="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-inline-block">
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                            <i class="fa fa-star text-warning text-xs"></i>
                                                        </div>
                                                        <div class="square--40 rounded-2 bg-success text-light fw-semibold">4.8</div>
                                                    </div>
                                                    <h4 class="fs-5 fw-bold mb-1">{{  hotel?.HotelName }}</h4>
                                                    <ul class="row g-2 p-0">
                                                        <li class="col-auto">
                                                            <span class="text-muted-2 text-md">{{ hotel?.AddressLine1 }}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="row align-items-center justify-content-between g-2 my-4">
                                        <div class="col-4">
                                            <div class="gray rounded-2 p-2 border br-dashed">
                                                <span class="d-block text-muted-3 text-sm fw-medium text-uppercase mb-2">Check-In</span>
                                                <p class="text-dark fw-semibold lh-base text-md mb-0">{{ changeDateFormat(hotel?.CheckInDate) }}</p>
                                                <span class="text-dark text-md">From 14:40</span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-4">
                                            <div class="gray rounded-2 p-2 border br-dashed">
                                                <span class="d-block text-muted-3 text-sm fw-medium text-uppercase mb-2">Check-Out</span>
                                                <p class="text-dark fw-semibold lh-base text-md mb-0">{{ changeDateFormat(hotel?.CheckOutDate) }}</p>
                                                <span class="text-dark text-md">By 11:50</span>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-xl-12 col-lg-12 col-md-12 mt-4">
                                        <div class="card border-1 border-secondary br-dashed" v-for="room,key in hotel?.Rooms" :key="key">
                                            <div class="card-body py-md-2 d-flex flex-column h-100 position-relative">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <h6 class="card-title mb-1">{{ room?.RoomTypeName }}</h6>
                                                </div>
                                                <ul class="nav small nav-divider gap-2 mb-0">
                                                    <li class="nav-item mb-0"><i class="fa-solid fa-user me-1"></i>{{ room?.HotelPassenger?.length }} Guest</li>
                                                </ul>
                                                <ul class="list-group1 list-group-borderless small mb-0 mt-3 p-0">
                                                    <li class="list-group-item d-flex text-success mb-1" v-for="attributes,key in room_attributes" :key="key"><i class="bi bi-shield-fill me-2"></i>{{ attributes }}</li>
                                                    <li class="d-flex justify-content-between align-items-center">
                                                        <a href="#" class="text-primary" data-bs-toggle="modal" data-bs-target="#amenities">See all amenities</a>
                                                        <div style="text-align: right;" v-if="hotel.HotelBookingStatus=='Completed'">
                                                            <span style="color: green; height: 40px; padding: 8px 16px; gap: 11px; border-radius: 8px; border: 1px;"> Thank You for Staying </span>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6" style="text-align: right;" v-if="hotel.HotelBookingStatus=='Cancelled'">
                                                            <span style="color: rgb(206, 52, 38);"> Ticket Cancelled </span>
                                                        </div>
                                                        <div v-if="hotel.HotelBookingStatus=='Confirmed'">
                                                            <a href="#" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#cancellationPolicies">Cancel Booking</a>
                                                        </div>    
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 class="fw-bold fs-6 text-uppercase">Guest Details</h5>
                                <div class="card p-3 mb-xl-4 mb-lg-4 mb-4">
                                    <div class="card-box list-layout-block" style="margin-top: -23px">
                                        <div class="col-xl-12 col-lg-12 col-md-12 mt-4">
                                            <div class="card border-1 border-secondary br-dashed" v-for="room,key in hotel?.Rooms" :key="key">
                                                <div class="card-body py-md-2 d-flex flex-column h-100 position-relative">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Age</th>
                                                                <th>Email</th>
                                                                <th>Phone No</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="room, room_key in hotel.Rooms" :key="room_key">
                                                            <tr v-for="passenger, key in room.HotelPassenger" :key="key">
                                                                <td><i class="fa-solid fa-user me-1"></i>&nbsp;{{ passenger.Title+' '+passenger.FirstName+' '+passenger.LastName }}</td>
                                                                <td>{{ passenger.Age }}</td>
                                                                <td>{{ passenger.Email }}</td>
                                                                <td>{{ passenger.Phoneno }}</Td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12" v-if="hotel.HotelBookingStatus=='Cancelled'">
                                <h5 class="fw-bold fs-6 text-uppercase">Price Summary</h5>
                                <div class="side-block card rounded-2 p-2 mb-4" v-if="hotel.CancellationStatus?.length">
                                    <div class="mid-block rounded-2 p-2">
                                        <ul class="list-group list-group-borderless border-width-0 border br-dashed rounded-2 p-2">
                                            <li class="list-group-item px-2 d-flex justify-content-between">
                                                <span class="h6 fw-light mb-0">Total Price <small>({{ hotel.NoOfRooms }} Room x {{ numberofDays(hotel) }} Night)</small></span>
                                                <span class="fw-normal mb-0">{{ hotel.CancellationStatus[0].RefundAmount?.toFixed(2) }}
                                            </span>
                                            </li>
                                            <hr class="m-1" />
                                            <li class="list-group-item px-2 d-flex justify-content-between">
                                                <span class="h6 fw-light mb-0">Service Tax</span>
                                                <span class="h6 fw-normal mb-0">{{ hotel.CancellationStatus[0].ServiceTax?.toFixed(2) }}</span>
                                            </li>
                                            <hr class="m-1" />
                                            <li class="list-group-item bg-light d-flex justify-content-between rounded-2 px-2 mt-2">
                                                <span class="h5 fw-normal mb-0 ps-1">Refundable Amount</span>
                                                <span class="h5 fw-normal mb-0">{{ (hotel.CancellationStatus[0].RefundAmount - (Math.abs(hotel.CancellationStatus[0].ServiceTax))).toFixed(2) }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12" v-else>
                                <h5 class="fw-bold fs-6 text-uppercase">Price Summary</h5>
                                <div class="side-block card rounded-2 p-2 mb-4">
                                    <div class="mid-block rounded-2 p-2">
                                        <ul class="list-group list-group-borderless border-width-0 border br-dashed rounded-2 p-2">
                                            <li class="list-group-item px-2 d-flex justify-content-between">
                                                <span class="h6 fw-light mb-0">Base Price <small>({{ hotel.NoOfRooms }} Room x {{ numberofDays(hotel) }} Night)</small></span>
                                                <span class="fw-normal mb-0">{{ getBasePrice(hotel) }}
                                            </span>
                                            </li>
                                            <hr class="m-1" />
                                            <li class="list-group-item px-2 d-flex justify-content-between">
                                                <span class="h6 fw-light mb-0">Taxes & Service Fees</span>
                                                <span class="h6 fw-normal mb-0">{{ hotel?.NetTax?.toFixed(2) }}</span>
                                            </li>
                                            <hr class="m-1" />
                                            <li class="list-group-item bg-light d-flex justify-content-between rounded-2 px-2 mt-2">
                                                <span class="h5 fw-normal mb-0 ps-1">Total</span>
                                                <span class="h5 fw-normal mb-0">{{ hotel?.NetAmount?.toFixed(2) }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal fade" id="cancellationPolicies" tabindex="-1" aria-labelledby="cancellationPoliciesLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="cancellationPoliciesLabel">Cancelation Policies</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="bus_cancelation"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-light table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">From Date</th>
                                    <th class="text-center">Charge Type</th>
                                    <th class="text-center">Cancellation Charge</th>
                                </tr>
                            </thead>
                            <tbody v-for="room, room_key in hotel.Rooms" :key="room_key">
                                <tr v-for="policy, key in room.CancelPolicies" :key="key">
                                    <td>{{ policy.FromDate }}</td>
                                    <td>{{ policy.ChargeType }}</td>
                                    <td class="text-center">{{ policy.CancellationCharge }}</td>
                                </tr>
                            </tbody>
                        </table> 
                        <div class="text-center"> 
                            <button class="btn btn-primary btn-sm" @click="cancelBooking()">Cancel Booking</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="amenities" tabindex="-1" aria-labelledby="amenitiesLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="cancellationPoliciesLabel">Amenities</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="aminities_close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="vertical_scroll">
                            <table class="table table-light table-bordered table-striped">
                                <thead>
                                </thead>
                                <tbody v-for="room, room_key in hotel.Rooms" :key="room_key">
                                    <tr v-for="amenity, key in room.Amenities" :key="key">
                                        <td>{{ amenity }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Search from "@/components/Search.vue";
    let City = require("@/components/City.vue").default;
    export default {
        components: { flatPickr, Search, City },
        data() {
            return {
                
                hotel: {
                    BookingId: null,
                    EndUserIp:'35.154.18.213',
                    TokenId:null,
                    booking_id:null
                },
                SendChangeRequest : {
                    RequestType : '4',
                    Remarks : 'personal reason',
                    BookingId : null,
                    EndUserIp : '35.154.18.213',
                    TokenId : null,
                    booking_id : null
                }
            };
        },

        beforeRouteEnter(to, from, next) {
		next((vm) => {
                vm.hotel.BookingId = to.params.BookingId
                vm.SendChangeRequest.booking_id = to.params.booking_id
                vm.getBookingDetails()
            });
        },

        methods:{
            cancelBooking(){
                let vm = this
                vm.SendChangeRequest.BookingId = vm.hotel.BookingId
                if(confirm("Are you sure you want to cancel booking?")){
                    let loader = vm.$loading.show();
                    vm.$store
                        .dispatch("post", { uri: "hotels/hotelCancellationRequest", data: vm.SendChangeRequest })
                        .then(function (response) {
                            loader.hide();
                            vm.$router.push('/bookings')
                        })
                        .catch(function (error) {
                            loader.hide();
                            console.log(error)
                        });
                }
            },
            numberofDays(hotel){
                const checkIn = moment(hotel.CheckInDate);
                const checkOut = moment(hotel.CheckOutDate);
                return checkOut.diff(checkIn, 'days');
            },
            getBasePrice(hotel){
                let base_price = hotel.NetAmount - hotel.NetTax
                if(base_price){
                    return base_price.toFixed(2)
                }else{
                    return ''
                }
            },
            changeDateFormat(date){
                return moment(date).format('DD-MM-yyyy')
            },
            getBookingDetails(){
                let vm = this;
                let hotel = {
                    BookingId:vm.hotel.BookingId,
                    EndUserIp: vm.SendChangeRequest.EndUserIp,
                    TokenId:vm.SendChangeRequest.TokenId
                }
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotels/getBookingDetails", data: hotel })
                    .then(function (response) {
                        loader.hide();
                        console.log(response)
                        if ("GetBookingDetailResult" in response.data) {
                            vm.hotel = response.data.GetBookingDetailResult
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error)
                    });
            },
            generateToken() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "generateToken" })
                    .then(function (response) {
                        // vm.SendChangeRequest.EndUserIp = response.data.EndUserIp;
                        vm.SendChangeRequest.EndUserIp = '35.154.18.213'
                        vm.SendChangeRequest.TokenId = response.data.Token;
                        vm.getBookingDetails()
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }
</script>
<style scoped>
    .border-width-0 {
        --bs-list-group-border-width: 0px;
    }
    .font12{
        font-size: 12px;
        line-height: 16px !important;
    }
    .tncErrorBox {
        background-color: #fcdadb;
        border-radius: 4px;
        color: #ec2127;
        font-size: 10px;
        margin-bottom: 10px;
        padding: 4px 8px;
    }
    .btn-danger:hover{
        background: #ce3426 !important;
        border-color:#ce3426 !important;
        color: white !important;    
    }
    .vertical_scroll {
        max-height: 300px; 
        overflow-y: auto; 
    }
    
</style>
