<template>
    <div>
        <section class="pt-3 gray-simple">
            <div class="container">
                <div class="py-2 bg-search position-relative mb-3">
                    <div class="container">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="search-wrap position-relative">
                                    <div class="row align-items-end gy-3 gx-md-3 gx-sm-2 mb-2">
                                        <div class="col-xl-8 col-lg-7 col-md-12">
                                            <div class="row gy-3 gx-md-3 gx-sm-2">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                    <div class="form-group hdd-arrow mb-0">
                                                        <label class="text-white text-sm">TO</label>
                                                        <City :customStyle="'height:50px !important'" :initialize="search.CityName" @selectCity="selectDestination($event)" :class="{ 'is-invalid': errors?.DestinationCityId }"></City>
                                                        <span v-if="errors?.DestinationCityId" class="invalid-feedback">{{ errors?.DestinationCityId[0] }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div class="form-group hdd-arrow mb-0">
                                                        <lable class="text-white text-sm">CHOOSE DATE</lable>
                                                        <flatPickr
                                                            style="height: 50px !important;"
                                                            class="form-control hgt-62 fw-bold"
                                                            placeholder="Check-In & Check-Out"
                                                            :class="{ 'is-invalid': errors?.selectedDates }"
                                                            v-model="search.selectedDates"
                                                            :config="{mode:'range', minDate: 'today' }"
                                                            @onChange="getSelectedDates"
                                                        ></flatPickr>
                                                        <span v-if="errors?.selectedDates" class="invalid-feedback">{{ errors?.selectedDates[0] }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <div class="row align-items-end gy-3 gx-md-3 gx-sm-2">
                                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                    <div class="form-group mb-0">
                                                        <lable class="text-white text-sm">ROOMS & GUESTS</lable>
                                                        <div class="booking-form__input guests-input mixer-auto dropdown guest-selector me-2">
                                                            <button name="guests-btn" id="dropdownGuest" data-bs-auto-close="outside" data-bs-toggle="dropdown" class="form-guest-selector form-control selection-result"  style="height: 50px !important;">
                                                                <span v-if="search.NoOfAdults <= 1">{{ search.NoOfAdults }} Adult, </span>
                                                                <span v-if="search.NoOfAdults > 1">{{ search.NoOfAdults }} Adults, </span>
                                                                <span v-if="search?.NoOfChild == 1">1 Child, </span>
                                                                <span v-if="search?.NoOfChild > 1">{{search?.NoOfChild}} Children, </span>
                                                                <span v-if="search?.NoOfRooms == 1">1 Room</span>
                                                                <span v-if="search?.NoOfRooms > 1">{{search?.NoOfRooms}} Rooms</span>
                                                            </button>
                                                            <ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
                                                                <li class="d-flex justify-content-between">
                                                                    <div  class="hstack gap-1 align-items-center">
                                                                        <h6 class="mb-0" style="color: black;">Rooms</h6>
                                                                    </div>
                                                                    <div class="hstack gap-1 align-items-center">
                                                                        <button @click="updatePaxRooms('subtract')" type="button" class=" p-0 mb-0">
                                                                            <span class="guests-input__ctrl minus" id="room-subs-btn">
                                                                                <i class="fa-solid fa-minus"></i>
                                                                            </span>										
                                                                        </button>
                                                                        <span id="guests-count-room">{{ search.NoOfRooms }}</span>
                        
                                                                        <button @click="updatePaxRooms('add')" type="button" class="btn btn-link p-0 mb-0">
                                                                            <span class="guests-input__ctrl plus" id="room-add-btn">
                                                                                <i class="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                                <li class="dropdown-divider"></li>
                                                                <li v-for="room,key in search.PaxRooms" :key="key">
                                                                    <div class="d-flex justify-content-between">
                                                                        <div>
                                                                            <h6 class="mb-0" style="color:black">Adults</h6>
                                                                            <small class="fs-12" >Ages 18 or above</small>
                                                                            <div class="d-flex align-items-center mt-2">
                                                                                <button @click="changePassengersCount('Adult','-', room)" class="p-0 mb-0 text-center">
                                                                                    <span class="guests-input__ctrl minus" id="adults-subs-btn">
                                                                                        <i class="fa-solid fa-minus"></i>
                                                                                    </span>
                                                                                </button>
                                                                                <span id="guests-count-adults">{{ room.Adults }}</span>
                                                                                <button @click="changePassengersCount('Adult','+', room)" type="button" class="text-center p-0 mb-0">
                                                                                    <span class="guests-input__ctrl plus" id="adults-add-btn">
                                                                                        <i class="fa-solid fa-plus"></i>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h6 class="mb-0" style="color:black">Children</h6>
                                                                            <small class="fs-12">Ages less than 18 years</small>
                                                                            <div class="d-flex align-items-center mt-2">
                                                                                <button @click="changePassengersCount('Child','-', room)" class="p-0 mb-0 text-center">
                                                                                    <span class="guests-input__ctrl minus" id="children-subs-btn">
                                                                                        <i class="fa-solid fa-minus"></i>
                                                                                    </span>													
                                                                                </button>
                                                                                <span id="guests-count-children"> {{ room.Children }}</span>
                                                                                <button @click="changePassengersCount('Child','+', room)" type="button" class="text-center p-0 mb-0">
                                                                                    <span class="guests-input__ctrl plus" id="children-add-btn">
                                                                                        <i class="fa-solid fa-plus"></i>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>															
                                                                    <div class="mt-2"v-if="room.Children>0">
                                                                        <small class="fs-12">Children ages less than 18 years</small>
                                                                        <div class="row g-2">
                                                                            <div v-for="child, child_key in room.Children" :key="child_key" class="col-6" >
                                                                                <select class="form-control" v-model="room.ChildrenAges[child_key]" :class="{'is-invalid': errors.ChildNo}" style="height: 35px !important;">
                                                                                    <option value="">Select <i class="fas fa fa-angle-down"></i></option>
                                                                                    <option value=0><1</option>
                                                                                        <option :value="num" v-for="num, key in 17" :key="key">{{ num }}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>	
                                                                    </div>
                                                                    <li class="dropdown-divider"></li>
                                                                </li>											
                                                            </ul>	
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                    <div class="form-group mb-0">
                                                        <button @click.prevent="searchBuses()" type="button" class="btn hgt-50 btn-primary full-width fw-medium">Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card border-0 p-3 mb-4">
                            <div class="crd-heaader d-md-flex align-items-center justify-content-between mb-2">
                                <div class="crd-heaader-first">
                                    <div class="d-inline-flex align-items-center mb-1">
                                        <div class="d-inline-block ms-2">
                                            <i class="fa fa-star text-warning text-xs"></i>
                                            <i class="fa fa-star text-warning text-xs"></i>
                                            <i class="fa fa-star text-warning text-xs"></i>
                                            <i class="fa fa-star text-warning text-xs"></i>
                                            <i class="fa fa-star text-warning text-xs"></i>
                                        </div>
                                    </div>
                                    <div class="d-block">
                                        <h4 class="mb-0">{{  hotel.hotel_name }}</h4>
                                        <div class="">
                                            <p class="text-md m-0"><i class="fa-solid fa-location-dot me-2"></i>{{ hotel.hotel_details?.Address }} <a href="javascript:void(0)" @click="googleMapsUrl()" class="text-primary fw-medium ms-2">Show on Map</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="crd-heaader-last my-md-0 my-2">
                                    <div class="drix-wrap d-flex align-items-center">
                                        <div class="drix-last">
                                            <a href="" class="btn btn-primary fw-semibold" @click.prevent="scrollToDiv('tour-pills-tabContent')">Select Rooms</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row g-2">
                                <div class="col-md-6">
                                    <img :src=search?.img0 alt="image" class="rounded-2 img-fluid" />
                                </div>

                                <div class="col-md-6">
                                    <div class="row g-2">
                                        <div class="col-md-6">
                                            <img :src="search?.img1" alt="image" class="rounded-2 img-fluid" />
                                        </div>
                                        <div class="col-md-6">
                                            <img :src="search?.img2" alt="image" class="rounded-2 img-fluid" />
                                        </div>

                                        <div class="col-md-6">
                                            <img :src="search?.img3" alt="image" class="rounded-2 img-fluid" />
                                        </div>
                                        <div class="col-md-6">
                                            <img :src="search?.img4" alt="image" class="rounded-2 img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card bg-light1 p-0 mb-2">
                            <div class="card-body">
                                <ul class="nav nav-tabs nav-bottom-line nav-responsive border-0 text-uppercase" role="tablist">
                                    <li class="nav-item"><a class="nav-link mb-0 active" data-bs-toggle="tab" href="#tab-1">Rooms</a></li>
                                    <li class="nav-item"><a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-2">About</a></li>
                                    <li class="nav-item"><a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-3">Facilities</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="tab-content mb-0" id="tour-pills-tabContent">
                            <div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
                                <h6>Select Your Room</h6>
                                <div class="row mb-4">
                                    <div class="col-md-4 col-xs-12 col-sm-12 col-lg-4" v-for="(room,key) in rooms" :key="key" style="margin-bottom: 10px;">
                                        <div class="card border-1 border-secondary br-dashed" style="height: 160px;">
                                            <div class="card-body py-md-2 d-flex flex-column h-100 position-relative">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <h6 class="card-title mb-1" v-if="'Name' in room && room.Name.length">{{ room.Name[0] }}</h6>
                                                    <div class="badge" style="background-color: #D0FFD8; color:green" v-if="room.IsRefundable">Refundable</div>
                                                    <div class="badge" style="background-color: #FFD0D3; color:red" v-if="!room.IsRefundable">Non-Refundable</div>
                                                </div>

                                                <ul class="list-group1 list-group-borderless small mb-3 mt-3 p-0">
                                                    <li class="list-group-item d-flex text-success mb-1" v-for="attributes,key in room_attributes" :key="key"><i class="bi bi-shield-fill me-2"></i>{{ attributes }}</li>
                                                </ul>
                                                
                                                <div class="">
                                                    <div class="d-flex align-items-center">
                                                        <h5 class="fw-bold mb-0 me-1"> {{ room.Currency+' '+room.BasePrice }}</h5>
                                                        <small class="mb-0 me-2 text-muted">Exclusive of taxes</small>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center mt-2">
                                                    <a href="javascript:void(0)" @click="selectRoom(room)" type="button" class="btn btn-primary btn-sm">Select Room</a>
                                                    <a href="#" class="mb-0 text-sm text-info" @click="updateCancellationPolicity(room)" data-bs-toggle="modal" data-bs-target="#cancellationPolicies">Cancellation Policies</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
                                <div class="card mb-3">
                                    <div class="card-header">
                                        <h6>About Resort</h6>
                                    </div>
                                    <div class="card-body">
                                        <p class="mb-0"
                                            v-html="$store.getters.hotel.hotel_details?.Description">
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-header"><h6>Facilities</h6></div>
                                            <div class="card-body">
                                                <div>
                                                    <table class="table table-light table-bordered table-striped">
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="facility, key in hotel.hotel_details?.HotelFacilities" :key="key">
                                                                <td>{{ facility }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal fade" id="cancellationPolicies" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="baggageFarelabel">Cancellation Policies</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3">
                        <div class="table-responsive py-2">
                            <table class="table table-light table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th class="text-center">From Date</th>
                                        <th class="text-center">Charge Type</th>
                                        <th class="text-center">Cancellation Charge</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!CancelPolicies?.length">
                                        <td colspan="3">No records found</td>
                                    </tr>
                                    <tr v-for="policy,key in CancelPolicies" :key="key">
                                        <td>{{ policy.FromDate }}</td>
                                        <td>{{ policy.ChargeType }}</td>
                                        <td class="text-center">{{ policy.CancellationCharge }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Search from "@/components/Search.vue";
    let City = require("@/components/City.vue").default;
    export default {
        components: { flatPickr, Search, City },
        data() {
            return {
                search: {
                    CheckIn: "",
                    CheckOut: "",
                    CountryCode: "IN",
                    DestinationCityId: "",
                    CityName: "",
                    CityId: "",
                    CityCode: "",
                    GuestNationality: "IN",
                    NoOfRooms: 1,
                    NoOfAdults: 1,
                    NoOfChild: 0,
                    PaxRooms: [],
                    ResponseTime: "30",
                    HotelCodes: "",
                    IsDetailedResponse: true,
                    Filters: {
                        MealType: 0,
                        NoOfRooms: 10,
                        OrderBy: 0,
                        Refundable: true,
                        StarRating: 0,
                        HotelName:''
                    },
                    selectedDates: [],
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 50,
                    totalRows : '',
                    lastPage : '',
                    from : '',
                    maxPage : '',
                    page:'',
                    to:''
                },
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 10,
                },
                hotel:{},
                CancelPolicies:[],
                errors: [],
                room_attributes: [],
                hotel_search:{},
                room_selected:{}
            };
        },
        watch:{
            '$store.getters.customer':function(){
                if(this.room_selected){
                    this.selectRoom(this.room_selected)
                }
            },
        },

        mounted(){
            let vm = this
            vm.hotel = vm.$store.getters.hotel
            vm.search.Filters.NoOfRooms = vm.$store.getters.hotel_search.NoOfRooms;
            vm.search.CheckIn = vm.$store.getters.hotel_search.CheckIn;
            vm.search.CheckOut = vm.$store.getters.hotel_search.CheckOut;
            vm.search.DestinationCityId = vm.$store.getters.hotel_search.DestinationCityId;
            vm.search.CityName = vm.$store.getters.hotel_search.CityName;
            vm.search.CityId = vm.$store.getters.hotel_search.CityId;
            vm.search.CityCode = vm.$store.getters.hotel_search.CityCode;
            vm.search.NoOfRooms = vm.$store.getters.hotel_search.NoOfRooms;
            vm.search.NoOfAdults = vm.$store.getters.hotel_search.NoOfAdults;
            vm.search.NoOfChild = vm.$store.getters.hotel_search.NoOfChild;
            vm.search.PaxRooms = vm.$store.getters.hotel_search.PaxRooms;
            vm.search.HotelCodes = vm.$store.getters.hotel_search.HotelCodes.toString()
            vm.search.selectedDates = vm.$store.getters.hotel_search.selectedDates
            vm.CancelPolicies = vm.$store.getters.hotel.CancelPolicies

            // try
            if(vm.$store.getters.hotel.hotel_details.Images.length){
                if(vm.$store.getters.hotel.hotel_details.Images.length >= 1){
                    vm.search.img0=vm.$store.getters.hotel.hotel_details.Images[0]
                }
                if(vm.$store.getters.hotel.hotel_details.Images.length >= 2){
                    vm.search.img1=vm.$store.getters.hotel.hotel_details.Images[1]
                }
                if(vm.$store.getters.hotel.hotel_details.Images.length >=3){
                    vm.search.img2=vm.$store.getters.hotel.hotel_details.Images[2]
                }
                if(vm.$store.getters.hotel.hotel_details.Images.length >=4){
                    vm.search.img3=vm.$store.getters.hotel.hotel_details.Images[3]
                }
                if(vm.$store.getters.hotel.hotel_details.Images.length >=5){
                    vm.search.img4=vm.$store.getters.hotel.hotel_details.Images[4]
                }
            }
            console.log('rooms')
            console.log(vm.$store.getters.hotel.search_result)
            vm.rooms=vm.$store.getters.hotel.search_result.Rooms;
            vm.rooms.map(function(room){
                if(room.DayRates[0].length){
                    room.BasePrice = room.DayRates[0][0].BasePrice
                    room.Currency = vm.$store.getters.hotel.search_result.Currency
                }
            })
        },

        methods:{
            updatePaxRooms(operand){
				if(operand == 'add' && this.search.NoOfRooms < 3){
					this.search.PaxRooms.push({
						Adults:2,
						Children:0,
						ChildrenAges:[]
					})
					this.search.NoOfRooms++
					this.search.NoOfAdults += 2
				}
				if(operand == 'subtract' &&  this.search.NoOfRooms > 1){
					this.search.PaxRooms.pop()
					this.search.NoOfRooms--
					this.updateAdultsChildrenCount()
				}
			},

            updateAdultsChildrenCount(){
				let adults = 0
				let children = 0
				this.search.PaxRooms.map(function(room){
					adults += room.Adults
					children += room.Children
				})
				this.search.NoOfAdults = adults
				this.search.NoOfChild = children
			},

			changePassengersCount(pax_type, sign, room) {
				if (pax_type === 'Adult' && sign === "+" && room.Adults<8) {
					this.search.NoOfAdults++
					room.Adults++
				} 
				if (pax_type === 'Adult' && sign === "-" && room.Adults > 1) {
					this.search.NoOfAdults--
					room.Adults--
				}

				if (pax_type === 'Child' && sign === "+" && room.Children<4) {
					this.search.NoOfChild++;
					room.Children++
					room.ChildrenAges.push(1)
				} 
				if (pax_type === 'Child' && sign === "-" && room.Children>0) {
					this.search.NoOfChild--;
					room.Children--
					room.ChildrenAges.pop()
				}
			},

            scrollToDiv(divId) {
                const element = document.getElementById(divId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            },
            updateCancellationPolicity(room){
                this.CancelPolicies = room.CancelPolicies
            },
            googleMapsUrl(){
                let vm = this
                let latitude = null
                let longitude = null
                if(vm.$store.getters.hotel){
                    if(vm.$store.getters.hotel.latitude && vm.$store.getters.hotel.longitude){
                        latitude = vm.$store.getters.hotel.latitude
                        longitude = vm.$store.getters.hotel.longitude
                        window.open(`https://www.google.com/maps?q=${latitude},${longitude}`)
                    }else{
                        alert('Unable to fetch map')                        
                    }
                }else{
                    alert('Unable to fetch map')
                }

            },
            getRoomName(room){
                if(room && room.DayRates.length){
                    if(room.DayRates[0].length){
                        room.BasePrice = room.DayRates[0][0].BasePrice 
                    }
                }
                if(room && room.Name.length){
                    if(room.Name[0].match(/^[a-zA-Z]+\s[a-zA-Z]+/)){
                        return room.Name[0].match(/^[a-zA-Z]+\s[a-zA-Z]+/)[0] 
                    }else{
                        this.room_attributes = room.Name[0].split(",")
                        return this.room_attributes[0]
                    }
                }else{
                    return ''
                }
            },
            selectRoom(room){
                let vm = this
                vm.room_selected = room
                if(vm.$store.getters.customer){
                    vm.$store.dispatch("setSelectedRoom", room);
                    vm.$router.push("/hotel_booking");
                }else{
                    document.getElementById('login1').click();
                }
            },
            getHotelDetails(){
                let vm = this;
                vm.hotel.Hotelcodes = vm.$store.getters.hotel.hotel_code
                vm.hotel.Language = 'EN'
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "hotelDetails", data: vm.hotel })
                    .then(function (response) {
                        loader.hide();
                        vm.hotel = response.data.HotelDetails
                        vm.hotel.rooms = vm.$store.getters.hotel.rooms
                        vm.hotel.rooms.map(function(room){
                            if(room.DayRates[0].length){
                                room.BasePrice = room.DayRates[0][0].BasePrice 
                            }
                        })
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error)
                    });
            },
            getSelectedDates(dates) {
                let vm = this;
                vm.search.CheckIn = moment(dates[0]).format("YYYY-MM-DD");
                vm.search.CheckOut = moment(dates[1]).format("YYYY-MM-DD");
            },

            searchBuses(){
                let vm = this
				vm.$store.dispatch("setHotelSearch", vm.search);
                vm.$router.push("/hotel_list")
            }
        }
    }
</script>
<style scoped>
    .border-dashed1{
	    border:dashed 2px;
    }
    .vertical_scroll {
        max-height: 300px; 
        overflow-y: auto; 
    }
    .img-fluid{
        height: 100%;
        object-fit: cover;
    }

    .img-fluid:hover {
        transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
</style>
