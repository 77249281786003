<template>
    <div :class="[isOpen ? 'city city-open' : 'city']">
        <div class="city-inner">
            <input class="form-guest-selector fw-bold form-control city-selected" :style=customStyle style="padding-left:16px"
                ref="toggle" 
                :tabindex="tabindex"
                :value="selectedText"
                :class="customClass"
                @click="onToggle"
                @keydown="onKey">
            <transition name="fade" mode="out-in">
                <div class="city-dropdown" v-if="isOpen"  style="height:200px; overflow-y:scroll">
                    <div class="city-input_wrap">
                        <input type="text" class="form-control form-control-sm city-input" autocomplete="off" placeholder="Search..."
                            ref="search" @blur="onBlur"
                            @input="onSearch"
                            @keydown.esc="onEsc"
                            @keydown.up="onUpKey"
                            @keydown.down="onDownKey"
                            @keydown.enter.prevent="onEnterKey">
                    </div>
                    <ul class="city-list" v-if="results.length">
                        <li class="city-item" v-for="(result, index) in results" @mousedown.prevent="select(result)" @mouseover.prevent="onMouse(index)" :class="['city-link', selectIndex === index ? 'city-active':'']" :key="index">
                            {{ result.city_name }}
                        </li>
                    </ul>
                    <!-- <table class="table table-responsive-sm table-bordered table-sm city-list" v-if="results.length">
                        <thead>
                            <tr>
                                <th class="city-link">City</th>
                                <th class="city-link">State</th>
                                <th class="city-link">Country</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="city-item" v-for="(result, index) in results" @mousedown.prevent="select(result)" @mouseover.prevent="onMouse(index)" :class="['city-link', selectIndex === index ? 'city-active':'']" :key="index">
                                <td>
                                    {{ result.city_name }}
                                </td>
                                <td>
                                    {{ result.state_name }}
                                </td>
                                <td>
                                    {{ result.country_name }}
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </transition>
        </div>
    </div>
</template>

<style>
    .city {
      position: relative;
      display: block;
      background: transparent;  
    }

    .city-open {
      border-bottom: 0;
    }

    .city-open .form-control {
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        border: 1px solid #f2f2f2;
    }

    .city-inner {
        position: relative;
    }

    .city-selected {
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        line-height: 15px;
        height:62px; 
    }

    .city-dropdown {
      /* column-width: 100%; */
      width: 100%;
      position: absolute;
      z-index: 999 !important;
      padding: 5px;
      background: #fff;
      border-right: 1px solid #f2f2f2;
      border-left: 1px solid #f2f2f2;
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    .city-input {
      line-height: 13px;
      font-size: 13px;
      background: #fafafa;
      border: none;
      border-radius: 1px;
      -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
              box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      padding: 4px 8px;
      width: 100%;
      display: block;
    }

    .city-input:focus {
      outline-style: dotted;
      outline-width: 1px;
      outline-offset: 1px;
    }

    .city-input_wrap {
      position: relative;
    }

    .city-list {
      display: block;
      margin: 0;
      padding: 0;
    }
    .city-item{
        padding:5px!important;
    }

    .city-link {
      cursor: pointer;
      padding: 2px;
      background: #fff;
    }

    .city-active {
      background: #5143d9 !important;
      color: #fff !important;
    }

    .city-list td, th { 
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow:ellipsis; 
    }
</style>

<script type="text/javascript">
    export default {
        props: {
            initialize: {
                default: null
            },
            customClass: {
                default: ''
            },
            tabindex: {
                default: 0
            },
            customStyle:{
                default: null
            }
        },
        data () {
            return {
                timer:null,
                selectIndex: -1,
                isOpen: false,
                search: '',
                results: [],
                query: {
                    search: '',
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 10,
                },
                temp:[],
            }
        },
        computed: {
            selectedText() {
                return this.initialize 
                    ? this.initialize
                    : 'Select Location'
            }
        },
        methods: {
            focus: function () {
                this.$refs.toggle.focus()
            },

            onToggle() {
                if(this.isOpen) {
                    this.isOpen = false
                } else {
                    this.open()
                }
            },
            onKey(e) {
                const KeyCode = e.KeyCode || e.which
                if(!e.shiftKey && KeyCode !== 9 && !this.isOpen) {
                    this.open()
                }
            },
            open() {
                this.fetchData('')
                this.isOpen = true
                this.$nextTick(() => {
                    this.$refs.search.focus()
                })
            },
            fetchData(query) {
                let vm = this;
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    vm.results = [];
                    vm.query.search = query;
                    vm.$store.dispatch('post',{'uri':'paginateCities',data: vm.query})
                    .then(function (response) {
                        vm.results = response.data.data;
                    });
                }, 250);
            },
            onBlur() {
                this.close()
            },
            onEsc() {
                this.close()
            },
            close() {
                this.results = []
                this.isOpen = false
                this.search = ''
                this.selectIndex = -1
            },
            onSearch(e) {
                const q = e.target.value
                this.selectIndex = 0
                this.fetchData(q)
            },
            onUpKey() {
                if(this.selectIndex > 0) {
                    this.selectIndex--
                }
            },
            onDownKey() {
                if(this.results.length - 1 > this.selectIndex) {
                    this.selectIndex++
                }
            },
            onEnterKey() {
                const found = this.results[this.selectIndex]
                if(found) {
                    this.select(found)
                }
            },
            select(result) {
                this.$emit('selectCity', {
                    target: {
                        value: result
                    }
                })
                this.close()
            },
            onMouse(index) {
                this.selectIndex = index
            }
         }
    }
</script>