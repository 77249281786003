<template>
    <div>
        <section class="gray-simple pt-3">
            <div class="container">
                <div class="py-2 bg-search position-relative mb-3">
                    <div class="container">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="search-wrap position-relative">
                                    <div class="row align-items-end gy-3 gx-md-3 gx-sm-2 mb-2">
                                        <div class="col-xl-8 col-lg-7 col-md-12">
                                            <div class="row gy-3 gx-md-3 gx-sm-2">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                    <div class="form-group hdd-arrow mb-0">
                                                        <label class="text-white text-sm">FROM</label>
                                                        <BusCity :customStyle="'height:50px !important'" :initialize="search.OriginName" @selectCity="selectOrigin($event)"></BusCity>
                                                        <span v-if="errors?.OriginId" class="invalid-feedback">{{ errors?.OriginId[0] }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div class="form-group hdd-arrow mb-0">
                                                        <label class="text-white text-sm">TO</label>
                                                        <BusCity :customStyle="'height:50px !important'" :initialize="search.DestinationName" @selectCity="selectDestination($event)"></BusCity>
                                                        <span v-if="errors?.DestinationId" class="invalid-feedback">{{ errors?.DestinationId[0] }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-12">
                                            <div class="row align-items-end gy-3 gx-md-3 gx-sm-2">
                                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                    <div class="form-group mb-0">
                                                        <label class="text-white text-sm">DEPARTURE</label>
                                                        <!-- {{ search.DateOfJourney }} -->
                                                        <flatPickr
                                                            class="form-control fw-bold hgt-50"
                                                            placeholder="Select date"
                                                            :class="{ 'is-invalid': errors?.DateOfJourney }"
                                                            v-model="search.DateOfJourney"
                                                            :config="{dateFormat: 'd-m-Y'}"
                                                        ></flatPickr>
                                                        <span v-if="errors?.DateOfJourney" class="invalid-feedback">{{ errors?.DateOfJourney[0] }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                    <div class="form-group mb-0">
                                                        <button @click.prevent="searchBuses" type="button" class="btn hgt-50 btn-primary full-width fw-medium">Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between gy-3 gx-xl-3 gx-lg-3 gx-md-3 gx-3">
                    <div class="col-xl-3 col-lg-4 col-md-12">
                        <div class="filter-searchBar bg-white rounded-3">
                            <div class="filter-searchBar-head border-bottom">
                                <div class="searchBar-headerBody d-flex align-items-start justify-content-between px-3 py-3">
                                    <div class="searchBar-headerfirst">
                                        <h6 class="fw-bold fs-5 m-0">Filters</h6>
                                    </div>
                                    <div class="searchBar-headerlast text-end">
                                        <a href="javascript:void(0)" class="text-md fw-medium text-primary active" @click="clearFilter()">Clear All</a>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-searchBar-body">
                                <div class="searchBar-single px-3 py-3 border-bottom">
                                    <div class="searchBar-single-title d-flex mb-3">
                                        <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Bus Type</h6>
                                    </div>
                                    <div class="searchBar-single-wrap mb-4">
                                        <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                            <li class="col-3 pl-0" :class="{active:active}" @click.prevent="getBusTypeFilter('A/C')">
                                                <input type="checkbox" class="btn-check" id="ac" value="A/C" v-model="bus_type_filter.ac" />
                                                <label v-bind:class="{ active: bustype_active === 'A/C' }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="ac">
                                                    <span><i class="fa-solid fa-snowflake"></i></span>AC
                                                </label>
                                            </li>
                                            <li class="col-3 pl-0" @click.prevent="getBusTypeFilter('Non A/C')">
                                                <input type="checkbox" class="btn-check" id="non-ac" value="Non A/C" v-model="bus_type_filter.non_ac" />
                                                <label v-bind:class="{ active: bustype_active === 'Non A/C' }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="non-ac">
                                                    <span><i class="fa-solid fa-snowflake"></i></span>NonAC
                                                </label>
                                            </li>
                                            <li class="col-3 pl-0" @click.prevent="getBusTypeFilter('Seater')">
                                                <input type="checkbox" class="btn-check" id="seater" value="Seater" v-model="bus_type_filter.seater" />
                                                <label v-bind:class="{ active: bustype_active === 'Seater' }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="seater">
                                                    <span><i class="fa-solid fa-chair"></i></span> <i class="fa-duotone fa-person-seat"></i>Seater
                                                </label>
                                            </li>
                                            <li class="col-3 pl-0" @click.prevent="getBusTypeFilter('Sleeper')">
                                                <input type="checkbox" class="btn-check" id="sleeper" value="Sleeper" v-model="bus_type_filter.sleeper" />
                                                <label v-bind:class="{ active: bustype_active === 'Sleeper' }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="sleeper">
                                                    <span><i class="fa-solid fa-bed"></i></span>Sleeper
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="searchBar-single-title d-flex mb-3">
                                        <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Departure Time</h6>
                                    </div>
                                    <div class="searchBar-single-wrap">
                                        <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2 mb-0">
                                            <li class="col-6" @click.prevent="toggleDepartureFilter('Before 10 AM')">
                                                <input type="checkbox" class="btn-check" id="time1" />
                                                <label :class="{ active: isSelected('Before 10 AM') }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="time1">
                                                    <span>
                                                        <svg width="1.5rem" height="1.5rem" viewBox="0 0 21 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="color: currentcolor;">
                                                            <path
                                                                d="M10.0014 5.00889C13.1191 4.97664 15.6871 7.41939 15.8371 10.5086L15.8439 10.7306L14.8441 10.7411C14.8149 8.10039 12.6519 5.98164 10.0119 6.00864C7.44164 6.03489 5.36639 8.08389 5.28089 10.6279L5.27864 10.8409L4.27889 10.8514C4.24589 7.65639 6.80714 5.04189 10.0014 5.00889ZM10.3839 0.150391C10.6291 0.150391 10.8339 0.327391 10.8759 0.560641L10.8834 0.650641V3.06414C10.8834 3.34014 10.6599 3.56439 10.3839 3.56439C10.1386 3.56439 9.93389 3.38739 9.89189 3.15414L9.88439 3.06414V0.650641C9.88439 0.374641 10.1079 0.150391 10.3839 0.150391ZM16.9711 3.48189C17.1729 3.29289 17.4886 3.30339 17.6784 3.50439C17.8456 3.68364 17.8569 3.95364 17.7159 4.14414L17.6559 4.21164L15.8874 5.86914C15.6856 6.05814 15.3699 6.04764 15.1801 5.84664C15.0121 5.66739 15.0016 5.39739 15.1426 5.20689L15.2026 5.13939L16.9711 3.48189ZM1.97714 3.68589C2.14064 3.50364 2.40914 3.46914 2.61089 3.59364L2.68289 3.64764L4.48814 5.26389C4.69439 5.44764 4.71164 5.76414 4.52714 5.96964C4.36364 6.15264 4.09589 6.18714 3.89339 6.06264L3.82139 6.00864L2.01614 4.39239C1.81064 4.20789 1.79264 3.89214 1.97714 3.68589Z"
                                                                fill="currentColor"
                                                            ></path>
                                                            <path
                                                                d="M20.0621 10.6035C20.3388 10.602 20.5638 10.8248 20.5646 11.1008C20.5661 11.346 20.3906 11.5508 20.1573 11.595L20.0673 11.6033L0.65583 11.7008C0.37983 11.703 0.15483 11.4803 0.15333 11.2043C0.15183 10.9583 0.32808 10.7535 0.56058 10.7093L0.65058 10.7018L20.0621 10.6035Z"
                                                                fill="currentColor"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                    Before 10 AM
                                                </label>
                                            </li>
                                            <li class="col-6" @click.prevent="toggleDepartureFilter('10 AM - 5 PM')">
                                                <input type="checkbox" class="btn-check" id="time2" />
                                                <label :class="{ active: isSelected('10 AM - 5 PM') }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="time2">
                                                    <span>
                                                        <svg width="1.5rem" height="1.5rem" viewBox="0 0 21 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="color: currentcolor;">
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M15.1158 11.1346C14.9891 13.8564 12.6791 15.9594 9.9581 15.8326C7.2356 15.7059 5.1326 13.3959 5.25935 10.6749C5.3861 7.95239 7.6961 5.84939 10.4178 5.97614C13.1396 6.10364 15.2426 8.41289 15.1158 11.1346Z"
                                                                stroke="currentColor"
                                                                stroke-width="1.333"
                                                            ></path>
                                                            <path
                                                                d="M10.6295 1.4375V3.71975M17.1013 4.421L15.449 5.96975M19.6633 11.0713L17.4013 11.0307M16.8688 17.627L15.2735 16.022M10.2973 20.3825V18.1302M3.66205 17.78L5.22055 16.1382M0.716797 11.2903L2.9788 11.1987M3.12505 4.583L4.8118 6.09275"
                                                                stroke="currentColor"
                                                                stroke-width="1.333"
                                                                stroke-linecap="round"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                    10 AM - 5 PM
                                                </label>
                                            </li>
                                            <li class="col-6" @click.prevent="toggleDepartureFilter('5 PM - 11 PM')">
                                                <input type="checkbox" class="btn-check" id="time3" />
                                                <label :class="{ active: isSelected('5 PM - 11 PM') }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="time3">
                                                    <span>
                                                        <svg width="1.5rem" height="1.5rem" viewBox="0 0 22 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="color: currentcolor;">
                                                            <path
                                                                d="M12.6043 0.662533C12.788 0.670033 12.935 0.810283 12.959 0.986533L12.9613 1.05403L12.8503 3.44053C12.8405 3.64678 12.665 3.80728 12.458 3.79753C12.2743 3.78853 12.128 3.64903 12.104 3.47278L12.101 3.40528L12.2128 1.01953C12.2218 0.812533 12.3973 0.652783 12.6043 0.662533ZM19.1593 3.91228C19.3108 3.76978 19.5478 3.77728 19.6895 3.92878C19.8155 4.06378 19.8238 4.26553 19.718 4.40878L19.673 4.45903L17.93 6.09403C17.7793 6.23503 17.5415 6.22753 17.3998 6.07678C17.2738 5.94253 17.2663 5.74003 17.372 5.59678L17.417 5.54653L19.1593 3.91228ZM4.39029 4.10653C4.51254 3.96928 4.71279 3.94378 4.86504 4.03678L4.91904 4.07728L6.70029 5.67028C6.85404 5.80828 6.86754 6.04528 6.72954 6.19978C6.60654 6.33703 6.40554 6.36253 6.25404 6.26953L6.20004 6.22903L4.41954 4.63603C4.26579 4.49803 4.25229 4.26103 4.39029 4.10653ZM21.257 10.9623C21.464 10.9615 21.6328 11.1288 21.6335 11.3358C21.635 11.5195 21.503 11.6733 21.3283 11.7063L21.2608 11.7123H10.5C10.293 11.713 10.1242 11.5465 10.1227 11.3395C10.122 11.155 10.254 11.0013 10.4288 10.969L10.4963 10.9623H21.257Z"
                                                                fill="currentColor"
                                                            ></path>
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M17.8456 10.788C17.7166 7.79251 15.2318 5.41876 12.2116 5.44951C9.68909 5.47591 7.57514 7.17153 6.9082 9.4765L7.65348 9.60181C8.25731 7.64923 10.0659 6.22179 12.2191 6.19951C14.8868 6.17176 17.0723 8.34102 17.1008 11.008L17.8508 11.0005L17.8456 10.788Z"
                                                                fill="currentColor"
                                                            ></path>
                                                            <path
                                                                d="M10.3962 12.595L10.3932 12.649C10.3707 12.8065 10.241 12.922 10.0835 12.922H1.37598L1.38123 12.7593C1.38123 12.1143 1.90398 11.5908 2.54973 11.5908C2.66973 11.5908 2.79348 11.614 2.92923 11.6605L3.37473 11.812L3.42348 11.3448C3.54723 10.1575 4.54023 9.25 5.73573 9.25C6.65073 9.25 7.46823 9.78625 7.85073 10.6075L7.99023 10.9075L8.30523 10.807C8.73498 10.6705 9.07998 10.6915 9.48048 10.933C9.99048 11.2383 10.322 11.8045 10.3857 12.4457L10.3962 12.595Z"
                                                                fill="white"
                                                            ></path>
                                                            <path
                                                                d="M5.5825 8.50375C4.27375 8.5675 3.1735 9.45475 2.79775 10.675L2.74825 10.8542L2.70475 10.8482C2.653 10.843 2.60125 10.8408 2.5495 10.8408C1.53175 10.8408 0.7 11.6327 0.63475 12.6332L0.625 12.9985C0.625 13.111 0.631 13.2025 0.6505 13.2872C0.6985 13.4987 0.823 13.672 1.08775 13.672H10.0825C10.648 13.672 11.1115 13.2302 11.1437 12.6775L11.143 12.535L11.1407 12.517C11.107 11.6133 10.6375 10.753 9.86575 10.2895L9.72775 10.2122C9.31675 9.997 8.917 9.93475 8.5 9.99325L8.383 10.0143L8.305 9.8875C7.7425 9.0355 6.7885 8.5 5.7355 8.5L5.5825 8.50375ZM10.396 12.5942L10.393 12.649C10.3705 12.8065 10.2407 12.922 10.0832 12.922H1.37575L1.381 12.7593C1.381 12.1143 1.90375 11.5908 2.5495 11.5908C2.6695 11.5908 2.79325 11.614 2.929 11.6605L3.3745 11.812L3.42325 11.3448C3.547 10.1575 4.54 9.25 5.7355 9.25C6.6505 9.25 7.468 9.78625 7.8505 10.6075L7.99 10.9075L8.305 10.807C8.73475 10.6705 9.07975 10.6915 9.48025 10.933C9.99025 11.2383 10.3217 11.8045 10.3855 12.4457L10.396 12.5942Z"
                                                                fill="currentColor"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                    5 PM - 11 PM
                                                </label>
                                            </li>
                                            <li class="col-6" @click.prevent="toggleDepartureFilter('After 11 PM')">
                                                <input type="checkbox" class="btn-check" id="time4" />
                                                <label :class="{ active: isSelected('After 11 PM') }" class="btn btn-sm btn-secondary rounded-1 fw-medium full-width bus_type" for="time4">
                                                    <span>
                                                        <svg width="1.5rem" height="1.5rem" viewBox="0 0 22 20" fill="white" xmlns="http://www.w3.org/2000/svg" style="color: currentcolor;">
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M21.2971 17.6366V17.6104C21.2469 16.3804 20.6469 15.1999 19.5931 14.5654C18.7164 14.0404 17.9679 14.0996 17.2884 14.3156C16.6186 12.8749 15.1674 11.8691 13.4739 11.8691C11.2959 11.8691 9.52437 13.5266 9.30237 15.6454C9.05787 15.5606 8.79837 15.5051 8.52462 15.5051C7.20087 15.5051 6.12612 16.5791 6.12612 17.9029C6.12612 18.1339 6.06687 18.7384 6.25362 18.7384H20.2284C20.8314 18.7384 21.3204 18.2404 21.2971 17.6366Z"
                                                                stroke="currentColor"
                                                            ></path>
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M7.4178 1.32031H7.39905C8.5338 2.66806 9.22005 4.40431 9.22005 6.30406C9.22005 10.5731 5.76405 14.0336 1.49805 14.0441C2.9178 15.7286 5.0418 16.8018 7.4178 16.8018C11.6928 16.8018 15.1578 13.3353 15.1578 9.06106C15.1578 4.78606 11.6928 1.32031 7.4178 1.32031Z"
                                                                fill="white"
                                                                stroke="currentColor"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                    After 11 PM
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="searchBar-single px-3 py-3 border-bottom">
                                    <div class="searchBar-single-title d-flex mb-3">
                                        <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Pricing Range</h6>
                                    </div>
                                    <div class="searchBar-single-wrap">
                                        <input ref="rangeSlider" type="text" class="js-range-slider" name="my_range" value="" data-skin="round" data-type="double" :data-min="min" :data-max="max" data-grid="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-9 col-lg-8 col-md-12">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-xl-4 col-lg-4 col-md-4">
                                <h5 class="fw-bold fs-6 mb-lg-0 mb-3">Showing {{results?.length }} Search Results</h5>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-12">
                                <div class="d-flex align-items-center justify-content-start justify-content-lg-end flex-wrap">
                                    <div class="flsx-first mt-sm-0 mt-2">
                                        <ul class="nav nav-pills nav-fill p-1 small lights blukker bg-white rounded-3 shadow-sm" id="filtersblocks" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active rounded-3" id="trending" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" @click.prevent="getPrice('low')">Low Price</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link rounded-3" id="mostpopular" data-bs-toggle="tab" type="button" role="tab" aria-selected="false" @click.prevent="getPrice('high')">High Price</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center g-3 mt-1">
                            <!-- <div class="col-xl-12 col-lg-12 col-md-12" v-for="result, key in results" :key="key">
                            <div class="flights-accordion">
                                <div class="flights-list-item bg-white rounded-3 p-3">
                                    <div class="row gy-4 align-items-center justify-content-between">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12">
                                                    <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                        <div class="col-sm-auto">
                                                            <div class="d-flex align-items-center justify-content-start">
                                                                <div class="d-end fl-title ps-2">
                                                                    <div class="text-dark fw-medium">{{ result.TravelName }}</div>
                                                                    <div class="text-sm text-muted" style="width: 141px;">{{result.BusType}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row gx-3 align-items-center">
                                                                <div class="col-auto">
                                                                    <div class="text-muted text-sm fw-medium">{{getTime(result.DepartureTime,'date') }}</div>
                                                                    <div class="text-dark fw-bold">{{getTime(result.DepartureTime,'time') }}</div>
                                                                    <div class="text-muted text-sm fw-medium">{{origin_name}}</div>
                                                                </div>
                                                                <div class="col text-center">
                                                                    <div class="position-relative my-4">
                                                                        <div class="opacity-5 position-relative border-dashed"></div>
                                                                        <div class="text-dark fw-medium bg-white rounded-circle position-absolute top-50 start-50 translate-middle">
                                                                            {{ GetHourMins(result.DepartureTime,result.ArrivalTime) }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-auto">
                                                                    <div class="text-muted text-sm fw-medium">{{getTime(result.ArrivalTime,'date') }}</div>
                                                                    <div class="text-dark fw-bold">{{getTime(result.ArrivalTime,'time') }}</div>
                                                                    <div class="text-muted text-sm fw-medium">{{ destination_name }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12 mt-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <span class="label bg-light-success text-success">{{ result.AvailableSeats }} Seat Left</span>
                                                        </div>
                                                        <span>
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-sm text-info"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#cancellationModal"
                                                                @click="cancellataionPolicy(result.ResultIndex)"
                                                            >
                                                                Cancellation Policy
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="d-flex items-center h-100">
                                                <div class="d-lg-block d-none border br-dashed me-4"></div>
                                                <div>
                                                    <div class="text-start text-md-end">
                                                        <span class="label bg-light-success text-success me-1" v-if="result.BusPrice.Discount!=0">{{result.BusPrice.Discount}}% Off</span>
                                                        <div class="text-muted text-sm mb-0">Starting at:</div>
                                                        <div class="text-dark fs-3 fw-bold lh-base">₹{{ result.BusPrice.PublishedPrice }}</div>
                                                    </div>
                                                    <div class="flight-button-wrap">
                                                        <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal" data-bs-target="#selectSeat" @click="selectSeats(result)">
                                                            Select Seats<i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                            <!-- try starts -->
                            <div class="col-xl-12 col-lg-12 col-md-12" v-for="result, key in results" :key="key">
                                <div class="flights-accordion">
                                    <div class="flights-list-item bg-white rounded-3 p-3">
                                        <div class="row gy-4 align-items-center justify-content-between">
                                            <div class="col-md-auto">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <div class="d-end fl-title mb-4 margin-hide">
                                                        <div class="text-dark fw-medium">{{ result.TravelName }}</div>
                                                        <div class="text-sm text-muted" style="width: 150px;">{{result.BusType}}</div>
                                                    </div>
                                                </div>
                                                <!-- <div class="">
                                                            <span class="label bg-light-success text-success">{{ result.AvailableSeats }} Seat Left</span>
                                                            </div> -->
                                            </div>
                                            <div class="col-md-auto px-0 col-hide">
                                                <div class="d-flex items-center h-100" style="height: 60px !important; width: 10px;">
                                                    <div class="d-lg-block d-none border br-dashed me-4"></div>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <div class="row">
                                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                                        <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                            <div class="col">
                                                                <div class="row gx-3 align-items-center">
                                                                    <div class="col-auto">
                                                                        <div class="text-muted text-sm fw-medium">{{getTime(result.DepartureTime,'date') }}</div>
                                                                        <div class="text-dark fw-bold">{{getTime(result.DepartureTime,'time') }}</div>
                                                                        <div class="text-muted text-sm fw-medium">{{origin_name}}</div>
                                                                    </div>
                                                                    <div class="col text-center">
                                                                        <div class="position-relative my-4">
                                                                            <div class="opacity-5 position-relative border-dashed"></div>
                                                                            <div class="text-dark fw-medium bg-white rounded-circle position-absolute top-50 start-50 translate-middle">
                                                                                {{ GetHourMins(result.DepartureTime,result.ArrivalTime) }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <div class="text-muted text-sm fw-medium">{{getTime(result.ArrivalTime,'date') }}</div>
                                                                        <div class="text-dark fw-bold">{{getTime(result.ArrivalTime,'time') }}</div>
                                                                        <div class="text-muted text-sm fw-medium">{{ destination_name }}</div>
                                                                    </div>

                                                                    <!-- <div class="col-12 mt-2">
                                                                    <span class="float-end">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-sm text-info"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#cancellationModal"
                                                                @click="cancellataionPolicy(result.ResultIndex)"
                                                            >
                                                                Cancellation Policy
                                                            </a>
                                                        </span>
                                                                </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-auto">
                                                <div class="d-flex items-center h-100">
                                                    <div class="d-lg-block d-none border br-dashed me-4"></div>
                                                    <div>
                                                        <div class="text-start text-md-end">
                                                            <span class="label bg-light-success text-success me-1" v-if="result.BusPrice.Discount!=0">{{result.BusPrice.Discount}}% Off</span>
                                                            <div class="text-muted text-sm mb-0">Starting at:</div>
                                                            <div class="text-dark fs-3 fw-bold lh-base">{{ result.BusPrice.CurrencyCode }}. {{ result.BusPrice.PublishedPrice }}</div>
                                                        </div>
                                                        <!-- <div class="flight-button-wrap">
                                                        <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal" data-bs-target="#selectSeat" @click="selectSeats(result)">
                                                            Select Seats<i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                        </button>
                                                    </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-1 align-items-center">
                                            <div class="col-sm-auto">
                                                <div class="">
                                                    <span class="label bg-light-success text-success">{{ result.AvailableSeats }} Seat Left</span>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <span class="float-end">
                                                    <a href="javascript:void(0)" class="text-sm text-info" data-bs-toggle="modal" data-bs-target="#cancellationModal" @click="cancellataionPolicy(result.ResultIndex)">
                                                        Cancellation Policy
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="col-sm-auto">
                                                

                                                <button v-if="result?.show_seat && selected_bus_id==result.ResultIndex" class="btn btn-primary btn-sm fw-medium full-width" @click="hideSeats(result)">
                                                    <span> Hide Seats <i class="fa-solid fa-arrow-trend-up ms-2"></i></span>                                                    
                                                </button>
                                                <button v-else class="btn btn-primary btn-sm fw-medium full-width" @click="selectSeats(result)">
                                                    <span> Select Seats<i class="fa-solid fa-arrow-trend-up ms-2"></i></span>

                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-1" v-if="result?.show_seat && selected_bus_id==result.ResultIndex">
                                        <div class="flights-accordion">
                                            <div class="flights-list-item bg-white rounded-3 p-3">
                                                <div class="">
                                                    <h4 class="fs-6">Select Seats</h4>
                                                </div>
                                                <div class="px-4 py-4 px-xl-5 px-lg-5">
                                                    <div class="row">
                                                        <div class="col-md-7">
                                                            <div class="card border br-dashed">
                                                                <div class="card-body" style="height: 540px; overflow-x: scroll">
                                                                    <div class="row" style="display: flex;" v-for="(row, index) in layout?.SeatLayout?.SeatDetails" :key="index" :style="row.length == 1 ? { width: '670px' } : {}">
                                                                        <div class="col-md-12 text-center" style="font-size: 18px;" v-if="layout?.SeatLayout?.SeatDetails.length/2 == 3 && index ==0">Lower Deck</div>
                                                                        <div class="col-md-12" style="display: flex;" :style="row.length == 1 ? { 'justify-content': 'end' } : {}">
                                                                            <Seat v-for="seat in row" :key="seat.SeatIndex" :seatDetails="seat" :selectedSeat="selectedSheet(seat)" @selectSeat="selectSeat($event)" />
                                                                        </div>
                                                                        <div class="col-md-12" v-if="Math.ceil(layout?.SeatLayout?.SeatDetails.length / 4) == index+1 || 
                                                                        layout?.SeatLayout?.SeatDetails.length-2 == index
                                                                         && Math.ceil(layout?.SeatLayout?.SeatDetails.length / 4)>=2" style="margin-top:20px"></div>
                                                                        <div class="col-md-12 text-center" v-if="layout?.SeatLayout?.SeatDetails.length/2 == index+1" style="font-size: 18px;margin-top:40px">
                                                                            <div v-if="layout?.SeatLayout?.SeatDetails.length/2 == 3">Upper Deck</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div class="card border br-dashed">
                                                                <div class="card-body gray-simple">
                                                                    <!-- <div v-if="search.BoardingPoint" class="bg-white rounded-2 p-2 mb-2"> -->
                                                                        <div class="bg-white rounded-2 p-2 mb-2">
                                                                        <div class="d-flex justify-content-between align-items-center" v-if="search.BoardingPoint">
                                                                            <div class="text-sm fw-medium" style="font-size: 12pt;">Boarding Point</div>
                                                                            <div>
                                                                                <a href="javascript:void(0)" @click="updateBoardingPoint()"><span class="badge rounded-1 mb-0 ms-2" style="background-color: #be4c4c">Change</span></a>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="!boarding_status">
                                                                            <p class="text-dark text-md mb-0" style="font-size: 10pt;">{{ search.BoardingPoint.CityPointName }}</p>
                                                                            <p class="text-dark text-md mb-0" style="margin-top: -8px;font-size: 10pt;"><span>{{search.BoardingPoint.CityPointAddress}}</span><span v-if="search.BoardingPoint.CityPointLocation">, {{ search.BoardingPoint.CityPointLocation }}</span><span v-if="search.BoardingPoint.CityPointLandmark">, {{ search.BoardingPoint.CityPointLandmark }}</span></p>
                                                                            <div class="row">
                                                                                <div class="col-6">
                                                                                    <span class="text-sm fw-medium" style="font-size: 10pt;">Date : </span>{{getTime(search.BoardingPoint?.CityPointTime,'date') }}
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <span class="text-sm fw-medium" style="font-size: 10pt;">Time : </span>{{getTime(search.BoardingPoint?.CityPointTime,'time') }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div v-if="boarding_status">
                                                                            <div class="form-group position-relative m-0 border-bottom">
                                                                                <input
                                                                                    type="text"
                                                                                    @keyup="searchBoardingPoint()"
                                                                                    class="form-control bold border-radius-0 border-0 ps-5"
                                                                                    placeholder="Choose Boarding Point"
                                                                                    :class="{ 'is-invalid': error.BoardingPointId }"
                                                                                    v-model="BoardingPoint"
                                                                                />
                                                                                <span class="position-absolute top-50 ms-4 translate-middle text-sm fw-medium text-muted"><i class="fa-solid fa-magnifying-glass"></i> </span>
                                                                            </div>
                                                                            <ul class="list-group list-group-borderless borderless mb-2" style="height: 120px; overflow-x: hidden;overflow-y: scroll;">
                                                                                <li class="list-group-item d-flex justify-content-between align-items-center border-radius-0" v-for="boarding, key in boardings" :key="key">
                                                                                    <span>
                                                                                        <input
                                                                                            class="form-check-input me-2"
                                                                                            style="margin-left: auto !important;"
                                                                                            type="radio"
                                                                                            :name="boarding.CityPointName"
                                                                                            :id="boarding.CityPointIndex"
                                                                                            @change="changeBoardingPoint"
                                                                                            :value="boarding.CityPointIndex"
                                                                                            v-model="search.BoardingPointId"
                                                                                        />
                                                                                        <span class="text-sm fw-medium mb-0" :for="boarding.CityPointIndex">{{ boarding.CityPointName }}</span>
                                                                                    </span>
                                                                                    <span class="text-sm fw-medium" :for="boarding.CityPointIndex">{{getTime(boarding.CityPointTime,'time')}}</span>
                                                                                </li>
                                                                            </ul>
                                                                            <span v-if="error.BoardingPointId" class="invalid-feedback">{{ error.BoardingPointId}}</span>
                                                                        </div>
                                                                    </div>
                                                
                                                                    <div v-if="!dropping_status" class="bg-white rounded-2 p-2 mb-2">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <div class="text-sm fw-medium" style="font-size: 12pt;">Dropping Point</div>
                                                                            <div>
                                                                                <a href="javascript:void(0)" @click="updateDroppingPoint()"><span class="badge rounded-1 mb-0 ms-2" style="background-color: #be4c4c">Change</span></a>
                                                                            </div>
                                                                        </div>
                                                                        <p class="text-dark text-md mb-0" style="font-size: 10pt;">{{ search.DroppingPoint.CityPointName }}</p>
                                                                        <p class="text-dark text-md mb-0" style="margin-top: -8px;font-size: 10pt;"><span>{{search.DroppingPoint.CityPointAddress}}</span><span v-if="search.DroppingPoint.CityPointLocation">, {{ search.DroppingPoint.CityPointLocation }}</span><span v-if="search.DroppingPoint.CityPointLandmark">, {{ search.DroppingPoint.CityPointLandmark }}</span></p>
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                <span class="text-sm fw-medium" style="font-size: 10pt;">Date : </span>{{getTime(search.DroppingPoint?.CityPointTime,'date') }}
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <span class="text-sm fw-medium" style="font-size: 10pt;">Time : </span>{{getTime(search.DroppingPoint?.CityPointTime,'time') }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-block">
                                                                        <div v-if="dropping_status">
                                                                            <div class="form-group position-relative m-0 border-bottom">
                                                                                <input
                                                                                    type="text"
                                                                                    @keyup="searchDroppingPoint()"
                                                                                    class="form-control bold border-radius-0 border-0 ps-5"
                                                                                    placeholder="Choose Dropping Point"
                                                                                    :class="{ 'is-invalid': error.DroppingPointId }"
                                                                                    v-model="DroppingPoint"
                                                                                />
                                                                                <span class="position-absolute top-50 ms-4 translate-middle text-sm fw-medium text-muted"><i class="fa-solid fa-magnifying-glass"></i> </span>
                                                                            </div>
                                                                            <ul class="list-group list-group-borderless borderless mb-2" style="height: 120px; overflow-x: hidden;overflow-y: scroll;">
                                                                                <li class="list-group-item d-flex justify-content-between align-items-center border-radius-0" v-for="dropping, key in droppings" :key="key">
                                                                                    <span>
                                                                                        <input
                                                                                            class="form-check-input me-2"
                                                                                            style="margin-left: auto !important;"
                                                                                            type="radio"
                                                                                            :name="dropping.CityPointName"
                                                                                            :id="dropping.CityPointIndex"
                                                                                            @change="changeDroppingPoint"
                                                                                            :value="dropping.CityPointIndex"
                                                                                            v-model="search.DroppingPointId"
                                                                                        />
                                                                                        <span class="text-sm fw-medium mb-0" :for="dropping.CityPointIndex">{{ dropping.CityPointName }}</span>
                                                                                    </span>
                                                                                    <span class="text-sm fw-medium" :for="dropping.CityPointIndex">{{getTime(dropping.CityPointTime,'time')}}</span>
                                                                                </li>
                                                                            </ul>
                                                                            <span v-if="error.DroppingPointId" class="invalid-feedback">{{ error.DroppingPointId}}</span>
                                                                        </div>

                                                                        <div class="form-group mb-2">
                                                                            
                                                                                <div class="card">
                                                                                    <div class="card-body">
                                                                                        <div class="row gx-3 align-items-center">
                                                                                        <div class="col-7">
                                                                                            <div class="crd-heady102Title lh-1" style="line-height: 1.6;">                                                                                        
                                                                                                <span class="txt-sm text-dark lh-1 mb-0">Selected Seat:</span>
                                                                                                <div class="text-dark fw-medium">{{selectSeatNo}}</div>
                                                                                                <div v-if="error.SelectedSeats" style="font-size: 0.875em; color: #dc3545;">{{error.SelectedSeats}}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-5">
                                                                                            <div style="float:right">
                                                                                                <div class="crd-heady103">
                                                                                                    <span class="text-dark fw-medium text-uppercase lh-1">{{book_currency}}. {{ payableAmount.toFixed(1) }}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group mb-2">
                                                                            <!-- <button type="button" class="btn btn-primary full-width fw-medium" @click="verifyBroadingDroppingPoints()">Continue</button> -->
                                                                            <button type="button" class="btn btn-primary full-width fw-medium" @click.prevent="confirmBooking">Continue</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- try ends -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal modal-lg fade" id="cancellationModal" tabindex="-1" aria-labelledby="cancellationModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title fs-6" id="cancellationModal">Cancellation Policy</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive py-2">
                            <table class="table table-light table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Cancellation Time</th>
                                        <th>Cancellation Charges</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="cancellataionPolicy,key in cancellataionPolicies" :key="key">
                                        <td>
                                            Between {{getTime(cancellataionPolicy.FromDate,'time') }} on {{ getTime(cancellataionPolicy.FromDate,'cancel_date') }} -- {{getTime(cancellataionPolicy.ToDate,'time') }} on {{
                                            getTime(cancellataionPolicy.ToDate,'cancel_date') }}
                                        </td>
                                        <td>{{cancellataionPolicy.CancellationCharge}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Select Seat -->
        <div class="modal modal-xl fade" id="selectSeat" tabindex="-1" aria-labelledby="selectSeatModalLabel" aria-hidden="true" ref="BoardingModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title fs-6" id="selectSeatModalLabel">Select Seats</h4>
                        <a href="#" class="text-muted fs-4" data-bs-dismiss="modal" id="close1" aria-label="Close"><i class="fa-solid fa-square-xmark"></i></a>
                    </div>
                    <div class="modal-body px-4 py-4 px-xl-5 px-lg-5">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-12" v-for="(row, index) in layout?.SeatLayout?.SeatDetails" :key="index">
                                        <Seat v-for="seat in row" :key="seat.SeatIndex" :seatDetails="seat" :selectedSeat="selectedSheet(seat)" @selectSeat="selectSeat($event)" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="card border br-dashed">
                                    <div class="card-body">
                                        <div class="d-block mb-2">
                                            <div class="align-items-center">
                                                <div class="text-dark fw-bold fs-5 me-2">{{search.SelectedBus.TravelName}}</div>

                                                <div class="detail ellipsis-container">
                                                    <span class="ellipsis-item">{{getTime(search.SelectedBus.DepartureTime,'cancel_date')}}</span>
                                                    <span class="separate ellipsis-item__normal"></span>
                                                    <span class="ellipsis-item">{{getTime(search.SelectedBus.ArrivalTime,'time') }} - {{getTime(search.SelectedBus.DepartureTime,'time') }}</span>
                                                    <span class="separate ellipsis-item__normal"></span>
                                                    <span class="ellipsis-item"> {{search.SelectedBus.BusType}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-block">
                                            <div class="form-group">
                                                <label class="form-label">Choose Boarding Point</label>
                                                <select class="form-control" :class="{ 'is-invalid': error.BoardingPointId }" v-model="search.BoardingPointId" @change="changeBoardingPoint">
                                                    <option value="">Choose Boarding Point</option>
                                                    <option v-for="boarding, key in boardings" :key="key" :value="boarding.CityPointIndex">{{ boarding.CityPointName }} </option>
                                                </select>
                                                <span v-if="error.BoardingPointId" class="invalid-feedback">{{ error.BoardingPointId}}</span>
                                            </div>

                                            <div class="form-group">
                                                <label class="form-label">Choose Dropping Point</label>
                                                <select class="form-control" :class="{ 'is-invalid': error.DroppingPointId }" v-model="search.DroppingPointId" @change="changeDroppingPoint">
                                                    <option value="">Choose Dropping Point</option>
                                                    <option v-for="dropping, key in droppings" :key="key" :value="dropping.CityPointIndex">{{ dropping.CityPointName }}</option>
                                                </select>
                                                <span v-if="error.DroppingPointId" class="invalid-feedback">{{ error.DroppingPointId }}</span>
                                            </div>
                                            <div class="form-group mb-2">
                                                <label class="form-label me-1">Selected Seats No</label>
                                                <span style="color: #5d6f7d;">{{selectSeatNo}} </span>
                                                <div v-if="error.SelectedSeats" style="font-size: 0.875em; color: #dc3545;">{{error.SelectedSeats}}</div>
                                            </div>

                                            <div class="form-group mb-2">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="crd-heady102 d-flex align-items-center justify-content-start">
                                                        <div class="crd-heady102Title lh-1 ps-2"><span class="fw-semibold text-dark text-uppercase lh-1 mb-0">You Pay</span></div>
                                                    </div>
                                                    <div class="crd-heady103">
                                                        <span class="text-dark fw-semibold text-uppercase">₹ {{ payableAmount.toFixed(1) }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group mb-2">
                                                <!-- <button type="button" class="btn btn-primary full-width fw-medium" @click="verifyBroadingDroppingPoints()">Continue</button> -->
                                                <button type="button" class="btn btn-primary full-width fw-medium" @click.prevent="confirmBooking">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 p-4">
                                <div class="table-responsive">
                                    <table class="table table-light table-striped">
                                        <tbody>
                                            <tr>
                                                <th>Boarding Point</th>
                                                <th>Landmark</th>
                                                <th>Address</th>
                                                <th>Contact No</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                            </tr>
                                            <tr v-for="boarding, key in boardings" :key="key">
                                                <td>{{boarding.CityPointName}}</td>
                                                <td>{{ boarding.CityPointLandmark }}</td>
                                                <td>{{boarding.CityPointAddress}}</td>
                                                <td>{{ boarding.CityPointContactNumber }}</td>
                                                <td>{{getTime(boarding.CityPointTime,'cancel_date')}}</td>
                                                <td>{{getTime(boarding.CityPointTime,'time')}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-12 p-4">
                                <div class="table-responsive">
                                    <table class="table table-light table-striped">
                                        <tbody>
                                            <tr>
                                                <th>Dropping Point</th>
                                                <th>Address</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                            </tr>
                                            <tr v-for="dropping, key in droppings" :key="key">
                                                <td>{{dropping.CityPointName}}</td>
                                                <td>{{dropping.CityPointAddress}}</td>
                                                <td>{{getTime(dropping.CityPointTime,'cancel_date')}}</td>
                                                <td>{{getTime(dropping.CityPointTime,'time')}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    let BusCity = require("@/components/BusCity.vue").default;
    import Search from "@/components/Search.vue";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Seat from "@/components/Seat.vue";

    import VueSimpleRangeSlider from "vue-simple-range-slider";
    import "vue-simple-range-slider/css";

    export default {
        components: { Search, flatPickr, Seat, BusCity, VueSimpleRangeSlider },
        data() {
            return {
                active:'',
                range: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 10,
                },
                destination_meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 10,
                },
                DateOfJourney: new Date().toLocaleDateString("en-GB"),
                search: {
                    DateOfJourney: "",
                    DestinationId: "",
                    OriginId: "",
                    ResultIndex: "",
                    SelectedBus: "",
                    EndUserIp: "",
                    TokenId: "",
                    TraceId: "",
                    SelectedSeats: [],
                    DestinationName: "",

                    OriginName: "",
                    PreferredCurrency: this.$store.state.currency,
                    BoardingPointId: "",
                    BoardingPoint: "",
                    BoardingPointAddress: "",
                    DroppingPointAddress: "",
                    DroppingPointId: "",
                    DroppingPoint: "",
                    BoardingPointTime: "",
                    DroppingPointTime: "",
                },
                filter: {
                    TraceId: this.search?.TraceId,
                    BusType: "",
                },
                layout: null,
                origin_name: "",
                destination_name: "",
                origin_cities: [],
                destination_cities: [],
                cancellataionPolicies: [],
                bus: {},
                seat_booking: {
                    boarding_point_id: "",
                    dropping_point_id: "",
                },
                boardings: [],
                droppings: [],
                error: {
                    BoardingPointId: "",
                    DroppingPointId: "",
                    SelectedSeats: "",
                },
                BoardingPoint: "",

                results: [],
                bus_results: [],
                keyword: "",
                errors: [],

                //new filter
                bus_type_filter: {
                    ac: false,
                    non_ac: false,
                    seater: false,
                    sleeper: false,
                },
                departure_active: [],
                bustype_active: "",
                filter_keywords: [],

                show_seat: false,
                selected_bus_id: "",

                min: 0,
                max: 0,
                filter_range_keyword: [],
                boardingPoints: [],
                droppingPoints: [],
                boarding_status: true,
                dropping_status: true,
                status: "dropping",
                book_currency:'',
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.search.OriginId = vm.$store.getters.bus_search.OriginId;
                vm.meta.search = vm.$store.getters.bus_search.OriginId;
                vm.search.DateOfJourney = vm.$store.getters.bus_search.DateOfJourney
                vm.search.DestinationId = vm.$store.getters.bus_search.DestinationId;
                vm.destination_meta.search = vm.$store.getters.bus_search.DestinationId;
                vm.searchBuses();
            });
        },
        mounted() {
            this.getOriginCities();
            $(this.$refs.rangeSlider).ionRangeSlider({
                skin: "round",
                type: "double",
                min: this.min,
                max: this.max,
                from: this.min,
                to: this.max,
                grid: false,
                onChange: this.updateValue,
            });
        },
        computed: {
            selectSeatNo() {
                return this.search.SelectedSeats.map(({ SeatName }) => SeatName).join(", ");
            },
            payableAmount() {
                return this.search.SelectedSeats.reduce((acc, item) => acc + item?.Price?.PublishedPrice, 0);
            },
        },
        watch: {
            keyword: function () {
                this.getFilterBusType();
            },
            min(newVal, oldVal) {
                console.log("Min changed from", oldVal, "to", newVal);
                this.updateSlider();  // or any other method that should be called
            },
            max(newVal, oldVal) {
                console.log("Max changed from", oldVal, "to", newVal);
                this.updateSlider();  // or any other method that should be called
            }
        },

        methods: {
            updateSlider() {
                const sliderInstance = $(this.$refs.rangeSlider).data("ionRangeSlider");
                if (sliderInstance) {
                    sliderInstance.update({
                        min: this.min,
                        max: this.max,
                        from: this.min,
                        to: this.max
                    });
                }
            },
            generateToken() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "generateToken" })
                    .then(function (response) {
                        vm.search.EndUserIp = response.data.EndUserIp;
                        vm.search.TokenId = response.data.Token;
                        vm.searchBuses();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            searchBuses() {
                let vm = this;
                let loader = vm.$loading.show();
                // vm.search.DateOfJourney = vm.DateOfJourney
                vm.search.PreferredCurrency = this.$store.state.currency;
                vm.$store.dispatch("setBusSearch", vm.search);
                vm.$store
                    .dispatch("post", { uri: "bus/search", data: vm.search })
                    .then(function (response) {
                        loader.hide();
                        
                        vm.origin_name = response.data.BusSearchResult.Origin;
                        vm.destination_name = response.data.BusSearchResult.Destination;
                        vm.results = response.data.BusSearchResult.BusResults;
                        vm.bus_results = response.data.BusSearchResult.BusResults;
                        vm.search.TraceId = response.data.BusSearchResult.TraceId;
                        vm.filter_range_keyword = vm.results;
                        const maxValueObject = vm.bus_results?.reduce((max, current) => (current.BusPrice.PublishedPrice > max.BusPrice.PublishedPrice ? current : max));
                        const minValueObject = vm.bus_results?.reduce((min, current) => (current.BusPrice.PublishedPrice < min.BusPrice.PublishedPrice ? current : min));
                        vm.min = parseInt(0);
                        vm.max = parseInt(maxValueObject.BusPrice.PublishedPrice);
                        // console.log("min", vm.min, "max", vm.max);
                        // const sliderInstance = $(vm.$refs.rangeSlider).data("ionRangeSlider");
                        // if (sliderInstance) {
                        //     sliderInstance.update({ min: vm.min, max: vm.max });
                        // }
                    })
                    .catch(function (error) {
                        loader.hide();
                        if (error?.response?.data?.message == "No Result Found.") {
                            vm.error_message = error?.response?.data?.message;
                            vm.results = [];
                        }
                        vm.errors = error?.response?.data?.errors;
                        vm.$store.dispatch("error", error?.response?.data?.message);
                    });
            },

            getOriginCities() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "bus/paginateCities", data: vm.meta })
                    .then(function (response) {
                        vm.origin_cities = response.data.data;

                        let origin = vm.origin_cities.filter(function (ele) {
                            return ele.city_code == vm.search.OriginId;
                        });
                        if (origin.length) {
                            vm.search.OriginName = origin[0].city_name;
                        }
                        vm.getDestinationCities();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getDestinationCities() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "bus/paginateCities", data: vm.destination_meta })
                    .then(function (response) {
                        vm.destination_cities = response.data.data;
                        let destination = vm.destination_cities.filter(function (ele) {
                            return ele.city_code == vm.search.DestinationId;
                        });
                        if (origin.length) {
                            vm.search.DestinationName = destination[0].city_name;
                        }
                    })
                    .catch(function (error) {
                        vm.errors = error.response?.data?.errors;
                        vm.$store.dispatch("error", error.response?.data?.message);
                    });
            },
            selectCity(e, keyword) {
                if (keyword == "origin") {
                    this.meta.search = e.target.value;
                    this.getOriginCities();
                }
                if (keyword == "destination") {
                    this.destination_meta.search = e.target.value;
                    this.getDestinationCities();
                }
            },
            getTime(datetime, keyword) {
                let vm = this;
                if (keyword == "date") {
                    let date = moment(datetime).format("Do MMM");
                    return date;
                }
                if (keyword == "time") {
                    let time = moment(datetime).format("HH:mm");
                    return time;
                }
                if (keyword == "cancel_date") {
                    let cancel_date = moment(datetime).format("DD MMM YYYY");
                    return cancel_date;
                }
            },
            cancellataionPolicy(policy) {
                let vm = this;
                let policy_list = vm.results.filter(function (element) {
                    if (element.ResultIndex == policy) {
                        vm.cancellataionPolicies = element.CancellationPolicies;
                    }
                });
            },
            hideSeats(result){
                result.show_seat = false
            },

            selectSeats(result) {
                let vm = this;
                console.log("Result", result);
                vm.search.ResultIndex = result.ResultIndex;
                vm.search.SelectedBus = result;
                vm.book_currency = result.BusPrice.CurrencyCode;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "bus/getDetails", data: vm.search })
                    .then(function (response) {
                        result.show_seat = true
                        vm.selected_bus_id = result.ResultIndex;
                        vm.layout = response.data.GetBusSeatLayOutResult.SeatLayoutDetails;
                        vm.boardings = response.data.GetBusRouteDetailResult.BoardingPointsDetails;
                        vm.boardingPoints = response.data.GetBusRouteDetailResult.BoardingPointsDetails;
                        vm.droppings = response.data.GetBusRouteDetailResult.DroppingPointsDetails;
                        vm.droppingPoints = response.data.GetBusRouteDetailResult.DroppingPointsDetails;
                        loader.hide();
                        
                        // vm.layout?.SeatLayout?.SeatDetails.forEach(row => { row.sort((a, b) => parseInt(a.RowNo) - parseInt(b.RowNo));});

                        // // Step 2: Sort the main array by RowNo of the first seat in each sub-array
                        // vm.layout?.SeatLayout?.SeatDetails.sort((a, b) => parseInt(a[0].RowNo) - parseInt(b[0].RowNo));

                        // console.log(vm.layout?.SeatLayout?.SeatDetails);
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.$store.dispatch("error", error?.response?.data?.message);
                    });
            },
            selectedSheet(seat) {
                let SelectedSeats = this.search.SelectedSeats.filter((selected_seat) => {
                    return selected_seat.SeatIndex == seat.SeatIndex;
                });
                if (SelectedSeats.length == 0) {
                    return false;
                } else {
                    return true;
                }
            },
            selectSeat(e) {
                if (!this.selectedSheet(e)) {
                    this.search.SelectedSeats.push(e);
                } else {
                    this.search.SelectedSeats.splice(
                        this.search.SelectedSeats.findIndex((selectedSeat) => {
                            return selectedSeat.SeatIndex === e.SeatIndex;
                        }),
                        1
                    );
                }
            },

            GetHourMins(from, to) {
                let vm = this;
                let difference = moment(to) - moment(from);
                const minutes = Math.floor(difference / (1000 * 60)) % 60;
                const hours = Math.floor(difference / (1000 * 60 * 60)) % 24;

                let time_differnece = hours + "H " + minutes + "M";

                return time_differnece;
            },

            confirmBooking() {
                let vm = this;
                vm.error.BoardingPointId = "";
                vm.error.DroppingPointId = "";
                vm.error.SelectedSeats = "";

                let flag = 0;
                if (vm.search.BoardingPointId == "") {
                    vm.error.BoardingPointId = "Boarding point field is required";
                    flag = 1;
                }
                if (vm.search.DroppingPointId == "") {
                    vm.error.DroppingPointId = "Dropping point field is required";
                    flag = 1;
                }
                if (vm.search.SelectedSeats.length == 0) {
                    vm.error.SelectedSeats = "Selected Seats field is required";
                    flag = 1;
                }

                if (flag == 0) {
                    vm.$store.dispatch("setBusSearch", vm.search);
                    let modal = vm.$refs.BoardingModal;
                    modal.style.display = "none !important";
                    document.getElementById("close1").click();
                    vm.$router.push("/bus_detail");
                }
            },

            changeBoardingPoint() {
                let boardings = this.boardings.filter((boarding) => {
                    return boarding.CityPointIndex == this.search.BoardingPointId;
                });
                if (boardings.length != 0) {
                    console.log('boardings[0]:-----',boardings[0])
                    this.search.BoardingPoint = boardings[0]
                    // this.search.BoardingPointTime = boardings[0].CityPointTime;
                    // this.search.BoardingPointAddress = boardings[0].CityPointAddress;
                }
                this.boarding_status = false;
                if (this.status == "dropping") {
                    this.dropping_status = true;
                }
            },
            changeDroppingPoint() {
                let droppings = this.droppings.filter((dropping) => {
                    return dropping.CityPointIndex == this.search.DroppingPointId;
                });
                if (droppings.length != 0) {
                    console.log('droppings[0]:-----',droppings[0])
                    this.search.DroppingPoint = droppings[0]
                    // this.search.DroppingPointTime = droppings[0].CityPointTime;
                    // this.search.DroppingPointAddress = droppings[0].CityPointAddress;
                }
                this.dropping_status = false;
            },
            selectOrigin(e) {
                const city = e.target.value;
                this.search.OriginId = city.city_code;
                this.search.OriginName = city.city_name;
            },
            selectDestination(e) {
                const city = e.target.value;
                this.search.DestinationId = city.city_code;
                this.search.DestinationName = city.city_name;
            },
            // getBusType(result) {
            //     console.log("ss---",this.bus_types);
            //     const bus_types = [...new Set(result.map((x) => x.BusType))];
            //     return bus_types;
            // },
            getFilterBusType() {
                let results = this.bus_results;
                if (this.keyword != "" && this.keyword) {
                    results = results.filter((item) => {
                        return item.BusType.toUpperCase().includes(this.keyword.toUpperCase());
                    });
                }
                this.results = results;
                return results;
            },

            clearFilter() {
                this.keyword = "";
                // this.searchBuses();

                this.bus_type_filter.ac = false;
                this.bus_type_filter.non_ac = false;
                this.bus_type_filter.seater = false;
                this.bus_type_filter.sleeper = false;
                this.departure_active = [];
                this.results = this.bus_results;
            },

            // new bus type

            getBusTypeFilter1(keyword) {
                let vm = this;
                let result = [];
                if (keyword == "A/C") {
                    vm.bus_type_filter.ac = keyword;
                }
                if (keyword == "Non A/C") {
                    vm.bus_type_filter.non_ac = keyword;
                }
                if (keyword == "Seater") {
                    vm.bus_type_filter.seater = keyword;
                }
                if (keyword == "Sleeper") {
                    vm.bus_type_filter.sleeper = keyword;
                }

                if (!vm.filter_keywords.includes(keyword)) {
                    vm.filter_keywords.push(keyword);
                }

                let results = vm.bus_results.filter((ele) => {
                    return this.filter_keywords.every((v) => ele.BusType.includes(v));
                });

                vm.results = results;
            },

            getBusTypeFilter(keyword) {
                let vm = this;

                if (keyword === "A/C") {
                    vm.bus_type_filter.ac = !vm.bus_type_filter.ac;
                } else if (keyword === "Non A/C") {
                    vm.bus_type_filter.non_ac = !vm.bus_type_filter.non_ac;
                } else if (keyword === "Seater") {
                    vm.bus_type_filter.seater = !vm.bus_type_filter.seater;
                } else if (keyword === "Sleeper") {
                    vm.bus_type_filter.sleeper = !vm.bus_type_filter.sleeper;
                }

                // Update filter keywords based on the bus type filters
                vm.filter_keywords = [];
                if (vm.bus_type_filter.ac) vm.filter_keywords.push("A/C");
                if (vm.bus_type_filter.non_ac) vm.filter_keywords.push("Non A/C");
                if (vm.bus_type_filter.seater) vm.filter_keywords.push("Seater");
                if (vm.bus_type_filter.sleeper) vm.filter_keywords.push("Sleeper");
                // console.log('bus filters1:---', vm.bus_type_filter)
                // console.log('bus filters2:---', vm.filter_keywords, vm.filter_keywords.length)
                
                // Filter the results based on all selected keywords
                let results = [];
                // if (vm.filter_keywords.length > 0) {
                //     results = vm.bus_results.filter((item) => {
                //         let BusType = item.BusType.toLowerCase();
                //         return vm.filter_keywords.some((keyword) => BusType.includes(keyword.toLowerCase()));
                //     });
                // } else {
                //     results = vm.bus_results;
                // }
                // vm.results = results;
                // vm.filter_range_keyword = results;

                if(vm.filter_keywords.length == 0){
                    results = vm.bus_results;
                }
                else{
                    let ac = [];
                    let non_ac = []
                    let seater = []
                    let sleeper = []
                    if(vm.bus_type_filter.ac==true){
                        ac = vm.bus_results.filter((item) => {                             
                            let BusType = item.BusType.toLowerCase();
                            if(vm.bus_type_filter.seater==true && vm.bus_type_filter.sleeper==true){
                                return BusType.includes("a/c") && BusType.includes("seater") && BusType.includes("sleeper") && !BusType.includes("non a/c");
                            }
                            else if(vm.bus_type_filter.seater==true){
                                return BusType.includes("a/c") && BusType.includes("seater") && !BusType.includes("non a/c");
                            }
                            else if(vm.bus_type_filter.sleeper==true){
                                return BusType.includes("a/c") && BusType.includes("sleeper") && !BusType.includes("non a/c");
                            }
                            else{
                                return BusType.includes("a/c") && !BusType.includes("non a/c");
                            }
                        });
                    }
                    if(vm.bus_type_filter.non_ac==true){
                        non_ac = vm.bus_results.filter((item) => {
                            let BusType = item.BusType.toLowerCase();

                            if(vm.bus_type_filter.seater==true && vm.bus_type_filter.sleeper==true){
                                return BusType.includes("non a/c") && BusType.includes("seater") && BusType.includes("sleeper");
                            }
                            else if(vm.bus_type_filter.seater==true){
                                return BusType.includes("non a/c") && BusType.includes("seater");
                            }
                            else if(vm.bus_type_filter.sleeper==true){
                                return BusType.includes("non a/c") && BusType.includes("sleeper");
                            }
                            else{
                                return BusType.includes("non a/c");
                            }
                            
                        });
                    }
                    if(vm.bus_type_filter.seater==true && vm.bus_type_filter.ac==false && vm.bus_type_filter.non_ac==false){
                        seater = vm.bus_results.filter((item) => {
                            let BusType = item.BusType.toLowerCase();                        
                            return BusType.includes("seater");
                        });
                    }
                    if(vm.bus_type_filter.sleeper==true && vm.bus_type_filter.ac==false && vm.bus_type_filter.non_ac==false){
                        sleeper = vm.bus_results.filter((item) => {
                            let BusType = item.BusType.toLowerCase();                        
                            return BusType.includes("sleeper");
                        });
                    }
                    // console.log('bus_ac:---', ac);
                    // console.log('bus_non_ac:---', non_ac);
                    // console.log('bus_seater:---', seater);
                    // console.log('bus_sleeper:---', sleeper);
                    results = [...ac, ...non_ac, ...seater, ...sleeper];
                }
                vm.results = results;
                vm.filter_range_keyword = results;                            
            },

            

            toggleDepartureFilter(keyword) {
                if (this.isSelected(keyword)) {
                    // Remove from array if already selected
                    this.departure_active = this.departure_active.filter((item) => item !== keyword);
                } else {
                    // Add to array if not already selected
                    this.departure_active.push(keyword);
                }
                // Call your filter function here based on updated departure_active array
                this.applyFilters();
            },
            isSelected(keyword) {
                return this.departure_active.includes(keyword);
            },
            applyFilters() {
                let resultdata = this.bus_results;
                if (this.departure_active.length > 0) {
                    resultdata = resultdata.filter((event) => {
                        const eventDate = new Date(event.DepartureTime);
                        return this.departure_active.some((keyword) => {
                            if (keyword === "Before 10 AM") {
                                const filterDate = new Date(this.search.DateOfJourney + " 10:00:00");
                                return eventDate <= filterDate;
                            } else if (keyword === "10 AM - 5 PM") {
                                const filterFromDate = new Date(this.search.DateOfJourney + " 10:00:00");
                                const filterToDate = new Date(this.search.DateOfJourney + " 17:00:00");
                                return eventDate >= filterFromDate && eventDate <= filterToDate;
                            } else if (keyword === "5 PM - 11 PM") {
                                const filterFromDate = new Date(this.search.DateOfJourney + " 17:00:00");
                                const filterToDate = new Date(this.search.DateOfJourney + " 23:00:00");
                                return eventDate >= filterFromDate && eventDate <= filterToDate;
                            } else if (keyword === "After 11 PM") {
                                const filterDate = new Date(this.search.DateOfJourney + " 23:00:00");
                                return eventDate >= filterDate;
                            }
                            return false;
                        });
                    });
                }

                this.results = resultdata;
                this.filter_range_keyword = resultdata;

                // const maxValueObject = this.results?.reduce((max, current) => (current.BusPrice.PublishedPrice > max.BusPrice.PublishedPrice ? current : max));
                //         const minValueObject = this.results?.reduce((min, current) => (current.BusPrice.PublishedPrice < min.BusPrice.PublishedPrice ? current : min));
                //         this.min = parseInt(minValueObject.BusPrice.PublishedPrice);
                //         this.max = parseInt(maxValueObject.BusPrice.PublishedPrice);
                //         const sliderInstance = $(this.$refs.rangeSlider).data("ionRangeSlider");
                //         if (sliderInstance) {
                //             sliderInstance.update({ min: this.min, max: this.max });
                //         }
            },

            updateValue(data) {
                this.value = data.from + "-" + data.to;
                // let bus_results = this.results
                let resultdata = this.filter_range_keyword;
                resultdata = resultdata.filter((event) => {
                    return event.BusPrice.PublishedPrice >= data.from && event.BusPrice.PublishedPrice <= data.to;
                });
                // console.log("RSSs", resultdata);
                this.results = resultdata;
                // console.log("results", this.results);
                window.scrollTo(0, 0);    
            },
            getHigestPrice() {
                let vm = this;
                let resultdata = this.bus_results;
                resultdata.sort((a, b) => b.BusPrice.PublishedPrice - a.BusPrice.PublishedPrice);
            },
            getPrice(keyword) {
                let vm = this;
                let resultdata = this.bus_results;
                if (keyword == "high") {
                    resultdata.sort((a, b) => b.BusPrice.PublishedPrice - a.BusPrice.PublishedPrice);
                }
                if (keyword == "low") {
                    resultdata.sort((a, b) => a.BusPrice.PublishedPrice - b.BusPrice.PublishedPrice);
                }
            },
            selectSeats2(result) {
                console.log("rrr---", result);
                let vm = this;
                vm.show_seat = !vm.show_seat;
                vm.selected_bus_id = result.ResultIndex;
                console.log("sss", vm.selected_bus_id);
            },
            searchBoardingPoint() {
                this.boardings = this.boardingPoints;

                if (this.BoardingPoint == "") {
                    this.boardings = this.boardingPoints;
                } else {
                    let selectedBoarding = this.boardings.filter((boarding) => boarding.CityPointLocation.toLowerCase().includes(this.BoardingPoint.toLowerCase()));
                    this.boardings = selectedBoarding;
                }
            },

            searchDroppingPoint() {
                this.droppings = this.droppingPoints;

                if (this.droppingPoint == "") {
                    this.droppings = this.droppingPoints;
                } else {
                    let selectedDropping = this.droppings.filter((dropping) => dropping.CityPointLocation.toLowerCase().includes(this.DroppingPoint.toLowerCase()));
                    this.droppings = selectedDropping;
                }
            },
            updateBoardingPoint() {
                this.status = "boarding";
                this.boarding_status = true;
                this.search.BoardingPoint = ''
                this.search.BoardingPointId = ''
            },
            updateDroppingPoint() {
                this.status = "boarding"
                this.dropping_status = true
                this.search.DroppingPointId = ''
            },
        },
    };
</script>

<style scoped>
    .flightLine.return:before {
        content: "\f5b6";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        left: 30%;
        transform: translate(65%, -50%);
        top: 65%;
        position: absolute;
        rotate: 360deg;
        color: #a0abb8;
    }

    .bus_type {
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        /*row-gap: 0.25rem;*/
    }

    .pl-0 {
        padding-left: 0px;
    }
    .filter-searchBar {
        position: sticky;
        top: 6rem;
        z-index: 7;
    }
    .hgt-50 {
        height: 50px;
    }
    .border-dashed {
        border: 1px dashed #ccd5de;
    }

    @media only screen and (max-width: 767px) {
        .col-hide {
            display: none !important;
        }
        .margin-hide {
            margin-bottom: 0px;
        }
    }

    .list-group-item {
        border: none;
    }
    .border-radius-0 {
        border-radius: 0px;
    }
</style>
