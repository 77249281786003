<template>
	<div>
    	<div class="image-cover hero-header bg-white" style="background:url(img/hotel.png)no-repeat;" data-overlay="5">
			<div class="container">
				<div class="row justify-content-center align-items-center">
					<div class="col-xl-9 col-lg-10 col-md-12 col-sm-12">
						<div class="position-relative text-center mb-3">
							<h1>Book Your Hotel</h1>
						</div>
					</div>

					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div class="search-wrap with-label bg-white rounded-3 p-3">
							<div class="row gx-lg-2 g-3">
								<div class="col-xl-3 col-lg-3 col-md-12">
									<div class="row gy-3 gx-lg-2 gx-3">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
											<div class="form-group mb-0">
												<label>Leaving From</label>
												<City :initialize="search.CityName" @selectCity="selectDestination($event)" :class="{ 'is-invalid': errors?.DestinationCityId }"></City>
                                            	<span v-if="errors?.DestinationCityId" class="invalid-feedback">{{ errors?.DestinationCityId[0] }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-4 col-lg-3 col-md-12">
									<div class="row gy-3 gx-lg-2 gx-3">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
											<div class="form-group mb-0">
												<label>Choose Date</label>
												<flatPickr class="form-control hgt-62 fw-bold" placeholder="Check-In & Check-Out" :class="{ 'is-invalid': errors?.selectedDates }" v-model="search.selectedDates" :config="{mode:'range', minDate: 'today' }" @onChange="getSelectedDates"></flatPickr>
												<span v-if="errors?.selectedDates" class="invalid-feedback">{{ errors?.selectedDates[0] }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-3 col-lg-3 col-md-12">
									<div class="row gy-3 gx-lg-2 gx-3">
										<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
											<div class="form-group mb-0">
												<label>Rooms & Guests</label>
												<div class="booking-form__input guests-input mixer-auto dropdown guest-selector me-2">
													<button name="guests-btn" id="dropdownGuest" data-bs-auto-close="outside" data-bs-toggle="dropdown" class="form-guest-selector form-control selection-result">
														<span v-if="search.NoOfAdults <= 1">{{ search.NoOfAdults }} Adult, </span>
														<span v-if="search.NoOfAdults > 1">{{ search.NoOfAdults }} Adults, </span>
														<span v-if="search?.NoOfChild == 1">1 Child, </span>
														<span v-if="search?.NoOfChild > 1">{{search?.NoOfChild}} Children, </span>
														<span v-if="search?.NoOfRooms == 1">1 Room</span>
														<span v-if="search?.NoOfRooms > 1">{{search?.NoOfRooms}} Rooms</span>
													</button>
													<ul class="dropdown-menu guest-selector-dropdown px-2 py-2" aria-labelledby="dropdownGuest">
														<li class="d-flex justify-content-between">
															<div  class="hstack gap-1 align-items-center">
                                                                <h6 class="mb-0" style="color: black;">Rooms</h6>
                                                            </div>
															<div class="hstack gap-1 align-items-center">
																<button @click="updatePaxRooms('subtract')" type="button" class=" p-0 mb-0">
																	<span class="guests-input__ctrl minus" id="room-subs-btn">
																		<i class="fa-solid fa-minus"></i>
																	</span>										
																</button>
																<span id="guests-count-room">{{ search.NoOfRooms }}</span>
				
																<button @click="updatePaxRooms('add')" type="button" class="btn btn-link p-0 mb-0">
																	<span class="guests-input__ctrl plus" id="room-add-btn">
																		<i class="fa-solid fa-plus"></i>
																	</span>
																</button>
															</div>
														</li>
														<li class="dropdown-divider"></li>
														<li v-for="room,key in search.PaxRooms" :key="key">
															<div class="d-flex justify-content-between">
																<div>
																	<h6 class="mb-0" style="color:black">Adults</h6>
																	<small class="fs-12" >Ages 13 or above</small>
																	<div class="d-flex align-items-center mt-2">
																		<button @click="changePassengersCount('Adult','-', room)" class="p-0 mb-0 text-center">
																			<span class="guests-input__ctrl minus" id="adults-subs-btn">
																				<i class="fa-solid fa-minus"></i>
																			</span>
																		</button>
																		<span id="guests-count-adults">{{ room.Adults }}</span>
																		<button @click="changePassengersCount('Adult','+', room)" type="button" class="text-center p-0 mb-0">
																			<span class="guests-input__ctrl plus" id="adults-add-btn">
																				<i class="fa-solid fa-plus"></i>
																			</span>
																		</button>
																	</div>
																</div>
																<div>
																	<h6 class="mb-0" style="color:black">Children</h6>
																	<small class="fs-12">Ages less than 13 years</small>
																	<div class="d-flex align-items-center mt-2">
																		<button @click="changePassengersCount('Child','-', room)" class="p-0 mb-0 text-center">
																			<span class="guests-input__ctrl minus" id="children-subs-btn">
																				<i class="fa-solid fa-minus"></i>
																			</span>													
																		</button>
																		<span id="guests-count-children"> {{ room.Children }}</span>
																		<button @click="changePassengersCount('Child','+', room)" type="button" class="text-center p-0 mb-0">
																			<span class="guests-input__ctrl plus" id="children-add-btn">
																				<i class="fa-solid fa-plus"></i>
																			</span>
																		</button>
																	</div>
																</div>
															</div>															
															<div class="mt-2"v-if="room.Children>0">
																<small class="fs-12">Children ages less than 13 years</small>
																<div class="row g-2">
																	<div v-for="child, child_key in room.Children" :key="child_key" class="col-6" >
																		<select class="form-control" v-model="room.ChildrenAges[child_key]" :class="{'is-invalid': errors.ChildNo}" style="height: 35px !important;">
																			<option value="">Select <i class="fas fa fa-angle-down"></i></option>
																			<option value=0><1</option>
																				<option :value="num" v-for="num, key in 13" :key="key">{{ num }}</option>
																		</select>
																	</div>
																</div>	
															</div>
															<li class="dropdown-divider"></li>
														</li>											
													</ul>	
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-2 col-md-12">
									<div class="form-group mb-0">
										<button type="submit" @click.prevent="searchHotels()" class="btn btn-primary full-width fw-medium"><i class="fa-solid fa-magnifying-glass me-2"></i>Search</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ================================ Article Section Start ======================================= -->
		<section class="py-5">
			<div class="container">

				<div class="row align-items-center justify-content-center">
					<div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
						<div class="secHeading-wrap text-center mb-5">
							<h2>Trending & Popular Articles</h2>
							<p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
						</div>
					</div>
				</div>


				<div class="row justify-content-center g-4">

					<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<div class="blogGrid-wrap d-flex flex-column h-100">
							<div class="blogGrid-pics">
								<a href="#" class="d-block"><img src="img/blog-1.jpg" class="img-fluid rounded" alt="Blog image"></a>
							</div>
							<div class="blogGrid-caps pt-3">
								<div class="d-flex align-items-center mb-1"><span
										class="label text-success bg-light-success">Destination</span></div>
								<h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in
										Comfirtable And Best Price</a></h4>
								<p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks
									to be unintendedly humorous or offensive day of going live.</p>
								<a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<div class="blogGrid-wrap d-flex flex-column h-100">
							<div class="blogGrid-pics">
								<a href="#" class="d-block"><img src="img/blog-2.jpg" class="img-fluid rounded" alt="Blog image"></a>
							</div>
							<div class="blogGrid-caps pt-3">
								<div class="d-flex align-items-center mb-1"><span
										class="label text-success bg-light-success">Journey</span></div>
								<h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in
										Comfirtable And Best Price</a></h4>
								<p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks
									to be unintendedly humorous or offensive day of going live.</p>
								<a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<div class="blogGrid-wrap d-flex flex-column h-100">
							<div class="blogGrid-pics">
								<a href="#" class="d-block"><img src="img/blog-3.jpg" class="img-fluid rounded" alt="Blog image"></a>
							</div>
							<div class="blogGrid-caps pt-3">
								<div class="d-flex align-items-center mb-1"><span
										class="label text-success bg-light-success">Business</span></div>
								<h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in
										Comfirtable And Best Price</a></h4>
								<p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks
									to be unintendedly humorous or offensive day of going live.</p>
								<a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
		<!-- ================================ Article Section Start ======================================= -->
	</div>


</template>
<script>
	import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Search from "@/components/Search.vue";
	let City = require("@/components/City.vue").default;

	export default {
		components: { flatPickr, Search, City},
		data() {
            return {
				search: {
					HotelCodes:[],
                    CheckIn: "",
                    CheckOut: "",
                    CountryCode: "IN",
                    DestinationCityId: "",
                    CityName: "",
                    CityId:"",
                    CityCode:"",
                    GuestNationality: "IN",
                    NoOfRooms: 1,
                    NoOfAdults: 1,
                    NoOfChild: 0,
					EndUserIp:'',
                    TokenId:'',
					PaxRooms:[],
					selectedDates:[],
					ResponseTime: 1,
					IsDetailedResponse: true,
					Filters: {
                        MealType: 0,
                        NoOfRooms: 10,
                        OrderBy: 0,
                        Refundable: false,
                        StarRating: 0,
                        HotelName:''
                    },
					search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 100,
                    totalRows : '',
                    lastPage : '',
                    from : '',
                    maxPage : '',
                    page:'',
                    to:''
                },
				hotels:[],
				meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 100,
                },
				PaxRoom:{
					Adults:1,
					Children:0,
					ChildrenAges: []
				},
				Children:[],
                cities: [],
                errors:[],
				allocatedRooms:[]
			}
		},
		mounted() {
			this.$store.dispatch("setHotel", null)
            this.$store.dispatch("setSelectedRoom", null)
            this.$store.dispatch("setHotelSearch", null);
			this.search.PaxRooms.push({
				Adults:2,
				Children:0,
				ChildrenAges:[]
			})
			this.search.NoOfAdults = 2
        },
		methods: {
			updatePaxRooms(operand){
				if(operand == 'add' && this.search.NoOfRooms < 3){
					this.search.PaxRooms.push({
						Adults:2,
						Children:0,
						ChildrenAges:[]
					})
					this.search.NoOfRooms++
					this.search.NoOfAdults += 2
				}
				if(operand == 'subtract' &&  this.search.NoOfRooms > 1){
					this.search.PaxRooms.pop()
					this.search.NoOfRooms--
					this.updateAdultsChildrenCount()
				}
			},

			updateAdultsChildrenCount(){
				let adults = 0
				let children = 0
				this.search.PaxRooms.map(function(room){
					adults += room.Adults
					children += room.Children
				})
				this.search.NoOfAdults = adults
				this.search.NoOfChild = children
			},

			changePassengersCount(pax_type, sign, room) {
				if (pax_type === 'Adult' && sign === "+" && room.Adults<8) {
					this.search.NoOfAdults++
					room.Adults++
				} 
				if (pax_type === 'Adult' && sign === "-" && room.Adults > 1) {
					this.search.NoOfAdults--
					room.Adults--
				}

				if (pax_type === 'Child' && sign === "+" && room.Children<4) {
					this.search.NoOfChild++;
					room.Children++
					room.ChildrenAges.push(1)
				} 
				if (pax_type === 'Child' && sign === "-" && room.Children>0) {
					this.search.NoOfChild--;
					room.Children--
					room.ChildrenAges.pop()
				}
			},
		
			validateSearch(){
				let vm = this
				vm.errors = {};
				if (vm.search.DestinationCityId == "") {
						vm.errors.DestinationCityId = ["Destination id field cannot be empty"];
					}
				if (vm.search.NoOfAdults == 0 && vm.search.NoOfAdults == 0) {
					vm.errors.NoOfAdults = ["Rooms & Guests filed cannot be empty"];
				}
				if (vm.search.selectedDates.length == 0) {
					vm.errors.selectedDates = ["Checkin and Checkout filed cannot be empty"];
				} 
			},

			searchHotels(){
				let vm = this
				vm.validateSearch()
				console.log(vm.errors)
				if (Object.keys(vm.errors).length > 0) {
					console.log(vm.errors)
				}else{
					vm.$store.dispatch("setHotelSearch", vm.search)
                	vm.$router.push("/hotel_list")
				}
			},


			getSelectedDates(dates){
				let vm = this;	
				vm.search.CheckIn = moment(dates[0]).format("YYYY-MM-DD")
				vm.search.CheckOut = moment(dates[1]).format("YYYY-MM-DD")
			},

			selectDestination(e) {
				let vm = this
                const city = e.target.value
                vm.search.CountryCode =city.country_code
                vm.search.DestinationCityId =city.city_code
                vm.search.CityName =city.city_name
				vm.search.CityId =city.city_code
                vm.search.CityCode =city.city_code
            },
		}

		
	}
</script>

<style scoped>
.search-wrap .form-control {
    height: 62px!important;
}
.hgt-62 {
	height: 62px !important;
}
.hero-header {
	padding: 5em 0 5em;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}

@media (min-width: 993px) {
	.hero-header {
		min-height: 400px;
		padding: 7rem 0;
		height: 0vh;
	}
}
.booking-form__input.guests-input button {
	min-width:3rem!important
}
.guests-input button:before {
    content:none;
}

.guests-input button:after {
    content:none;
}
.formcontrol {
	height: 35px !important;
}


.fs-12{
	font-size: 12px;
}
.dropdown-menu.show {
    display: block;
    width: 300px;
}
</style>