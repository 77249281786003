<template>
    <div>
        <div class="image-cover hero-header bg-white" style="background: url(images/Bus1.png) no-repeat;" data-overlay="5">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12">
                        <div class="position-relative text-center mb-3">
                            <h1>Book Bus Tickets</h1>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="search-wrap with-label bg-white rounded-3 p-3">
                            <div class="row gx-lg-2 g-3">
                                <div class="col-xl-7 col-lg-7 col-md-12">
                                    <div class="row gy-3 gx-lg-2 gx-3">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                            <div class="form-group hdd-arrow mb-0">
                                                <label>Leaving From</label>
                                                <BusCity :initialize="search.OriginName" @selectCity="selectOrigin($event)" :class="{ 'is-invalid': errors?.OriginId }"></BusCity>
                                                <span v-if="errors?.OriginId" class="invalid-feedback">{{ errors?.OriginId[0] }}</span>
                                            </div>
                                            <div class="btn-flip-icon mt-md-0">
                                                <button class="p-0 m-0 text-primary"><i class="fa-solid fa-right-left"></i></button>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group hdd-arrow mb-0">
                                                <label>Going To</label>
                                                <BusCity :initialize="search.DestinationName" @selectCity="selectDestination($event)" :class="{ 'is-invalid': errors?.DestinationId }"></BusCity>
                                                <span v-if="errors?.DestinationId" class="invalid-feedback">{{ errors?.DestinationId[0] }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-12">
                                    <div class="row gy-3 gx-lg-2 gx-3">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group mb-0">
                                                <label>Select date</label>
                                                <!-- <flatPickr class="form-control fw-bold" placeholder="Select date" :class="{ 'is-invalid': errors?.DateOfJourney }" v-model="search.DateOfJourney" :config="{mode:'range'}"></flatPickr> -->
                                                <flatPickr class="form-control hgt-62 fw-bold" placeholder="Select date" :class="{ 'is-invalid': errors?.DateOfJourney }" v-model="DateOfJourney" :config="{dateFormat: 'd-m-Y'}"></flatPickr>
                                                <span v-if="errors?.DateOfJourney" class="invalid-feedback">{{ errors?.DateOfJourney[0] }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-12">
                                    <div class="form-group mb-0">
                                        <button type="submit" @click.prevent="searchBuses" class="btn btn-primary full-width fw-medium"><i class="fa-solid fa-magnifying-glass me-2"></i>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="pt-0">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
                        <div class="secHeading-wrap text-center mb-5">
                            <h2>Trending & Popular Articles</h2>
                            <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center g-4">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div class="blogGrid-wrap d-flex flex-column h-100">
                            <div class="blogGrid-pics">
                                <a href="#" class="d-block"><img src="img/blog-1.jpg" class="img-fluid rounded" alt="Blog image" /></a>
                            </div>
                            <div class="blogGrid-caps pt-3">
                                <div class="d-flex align-items-center mb-1"><span class="label text-success bg-light-success">Destination</span></div>
                                <h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in Comfirtable And Best Price</a></h4>
                                <p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks to be unintendedly humorous or offensive day of going live.</p>
                                <a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div class="blogGrid-wrap d-flex flex-column h-100">
                            <div class="blogGrid-pics">
                                <a href="#" class="d-block"><img src="img/blog-2.jpg" class="img-fluid rounded" alt="Blog image" /></a>
                            </div>
                            <div class="blogGrid-caps pt-3">
                                <div class="d-flex align-items-center mb-1"><span class="label text-success bg-light-success">Journey</span></div>
                                <h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in Comfirtable And Best Price</a></h4>
                                <p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks to be unintendedly humorous or offensive day of going live.</p>
                                <a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div class="blogGrid-wrap d-flex flex-column h-100">
                            <div class="blogGrid-pics">
                                <a href="#" class="d-block"><img src="img/blog-3.jpg" class="img-fluid rounded" alt="Blog image" /></a>
                            </div>
                            <div class="blogGrid-caps pt-3">
                                <div class="d-flex align-items-center mb-1"><span class="label text-success bg-light-success">Business</span></div>
                                <h4 class="fw-bold fs-6 lh-base"><a href="#" class="text-dark">Make Your Next Journey Delhi To Paris in Comfirtable And Best Price</a></h4>
                                <p class="mb-3">Think of a news blog that's filled with content hourly on the Besides, random text risks to be unintendedly humorous or offensive day of going live.</p>
                                <a class="text-primary fw-medium" href="#">Read More<i class="fa-solid fa-arrow-trend-up ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Search from "@/components/Search.vue";
    let BusCity = require("@/components/BusCity.vue").default;
    export default {
        components: { flatPickr, Search, BusCity },
        data() {
            return {
                myOptions: ["op1", "op2", "op3"],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 10,
                },
                destination_meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "city_name",
                    per_page: 10,
                },
                cities: [],
                origin_cities: [],
                destination_cities: [],
                city_list: [],
                DateOfJourney:new Date().toLocaleDateString('en-GB'),
                search: {
                    // DateOfJourney: moment().format("YYYY-MM-DD"),
                    DateOfJourney: "",
                    DestinationId: "",
                    OriginId: "",

                    EndUserIp: "",
                    TokenId: "",

                    DestinationName: "",

                    OriginName: "",
                    PreferredCurrency: this.$store.state.currency,
                },
                error_message: "",
                errors: [],
            };
        },

        mounted() {
            this.getOriginCities();
            this.getDestinationCities();
        },

        methods: {
            selectCity(e, keyword) {
                if (keyword == "origin") {
                    this.meta.search = e.target.value;
                    this.getOriginCities();
                }
                if (keyword == "destination") {
                    this.destination_meta.search = e.target.value;
                    this.getDestinationCities();
                }
                // this.meta.search=e.target.value;
                // this.getCities();
            },
            mySelectEvent({ id, text }, keyword) {
                let vm = this;
                // vm.search.OriginId=id;
                if (keyword == "destination") {
                    vm.search.DestinationName = text;
                }
                if (keyword == "origin") {
                    vm.search.OriginName = text;
                }

                // vm.search.OriginName=text;
            },

            generateToken() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "generateToken" })
                    .then(function (response) {
                        loader.hide();
                        vm.search.EndUserIp = response.data.EndUserIp;
                        vm.search.TokenId = response.data.Token;
                        // vm.meta.EndUserIp = response.data.EndUserIp
                        // vm.meta.TokenId = response.data.Token
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },
            getOriginCities() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "bus/paginateCities", data: vm.meta })
                    .then(function (response) {
                        vm.origin_cities = response.data.data;
                        console.log("cities", vm.origin_cities);
                        // vm.getDestinationCities();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getDestinationCities() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "bus/paginateCities", data: vm.destination_meta })
                    .then(function (response) {
                        vm.destination_cities = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getCities() {
                let vm = this;
                // let loader = vm.$loading.show();

                vm.$store
                    .dispatch("post", { uri: "bus/paginateCities", data: vm.meta })
                    .then(function (response) {
                        // loader.hide();
                        vm.cities = response.data.data;
                        // vm.cities.map(function (element) {
                        //     vm.city_list.push({
                        //         id: element.city_id,
                        //         text: element.city_name,
                        //     });
                        // });
                    })
                    .catch(function (error) {
                        // loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            searchBuses() {
                let vm = this;
                vm.errors = [];
                if (vm.search.OriginId == "" || vm.search.DestinationId == "") {
                    if (vm.search.OriginId == "") {
                        vm.errors.OriginId = ["Origin id cannot be empty"];
                    }
                    if (vm.search.DestinationId == "") {
                        vm.errors.DestinationId = ["Destination id cannot be empty"];
                    }
                } else {
                    vm.search.DateOfJourney = vm.DateOfJourney
                    vm.$store.dispatch("setBusSearch", vm.search)
                    vm.$router.push('/bus_list')
                }
            },
            selectOrigin(e) {
                const city = e.target.value;
                this.search.OriginId = city.city_code;
                this.search.OriginName = city.city_name;
            },
            selectDestination(e) {
                const city = e.target.value;
                this.search.DestinationId = city.city_code;
                this.search.DestinationName = city.city_name;
            },
        },
    };
</script>
<style scoped>
    .search-wrap .form-control {
        height: 62px !important;
    }
    .hgt-62 {
        height: 62px !important;
    }
    .hero-header {
        padding: 5em 0 5em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-height: 400px;
    }
    @media (min-width: 993px) {
        .hero-header {
            min-height: 400px;
            padding: 7rem 0;
            height: 0vh;
        }
    }
</style>
