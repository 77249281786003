<template>
    <section class="pt-3 gray-simple">
        <div class="container">
            <div class="row">
                <!-- Flight Info -->
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="row">
                        <div class="col-xl-9 col-lg-8 col-md-12">
                            <div class="card border-0 mb-4">
                                <div class="card-body">
                                    <div class="flights-accordion">
                                        <div class="flights-list-item bg-white rounded-3 p-3">
                                            <div class="row gy-4 align-items-center justify-content-between">
                                                <div class="col">
                                                    <div class="row">
                                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                                            <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                                <div class="col-sm-auto">
                                                                    <div class="d-flex align-items-center justify-content-start">
                                                                        <!-- <div class="d-start fl-pic">
                                                                            <img class="img-fluid" src="img/air-2.png" width="45" alt="image" />
                                                                            <div class="square--70 rounded-2 bg-light-primary text-primary fs-3"><i class="fa-solid fa-plane"></i></div>
                                                                        </div> -->
                                                                        <div class="d-end fl-title ps-2" style="width:210px">
                                                                            <div class="text-dark fw-medium">{{ $store.getters?.bus_search?.SelectedBus?.TravelName }}</div>
                                                                            <div class="text-sm text-muted"  >{{ $store.getters?.bus_search?.SelectedBus?.BusType }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row gx-3 align-items-center">
                                                                        <div class="col-auto">
                                                                            <div class="text-dark fw-medium">{{$store.getters?.bus_search?.OriginName}}</div>
                                                                            <div class="text-dark fw-bold">{{getTime($store.getters?.bus_search?.SelectedBus?.DepartureTime,'time') }}</div>
                                                                            <div class="text-muted text-sm fw-medium">{{getTime($store.getters?.bus_search?.SelectedBus?.DepartureTime,'day') }}</div>
                                                                        </div>
                                                                        <div class="col text-center">
                                                                            <!-- <div class="flightLine return">
                                                                                <div></div>
                                                                                <div></div>
                                                                            </div>
                                                                            <div class="text-muted text-sm fw-medium mt-3">Direct</div> -->
                                                                            <div class="position-relative my-4">
                                                                        <div class="opacity-5 position-relative border-dashed"></div>
                                                                        <div class="text-dark fw-medium bg-white rounded-circle position-absolute top-50 start-50 translate-middle">
                                                                            {{ GetHourMins($store.getters?.bus_search?.SelectedBus?.DepartureTime,$store.getters?.bus_search?.SelectedBus?.ArrivalTime) }}
                                                                        </div>
                                                                    </div>
                                                                        </div>
                                                                        <div class="col-auto">
                                                                            <div class="text-dark fw-medium">{{$store.getters?.bus_search?.DestinationName}}</div>
                                                                            <div class="text-dark fw-bold">{{getTime($store.getters?.bus_search?.SelectedBus?.ArrivalTime,'time') }}</div>
                                                                            <div class="text-muted text-sm fw-medium">{{getTime($store.getters?.bus_search?.SelectedBus?.ArrivalTime,'day') }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-auto">
                                                                    <div class="text-dark fw-medium">{{ GetHourMins($store.getters?.bus_search?.SelectedBus?.DepartureTime,$store.getters?.bus_search?.SelectedBus?.ArrivalTime) }}</div>
                                                                    <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                                </div> -->
                                                              
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                                            <!-- <div class="col-xl-10 col-lg-10 col-md-10"> -->
                                                            <div class="row mt-4">
                                                                <div class="col-md-6">
                                                                    <b>Boarding Point &amp; Time</b>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <span class="d-block"> {{ $store.getters?.bus_search?.BoardingPoint?.CityPointAddress }}, {{ $store.getters?.bus_search?.BoardingPoint?.CityPointLandmark }}, </span>   
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <span class="d-block"> {{$store.getters?.bus_search?.BoardingPoint?.CityPointName }}, {{$store.getters?.bus_search?.BoardingPoint?.CityPointLocation }}, </span>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <span class="d-block"> {{ $formatDate($store.getters?.bus_search?.BoardingPoint.CityPointTime, 'long') }} </span>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <span class="d-block"><b>Contact:</b> {{$store.getters?.bus_search?.BoardingPoint?.CityPointContactNumber }} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b>Seat no(s) :</b>
                                                                    <span class="d-block" >{{selectedSeatNo}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Traveler Details -->
                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <!-- Overview -->
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="fw-semibold mb-0">Traveler Details</h6>
                                    </div>

                                    <div class="card-body">
                                        <div class="bg-success bg-opacity-10 rounded-2 p-3 mb-3">
                                            <p class="h6 text-md mb-0"><span class="badge bg-success me-2">Note</span>Please enter your name as per your Government photo id.</p>
                                        </div>
                                        <div class="full-width d-flex flex-column mb-4 position-relative">
                                            <div class="row align-items-stat mb-2" v-for="passenger, key in booking.Passenger" :key="key">
                                                <div class="col-xl-12 col-lg-12 col-md-12 mb-2">
                                                    <h5>Passenger {{ key+1 }}</h5>

                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">Title <span style="color:red">*</span></label>
                                                        <select class="form-control" v-model="passenger.Title" :class="{'is-invalid': passenger?.error?.Title}">
                                                            <option value="">Select Title</option>
                                                            <option>Mr.</option>
                                                            <option>Miss.</option>
                                                            <option>Ms.</option>
                                                            <option>Mrs.</option>                                                            
                                                        </select>
                                                        <span v-if="passenger?.error?.Title"><small class="text-danger">{{ passenger?.error?.Title }}</small></span>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">First Name <span style="color:red">*</span></label>
                                                        <input type="text" class="form-control" placeholder="Your First Name" v-model="passenger.FirstName" :class="{'is-invalid': passenger?.error?.FirstName}"/>
                                                        <span v-if="passenger?.error?.FirstName"><small class="text-danger">{{ passenger?.error?.FirstName }}</small></span>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">Last Name <span style="color:red">*</span></label>
                                                        <input type="text" class="form-control" placeholder="Your Last Name" v-model="passenger.LastName" :class="{'is-invalid': passenger?.error?.LastName}"/>
                                                        <span v-if="passenger?.error?.LastName"><small class="text-danger">{{ passenger?.error?.LastName }}</small></span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">Age <span style="color:red">*</span></label>
                                                        <input type="text" class="form-control" placeholder="Your Age" v-model="passenger.Age" :class="{'is-invalid': passenger?.error?.Age}"/>
                                                        <span v-if="passenger?.error?.Age"><small class="text-danger">{{ passenger?.error?.Age }}</small></span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">Gender <span style="color:red">*</span></label>
                                                        <select class="form-control"  v-model="passenger.Gender" :class="{'is-invalid': passenger?.error?.Gender}">
                                                            <option value="">Select Gender</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female </option>
                                                        </select>
                                                        <span v-if="passenger?.error?.Gender"><small class="text-danger">{{ passenger?.error?.Gender }}</small></span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">Email <span style="color:red">*</span></label>
                                                        <input type="text" class="form-control" placeholder="Your Email" v-model="passenger.Email" :class="{'is-invalid': passenger?.error?.Email}"/>
                                                        <span v-if="passenger?.error?.Email"><small class="text-danger">{{ passenger?.error?.Email }}</small></span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">PhoneNo <span style="color:red">*</span></label>
                                                        <input type="text" class="form-control" placeholder="Your PhoneNo" v-model="passenger.Phoneno" :class="{'is-invalid': passenger?.error?.Phoneno}"/>
                                                        <span v-if="passenger?.error?.Phoneno"><small class="text-danger">{{ passenger?.error?.Phoneno }}</small></span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">ID Type <span style="color:red">*</span></label>
                                                        <select class="form-control"  v-model="passenger.IdType" :class="{'is-invalid': passenger?.error?.IdType}">
                                                            <option value="">Select Id Type</option>
                                                            <option>Aadhaar Card</option>
                                                            <option>PAN Card</option>
                                                            <option>Voter Id Card</option>
                                                            <option>Driving Licence</option>
                                                        </select>
                                                        <span v-if="passenger?.error?.IdType"><small class="text-danger">{{ passenger?.error?.IdType }}</small></span>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label class="form-label">ID No. <span style="color:red">*</span></label>
                                                        <input type="text" class="form-control" placeholder="Your ID No." v-model="passenger.IdNumber" :class="{'is-invalid': passenger?.error?.IdNumber}" />
                                                        <span v-if="passenger?.error?.IdNumber"><small class="text-danger">{{ passenger?.error?.IdNumber }}</small></span>
                                                    </div>
                                                </div>

                                                <div class="col-xl-12 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-label">Address <span style="color:red">*</span></label>
                                                        <textarea  class="form-control" type="text" placeholder="Your address.." id="basicDate" v-model="passenger.Address" :class="{'is-invalid': passenger?.error?.Address}"></textarea>
                                                        <span v-if="passenger?.error?.Address"><small class="text-danger">{{ passenger?.error?.Address }}</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="full-width d-flex flex-column mb-2 position-relative">
                                            <div class="row align-items-stat">
                                              

                                                <div class="col-xl-12 col-lg-12 col-md-12">
                                                    <!-- <router-link to="/bus_booking"  class="btn btn-md full-width px-5 btn-primary fw-medium" type="button">Submit & Proceed for Payment</router-link> -->
                                                    <button @click="blockBus" class="btn btn-md full-width px-5 btn-primary fw-medium" type="button">Submit & Proceed for Payment</button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Sidebar -->
                        <div class="col-xl-3 col-lg-4 col-md-12">
                            <div class="card mb-4 mt-lg-0 mt-4">
                                <div class="card-header"><h4>Price Summary</h4></div>
                                <div class="card-body py-2">
                                    <div class="price-summary">
                                        <ul class="list-group">
                                            <template v-for="SelectedSeat in booking.Passenger">
                                                
                                                <li class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                    Seat - {{ SelectedSeat?.Seat?.SeatName }}
                                                    <span class="fw-semibold text-dark">{{SelectedSeat?.Seat?.Price.CurrencyCode}}. {{ SelectedSeat?.Seat?.Price?.PublishedPrice }}</span>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-footer bg-white border-top py-3">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="fw-semibold text-muted-2 mb-0">Total Price</p>
                                        <p class="fw-semibold text-primary mb-0">{{ $store.getters?.bus_search?.SelectedBus?.BusPrice?.CurrencyCode }}. {{ payableAmount }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card border rounded-3">
                                <div class="card-header">
                                    <h4>Contact Details</h4>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <label class="form-label">Dial Code</label>
                                        <select class="form-control form-control-lg">
                                            <option value="">Select Code</option>
                                            <option selected>India (91)</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Mobile number</label>
                                        <input v-model="passenger.mobile_no" type="text" class="form-control form-control-lg" placeholder="Enter your mobile number" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Email id</label>
                                        <input v-model="passenger.email" type="email" class="form-control form-control-lg" placeholder="Enter your email" />
                                    </div>
                                    <span class="mt-4">
                                        <strong>Your ticket and bus information will be sent to this email address.</strong>
                                    </span>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  import moment from "moment";
    export default {
        components: { },
        data() {
            
            return {
                meta: {},
                passenger:{
                    customer_id:this.$store.getters?.customer?.customer_id,
                    name:this.$store.getters?.customer?.name,
                    mobile_no:this.$store.getters?.customer?.mobile_no,
                    email:this.$store.getters?.customer?.email,
                },
                booking:{
                    EndUserIp:'',
                    TokenId:'',
                    ResultIndex:'',
                    TraceId:'',
                    BoardingPointId:'',
                    DroppingPointId:'',
                    Passenger:[],
                    razorpay_order_id:'',
                    razorpay_payment_id:'',
                },
                confirm_booking:{
                    bus_result:"",
                    Passenger:[],
                },
                errors: [],
                validation_errors: false,
            };
        },
     
        mounted() {
            this.meta = this.$store.getters.bus_search
            this.booking.ResultIndex = this.meta?.ResultIndex
            this.booking.EndUserIp = this.meta?.EndUserIp
            this.booking.TokenId = this.meta?.TokenId
            this.booking.TraceId = this.meta?.TraceId
            this.booking.BoardingPointId = this.meta?.BoardingPointId
            this.booking.DroppingPointId = this.meta?.DroppingPointId
            this.meta.SelectedSeats.forEach((selectedSeat,key) => {
                let data = {
                    'key':key+1,
                    'LeadPassenger': key==0 ? 1 : 0,
                    'PassengerId' : key,
                    'Title' : "",
                    'Address' : "",
                    'Age' : "",
                    'Email' : "",
                    'FirstName' : "",
                    'Gender' : "",
                    'IdNumber' : "",
                    'IdType': "",
                    'LastName' : "",
                    'Phoneno' : "",
                    'Seat' : selectedSeat,
                    'Address': "",
                    'error' : {}
                }
                this.booking.Passenger.push(data);
            });
        },
        computed: {
            selectedSeatNo() {
                return this.$store.getters?.bus_search?.SelectedSeats.map(({SeatName}) =>SeatName).join(', ');
            },
            payableAmount() {
                return this.booking?.Passenger.reduce((acc, item) => acc + item?.Seat?.Price?.PublishedPrice, 0);
            }
        },
        watch:{
            '$store.getters.customer':{
                handler(){
                    this.passenger.customer_id = this.$store.getters?.customer?.customer_id;
                    this.passenger.name = this.$store.getters?.customer?.name;
                    if(!this.passenger.mobile_no){
                        this.passenger.mobile_no=this.$store.getters?.customer?.mobile_no
                    }
                    if(!this.passenger.email){
                        this.passenger.email=this.$store.getters?.customer?.email
                    }
                }
            }
        },
        methods: {
        
            getTime(datetime,keyword){
                let vm=this;
                if(keyword=='date'){
                    let date=moment(datetime).format("Do MMM")
                    return date
                }
                if(keyword=='time'){
                let time=moment(datetime).format("HH:mm")
                return time
                }
                if(keyword=='cancel_date'){
                    let cancel_date=moment(datetime).format("DD MMM YYYY")
                    return cancel_date
                }
                if(keyword=='day'){
                    let day=moment(datetime).format("ddd, MMMM Do YYYY")
                    return day
                }
            },
            GetHourMins(from,to){
                let vm=this;
                let difference=moment(to)-moment(from);
                const minutes = Math.floor(difference / (1000 * 60)) % 60;
                const hours = Math.floor(difference / (1000 * 60 * 60)) % 24;

                let time_differnece=hours+'H '+minutes+'M';

                return time_differnece;
               
            },

            validateBooking(){
                let vm = this
                vm.validation_errors = false
                vm.booking.Passenger.map(function(passenger){
                    if (!passenger.error) {
                        passenger.error = {};
                    }
                    if(passenger.Title){
                        delete passenger.error.Title
                    }else{
                        passenger.error.Title = "Title field is required";
                    }
                    const nameRegex = /^[A-Za-z]{2,}$/;
                    if(passenger.FirstName){
                        if (!nameRegex.test(passenger.FirstName)) {
                            passenger.error.FirstName = "First name must have at least 2 characters"
                        }else{
                            delete passenger.error.FirstName
                        }
                    }else{
                        passenger.error.FirstName = "FirstName field is required"
                    }

                    if(passenger.LastName){
                        if (!nameRegex.test(passenger.LastName)) {
                            passenger.error.FirstName = "Last name must have at least 2 characters"
                        }else{
                            delete passenger.error.LastName
                        }
                    }else{
                        passenger.error.LastName = "LastName field is required"
                    }

                    if (passenger.Age) {
                        if (isNaN(passenger.Age) || passenger.Age <= 0) {
                            passenger.error.Age = "Age must be a positive number";
                        } else if (passenger.Age > 100) {
                            passenger.error.Age = "Age must be less than 100";
                        } else {
                            delete passenger.error.Age;
                        }
                    } else {
                        passenger.error.Age = "Age field is required";
                    }

                    if (passenger.Email) {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(passenger.Email)) {
                            passenger.error.Email = "Invalid email format";
                        } else {
                            delete passenger.error.Email;
                        }
                    } else {
                        passenger.error.Email = "Email field is required";
                    }

                    if (passenger.Phoneno) {
                        const phoneRegex = /^[0-9]{10}$/;
                        if (!phoneRegex.test(passenger.Phoneno)) {
                            passenger.error.Phoneno = "Phone number must be 10 digits long and contain only numbers";
                        } else {
                            delete passenger.error.Phoneno;
                        }
                    } else {
                        passenger.error.Phoneno = "Phoneno field is required";
                    }
                    if (passenger.Gender) {
                        delete passenger.error.Gender;
                    } else {
                        passenger.error.Gender = "Gender field is required";
                    }
                    if (passenger.IdType) {
                        delete passenger.error.IdType;
                    } else {
                        passenger.error.IdType = "IdType field is required";
                    }
                    if (passenger.IdNumber) {
                        delete passenger.error.IdNumber;
                    } else {
                        passenger.error.IdNumber = "IdNumber field is required";
                    }
                    if (passenger.Address) {
                        delete passenger.error.Address;
                    } else {
                        passenger.error.Address = "Address field is required";
                    }
                    if (Object.keys(passenger.error).length > 0) {
                        vm.validation_errors = true;
                    }
                })
            },

            blockBus() {
                let vm = this;
                vm.validateBooking()
                console.log(vm.validation_errors)
                if(!vm.validation_errors){
                    if(vm.$store.getters.customer==null) {
                        document.getElementById('login1').click();
                    }
                    else{
                        let loader = vm.$loading.show();
                        vm.$store.dispatch("post", { uri: "bus/block", data: vm.booking })
                        .then(function (response) {
                            loader.hide();
                            if(response.data.BlockResult.ResponseStatus==1) {
                                vm.initPayment()
                            }
                        })
                        .catch(function (error) {
                            loader.hide();
                            if(error.response.data.errors){
                                vm.errors = error?.response?.data?.errors;
                            }
                        });
                    }
                }
            },
            initPayment() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "hotels/rooms/initPayment", data:{ customer_id:vm.passenger.customer_id,amount:vm.payableAmount} })
                .then(function (response) {
                    loader.hide();
                    console.log("init--",response);
                    vm.makePayment(response.data)
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error?.response?.data?.errors;
                    vm.$store.dispatch("error", error?.response?.data?.message);
                });
            },
            makePayment(order_id) {
                console.log("makePayment",order_id);
                let vm =this;
                var options = {
                    "key": "rzp_test_2Y3JxdhArTPe4V", 
                    "amount": (vm.payableAmount * 100),
                    "currency": this.$store?.getters?.currency,
                    "name": "Fastays",
                    "description": "Booking",
                    "image": "http://booking.fastays.com/assets/images/logo.png",
                    "order_id": order_id, 
                    "handler": function (response){
                        vm.bookBus(response,order_id);
                    },
                    "prefill": {
                        "name": this.$store.getters.customer.name,
                        "email": this.$store.getters.customer.email,
                        "contact": this.$store.getters.customer.mobile_no
                    },
                    "theme": {
                        "color": "#5143d9"
                    }
                };
                
                var rzp1 = new Razorpay(options);
                console.log("options",rzp1);

                rzp1.on('payment.failed', function (response){
                    alert(response.error.description);
                });
                rzp1.open();
            },
         
            bookBus(response,order_id) {
                console.log("response--",response);
                let vm = this;
                let loader = vm.$loading.show();
                vm.booking.razorpay_order_id=order_id; 
                vm.booking.razorpay_payment_id=response.razorpay_payment_id;
                vm.$store.dispatch("post", { uri: "bus/book", data: vm.booking })
                .then(function (response) {
                    loader.hide();
                    if(response.data?.BookResult){
                        vm.confirm_booking.bus_result=response?.data?.BookResult;
                        vm.confirm_booking.Passenger=vm.passenger
                        if(response.data.BookResult.ResponseStatus==1) {
                            vm.$store.dispatch("setBusBooking", vm.confirm_booking);
                            vm.$router.push('/bus_booking_success');
                        }
                        else {
                            vm.$store.dispatch("error", response.data.BookResult.Error.ErrorMessage);
                        }
                    }
                    else{
                        vm.$store.dispatch("error", response?.data?.message);
                    }
                    
                })
                .catch(function (error) {
                    loader.hide();
                    vm.$store.dispatch("error", error?.response?.data?.message);
                });
            }
        
           
        },
    };
</script>

<style scoped>
.flightLine.return:before {
    content: "\f5b6";
    font-family: "Font Awesome 6 Free";
	font-weight: 900;
    left: 40%;
    transform: translate(65%, -50%);
    top: 65%;
    position: absolute;
    rotate: 360deg;
    color: #a0abb8;
}
.border-dashed {
        border: 1px dashed #ccd5de;
    }
</style>