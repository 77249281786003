<template>
  <div id="main-wrapper">
      <Header></Header>
      <div class="clearfix"></div>
      <router-view></router-view>
      <Footer></Footer>
      <a id="back2Top" class="top-scroll" title="Back to top" href="javascript:void(0)" @click.prevent="scrollToTop"><i class="fa-solid fa-sort-up"></i></a>
  </div>
</template>
<script>
  import Header from "./components/Header.vue";
  import Footer from "./components/Footer.vue";
  export default {
      name: "App",
      components: { Header, Footer },

      created() {
        //Read the status information in sessionStorage when the page is loaded
        if (sessionStorage.getItem("customer")) {
            this.$store.dispatch('setCustomer', JSON.parse(sessionStorage.getItem("customer")))
            sessionStorage.removeItem('customer')
        }
        if (sessionStorage.getItem("token")) {
          this.$store.dispatch('setToken', sessionStorage.getItem("token"))
          sessionStorage.removeItem('token')
        }
        if (sessionStorage.getItem("currency")) {
          this.$store.dispatch('setCurrency', sessionStorage.getItem("currency"))
          sessionStorage.removeItem('currency')
        }
        if (sessionStorage.getItem("bus_search")) {
          this.$store.dispatch('setBusSearch', JSON.parse(sessionStorage.getItem("bus_search")))
          sessionStorage.removeItem('bus_search')
        }
        if (sessionStorage.getItem("flight_search")) {
          this.$store.dispatch('setFlightSearch', JSON.parse(sessionStorage.getItem("flight_search")))
          sessionStorage.removeItem('flight_search')
        }
        if (sessionStorage.getItem("flight_meta")) {
          this.$store.dispatch('setFlightMeta', JSON.parse(sessionStorage.getItem("flight_meta")))
          sessionStorage.removeItem('flight_meta')
        }
        if (sessionStorage.getItem("hotel_search")) {
          this.$store.dispatch('setHotelSearch', JSON.parse(sessionStorage.getItem("hotel_search")))
          sessionStorage.removeItem('hotel_search')
        }
        if (sessionStorage.getItem("hotel")) {
          this.$store.dispatch('setHotel', JSON.parse(sessionStorage.getItem("hotel")))
          sessionStorage.removeItem('hotel')
        }
        if (sessionStorage.getItem("selected_room")) {
          this.$store.dispatch('setSelectedRoom', JSON.parse(sessionStorage.getItem("selected_room")))
          sessionStorage.removeItem('selected_room')
        }
      
        //Save the information in vuex to sessionStorage when the page is refreshed
        window.addEventListener("beforeunload", () => {
            sessionStorage.setItem("customer", JSON.stringify(this.$store?.getters?.customer));
            sessionStorage.setItem("token", this.$store?.getters?.token)
            sessionStorage.setItem("currency", this.$store?.getters?.currency)
            sessionStorage.setItem("bus_search", JSON.stringify(this.$store?.getters?.bus_search));
            sessionStorage.setItem("flight_search", JSON.stringify(this.$store?.getters?.flight_search));
            sessionStorage.setItem("flight_meta", JSON.stringify(this.$store?.getters?.flight_meta));
            sessionStorage.setItem("hotel_search", JSON.stringify(this.$store?.getters?.hotel_search));
            sessionStorage.setItem("hotel", JSON.stringify(this.$store?.getters?.hotel));
            sessionStorage.setItem("selected_room", JSON.stringify(this.$store?.getters?.selected_room));
        })
    },
  methods: {
      scrollToTop() {
          window.scrollTo({
              top: 0,
              behavior: "smooth",
          });
      },
  },
  };
</script>
<style>
  .search-wrap .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: var(--bs-body-color);
      font-weight: 700;
      line-height: 62px !important;
  }
  .select2-container--default .select2-selection--single {
      background-color: #fff;
      border: 1px solid #dadfe6 !important;
      border-radius: 4px;
  }
  textarea.form-control {
      height: 54px !important;
  }
  .fw-bold{
    font-weight: 500!important;
  }
</style>
